import {HashConnect} from 'hashconnect'
import { 
    motion,
    useAnimationControls 
  } from "framer-motion"

  import {
    AccountId,
    TokenId,
    TokenAssociateTransaction,
    TopicMessageSubmitTransaction,
    TransferTransaction,
    ScheduleSignTransaction,
    Client,
    PrivateKey,
    Hbar
} from '@hashgraph/sdk'

import {WalletConnectedNoToken,WalletConnectedTokenAssociated,GetNFTavailable,load,loss} from 'pages/Hashpack'
import { json } from 'react-router-dom';

const axios = require('axios').default;

let hashconnect = new HashConnect();
const delay = ms => new Promise(res => setTimeout(res, ms));

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}

const keyTokenId = TokenId.fromString('0.0.1385462');
const heavenaspirantTokenId = TokenId.fromString('0.0.2125331');
const infinityjarTokenId = TokenId.fromString('0.0.1881189');
const hashpackTokenId = TokenId.fromString('0.0.2173899');

const tokengatedID = hashpackTokenId;


const operatorId = AccountId.fromString("0.0.1380960");

let account_save = ""

let serial = 0;

const WLlist = [
    "0.0.1157105",
    "0.0.1266223",
    "0.0.1742830",
    "0.0.512364", 
    "0.0.585210",
    "0.0.1267298"
]

const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can exchange your Heaven Key against the Infinity jar, a jar that will give you lot of things.',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}

let connected = false;
export const pairHashpack = async () => {
    if(connected){
        return;
    }

    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic

    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = pairingData.accountIds[0];
        account_save = pairingData.accountIds[0];
        //account_save = "0.0.2180850"
        //account_save = "0.0.908390"
        //account_save = "0.0.1338071"
        connected = true;
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        CheckAccountHasHeavenAspirant();
        //checkWL(pairingData.accountIds[0])
    })
    
    return initData;
}

///api/v1/tokens/0.0.2173899/nfts?account.id=0.0.908390&order=asc&serialnumber=gt:24944
export const CheckAccountHasHeavenAspirant = async(speurl = "") => {

    if(WLlist.includes(account_save)){
        console.log("WL user");
        CheckAssociateToken();
        return;
    }

    //WalletConnectedNoToken();
    //return;

    let serialUser=[]
    let url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${tokengatedID}/nfts?account.id=${account_save}&order=asc`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    if (speurl !== "") {
        url = `https://`+mirrornodeString+`.mirrornode.hedera.com` + speurl;
    }
    let res= false;
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length == 0){
                WalletConnectedNoToken();
            }
            else{
                //CheckAssociateToken();
                for (let i = 0; i < jsonResponse.nfts.length; i++) {
                    serialUser.push(jsonResponse.nfts[i].serial_number);
                }

                console.log("serialuser length : "+serialUser.length)
                for (let i = 0; i < serialUser.length; i++) {
                    console.log("serial : "+serialUser[i])
                    if (goldserial.includes(serialUser[i].toString())) {
                        CheckAssociateToken();
                        return;
                    }
                }
                if(jsonResponse.links.next != null){
                    CheckAccountHasHeavenAspirant(jsonResponse.links.next);
                }
                else{
                    WalletConnectedNoToken();
                }
                
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});



    
    return res;
}

let nft_available = 1;

//make the diff with last Heaven Key serial(385 => 370) 370 exclu 15 Heaven Key
//make the diff with last Heaven Key serial(325 => 310) 310 exclu 15 Heaven Key
//make the diff with last Heaven Key serial(334 => 325) 325 exclu 9 Heaven Key
//make the diff with last Heaven Key serial(335 => 350) 335 exclu
//make the diff with last Heaven Key serial(330 => 335) 330 exclu
//make the diff with last Heaven Key serial(346 => 350) 335 exclu
//896,897,898,899 Heaven Aspirant (899 => 895) 895 exclu
//895,894,893,892,891,890,889,888,887,886,885,884,883,882,881 Heaven Aspirant (895 => 880) 880 exclu
export const AskSupplyAndUpdateVisual= async () => 
{
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=${operatorId}`
    //if we have a response => break transaction because we don't have NFT to give 

    let last_serial = 385;
    axios
		.get(url)
		.then(function (response) {
            const jsonResponse = response.data;

            last_serial = parseInt(jsonResponse.nfts[0]["serial_number"])
            //console.log(`${last_serial} last serial`);
            nft_available = last_serial - 370;
            //TODO to delete
            GetNFTavailable(nft_available);
            

            if(nft_available >= 0){
                //console.log(`${nft_available} NFT are still available`);
            }
            else{
                //console.log('NFT are all sold');
            }
            document.getElementById("supplyLoadingBar").style.width =(nft_available*100/15).toString()+"%";
            document.getElementById("supplyText").innerHTML = nft_available.toString()+"/15";
		})
		.catch(function (err) {
            console.log(`error: ${err}`);
		})
    await delay(1000); //5 seconds
    AskSupplyAndUpdateVisual();
}

const TimeStart = async () =>{
    const startGameDate = new Date(2023,5,16,17,0,0,0);
    const url = "https://sh71h1oegb.execute-api.eu-west-2.amazonaws.com/default/GetTime";

    console.log("startGameDate: "+startGameDate);

    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            console.log("date: "+jsonResponse);
            let month = jsonResponse.substring(0,2)
            let day = jsonResponse.substring(3,5)
            const dateAnswer = new Date(day+"/"+month+jsonResponse.substring(5));
            console.log("date format: "+dateAnswer.toString());

            let diff = startGameDate - dateAnswer
            console.log("diff: "+diff)

            if(diff<= 1000){
                WalletConnectedTokenAssociated();
            }
            else{
                startGameTimer(diff);
            }

            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
  
    //WalletConnectedTokenAssociated();
  
  }

const startGameTimer = async(diff) => {

    const response = document.getElementById('response');
    while(diff > 1000){
        
        response.innerHTML = `${formatTime(diff/1000)} seconds before the game start`;
        await delay(1000);
        diff = diff -1000
    }

    WalletConnectedTokenAssociated();

}

function formatTime(seconds) {
    var days = Math.floor(seconds / (24*60*60));
    var hours = Math.floor(seconds % (24*60*60) / (60*60));
    var minutes = Math.floor(seconds % (60*60) / 60);
    var seconds = Math.floor(seconds % 60);

    var result = "";
    if(days > 0) {
        if (days < 10) days = "0" + days;
        result += days + ":";
    }

    if(hours > 0 || days > 0) {
        if (hours < 10) hours = "0" + hours;
        result += hours + ":";
    }

    if (minutes < 10) minutes = "0" + minutes;
    result += minutes + ":";

    if (seconds < 10) seconds = "0" + seconds;
    result += seconds;

    return result;
}

export const CheckAssociateToken = async(bool) => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${account_save}/tokens?token.id=${keyTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken();
            }
            else{
                console.log(`token ${heavenaspirantTokenId} is already associated`);
                TimeStart();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

export const AssociateToken = async() => {
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, AccountId.fromString(account_save))
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(AccountId.fromString(account_save))
            .setTokenIds([keyTokenId])
            .setMaxTransactionFee(new Hbar(150))

            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            WalletConnectedTokenAssociated();
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
}

let askServer = false;
export const AskServerToSendKey = async() => {
    //post request 
    if (askServer) {
        delay(2000);
        show_text("already ask server!")
        return;
    }
    askServer = true;
    //launch anim ?

    if (nft_available <= 0) {
        loss();
        return;
    }

    console.log("walletid="+account_save);

    axios
        .get("https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+account_save+"&target=game")
        .then(function (response) {
            console.log(response);
			const jsonResponse = response.data;
            console.log(jsonResponse);
            show_text(JSON.parse(jsonResponse.replace(/'/g,'"'))["status"]);
            //OnSendKey();
            /*if (jsonResponse["status"] === "sending the key") {
                console.log("Receive Key");
                
            }*/
            
		})
		.catch(function (err) {
			console.log(err);
            alert(err.toString());
		});
}

export const show_text = (message)=>{

    if (message.includes("wallet")) {
        load();
    }
    else{
        loss();
    }

    let text = document.getElementById("text_key");
    text.className = "italic opacity-100 text-white z-50 mx-8 lg:mx-0 h-10 text-[0.8rem] lg:text-[1.2rem] mt-4 font-bold";
    text.innerHTML = message;
}

let goldserial =[
"29930",
"29929",
"29928",
"29927",
"29926",
"29925",
"29924",
"29923",
"29922",
"29921",
"29920",
"29919",
"29918",
"29930",
"29929",
"29928",
"29927",
"29926",
"29925",
"29924",
"29923",
"29922",
"29921",
"29920",
"29919",
"29918",
"29812",
"29811",
"29810",
"29809",
"29808",
"29807",
"29779",
"29778",
"29777",
"29776",
"29775",
"29750",
"29749",
"29748",
"29747",
"29746",
"29716",
"29715",
"29714",
"29713",
"29712",
"29711",
"29710",
"29709",
"29708",
"29678",
"28490",
"28462",
"28461",
"28446",
"28439",
"28433",
"28426",
"28425",
"28424",
"28419",
"28418",
"28417",
"28406",
"28405",
"28399",
"28393",
"28382",
"28374",
"28373",
"28365",
"28357",
"28356",
"28355",
"28352",
"28351",
"28350",
"28341",
"28340",
"28339",
"28316",
"28272",
"28271",
"28270",
"28260",
"28256",
"28255",
"28254",
"28243",
"28217",
"28216",
"28215",
"28213",
"28209",
"28192",
"28191",
"28176",
"28166",
"28165",
"28160",
"28150",
"28149",
"28148",
"28140",
"28133",
"28124",
"28123",
"28122",
"28121",
"28108",
"28097",
"28096",
"28095",
"28094",
"28090",
"28077",
"28076",
"28069",
"28068",
"28067",
"28062",
"28061",
"28053",
"28052",
"28049",
"28048",
"28043",
"28042",
"28038",
"28033",
"28032",
"28007",
"28006",
"28005",
"27991",
"27984",
"27978",
"27977",
"27970",
"27969",
"27968",
"27962",
"27961",
"27958",
"27952",
"27934",
"27933",
"27932",
"27924",
"27919",
"27918",
"27917",
"27912",
"27911",
"27910",
"27909",
"27880",
"27879",
"27871",
"27867",
"27862",
"27861",
"27857",
"27853",
"27852",
"27851",
"27850",
"27846",
"27845",
"27842",
"27839",
"27832",
"27827",
"27826",
"27823",
"27814",
"27809",
"27806",
"27802",
"27793",
"27792",
"27791",
"27790",
"27789",
"27782",
"27773",
"27772",
"27770",
"27769",
"27768",
"27767",
"27766",
"27765",
"27759",
"27758",
"27756",
"27755",
"27747",
"27745",
"27744",
"27743",
"27727",
"27726",
"27711",
"27705",
"27696",
"27695",
"27694",
"27680",
"27675",
"27674",
"27664",
"27659",
"27658",
"27657",
"27648",
"27640",
"27635",
"27627",
"27619",
"27618",
"27617",
"27598",
"27589",
"27580",
"27579",
"27568",
"27567",
"27557",
"27545",
"27544",
"27530",
"27528",
"27522",
"27511",
"27508",
"27498",
"27486",
"27484",
"27483",
"27482",
"27481",
"27476",
"27454",
"27453",
"27438",
"27427",
"27424",
"27423",
"27419",
"27418",
"27417",
"27414",
"27405",
"27396",
"27391",
"27384",
"27375",
"27349",
"27340",
"27339",
"27338",
"27337",
"27336",
"27328",
"27321",
"27310",
"27309",
"27304",
"27303",
"27302",
"27297",
"27289",
"27276",
"27275",
"27268",
"27267",
"27266",
"27257",
"27256",
"27241",
"27240",
"27238",
"27233",
"27226",
"27225",
"27204",
"27197",
"27196",
"27195",
"27194",
"27179",
"27162",
"27158",
"27151",
"27150",
"27142",
"27138",
"27125",
"27124",
"27123",
"27110",
"27104",
"27092",
"27091",
"27083",
"27079",
"27078",
"27050",
"27042",
"27022",
"27016",
"26995",
"26994",
"26987",
"26986",
"26960",
"26959",
"26958",
"26949",
"26937",
"26918",
"26909",
"26893",
"26885",
"26884",
"26874",
"26860",
"26859",
"26853",
"26841",
"26835",
"26834",
"26823",
"26814",
"26813",
"26803",
"26794",
"26789",
"26788",
"26775",
"26768",
"26754",
"26753",
"26748",
"26747",
"26743",
"26737",
"26732",
"26727",
"26726",
"26725",
"26718",
"26715",
"26714",
"26709",
"26700",
"26699",
"26698",
"26697",
"26696",
"26666",
"26665",
"26661",
"26660",
"26651",
"26646",
"26644",
"26643",
"26642",
"26641",
"26640",
"26639",
"26638",
"26630",
"26614",
"26613",
"26608",
"26607",
"26599",
"26587",
"26586",
"26582",
"26581",
"26568",
"26567",
"26566",
"26558",
"26557",
"26545",
"26534",
"26533",
"26523",
"26522",
"26502",
"26501",
"26500",
"26494",
"26463",
"26453",
"26452",
"26449",
"26443",
"26442",
"26441",
"26433",
"26432",
"26431",
"26428",
"26418",
"26415",
"26414",
"26403",
"26392",
"26380",
"26379",
"26378",
"26377",
"26348",
"26327",
"26326",
"26318",
"26317",
"26298",
"26297",
"26274",
"26270",
"26269",
"26268",
"26267",
"26256",
"26255",
"26254",
"26253",
"26252",
"26246",
"26245",
"26244",
"26243",
"26242",
"26241",
"26240",
"26231",
"26222",
"26217",
"26207",
"26206",
"26201",
"26200",
"26185",
"26184",
"26179",
"26178",
"26166",
"26165",
"26164",
"26163",
"26159",
"26158",
"26157",
"26150",
"26140",
"26139",
"26124",
"26123",
"26088",
"26087",
"26074",
"26055",
"26054",
"26051",
"26050",
"26046",
"26045",
"26015",
"25990",
"25975",
"25967",
"25966",
"25959",
"25958",
"25957",
"25956",
"25955",
"25945",
"25944",
"25943",
"25942",
"25932",
"25931",
"25930",
"25929",
"25928",
"25914",
"25913",
"25912",
"25911",
"25910",
"25909",
"25908",
"25898",
"25897",
"25888",
"25885",
"25884",
"25883",
"25882",
"25880",
"25879",
"25868",
"25867",
"25858",
"25857",
"25856",
"25855",
"25854",
"25845",
"25844",
"25840",
"25839",
"25822",
"25817",
"25816",
"25812",
"25811",
"25810",
"25796",
"25795",
"25794",
"25793",
"25792",
"25786",
"25785",
"25784",
"25783",
"25782",
"25781",
"25774",
"25773",
"25767",
"25766",
"25765",
"25764",
"25763",
"25762",
"25749",
"25748",
"25747",
"25743",
"25723",
"25722",
"25721",
"25720",
"25707",
"25668",
"25667",
"25666",
"25658",
"25656",
"25655",
"25654",
"25653",
"25633",
"25632",
"25631",
"25619",
"25603",
"25584",
"25583",
"25582",
"25575",
"25553",
"25539",
"25538",
"25529",
"25519",
"25518",
"25502",
"25493",
"25487",
"25481",
"25477",
"25470",
"25469",
"25448",
"25444",
"25443",
"25423",
"25422",
"25421",
"25420",
"25412",
"25411",
"25410",
"25409",
"25402",
"25395",
"25388",
"25379",
"25378",
"25375",
"25368",
"25358",
"25357",
"25356",
"25351",
"25350",
"25349",
"25344",
"25322",
"25319",
"25308",
"25306",
"25305",
"25304",
"25291",
"25290",
"25274",
"25273",
"25268",
"25267",
"25266",
"25265",
"25262",
"25254",
"25243",
"25232",
"25222",
"25218",
"25212",
"25191",
"25190",
"25189",
"25176",
"25175",
"25174",
"25173",
"25160",
"25159",
"25153",
"25140",
"25139",
"25130",
"25129",
"25128",
"25110",
"25107",
"25106",
"25101",
"25091",
"25090",
"25081",
"25071",
"25066",
"25064",
"25059",
"25056",
"25043",
"25021",
"25020",
"25019",
"25010",
"24997",
"24996",
"24994",
"24991",
"24984",
"24983",
"24979",
"24978",
"24977",
"24973",
"24971",
"24970",
"24966",
"24965",
"24958",
"24956",
"24954",
"24952",
"24949",
"24948",
"24945",
"24942",
"24941",
"24926",
"24925",
"24917",
"24916",
"24915",
"24912",
"24908",
"24905",
"24900",
"24892",
"24890",
"24884",
"24883",
"24882",
"24881",
"24872",
"24871",
"24865",
"24841",
"24840",
"24839",
"24838",
"24837",
"24828",
"24827",
"24826",
"24823",
"24822",
"24805",
"24804",
"24787",
"24775",
"24774",
"24773",
"24749",
"24748",
"24743",
"24742",
"24731",
"24730",
"24719",
"24717",
"24716",
"24715",
"24710",
"24707",
"24700",
"24699",
"24698",
"24693",
"24692",
"24691",
"24685",
"24684",
"24683",
"24671",
"24668",
"24667",
"24666",
"24653",
"24652",
"24644",
"24639",
"24638",
"24637",
"24636",
"24630",
"24629",
"24628",
"24621",
"24620",
"24616",
"24612",
"24611",
"24610",
"24608",
"24607",
"24596",
"24595",
"24594",
"24588",
"24587",
"24586",
"24580",
"24575",
"24574",
"24570",
"24564",
"24563",
"24552",
"24551",
"24550",
"24549",
"24543",
"24539",
"24535",
"24534",
"24524",
"24523",
"24522",
"24521",
"24513",
"24510",
"24509",
"24508",
"24503",
"24502",
"24499",
"24498",
"24488",
"24487",
"24483",
"24472",
"24464",
"24453",
"24452",
"24446",
"24436",
"24435",
"24426",
"24425",
"24415",
"24414",
"24413",
"24412",
"24405",
"24404",
"24403",
"24391",
"24386",
"24385",
"24384",
"24372",
"24371",
"24370",
"24369",
"24365",
"24364",
"24363",
"24362",
"24361",
"24352",
"24351",
"24350",
"24341",
"24340",
"24333",
"24332",
"24331",
"24330",
"24329",
"24323",
"24322",
"24321",
"24320",
"24307",
"24306",
"24305",
"24304",
"24303",
"24288",
"24287",
"24286",
"24285",
"24281",
"24280",
"24279",
"24278",
"24277",
"24275",
"24274",
"24270",
"24269",
"24268",
"24264",
"24263",
"24262",
"24261",
"24254",
"24253",
"24252",
"24248",
"24247",
"24246",
"24237",
"24236",
"24235",
"24229",
"24228",
"24227",
"24226",
"24210",
"24209",
"24208",
"24207",
"24194",
"24193",
"24177",
"24176",
"24163",
"24162",
"24156",
"24151",
"24119",
"24118",
"24101",
"24092",
"24091",
"24082",
"24070",
"24069",
"24068",
"24063",
"24062",
"24058",
"24055",
"24040",
"24033",
"24031",
"24021",
"24013",
"24012",
"24010",
"24009",
"24001",
"23995",
"23986",
"23980",
"23977",
"23959",
"23949",
"23938",
"23937",
"23936",
"23919",
"23902",
"23896",
"23878",
"23877",
"23876",
"23863",
"23862",
"23861",
"23850",
"23824",
"23818",
"23803",
"23802",
"23800",
"23792",
"23791",
"23790",
"23785",
"23782",
"23769",
"23768",
"23758",
"23757",
"23756",
"23755",
"23754",
"23744",
"23732",
"23731",
"23713",
"23701",
"23699",
"23698",
"23681",
"23680",
"23674",
"23673",
"23672",
"23671",
"23661",
"23647",
"23641",
"23627",
"23626",
"23588",
"23586",
"23576",
"23562",
"23561",
"23548",
"23443",
"23421",
"23415",
"23414",
"23407",
"23406",
"23405",
"23404",
"23396",
"23395",
"23394",
"23393",
"23392",
"23391",
"23388",
"23367",
"23366",
"23361",
"23354",
"23353",
"23337",
"23336",
"23297",
"23222",
"23221",
"23220",
"23200",
"23171",
"23132",
"23123",
"23116",
"23115",
"23110",
"23091",
"23083",
"23061",
"23047",
"23033",
"23022",
"23012",
"23001",
"22992",
"22983",
"22966",
"22962",
"22958",
"22957",
"22950",
"22949",
"22874",
"22850",
"22849",
"22826",
"22771",
"22761",
"22760",
"22676",
"22675",
"22663",
"22662",
"22651",
"22649",
"22613",
"22581",
"22574",
"22573",
"22572",
"22558",
"22553",
"22552",
"22543",
"22521",
"22495",
"22484",
"22476",
"22463",
"22461",
"22457",
"22454",
"22438",
"22424",
"22418",
"22417",
"22416",
"22412",
"22401",
"22399",
"22393",
"22386",
"22384",
"22383",
"22372",
"22369",
"22359",
"22354",
"22352",
"22335",
"22330",
"22314",
"22304",
"22303",
"22273",
"22269",
"22264",
"22243",
"22237",
"22231",
"22230",
"22225",
"22217",
"22210",
"22205",
"22200",
"22186",
"22185",
"22162",
"22158",
"22157",
"22150",
"22136",
"22127",
"22118",
"22117",
"22109",
"22096",
"22095",
"22094",
"22088",
"22081",
"22080",
"22079",
"22068",
"22067",
"22065",
"22049",
"22042",
"22040",
"22032",
"22025",
"22017",
"22016",
"22015",
"22007",
"22006",
"21991",
"21990",
"21988",
"21987",
"21977",
"21976",
"21966",
"21955",
"21954",
"21941",
"21931",
"21930",
"21917",
"21916",
"21915",
"21914",
"21893",
"21873",
"21872",
"21871",
"21863",
"21860",
"21841",
"21815",
"21782",
"21729",
"21728",
"21709",
"21679",
"21667",
"21654",
"21642",
"21641",
"21640",
"21631",
"21630",
"21629",
"21594",
"21592",
"21591",
"21585",
"21582",
"21581",
"21572",
"21561",
"21549",
"21546",
"21545",
"21536",
"21524",
"21523",
"21505",
"21504",
"21490",
"21489",
"21488",
"21479",
"21478",
"21477",
"21468",
"21467",
"21460",
"21446",
"21445",
"21415",
"21381",
"21341",
"21275",
"21274",
"21242",
"21234",
"21219",
"21159",
"21150",
"21119",
"21088",
"21087",
"21057",
"21056",
"21036",
"21024",
"21020",
"21012",
"20986",
"20971",
"20956",
"20955",
"20913",
"20877",
"20729",
"20728",
"20692",
"20686",
"20685",
"20676",
"20675",
"20665",
"20654",
"20634",
"20625",
"20624",
"20614",
"20580",
"20568",
"20567",
"20536",
"20514",
"20470",
"20469",
"20444",
"20443",
"20430",
"20429",
"20425",
"20411",
"20410",
"20409",
"20389",
"20388",
"20376",
"20375",
"20349",
"20348",
"20304",
"20296",
"20273",
"20271",
"20270",
"20269",
"20203",
"20183",
"20176",
"20171",
"20163",
"20162",
"20152",
"20144",
"20109",
"20073",
"20069",
"20068",
"20043",
"20030",
"20029",
"20022",
"19997",
"19990",
"19974",
"19973",
"19960",
"19959",
"19958",
"19948",
"19947",
"19946",
"19940",
"19936",
"19932",
"19921",
"19912",
"19910",
"19880",
"19879",
"19841",
"19830",
"19803",
"19802",
"19789",
"19772",
"19771",
"19752",
"19698",
"19688",
"19687",
"19677",
"19666",
"19662",
"19661",
"19660",
"19648",
"19647",
"19646",
"19645",
"19639",
"19635",
"19626",
"19622",
"19618",
"19609",
"19608",
"19607",
"19596",
"19585",
"19575",
"19567",
"19545",
"19544",
"19543",
"19521",
"19505",
"19504",
"19503",
"19496",
"19479",
"19478",
"19454",
"19429",
"19428",
"19396",
"19390",
"19389",
"19388",
"19387",
"19369",
"19368",
"19352",
"19343",
"19275",
"19274",
"19266",
"19265",
"19258",
"19257",
"19256",
"19244",
"19243",
"19213",
"19191",
"19190",
"19153",
"19106",
"19105",
"19104",
"19079",
"19075",
"19074",
"19067",
"19048",
"19039",
"19025",
"18996",
"18974",
"18973",
"18960",
"18932",
"18931",
"18924",
"18913",
"18912",
"18903",
"18887",
"18878",
"18871",
"18859",
"18849",
"18842",
"18832",
"18831",
"18824",
"18819",
"18807",
"18790",
"18761",
"18749",
"18748",
"18747",
"18742",
"18741",
"18731",
"18689",
"18635",
"18606",
"18598",
"18585",
"18570",
"18569",
"18557",
"18556",
"18550",
"18549",
"18540",
"18532",
"18525",
"18515",
"18491",
"18464",
"18451",
"18441",
"18440",
"18397",
"18396",
"18395",
"18383",
"18382",
"18381",
"18373",
"18372",
"18363",
"18357",
"18348",
"18347",
"18337",
"18336",
"18326",
"18325",
"18324",
"18312",
"18311",
"18303",
"18291",
"18290",
"18279",
"18278",
"18268",
"18248",
"18247",
"18246",
"18192",
"18185",
"18177",
"18174",
"18173",
"18172",
"18154",
"18153",
"18134",
"18133",
"18123",
"18107",
"18106",
"18096",
"18091",
"18090",
"18078",
"18077",
"18055",
"18036",
"18035",
"18014",
"18013",
"18012",
"18011",
"17994",
"17984",
"17973",
"17964",
"17963",
"17962",
"17961",
"17960",
"17959",
"17958",
"17934",
"17904",
"17883",
"17882",
"17881",
"17880",
"17833",
"17818",
"17817",
"17816",
"17795",
"17794",
"17780",
"17752",
"17751",
"17750",
"17739",
"17731",
"17730",
"17722",
"17721",
"17720",
"17719",
"17704",
"17691",
"17680",
"17667",
"17645",
"17631",
"17630",
"17623",
"17622",
"17621",
"17605",
"17593",
"17592",
"17588",
"17574",
"17563",
"17552",
"17531",
"17519",
"17518",
"17511",
"17498",
"17488",
"17487",
"17486",
"17470",
"17469",
"17438",
"17414",
"17413",
"17412",
"17387",
"17386",
"17385",
"17384",
"17363",
"17354",
"17340",
"17339",
"17331",
"17330",
"17329",
"17304",
"17303",
"17302",
"17301",
"17300",
"17299",
"17284",
"17267",
"17241",
"17240",
"17239",
"17238",
"17237",
"17224",
"17223",
"17222",
"17153",
"17152",
"17137",
"17127",
"17115",
"17076",
"17075",
"17055",
"17039",
"17038",
"17037",
"17036",
"17026",
"17025",
"17009",
"17002",
"16995",
"16993",
"16984",
"16964",
"16953",
"16932",
"16922",
"16910",
"16909",
"16889",
"16888",
"16887",
"16872",
"16866",
"16865",
"16854",
"16834",
"16824",
"16816",
"16815",
"16785",
"16766",
"16765",
"16764",
"16763",
"16762",
"16761",
"16760",
"16688",
"16687",
"16666",
"16665",
"16656",
"16640",
"16639",
"16629",
"16628",
"16596",
"16595",
"16572",
"16571",
"16556",
"16555",
"16546",
"16538",
"16537",
"16536",
"16535",
"16521",
"16520",
"16490",
"16489",
"16480",
"16479",
"16475",
"16474",
"16466",
"16465",
"16453",
"16441",
"16440",
"16439",
"16438",
"16424",
"16419",
"16418",
"16417",
"16416",
"16396",
"16395",
"16361",
"16349",
"16348",
"16347",
"16346",
"16345",
"16340",
"16339",
"16329",
"16328",
"16323",
"16318",
"16282",
"16276",
"16275",
"16262",
"16256",
"16255",
"16248",
"16247",
"16241",
"16232",
"16225",
"16207",
"16196",
"16184",
"16183",
"16159",
"16158",
"16157",
"16137",
"16136",
"16135",
"16126",
"16116",
"16115",
"16114",
"16097",
"16092",
"16080",
"16079",
"16078",
"16077",
"16076",
"16054",
"16053",
"16052",
"16049",
"16037",
"16025",
"16024",
"16016",
"15997",
"15996",
"15988",
"15987",
"15986",
"7138",
"7137",
"7136",
"7135",
"7134",
"7133",
"7085",
"7084",
"7083",
"7082",
"7081",
"7080",
"7079",
"7078",
"7077",
"7076",
"7075",
"7074",
"7073",
"7072",
"7071",
"7070",
"7069",
"7068",
"7067",
"7066",
"7065",
"7064",
"7063",
"7062",
"6970",
"6969",
"6968",
"6967",
"6966",
"6932",
"6931",
"6930",
"6929",
"6928",
"6927",
"6926",
"6925",
"6879",
"6866",
"6865",
"6864",
"6863",
"6862",
"6861",
"6860",
"6844",
"6843",
"6825",
"6824",
"6823",
"6822",
"6821",
"6820",
"6819",
"6818",
"6817",
"6816",
"6815",
"6814",
"6813",
"6812",
"6811",
"6810",
"6772",
"6762",
"6761",
"6746",
"6745",
"6744",
"6743",
"6735",
"6734",
"6733",
"6715",
"6714",
"6713",
"6712",
"6711",
"6710",
"6709",
"6708",
"6707",
"6703",
"6702",
"6694",
"6693",
"6686",
"6685",
"6684",
"6683",
"6677",
"6673",
"6672",
"6666",
"6665",
"6663",
"6662",
"6658",
"6651",
"6650",
"6649",
"6648",
"6639",
"6638",
"6637",
"6633",
"6632",
"6631",
"6630",
"6629",
"6628",
"6624",
"6621",
"6613",
"6612",
"6609",
"6608",
"6607",
"6606",
"6605",
"6604",
"6602",
"6592",
"6591",
"6590",
"6589",
"6563",
"6557",
"6556",
"6555",
"6554",
"6553",
"6552",
"6551",
"6547",
"6546",
"6543",
"6542",
"6538",
"6537",
"6536",
"6530",
"6527",
"6526",
"6525",
"6524",
"6523",
"6522",
"6521",
"6508",
"6503",
"6500",
"6499",
"6498",
"6497",
"6496",
"6495",
"6494",
"6493",
"6484",
"6483",
"6482",
"6481",
"6480",
"6479",
"6478",
"6477",
"6476",
"6475",
"6474",
"6473",
"6472",
"6471",
"6470",
"6469",
"6468",
"6467",
"6466",
"6465",
"6464",
"6463",
"6462",
"6461",
"6460",
"6459",
"6458",
"6457",
"6456",
"6455",
"6454",
"6453",
"6452",
"6451",
"6450",
"6449",
"6448",
"6447",
"6446",
"6445",
"6444",
"6443",
"6442",
"6441",
"6440",
"6439",
"6438",
"6437",
"6436",
"6435",
"6434",
"6433",
"6432",
"6431",
"6430",
"6429",
"6428",
"6427",
"6426",
"6425",
"6424",
"6423",
"6422",
"6421",
"6420",
"6419",
"6418",
"6417",
"6416",
"6415",
"6414",
"6413",
"6412",
"6411",
"6410",
"6409",
"6408",
"6407",
"6406",
"6405",
"6404",
"6403",
"6402",
"6401",
"6400",
"6399",
"6398",
"6397",
"6396",
"6395",
"6394",
"6393",
"6392",
"6391",
"6390",
"6389",
"6388",
"6387",
"6386",
"6385",
"6384",
"6383",
"6382",
"6381",
"6380",
"6379",
"6378",
"6377",
"6376",
"6375",
"6374",
"6373",
"6372",
"6371",
"6370",
"6369",
"6368",
"6367",
"6366",
"6365",
"6364",
"6363",
"6362",
"6361",
"6360",
"6359",
"6358",
"6357",
"6356",
"6355",
"6354",
"6353",
"6352",
"6351",
"6350",
"6349",
"6348",
"6347",
"6346",
"6345",
"6344",
"6343",
"6342",
"6341",
"6340",
"6339",
"6338",
"6337",
"6336",
"6335",
"6334",
"6333",
"6332",
"6331",
"6330",
"6329",
"6328",
"6327",
"6326",
"6325",
"6324",
"6323",
"6322",
"6321",
"6320",
"6319",
"6318",
"6317",
"6316",
"6315",
"6314",
"6313",
"6312",
"6311",
"6310",
"6309",
"6308",
"6307",
"6306",
"6305",
"6304",
"6303",
"6302",
"6301",
"6300",
"6299",
"6298",
"6297",
"6296",
"6295",
"6294",
"6293",
"6292",
"6291",
"6290",
"6289",
"6288",
"6287",
"6286",
"6285",
"6284",
"6283",
"6282",
"6281",
"6280",
"6279",
"6278",
"6277",
"6276",
"6275",
"6274",
"6273",
"6272",
"6271",
"6270",
"6269",
"6268",
"6267",
"6266",
"6265",
"6264",
"6263",
"6262",
"6261",
"6260",
"6259",
"6258",
"6257",
"6256",
"6255",
"6254",
"6253",
"6252",
"6251",
"6250",
"6249",
"6248",
"6247",
"6246",
"6245",
"6244",
"6243",
"6242",
"6241",
"6240",
"6239",
"6238",
"6237",
"6236",
"6235",
"6234",
"6233",
"6232",
"6231",
"6230",
"6229",
"6228",
"6227",
"6226",
"6225",
"6224",
"6223",
"6222",
"6221",
"6220",
"6219",
"6218",
"6217",
"6216",
"6215",
"6214",
"6213",
"6212",
"6211",
"6210",
"6209",
"6208",
"6207",
"6206",
"6205",
"6204",
"6203",
"6202",
"6201",
"6200",
"6199",
"6198",
"6197",
"6196",
"6195",
"6194",
"6193",
"6192",
"6191",
"6190",
"6189",
"6188",
"6187",
"6186",
"6185",
"6184",
"6183",
"6182",
"6181",
"6180",
"6179",
"6178",
"6177",
"6176",
"6175",
"6174",
"6173",
"6172",
"6171",
"6170",
"6169",
"6168",
"6167",
"6166",
"6165",
"6164",
"6163",
"6162",
"6161",
"6160",
"6159",
"6158",
"6157",
"6156",
"6155",
"6154",
"6153",
"6152",
"6151",
"6150",
"6149",
"6148",
"6147",
"6146",
"6145",
"6144",
"6143",
"6142",
"6141",
"6140",
"6139",
"6138",
"6137",
"6136",
"6135",
"6134",
"6133",
"6132",
"6131",
"6130",
"6129",
"6128",
"6127",
"6126",
"6125",
"6124",
"6123",
"6122",
"6121",
"6120",
"6119",
"6118",
"6117",
"6116",
"6115",
"6114",
"6113",
"6112",
"6111",
"6110",
"6109",
"6108",
"6107",
"6106",
"6105",
"6104",
"6103",
"6102",
"6101",
"6100",
"6099",
"6098",
"6097",
"6096",
"6095",
"6094",
"6093",
"6092",
"6091",
"6090",
"6089",
"6088",
"6087",
"6086",
"6085",
"6084",
"6083",
"6082",
"6081",
"6080",
"6079",
"6078",
"6077",
"6076",
"6075",
"6074",
"6073",
"6072",
"6071",
"6070",
"6069",
"6068",
"6067",
"6066",
"6065",
"6064",
"6063",
"6062",
"6061",
"6060",
"6059",
"6058",
"6057",
"6056",
"6055",
"6054",
"6053",
"6052",
"6051",
"6050",
"6049",
"6048",
"6047",
"6046",
"6045",
"6044",
"6043",
"6042",
"6041",
"6040",
"6039",
"6038",
"6037",
"6036",
"6035",
"6034",
"6033",
"6032",
"6031",
"6030",
"6029",
"6028",
"6027",
"6026",
"6025",
"6024",
"6023",
"6022",
"6021",
"6020",
"6019",
"6018",
"6017",
"6016",
"6015",
"6014",
"6013",
"6012",
"6011",
"6010",
"6009",
"6008",
"6007",
"6006",
"6005",
"6004",
"6003",
"6002",
"6001",
"6000",
"5999",
"5998",
"5997",
"5996",
"5995",
"5994",
"5993",
"5992",
"5991",
"5990",
"5989",
"5988",
"5987",
"5986",
"5985",
"5984",
"5983",
"5982",
"5981",
"5980",
"5979",
"5978",
"5977",
"5976",
"5975",
"5974",
"5973",
"5972",
"5971",
"5970",
"5969",
"5968",
"5967",
"5966",
"5965",
"5964",
"5963",
"5962",
"5961",
"5960",
"5959",
"5958",
"5957",
"5956",
"5955",
"5954",
"5953",
"5952",
"5951",
"5950",
"5949",
"5948",
"5947",
"5946",
"5945",
"5944",
"5943",
"5942",
"5941",
"5940",
"5939",
"5938",
"5937",
"5936",
"5935",
"5934",
"5933",
"5932",
"5931",
"5930",
"5929",
"5928",
"5927",
"5926",
"5925",
"5924",
"5923",
"5922",
"5921",
"5920",
"5919",
"5918",
"5917",
"5916",
"5915",
"5914",
"5913",
"5912",
"5911",
"5910",
"5909",
"5908",
"5907",
"5906",
"5905",
"5904",
"5903",
"5902",
"5901",
"5900",
"5899",
"5898",
"5897",
"5896",
"5895",
"5894",
"5893",
"5892",
"5891",
"5890",
"5889",
"5888",
"5887",
"5886",
"5885",
"5884",
"5883",
"5882",
"5881",
"5880",
"5879",
"5878",
"5877",
"5876",
"5875",
"5874",
"5873",
"5872",
"5871",
"5870",
"5869",
"5868",
"5867",
"5866",
"5865",
"5864",
"5863",
"5862",
"5861",
"5860",
"5859",
"5858",
"5857",
"5856",
"5855",
"5854",
"5853",
"5852",
"5851",
"5850",
"5849",
"5848",
"5847",
"5846",
"5845",
"5844",
"5843",
"5842",
"5841",
"5840",
"5839",
"5838",
"5837",
"5836",
"5835",
"5834",
"5833",
"5832",
"5831",
"5830",
"5829",
"5828",
"5827",
"5826",
"5825",
"5824",
"5823",
"5822",
"5821",
"5820",
"5819",
"5818",
"5817",
"5816",
"5815",
"5814",
"5813",
"5812",
"5811",
"5810",
"5809",
"5808",
"5807",
"5806",
"5805",
"5804",
"5803",
"5802",
"5801",
"5800",
"5799",
"5798",
"5797",
"5796",
"5795",
"5794",
"5793",
"5792",
"5791",
"5790",
"5789",
"5788",
"5787",
"5786",
"5785",
"5784",
"5783",
"5782",
"5781",
"5780",
"5779",
"5778",
"5777",
"5776",
"5775",
"5774",
"5773",
"5772",
"5771",
"5770",
"5769",
"5768",
"5767",
"5766",
"5765",
"5764",
"5763",
"5762",
"5761",
"5760",
"5759",
"5758",
"5757",
"5756",
"5755",
"5754",
"5753",
"5752",
"5751",
"5750",
"5749",
"5748",
"5747",
"5746",
"5745",
"5744",
"5743",
"5742",
"5741",
"5740",
"5739",
"5738",
"5737",
"5736",
"5735",
"5734",
"5733",
"5732",
"5731",
"5730",
"5729",
"5728",
"5727",
"5726",
"5725",
"5724",
"5723",
"5722",
"5721",
"5720",
"5719",
"5718",
"5717",
"5716",
"5715",
"5714",
"5713",
"5712",
"5711",
"5710",
"5709",
"5708",
"5707",
"5706",
"5705",
"5704",
"5703",
"5702",
"5701",
"5700",
"5699",
"5698",
"5697",
"5696",
"5695",
"5694",
"5693",
"5692",
"5691",
"5690",
"5689",
"5688",
"5687",
"5686",
"5685",
"5684",
"5683",
"5682",
"5681",
"5680",
"5679",
"5678",
"5677",
"5676",
"5675",
"5674",
"5673",
"5672",
"5671",
"5670",
"5669",
"5668",
"5667",
"5666",
"5665",
"5664",
"5663",
"5662",
"5661",
"5660",
"5659",
"5658",
"5657",
"5656",
"5655",
"5654",
"5653",
"5652",
"5651",
"5650",
"5649",
"5648",
"5647",
"5646",
"5645",
"5644",
"5643",
"5642",
"5641",
"5640",
"5639",
"5638",
"5637",
"5636",
"5635",
"5634",
"5633",
"5632",
"5631",
"5630",
"5629",
"5628",
"5627",
"5626",
"5625",
"5624",
"5623",
"5622",
"5621",
"5620",
"5619",
"5618",
"5617",
"5616",
"5615",
"5614",
"5613",
"5612",
"5611",
"5610",
"5609",
"5608",
"5607",
"5606",
"5605",
"5604",
"5603",
"5602",
"5601",
"5600",
"5599",
"5598",
"5597",
"5596",
"5595",
"5594",
"5593",
"5592",
"5591",
"5590",
"5589",
"5588",
"5587",
"5586",
"5585",
"5584",
"5583",
"5582",
"5581",
"5580",
"5579",
"5578",
"5577",
"5576",
"5575",
"5574",
"5573",
"5572",
"5571",
"5570",
"5569",
"5568",
"5567",
"5566",
"5565",
"5564",
"5563",
"5562",
"5561",
"5560",
"5559",
"5558",
"5557",
"5556",
"5555",
"5554",
"5553",
"5552",
"5551",
"5550",
"5549",
"5548",
"5547",
"5546",
"5545",
"5544",
"5543",
"5542",
"5541",
"5540",
"5539",
"5538",
"5537",
"5536",
"5535",
"5534",
"5533",
"5532",
"5531",
"5530",
"5529",
"5528",
"5527",
"5526",
"5525",
"5524",
"5523",
"5522",
"5521",
"5520",
"5519",
"5518",
"5517",
"5516",
"5515",
"5514",
"5513",
"5512",
"5511",
"5510",
"5509",
"5508",
"5507",
"5506",
"5505",
"5504",
"5503",
"5502",
"5501",
"5500",
"5499",
"5498",
"5497",
"5496",
"5495",
"5494",
"5493",
"5492",
"5491",
"5490",
"5489",
"5488",
"5487",
"5486",
"5485",
"5484",
"5483",
"5482",
"5481",
"5480",
"5479",
"5478",
"5477",
"5476",
"5475",
"5474",
"5473",
"5472",
"5471",
"5470",
"5469",
"5468",
"5467",
"5466",
"5465",
"5464",
"5463",
"5462",
"5461",
"5460",
"5459",
"5458",
"5457",
"5456",
"5455",
"5454",
"5453",
"5452",
"5451",
"5450",
"5449",
"5448",
"5447",
"5446",
"5445",
"5444",
"5443",
"5442",
"5441",
"5440",
"5439",
"5438",
"5437",
"5436",
"5435",
"5434",
"5433",
"5432",
"5431",
"5430",
"5429",
"5428",
"5427",
"5426",
"5425",
"5424",
"5423",
"5422",
"5421",
"5420",
"5419",
"5418",
"5417",
"5416",
"5415",
"5414",
"5413",
"5412",
"5411",
"5410",
"5409",
"5408",
"5407",
"5406",
"5405",
"5404",
"5403",
"5402",
"5401",
"5400",
"5399",
"5398",
"5397",
"5396",
"5395",
"5394",
"5393",
"5392",
"5391",
"5390",
"5389",
"5388",
"5387",
"5386",
"5385",
"5384",
"5383",
"5382",
"5381",
"5380",
"5379",
"5378",
"5377",
"5376",
"5375",
"5374",
"5373",
"5372",
"5371",
"5370",
"5369",
"5368",
"5367",
"5366",
"5365",
"5364",
"5363",
"5362",
"5361",
"5360",
"5359",
"5358",
"5357",
"5356",
"5355",
"5354",
"5353",
"5352",
"5351",
"5350",
"5349",
"5348",
"5347",
"5346",
"5345",
"5344",
"5343",
"5342",
"5341",
"5340",
"5339",
"5338",
"5337",
"5336",
"5335",
"5334",
"5333",
"5332",
"5331",
"5330",
"5329",
"5328",
"5327",
"5326",
"5325",
"5324",
"5323",
"5322",
"5321",
"5320",
"5319",
"5318",
"5317",
"5316",
"5315",
"5314",
"5313",
"5312",
"5311",
"5310",
"5309",
"5308",
"5307",
"5306",
"5305",
"5304",
"5303",
"5302",
"5301",
"5300",
"5299",
"5298",
"5297",
"5296",
"5295",
"5294",
"5293",
"5292",
"5291",
"5290",
"5289",
"5288",
"5287",
"5286",
"5285",
"5284",
"5283",
"5282",
"5281",
"5280",
"5279",
"5278",
"5277",
"5276",
"5275",
"5274",
"5273",
"5272",
"5271",
"5270",
"5269",
"5268",
"5267",
"5266",
"5265",
"5264",
"5263",
"5262",
"5261",
"5260",
"5259",
"5258",
"5257",
"5256",
"5255",
"5254",
"5253",
"5252",
"5251",
"5250",
"5249",
"5248",
"5247",
"5246",
"5245",
"5244",
"5243",
"5242",
"5241",
"5240",
"5239",
"5238",
"5237",
"5236",
"5235",
"5234",
"5233",
"5232",
"5231",
"5230",
"5229",
"5228",
"5227",
"5226",
"5225",
"5224",
"5223",
"5222",
"5221",
"5220",
"5219",
"5218",
"5217",
"5216",
"5215",
"5214",
"5213",
"5212",
"5211",
"5210",
"5209",
"5208",
"5207",
"5206",
"5205",
"5204",
"5203",
"5202",
"5201",
"5200",
"5199",
"5198",
"5197",
"5196",
"5195",
"5194",
"5193",
"5192",
"5191",
"5190",
"5189",
"5188",
"5187",
"5186",
"5185",
"5184",
"5183",
"5182",
"5181",
"5180",
"5179",
"5178",
"5177",
"5176",
"5175",
"5174",
"5173",
"5172",
"5171",
"5170",
"5169",
"5168",
"5167",
"5166",
"5165",
"5164",
"5163",
"5162",
"5161",
"5160",
"5159",
"5158",
"5157",
"5156",
"5155",
"5154",
"5153",
"5152",
"5151",
"5150",
"5149",
"5148",
"5147",
"5146",
"5145",
"5144",
"5143",
"5142",
"5141",
"5140",
"5139",
"5138",
"5137",
"5136",
"5135",
"5134",
"5133",
"5132",
"5131",
"5130",
"5129",
"5128",
"5127",
"5126",
"5125",
"5124",
"5123",
"5122",
"5121",
"5120",
"5119",
"5118",
"5117",
"5116",
"5115",
"5114",
"5113",
"5112",
"5111",
"5110",
"5109",
"5108",
"5107",
"5106",
"5105",
"5104",
"5103",
"5102",
"5101",
"5100",
"5099",
"5098",
"5097",
"5096",
"5095",
"5094",
"5093",
"5092",
"5091",
"5090",
"5089",
"5088",
"5087",
"5086",
"5085",
"5084",
"5083",
"5082",
"5081",
"5080",
"5079",
"5078",
"5077",
"5076",
"5075",
"5074",
"5073",
"5072",
"5071",
"5070",
"5069",
"5068",
"5067",
"5066",
"5065",
"5064",
"5063",
"5062",
"5061",
"5060",
"5059",
"5058",
"5057",
"5056",
"5055",
"5054",
"5053",
"5052",
"5051",
"5050",
"5049",
"5048",
"5047",
"5046",
"5045",
"5044",
"5043",
"100",
"99",
"98",
"97",
"96",
"95",
"94",
"93",
"92",
"91",
"90",
"89",
"88",
"87",
"86",
"85",
"84",
"83",
"82",
"81",
"80",
"79",
"78",
"77",
"76",
"75",
"74",
"73",
"72",
"71",
"70",
"69",
"68",
"67",
"66",
"65",
"64",
"63",
"62",
"61",
"60",
"59",
"58",
"57",
"56",
"5",
"4",
"3",
"2",
"1"
]