import React, { useEffect, useRef, useState } from "react";
import { pairHashpack, OnClickGetKey } from "./DropManagement";
import "../../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";

let controlsButtonUseKey = "";
let controlsKeyHoleJar = "";
let controlsSceaux = "";
let controlsScrollLeft = "";
let controlsScrollRight = "";
let controlsScrollMiddle = "";

let infinityJar = "";
let scroll = "";
let scrollCoin = "";
let flash = "";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

let account;
let useMagicLink;
let mail_info;

export const OnSendKey = async () => {
  //load();
  const realGetKey = document.getElementById("buttonGetHK");
  realGetKey.className = "hidden";

  const scroll_img = document.getElementById("scroll");
  const scroll_coin = document.getElementById("scrollCoin");

  //await delay(3000);
  text_anim();
  await delay(3000);

  controlsSceaux.start({ y: "0vw", transition: { duration: 1 } });
  controlsScrollLeft.start({ y: "0vw", transition: { duration: 1 } });
  controlsScrollRight.start({ y: "0vw", transition: { duration: 1 } });

  await delay(1000);

  controlsSceaux.start({ scale: 1.5, opacity: 0, transition: { duration: 2 } });

  await delay(2000);

  controlsScrollLeft.start({ x: "-5vw", transition: { duration: 2 } });
  controlsScrollRight.start({ x: "5vw", transition: { duration: 2 } });
  controlsScrollMiddle.start({ opacity: 1, transition: { duration: 2 } });

  await delay(1500);
  flash.start({ opacity: 1, transition: { duration: 0.2 } });
  await delay(200);

  controlsScrollLeft.start({ opacity: 0, transition: { duration: 0.1 } });
  controlsScrollRight.start({ opacity: 0, transition: { duration: 0.1 } });
  controlsScrollMiddle.start({ opacity: 0, transition: { duration: 0.1 } });

  scroll.start({ scale: 1.2, transition: { duration: 0.1 } });
  scrollCoin.start({ scale: 1.2, transition: { duration: 0.1 } });

  await delay(100);
  flash.start({ opacity: 0, transition: { duration: 0.1 } });

  scroll.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 1,
    },
  });
  scrollCoin.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 5,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 1,
    },
  });

  //controlsHeavenKey.start({y:["0.5vw","-0.5vw","0.5vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
};

const text_anim = async () => {
  const response = document.getElementById("response");
  response.className = "hidden";
  await delay(1000);
  response.className =
    "italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold";
  response.innerHTML = "3";
  await delay(1000);
  response.innerHTML = "2";
  await delay(1000);
  response.innerHTML = "1";
  await delay(1000);
  response.innerHTML =
    "The Heaven of Legends is more than just a battleground for Legends";
  await delay(3000);
  response.innerHTML =
    "You'll have access to unique places like The Forge or The Academy";
  await delay(3000);
  response.innerHTML = "When you step into these realms...";
  await delay(3000);
  response.innerHTML = "A few coins in your pockets might come in handy 🎁";
  await delay(3000);
  response.innerHTML =
    "Make sure to follow our X / Discord to know when to use your coins.";
  if (useMagicLink) {
    const dialog = document.getElementById("dialog");
    const mail = document.getElementById("mail");
    const wallet = document.getElementById("wallet");
    wallet.innerHTML = `wallet number: ${account}`;
    mail.innerHTML = `mail: ${mail_info}`;
    dialog.className =
      "absolute rounded-xl w-[90%] lg:w-[50%] bg-[#25191E] m-auto flex flex-col justify-center gap-3 h-[50%] z-[100] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2";
  }
};

export const load = async () => {
  let confetti = import("../../components/confetti"); // Module loaded (export default)!
};

export const init_animation = async () => {
  console.log("InitAnimation");
  controlsSceaux.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 1,
    },
  });
  controlsScrollLeft.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 1,
    },
  });
  controlsScrollRight.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 1,
    },
  });

  await delay(1000);
  OnSendKey();

  /*infinityJar.start({rotate:[-2, 2, -2],
    transition: {
      repeat: Infinity,
      duration: 0.4
    }})*/
};

export const enterHeavenOfLegends = async () => {};

const Mint = () => {
  controlsButtonUseKey = useAnimationControls();
  controlsKeyHoleJar = useAnimationControls();
  controlsSceaux = useAnimationControls();
  controlsScrollLeft = useAnimationControls();
  controlsScrollRight = useAnimationControls();
  controlsScrollMiddle = useAnimationControls();

  infinityJar = useAnimationControls();
  scroll = useAnimationControls();
  scrollCoin = useAnimationControls();
  flash = useAnimationControls();

  const [pairingString, setPairingString] = useState();
  const [isCoin, setIsCoin] = useState(false);
  const controlsHeavenKey = useAnimationControls();

  useEffect(() => {
    let url = window.location.search;
    const urlParams = new URLSearchParams(url);
    urlParams.get("iscoin") === "true" ? setIsCoin(true) : setIsCoin(false);

    useMagicLink = urlParams.get("magiclink") === "true";
    if (useMagicLink) {
      account = urlParams.get("account");
      mail_info = urlParams.get("email");
    }
    init_animation();
  }, []);

  console.log(isCoin);
  console.log(typeof isCoin);
  return (
    <section className="overflow-hidden">
      <div className="App">
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
          <canvas
            className="confetti absolute h-screen w-screen z-10"
            id="canvas"
          ></canvas>
          {/* Header & Social networks */}
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a>
            <h2 className="absolute text-white uppercase text-shadow-blue-low-mobile  italic font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50">
              Welcoming mortals to LOP and Hedera
            </h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
            {pairingString && <p>{pairingString}</p>}
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="italic px-4 lg:px-16 py-1 lg:py-2 parrallelogram absolute z-50 bg-[#8626EC] hover:bg-[#8611EC] top-1.5 right-2 lg:right-10 lg:-top-16"
              onClick={async () => {
                const saveData = await pairHashpack();
                //setPairingString(saveData.pairingString)
              }}
            >
              <span
                className="text-radial-gradient text-[0.5rem] lg:text-xl font-bold "
                id="accountid"
              >
                Connect wallet
              </span>
            </motion.button>
          </figure>

          <figure className="absolute z-20 -bottom-20 -right-20">
            <img src="/assets/trame.svg" alt="" />
          </figure>
          <motion.img
            animate={controlsScrollMiddle}
            initial={{ y: "0vw", opacity: 0 }}
            src="/assets/Scroll_Coin/scroll_middle_naked.png"
            className="
                    h-[20rem]
                    lg:h-[14rem]
                    2xl:h-[30rem] 
                    3xl:h-[35rem] 
                    z-10 absolute 
                    m-auto 
                    left-0 
                    right-0 
                    top-0
                    bottom-0"
          ></motion.img>
          <motion.img
            animate={controlsScrollLeft}
            initial={{ y: "0vw" }}
            src="/assets/scroll_left.png"
            className="
                    h-[20rem]
                    lg:h-[14rem]
                    2xl:h-[30rem] 
                    3xl:h-[35rem] 
                    z-10 absolute 
                    m-auto 
                    left-0 
                    right-0 
                    top-0
                    bottom-0"
          ></motion.img>
          <motion.img
            animate={controlsScrollRight}
            initial={{ y: "0vw" }}
            src="/assets/scroll_right.png"
            className="
                    h-[20rem]
                    lg:h-[14rem]
                    2xl:h-[30rem] 
                    3xl:h-[35rem] 
                    z-10 absolute 
                    m-auto 
                    left-0 
                    right-0 
                    top-0
                    bottom-0"
          ></motion.img>
          <motion.img
            animate={controlsSceaux}
            initial={{ y: "0vw" }}
            src="/assets/sceaux.png"
            className="
                    h-[20rem]
                    lg:h-[14rem]
                    2xl:h-[30rem] 
                    3xl:h-[35rem] 
                    z-10 absolute 
                    m-auto 
                    left-0 
                    right-0 
                    top-0
                    bottom-0"
          ></motion.img>
          <motion.img
            id="scroll"
            animate={scroll}
            initial={{ scale: 0 }}
            src="/assets/Scroll_Coin/scroll_coin_full.png"
            className="
                    h-[20rem]
                    lg:h-[20rem]
                    2xl:h-[30rem] 
                    3xl:h-[35rem] 
                    z-0 absolute 
                    m-auto 
                    left-0 
                    right-0 
                    top-0
                    bottom-0"
          ></motion.img>
          <motion.img
            id="scrollCoin"
            animate={scrollCoin}
            initial={{ scale: 0 }}
            src={
              isCoin
                ? "/assets/Scroll_Coin/scroll_middle_coin.png"
                : "/assets/Scroll_Coin/scroll_middle_gemme.png"
            }
            className="
            h-[20rem]
            md-[30rem]
            2xl:h-[35rem] 
                    z-0 absolute 
                    m-auto 
                    left-0 
                    right-0 
                    top-0
                    bottom-0"
          ></motion.img>
          <motion.div
            id="flash"
            animate={flash}
            initial={{ opacity: 0 }}
            className="bg-[#ffffff] absolute h-screen w-screen z-40"
          ></motion.div>
          <figure className="grid place-content-center absolute bottom-0 h-[20%] w-screen z-50">
            <motion.button
              id="FakebuttonGetHK"
              onClick=""
              className="hidden parrallelogram bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
            >
              <h3
                id="textGetHK"
                className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold "
              >
                Open your scroll!
              </h3>
            </motion.button>
            <motion.button
              id="buttonGetHK"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={async () => {
                OnClickGetKey(); /*OnSendKey()*/
              }}
              className="hidden parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
            >
              <h3
                id="textGetHK"
                className="italic text-radial-gradient z-50 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold "
              >
                Open your scroll!
              </h3>
            </motion.button>
            <h3
              className="italic opacity-0 text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold"
              id="response"
            >
              {" "}
              void
            </h3>
          </figure>
          <dialog
            id="dialog"
            className="hidden absolute rounded-xl w-[90%] lg:w-[50%] bg-[#25191E] m-auto flex flex-col justify-center gap-3 h-[50%] z-[100] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <div className="flex flex-col pt-16 pb-12">
              <div className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8 text-white">
                <div className="py-16">
                  <div id="container" className="text-center flex flex-col">
                    <div id="app" className="flex flex-col gap-5">
                      <h1 className="text-[1.5rem]">
                        You'll have access to your wallet and what's inside
                        using these credentials:
                      </h1>
                      <span id="mail">mail: xxx</span>
                      <span id="wallet">wallet number: xxx</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dialog>

          <figure className="absolute -bottom-20 -right-20">
            <img src="/assets/trame.svg" alt="" />
          </figure>
        </section>
      </div>
    </section>
  );
};

export default Mint;
