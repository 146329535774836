import React from "react";

const Rgpd = () => {
  return (
    <section>
      <section id="RGPD" className="pb-5">
        <div className="container mx-auto px-20 ">
          <div className="align-middle">
            <div className="text-sm text-gray-500">
              Last updated April 20, 2024
            </div>
            <h2 className="text-3xl font-bold mb-4">
              Iterative Education App Privacy Policy
            </h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              At Iterative Education App (“Iterative Education” or "we" / “our”)
              data protection and confidentiality is a high priority. This
              Privacy Policy explains how personal information about you is
              collected, shared, and used by Iterative Education and how you can
              exercise your privacy rights. This Privacy Policy applies to
              personal information that we collect when you use our mobile game
              applications, forums, websites, and other online products and
              services (collectively, the “Services”) or when you otherwise
              interact with us. This Privacy Policy governs all our Services. We
              may amend and/or update this Privacy Policy from time to time by
              posting a new version in response to changing legal, technical, or
              business reasons and developments. If we make material changes, we
              will notify you by revising the date at the top of the notice and,
              depending on the specific amendments, we may provide you with
              additional notice. We encourage you to review the Privacy Policy
              whenever you access our Services to stay informed about our
              information practices and the ways you can help protect your
              privacy. Your continued use of the Services shall mean your
              acceptance of the changes to this Privacy Policy.
            </p>
            <h2 className="text-2xl font-bold mb-4">Information we collect</h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              Information you provide to us We collect information you provide
              voluntarily to us, such as when you participate in a contest and
              promotion, communicate with us via third-party social media sites,
              request customer support, send us an email or otherwise interact
              with us. The types of information we may collect about you include
              your name, social networking screen names or IDs, email address
              and any other information you choose to provide.{" "}
              <b>Information we collect automatically</b> When you access or use
              our Services, we automatically collect certain information from
              you and/or your device (which may be considered personal
              information under applicable data protection laws). We collect
              this information with either your explicit consent in accordance
              with GDPR article 6(1)(a) or as part of our legitimate interest in
              accordance with GDPR article 6 (1)(f), including:
              <ul className="list-disc ml-5 text-left">
                <li>
                  Age: when you download our game, we collect your age as
                  indicated by you.
                </li>
                <li>
                  Pseudo: when you download our game, we collect your pseudo as
                  indicated by you.
                </li>
                <li>
                  Device information: about the device you use to access our
                  Services, including information about the device’s type,
                  software, hardware, and other identifiers (such as Device ID
                  and Advertising ID), device token, mobile network information,
                  and time zone.
                </li>
                <li>
                  Location information: We collect location information in order
                  to comply with legislation and policies that apply to your
                  area.
                </li>
                <li>
                  Usage information: relating to your use of our Services,
                  including which applications you use and your interactions.
                </li>
                <li>
                  Consumption information: about your consumption habits
                  relating to your use of our Services, including purchases made
                  with virtual currencies.
                </li>
                <li>
                  Information from third-party surveys: We may invite you to
                  participate in surveys facilitated by third-party survey
                  partners.
                </li>
              </ul>
              Our Services may offer social sharing features and other
              integrated tools (such as the Facebook “Like” button), which let
              you share actions you take on our Services with other media, and
              vice versa.
            </p>
            <h2 className="text-2xl font-bold mb-4">
              How we use your information
            </h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              We may use information about you for various purposes, including:
              <ul className="list-disc ml-5 text-left">
                <li>Provide, maintain and improve our current Services;</li>
                <li>Develop new Services;</li>
                <li>
                  Provide and deliver the products and services you request and
                  send you related information;
                </li>
                <li>Respond to your comments, questions and requests;</li>
                <li>
                  Send you technical notices, updates, security alerts, and
                  support messages;
                </li>
                <li>
                  Communicate with you about products, services, offers,
                  promotions, and events offered by Iterative Education and
                  others;
                </li>
                <li>Process and deliver contest entries and rewards;</li>
                <li>
                  Monitor and analyse trends, usage, and activities in
                  connection with our Services;
                </li>
              </ul>
            </p>
            <h2 className="text-2xl font-bold mb-4 ">
              How we may disclose your information
            </h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              We may share information about you as follows or as otherwise
              described in this Privacy Policy:
              <ul className="list-disc ml-5 text-left">
                <li>With other users of our Services;</li>
                <li>In response to a legal request for information;</li>
                <li>
                  If we believe your actions violate our user agreements or
                  policies;
                </li>
                <li>During negotiations of a merger or acquisition;</li>
                <li>With your consent or at your direction.</li>
              </ul>
              We may also share aggregated or anonymized information.
            </p>
            <h2 className="text-2xl font-bold mb-4">
              International Data Transfers
            </h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              Iterative Education operates globally, and your personal
              information may be transferred to and processed in countries other
              than your own. We take steps to ensure that adequate safeguards
              are in place to protect your personal information.
            </p>
            <h2 className="text-2xl font-bold mb-4">Data retention</h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              We retain your personal information for as long as needed to
              provide you the Services or for legitimate business reasons.
            </p>
            <h2 className="text-2xl font-bold mb-4">Security</h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              We implement security measures, including encryption and
              pseudonymization, to protect your personal information.
            </p>
            <h2 className="text-2xl font-bold mb-4">
              Your data protection rights
            </h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              You have the right to access, update, rectify, or delete your
              personal data, among other rights. To exercise your rights, you
              must log in to one of our Services to verify your identity.
              Contact us at{" "}
              <a
                href="mailto:contact@playlearner.com"
                className="text-blue-600 underline"
              >
                contact@playlearner.com
              </a>{" "}
              for further assistance.
            </p>
            <h2 className="text-2xl font-bold mb-4">Withdrawal of consent</h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              You may withdraw your consent at any time by contacting us. We
              will stop processing your data unless we have another legal basis
              for doing so.
            </p>
            <h2 className="text-2xl font-bold mb-4">Age, Children</h2>
            <p className="text-base leading-relaxed mb-6 text-justify">
              Our services are not directed at children under the age of 13. We
              do not knowingly collect personal information from children under
              this age.
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Rgpd;
