import React, { useRef, useState } from 'react';
import '../../styles/mint.css';
import { 
  motion,
  useAnimationControls 
} from "framer-motion"
import { bool } from 'prop-types';

const delay = ms => new Promise(res => setTimeout(res, ms));

const show_answer = ()=>{
    let element= document.getElementById("help_text");
    element.className = " absolute h-[20%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191EEE] m-auto left-0 right-0 bottom-[10rem] lg:bottom-[3rem]  z-50 ";
}

const Mint = () => {
    const [pairingString, setPairingString] = useState('');
    let posSword = 0;
  return (
    <section className="overflow-hidden">
      <div className="App">    
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a> 
            <h2 className='absolute text-white uppercase text-shadow-blue-low-mobile  italic font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50'>Can you guess our next collab? 🤔</h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>
          <figure className="absolute z-20 -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          <img 
            src="/assets/Legends_Memories/waiter.png" 
            className='
              ring-4 
              ring-[#E5AE20] 
              absolute 
              w-[80%] 
              lg:w-auto
              aspect-square
              lg:h-[80%] 
              m-auto 
              left-0 
              right-0 
              -top-[14rem] 
              lg:top-[10rem]
              lg:top-16 
              bottom-0 
              pointer-events-none'>
            </img>
            <div className=' 
                absolute 
                w-[80%] 
                lg:w-auto
                aspect-square
                lg:h-[80%] 
                m-auto 
                left-0 
                right-0 
                -top-[14rem] 
                lg:top-[10rem]
                lg:top-16 
                bottom-0 
                z-50'>
                <img  
                src="/assets/key3D_back.png" 
                className=' 
                  absolute
                  w-[16%] 
                  lg:w-auto 
                  lg:h-[16%]  
                  left-[32%]
                  top-[48%]
                  z-30'></img>
                  <motion.img  
                src="/assets/Legends_Memories/bell.png" 
                className=' 
                  absolute
                  w-[35%] 
                  lg:w-auto 
                  lg:h-[35%]  
                  left-[25%]
                  top-[35%]
                  z-40'
                  drag
                dragConstraints={{
                    top: -150,
                    left: 0,
                    right:0,
                    bottom: 0,
                }}
                dragTransition={{ bounceStiffness: 1500, bounceDamping: 500 }}
                dragElastic={0.03}
                onDrag={
                    (event, info) => {
                        if(posSword === 0 || posSword === undefined){
                            //console.log("set sword");
                            posSword = info.point.y;
                        }
                        //console.log(info.point.x);
                        //console.log("sword: "+posSword.toString());
                        if(info.point.y <= posSword + 3){
                            show_answer();
                        }
                    }
                    }
                whileTap={{ cursor: "grabbing" }}>
                  </motion.img>
            </div>
            <img 
            src="/assets/Legends_Memories/waiter_plate.png" 
            className='
              absolute 
              w-[80%] 
              lg:w-auto
              aspect-square
              lg:h-[80%] 
              m-auto 
              left-0 
              right-0 
              -top-[14rem] 
              lg:top-[10rem]
              lg:top-16 
              bottom-0 
              z-50
              pointer-events-none'>
            </img>
        
          
          <div id="help_text" className='hidden absolute h-[20%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191EEE] m-auto left-0 right-0 bottom-[10rem] lg:bottom-[3rem]  z-50 '>
            <p className="w-full text-[0.8rem] lg:text-[0.9rem] 3xl:text-[1.1rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.2rem] 2xl:leading-[1.4rem]  3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">  
            Dear esteemed guest,
            <br/><br/>
            Here's a hint for you.
            <br/><br/>
            Have you figured out which HBAR project we're collaborating with next?
            </p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Mint;