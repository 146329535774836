import React, { useRef, useState } from "react";
import {
  pairHashpack,
  AskServerToTransaction,
  OnClickGetKey,
  Plus,
  Minus,
  Plus10,
  Minus10,
} from "../CleoManagement";
import "../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";

let controlsButtonUseKey = "";
let controlsKeyHoleJar = "";
let controlsBackground = "";
let controlsShadow = "";
let controlsBigShadow = "";

let infinityJar = "";
let scroll = "";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const OnSendKey = async () => {
  //load();
  const realGetKey = document.getElementById("buttonGetHK");
  realGetKey.className = "hidden";
  const response = document.getElementById("response");
  response.className = "hidden";

  const cadre = document.getElementById("cadrebutton");
  cadre.className = "hidden";

  controlsButtonUseKey.start({ opacity: 0, transition: { duration: 3 } });
  controlsBackground.start({
    opacity: [1, 1, 0, 0],
    transition: { duration: 5, times: [0, 0.7, 0.9, 1] },
  });
  controlsShadow.start({
    opacity: [0.5, 0.5, 0, 0],
    transition: { duration: 5, times: [0, 0.7, 0.9, 1] },
  });
  controlsBigShadow.start({
    opacity: [0.5, 0.5, 0, 0],
    transition: { duration: 5, times: [0, 0.7, 0.9, 1] },
  });
  controlsKeyHoleJar.start({
    opacity: [1, 1, 0.3, 0],
    scale: [1, 1, 100, 100],
    y: ["0vw", "-5vw", "-300vw", "-300vw"],
    transition: { duration: 5, times: [0, 0.7, 0.9, 1] },
  });

  await delay(4000);
  const sale = document.getElementById("sale");
  sale.className =
    " h-[85%] lg:h-[100%] w-[100%] overflow-y-scroll overflow-hidden";
  await delay(1000);
  const bg = document.getElementById("bg");
  bg.className = "hidden";

  /*
  infinityJar.start({rotate:[-5, 5, -5],
    transition: {
      repeat: Infinity,
      duration: 0.25
    }})

    await delay(5000);
  response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
  response.innerHTML = "3"
  await delay(1000);
  response.innerHTML = "2"
  await delay(1000);
  response.innerHTML = "1"
  await delay(1000);
  response.innerHTML = "Dear Jar Bearer..."
  await delay(2000);
  response.innerHTML = "CLEO has entered the Legend Arena"
  await delay(3000);
  response.innerHTML = "Are you ready to meet her next week?"
  await delay(3500);
  response.innerHTML = "The gods in the Heaven of Legends have a celestial gift for you."
  await delay(4000);
  response.innerHTML = "Once forged, it will be highly useful in the Legend Arena."
  await delay(4000);
  response.innerHTML = "You can now proudly claim ..."
  
  

  infinityJar.start({scaleY:0.75,scaleX:0.9,
    transition: {
      duration: 1
    }})

  await delay(1000)

  infinityJar.start({scaleY:1.2,scaleX:1.1,
    transition: {
      duration: 0.2
    }})
  
    await delay(300)
  
    infinityJar.start({scaleY:1,scaleX:1,rotate:0,
      transition: {
        duration: 0.1,
        type: "tween",
        damping: 25,
        stiffness: 500
      }})

    scroll.start({scale:1.3,
    transition: {
      duration: 1
    }})

    scroll.start({y:"-40vh",
      transition: {
        duration: 0.5
      }})
    
    await delay(500)
    scroll_img.className= "h-[30rem] lg:h-[14rem] 2xl:h-[22rem] 3xl:h-[22rem] z-20 absolute m-auto left-0 right-0 top-0 bottom-0";


    scroll.start({y:"2vh",
      transition: {
        duration: 0.5
      }})

    await delay(500)
    scroll.start( {y:["2vh","-2vh","2vh"] ,transition:{duration: 7,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
    infinityJar.start({opacity:0,
      transition: {
        duration: 1
      }})


  
    response.innerHTML = "your Heaven Scroll"
    await delay(3000);
    response.innerHTML = "which will turn into an epic artifact! (Check your wallet.)"*/
  //controlsHeavenKey.start({y:["0.5vw","-0.5vw","0.5vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
};

export const load = async () => {
  let confetti = import("../components/confetti"); // Module loaded (export default)!
};

export const init_animation = async () => {
  console.log("InitAnimation");
  controlsKeyHoleJar.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
    },
  });
  //infinityJar.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
  controlsShadow.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 1,
    },
  });
  controlsBigShadow.start({
    y: ["2vw", "-2vw", "2vw"],
    transition: {
      duration: 10,
      times: [0, 0.5, 1],
      repeatType: "loop",
      repeat: Infinity,
      type: "tween",
      damping: 25,
      stiffness: 500,
      delay: 2,
    },
  });
  infinityJar.start({
    rotate: [-2, 2, -2],
    transition: {
      repeat: Infinity,
      duration: 0.4,
    },
  });
};

export const enterHeavenOfLegends = async () => {};

const Mint = () => {
  controlsButtonUseKey = useAnimationControls();
  controlsKeyHoleJar = useAnimationControls();
  controlsBackground = useAnimationControls();
  controlsShadow = useAnimationControls();
  controlsBigShadow = useAnimationControls();

  const [pairingString, setPairingString] = useState("");
  return (
    <section className="overflow-hidden">
      <div className="App" onload={init_animation()}>
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
          <canvas
            class="confetti absolute h-screen w-screen z-10 pointer-events-none"
            id="canvas"
          ></canvas>
          {/* Header & Social networks */}
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-14 z-50 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              class="italic px-4 lg:px-16 py-1 lg:py-2 square rounded-xl absolute bg-[#8626EC] hover:bg-[#8611EC] top-1.5 right-2 lg:right-10 lg:-top-16 z-50"
              onClick={async () => {
                const saveData = await pairHashpack();
                //setPairingString(saveData.pairingString)
              }}
            >
              <span
                id="accountid"
                className="text-radial-gradient lg:text-2xl font-bold"
              >
                Connect wallet
              </span>
            </motion.button>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>

          <img
            src="/assets/HeavenKey_Space.jpg"
            className=" h-screen object-cover right-0 left-0 mx-auto lg:w-screen absolute z-0 pointer-events-none"
          ></img>
          <div
            id="sale"
            className="hidden h-[85%] lg:h-[100%] w-[100%] overflow-y-scroll overflow-hidden"
          >
            <figure className="ring-[0.2rem]   backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg m-auto mt-4 lg:mt-28 bottom-0 left-0 right-0 max-h-[48rem]  min-h-[40rem] lg:h-[78%] w-[90%] lg:w-[60%] z-10">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="w-14 z-50 -top-1 xl:w-20 2xl:w-28 lg:-top-24 mx-auto "
              />
              <span className=" italic text-[1.25rem] 2xl:text-[2rem] text-radial-gradient">
                JAR LORDS PRIVATE SALE
              </span>
              <figure className="grid px-10 grid-cols-1 lg:grid-cols-2 place-content-center justify-center content-center lg:gap-10 mt-4 ">
                <figure className="flex-wrap gap-4 3xl:gap-8  grid-rows-3 flex justify-center content-center ">
                  <video
                    src="/assets/FRIDA_IDDLE_WITH_BG_reduced_HD.mp4"
                    class="w-[10rem] xl:w-[17rem] 2xl:w-[20rem] 3xl:w-[23rem] aspect-square ring-4 ring-[#00000033] rounded-lg"
                    loop
                    autoplay
                    muted
                    controls
                    preload="auto"
                    playsinline
                  >
                    Your browser does not support the video tag.
                  </video>

                  <div>
                    <div class=" justify-between w-[15rem] lg:w-[20rem] 3xl:w-[30rem]">
                      <span
                        id="supplyText"
                        class="text-sm font-medium lg:text-[1.25rem] 3xl:text-[1.75rem] text-white z-10"
                      >
                        Supply: 0/333
                      </span>
                    </div>
                    <div class="w-full relative bg-gray-200 rounded-full h-2.5 3xl:h-4 dark:bg-gray-700 z-10">
                      <div
                        id="supplyLoadingBar"
                        class="bg-[#8626EC] relative h-2.5 3xl:h-4 rounded-full w-[100%] z-10"
                      ></div>
                    </div>
                  </div>
                  {/*<span id="time" className=' text-white lg:text-[1.25rem] 3xl:text-[2rem]'>Remaining Time: ??:??:??</span>*/}
                </figure>

                <figure className="grid gap-2 lg:gap-4 grid-cols-1">
                  <div class="flex flex-col items-center justify-center space-y-4 p-6">
                    <div class="flex items-center justify-between space-x-4">
                      <button
                        class="px-3 py-2 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                        onClick={Minus10}
                      >
                        -10
                      </button>
                      <button
                        class="px-4 py-2 bg-gray-500 hover:bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-red-300 focus:ring-opacity-50"
                        onClick={Minus}
                      >
                        -1
                      </button>
                      <span
                        id="nb_cleo"
                        class="text-[2rem] text-white font-bold"
                      >
                        1
                      </span>
                      <button
                        class="px-4 py-2 bg-gray-500 hover:bg-gray-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50"
                        onClick={Plus}
                      >
                        +1
                      </button>
                      <button
                        class="px-3 py-2 bg-gray-700 hover:bg-gray-600 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                        onClick={Plus10}
                      >
                        +10
                      </button>
                    </div>
                  </div>
                  {/*<span id="time" className=' text-white lg:text-[1.25rem] 3xl:text-[2rem]'>Remaining Time: ??:??:??</span>*/}
                  <figure>
                    <span className=" text-white text-[1rem] lg:text-[1.5rem]">
                      Price :
                    </span>
                    <span
                      id="priceHBAR"
                      className=" text-white text-[0.75rem] lg:text-[1.5rem]"
                    >
                      {" "}
                      1250 HBAR
                    </span>
                    <span
                      id="priceHKEY"
                      className="hidden text-white text-[0.75rem] lg:text-[1.5rem]"
                    >
                      {" "}
                      + 1 Heaven Key
                    </span>
                  </figure>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    class="italic  square rounded-xl bg-[#8626EC] hover:bg-[#8611EC] py-2 top-1.5 right-2 lg:right-10 lg:-top-16 z-50"
                    onClick={async () => {
                      //const saveData = await pairHashpack()
                      //setPairingString(saveData.pairingString)
                      AskServerToTransaction();
                    }}
                  >
                    <span className="text-white lg:text-3xl font-bold">
                      MINT
                    </span>
                  </motion.button>
                  <h3
                    className="text-white opacity-0 z-50 mx-8 lg:mx-0 h-10 leading-5 text-[0.6rem] lg:text-[1rem] 3xl:mt-4 3xl:text-[1.25rem] "
                    id="responseMint"
                  >
                    {" "}
                    void
                  </h3>
                  <ul className="list-none pl-0 text-white text-left">
                    <li>
                      <span>1-24 FRIDAs:</span> 650h each
                    </li>
                    <li>
                      <span>25-49 FRIDAs:</span> 600h each
                    </li>
                    <li>
                      <span>50-99 FRIDAs:</span> 550h each + 1 LOP Loyal
                    </li>
                    <li>
                      <span>100+ FRIDAs:</span> 500h each + 1 LOP Loyal + Name
                      in Game Credits.
                    </li>
                  </ul>
                </figure>
              </figure>
            </figure>
          </div>

          <motion.img
            id="bg"
            animate={controlsBackground}
            src="/assets/Heaven.jpg"
            className=" h-screen object-cover right-0 left-0 mx-auto lg:w-screen absolute z-20 "
          ></motion.img>

          <span className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center pointer-events-none">
            <motion.img
              initial={{ opacity: 0.5 }}
              animate={controlsBigShadow}
              src="/assets/Big_Shadow.png"
              className="  h-[30rem] lg:h-[50rem] 2xl:h-[55rem] 3xl:h-[60rem] z-30 pointer-events-none"
            ></motion.img>
          </span>

          <span className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center pointer-events-none">
            <motion.img
              initial={{ opacity: 0.5 }}
              animate={controlsShadow}
              src="/assets/Little_Shadow.png"
              className=" h-[30rem] lg:h-[40rem] 2xl:h-[45rem] 3xl:h-[50rem] z-40 pointer-events-none"
            ></motion.img>
          </span>

          <span className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center pointer-events-none">
            <motion.img
              animate={controlsKeyHoleJar}
              src="/assets/KEY_HOLE_JAR.png"
              className=" h-[20rem] mt-10 lg:-mt-10 lg:h-[22rem] 2xl:h-[26rem] 3xl:h-[32rem] z-40 pointer-events-none"
            ></motion.img>
          </span>

          <figure
            id="cadrebutton"
            className="grid place-content-center absolute bottom-0 h-[20%] w-screen z-50 "
          >
            <motion.button
              id="FakebuttonGetHK"
              onClick=""
              className=" square rounded-xl bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
            >
              <h3
                id="textGetHK"
                className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold "
              >
                JAR LORDS ONLY
              </h3>
            </motion.button>
            <motion.button
              id="buttonGetHK"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={async () => {
                OnClickGetKey();
              }}
              className="hidden square rounded-xl bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
            >
              <h3
                id="textGetHK"
                className="italic text-radial-gradient z-50 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold "
              >
                JAR LORDS ONLY
              </h3>
            </motion.button>
            <h3
              className="italic opacity-0 text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold"
              id="response"
            >
              {" "}
              void
            </h3>
          </figure>
        </section>
      </div>
    </section>
  );
};

export default Mint;
