import { HashConnect } from "hashconnect";

import {
  AccountId,
  TokenId,
  TransferTransaction,
  TokenAssociateTransaction,
  Hbar,
} from "@hashgraph/sdk";

import { OnSendKey } from "pages/Cleo";

const axios = require("axios").default;

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
  console.log(`connexion status: ${connectionStatus}`);
});

let saveData = {
  topic: "",
  pairingString: "",
  privateKey: "",
  pairedWalletData: null,
  pairedAccounts: [],
};

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if (isMainnet) {
  hashConnectString = "mainnet";
  mirrornodeString = "mainnet-public";
}

const infinityJarTokenId = TokenId.fromString("0.0.1881189");
const cleoTokenId = TokenId.fromString("0.0.5376090");
const heavenKeyTokenId = TokenId.fromString("0.0.1385462");

const operatorId = AccountId.fromString("0.0.1380960");

let serial = 0;

let nb_cleo = 1;
let nb_cleo_front;

let status = 3;
let price_user = 2000;
let number_jar = 0;
let number_HeavenKey = 0;
let number_Hbar = 5000;
// 0 jar whales, 1 jar lords, 2 Heaven key, 3 WL
//1000,1250,1500,2000

let WL = ["0.0.617606"];

const appMetaData = {
  name: "Legends of the past",
  description:
    "Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar",
  icon: "https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk",
};

export const pairHashpack = async () => {
  let initData = await hashconnect.init(appMetaData, hashConnectString, false);
  saveData.privateKey = initData.privKey;
  saveData.topic = initData.topic;
  console.log("initData");
  console.log(initData);
  console.log(`topic: ${initData.topic}`);

  hashconnect.foundExtensionEvent.once((walletMetadata) => {
    hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
  });

  hashconnect.pairingEvent.once((pairingData) => {
    console.log(`wallet paired`);
    console.log(pairingData);

    const accountId = document.getElementById("accountid");
    accountId.innerHTML = pairingData.accountIds[0];
    saveData.pairedAccounts.push(pairingData.accountIds[0]);
    checkWL(pairingData.accountIds[0]);
  });
  return initData;
};

let isWL = false;
//make the button clickable or show error message "not whitelist"
export const checkWL = async (accountID) => {
  console.log("Check WL for: " + accountID);
  number_jar = await CheckClientHas(infinityJarTokenId);
  number_HeavenKey = await CheckClientHas(heavenKeyTokenId);
  number_Hbar = await CheckClientHbar();
  isWL = WL.includes(accountID);

  Update_Status();
  supplyUpdate();
  timerUpdate();
  if (
    number_jar >= 5 ||
    accountID == "0.0.1157105" ||
    accountID == "0.0.1266223"
  ) {
    console.log(accountID + " is WL");
    //the account is WL => clickable button
    const buttonGetKey = document.getElementById("FakebuttonGetHK");
    buttonGetKey.className = "hidden";
    const realGetKey = document.getElementById("buttonGetHK");
    realGetKey.className =
      "square rounded-xl bg-[#8626EC] hover:bg-[#8611EC] z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto";
    //add onclick OnClickGetKey
    //realGetKey.onclick = async ()=>{OnClickGetKey()};
  } else {
    //show message "are you sure you are White List ?"
    const response = document.getElementById("response");
    response.className =
      "italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold";
    response.innerHTML = "You need to be a Jar Lord!";
  }
};

function messageMint(message) {
  const response = document.getElementById("responseMint");
  response.className =
    "text-white z-50 mx-8 lg:mx-0 h-10 text-[0.6rem] lg:text-[1rem] 3xl:mt-4 3xl:text-[1.25rem] leading-5";
  response.innerHTML = message;
}

async function timerUpdate() {
  //Heaven Key
  //let reference_time = new Date(Date.UTC(2023,8,28,10,0,0,0));
  //WL
  //let reference_time = new Date(Date.UTC(2023,8,28,22,0,0,0));
  //public
  let reference_time = new Date(Date.UTC(2023, 8, 29, 10, 0, 0, 0));

  const timer = document.getElementById("time");

  while (true) {
    let now = new Date(Date.now());

    let remaining_time = reference_time.getTime() - now.getTime();

    if (remaining_time <= 0) {
      timer.innerHTML = "Remaining Time: 00:00:00";
      break; // Sortir de la boucle lorsque le temps est écoulé
    } else {
      let hours = Math.floor(remaining_time / (1000 * 60 * 60));
      let minutes = Math.floor(
        (remaining_time % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((remaining_time % (1000 * 60)) / 1000);

      timer.innerHTML =
        "Remaining Time: " +
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0") +
        ":" +
        String(seconds).padStart(2, "0");
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));
  }
}

let supply = 333;
async function supplyUpdate() {
  const loadingBar = document.getElementById("supplyLoadingBar");
  const supplyText = document.getElementById("supplyText");
  let url =
    "https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?target=supply&walletid=" +
    saveData.pairedAccounts[0];

  while (true) {
    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const jsonResponse = JSON.parse(response.data.replace(/'/g, '"'));
        //console.log(jsonResponse);
        console.log(jsonResponse["status"]);
        supply = parseInt(jsonResponse["status"]);
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });

    loadingBar.style.width = ((supply / 333.0) * 100).toString() + "%";
    supplyText.innerHTML = "Supply: " + supply.toString() + "/333";
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
}

//0 jar Whales
//1 jar lords
//2 Heaven Key ( Jar bearers)
//3 WL

//manage multi airdrop
async function CheckClientHas(TokenId) {
  const url =
    `https://` +
    mirrornodeString +
    `.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc&limit=50`;
  //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
  let res = 0;
  await axios
    .get(url)
    .then(function (response) {
      const jsonResponse = response.data;
      // output the from address and message stored in the event
      if (jsonResponse.nfts.length != 0) {
        res = jsonResponse.nfts.length;
        serial = [];
        for (let index = 0; index < jsonResponse.nfts.length; index++) {
          serial.push(jsonResponse.nfts[index].serial_number.toString());
        }
        console.log(`Mirror event(s): ${serial}`);
      }
    })
    .catch(function (err) {
      console.error(err);
      alert(err.toString());
    });
  return res;
}

async function CheckClientHbar() {
  const url =
    `https://` +
    mirrornodeString +
    `.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}`;
  //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
  let res = 0;
  await axios
    .get(url)
    .then(function (response) {
      const jsonResponse = response.data;
      // output the from address and message stored in the event
      res = parseInt(jsonResponse.balance.balance) / 100000000;
      console.log("HBAR: " + res);
    })
    .catch(function (err) {
      console.error(err);
      alert(err.toString());
    });
  return res;
}

export const OnClickGetKey = async () => {
  CheckAssociateToken();
  //OnSendKey();
  //http://13.42.62.231:3000
  //localhost:4000
};

export const CheckAssociateToken = async () => {
  const url =
    `https://` +
    mirrornodeString +
    `.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${cleoTokenId}`;

  //TODO try catch
  axios
    .get(url)
    .then(function (response) {
      const jsonResponse = response.data;
      if (jsonResponse.tokens.length == 0) {
        return AssociateToken();
      } else {
        console.log(`token ${cleoTokenId} is already associated`);
        OnSendKey();
        console.log("launch On Send Key");
      }
    })
    .catch(function (err) {
      console.error(err);
      alert(err.toString());
    });
};

export const AssociateToken = async () => {
  try {
    const provider = hashconnect.getProvider(
      hashConnectString,
      saveData.topic,
      saveData.pairedAccounts[0]
    );
    const signer = hashconnect.getSigner(provider);
    let associateLocalWalletTx = await new TokenAssociateTransaction()
      .setAccountId(saveData.pairedAccounts[0])
      .setTokenIds([cleoTokenId])
      .setMaxTransactionFee(new Hbar(150))
      .freezeWithSigner(signer);

    let associateLocalWalletTxSubmit =
      await associateLocalWalletTx.executeWithSigner(signer);
    if (associateLocalWalletTxSubmit == undefined) {
      console.log(`user refuse to associate token`);
    } else {
      OnSendKey();
    }
  } catch (error) {
    console.log(`error token don't associated: ${error}`);
  }
};

let askServer = false;

export const AskServerToTransaction = async () => {
  //post request
  //launch anim ?
  //TODO neal
  /*if(!isWL){
        messageMint("Only WL can mint for the moment!");
        return;
    }*/

  //messageMint("Sold out");
  //return;

  if (askServer) {
    console.log("already ask server !");
    messageMint("Already asked the server! Reload the website.");
    return;
  }
  askServer = true;

  let url =
    "https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid=" +
    saveData.pairedAccounts[0] +
    "&target=cleo&serial=" +
    serial +
    "&nb_cleo=" +
    nb_cleo.toString() +
    "&price=" +
    price_user.toString();

  if (/*nb_cleo <= 6*/ true) {
    url = url + "&paid=false";
    console.log("URL : " + url);
    axios
      .get(url)
      .then(function (response) {
        console.log(response);
        const jsonResponse = JSON.parse(response.data.replace(/'/g, '"'));
        //console.log(jsonResponse);
        console.log(jsonResponse["status"]);

        if (
          jsonResponse["status"].includes("Not a Jar Lords") ||
          jsonResponse["status"].includes("Try again or contact support")
        ) {
          messageMint(jsonResponse["status"]);
        } else {
          ReadTransaction(jsonResponse["status"]);
        }
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });
  } else {
    let step1done = await TransactionIn2Steps(nb_cleo, price_user);
    if (step1done) {
      url = url + "&paid=true";
      console.log("URL : " + url);
      axios
        .get(url)
        .then(function (response) {
          console.log(response);
          const jsonResponse = response.data;
          console.log(jsonResponse);
          console.log(jsonResponse["status"]);

          if (jsonResponse.toString().includes("are not WL")) {
            const response = document.getElementById("response");
            response.className =
              "italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold";
            response.innerHTML =
              "You have already received your heavenly gifts!";
          } else {
            //OnSendKey();
            messageMint("We send you the Cleo!");
          }
        })
        .catch(function (err) {
          console.log(err);
          messageMint("We send you the Cleo!");
        });
    }
  }
};

function Update_Status() {
  let local_status = 3; //WL
  if (number_HeavenKey != 0) {
    local_status = 2;
    if (number_jar >= 20) {
      local_status = 0;
    } else if (number_jar >= 5) {
      local_status = 1;
    }
  }

  local_status = 3;
  status = local_status;
  Update_Price_StatusSTR();
  Update_HeavenKey_Front();
  Update_Hbar_Front();
  Update_Cleo_Front();
}

function Update_Price_StatusSTR() {
  let status_str = "Jar Lord";
  if (nb_cleo >= 100) {
    price_user = 500;
  } else if (nb_cleo >= 50) {
    price_user = 550;
  } else if (nb_cleo >= 25) {
    price_user = 600;
  } else {
    price_user = 650;
  }

  Update_Status_Front(status_str);
}

function Update_Status_Front(status_str) {
  //const response = document.getElementById("statusid");
  //response.innerHTML = "Role : " + status_str;
}

function Update_Hbar_Front() {
  const response = document.getElementById("priceHBAR");
  response.innerHTML = " " + (nb_cleo * price_user).toString() + " HBAR";
}

function Update_HeavenKey_Front() {
  let nb_HK = Math.floor(nb_cleo / 2.0 + 0.5);
  //const response = document.getElementById("priceHKEY");
  //response.innerHTML = " + " + nb_HK.toString() + " HEAVEN KEY";
}

function Update_Cleo_Front() {
  const response = document.getElementById("nb_cleo");
  response.innerHTML = nb_cleo.toString();
}

export const Plus10 = async () => {
  if (/*price_user * (nb_cleo + 1) <= number_Hbar*/ nb_cleo + 10 <= supply) {
    nb_cleo = nb_cleo + 10;

    //Update_HeavenKey_Front();
    Update_Price_StatusSTR();
    Update_Hbar_Front();
    Update_Cleo_Front();
  } else {
    if (nb_cleo + 10 > supply) {
      messageMint("You can't buy more Cleo");
    } /*else if (price_user * (nb_cleo + 10) > number_Hbar) {
      messageMint(
        "You need more Hbar to buy " + (nb_cleo + 10).toString() + " CLEO!"
      );
    }*/
  }
};

export const Plus = async () => {
  console.log("Plus\n nb_Cleo: " + nb_cleo + "\n nb_HK: " + number_HeavenKey);

  if (/*price_user * (nb_cleo + 1) <= number_Hbar*/ nb_cleo + 1 <= supply) {
    nb_cleo = nb_cleo + 1;

    //Update_HeavenKey_Front();
    Update_Price_StatusSTR();
    Update_Hbar_Front();
    Update_Cleo_Front();
  } else {
    if (nb_cleo + 1 > supply) {
      messageMint("You can't buy more Cleo");
    } else if (price_user * (nb_cleo + 1) > number_Hbar) {
      messageMint(
        "You need more Hbar to buy " + (nb_cleo + 1).toString() + " CLEO!"
      );
    }
  }

  /*if (status !=3 false) {
    console.log(price_user * (nb_cleo + 1));
    console.log(number_Hbar);
    if (
      nb_cleo < 6 &&
      nb_cleo < number_HeavenKey * 2 &&
      price_user * (nb_cleo + 1) <= number_Hbar
    ) {
      nb_cleo = nb_cleo + 1;

      Update_HeavenKey_Front();
      Update_Hbar_Front();
      Update_Cleo_Front();
    } else {
      if (nb_cleo == 6) {
        messageMint("You can't buy more than 6 Cleo!");
      } else if (nb_cleo + 1 > number_HeavenKey * 2) {
        messageMint("You need more Heaven Key!");
      } else if (price_user * (nb_cleo + 1) > number_Hbar) {
        messageMint(
          "You need more Hbar to buy " + (nb_cleo + 1).toString() + " CLEO!"
        );
      }
    }
  } else {
    if (nb_cleo < 5 && price_user * (nb_cleo + 1) <= number_Hbar) {
      nb_cleo = nb_cleo + 1;

      Update_HeavenKey_Front();
      Update_Hbar_Front();
      Update_Cleo_Front();
    } else {
      if (nb_cleo == 5) {
        messageMint("You can't buy more than 5 Cleo!");
      } else if (price_user * (nb_cleo + 1) > number_Hbar) {
        messageMint(
          "You need more Hbar to buy " + (nb_cleo + 1).toString() + " CLEO!"
        );
      }
    }
  }*/
};

export const Minus = async () => {
  if (nb_cleo >= 2) {
    nb_cleo = nb_cleo - 1;
  }
  Update_Price_StatusSTR();
  //Update_HeavenKey_Front();
  Update_Hbar_Front();
  Update_Cleo_Front();
};

export const Minus10 = async () => {
  if (nb_cleo < 11) {
    nb_cleo = 1;
  } else {
    nb_cleo = nb_cleo - 10;
  }
  Update_Price_StatusSTR();
  //Update_HeavenKey_Front();
  Update_Hbar_Front();
  Update_Cleo_Front();
};

const TransactionIn2Steps = async (nb_cleo, price) => {
  const provider = hashconnect.getProvider(
    hashConnectString,
    saveData.topic,
    saveData.pairedAccounts[0]
  );
  const signer = hashconnect.getSigner(provider);
  let tokenTransfer = await new TransferTransaction()
    .addHbarTransfer(operatorId, nb_cleo * price)
    .addHbarTransfer(saveData.pairedAccounts[0], -price * nb_cleo);

  for (let index = 0; index < Math.floor(nb_cleo / 2.0 + 0.5); index++) {
    tokenTransfer = await tokenTransfer.addNftTransfer(
      heavenKeyTokenId,
      serial[index],
      saveData.pairedAccounts[0],
      operatorId
    );
  }

  tokenTransfer.freezeWithSigner(signer);

  let tokenTransferSubmit = await tokenTransfer.executeWithSigner(signer);

  console.log(JSON.stringify(tokenTransferSubmit));
  //other solution query balance of user
  //depend of hashpack ask May Chan about ExecuteWithSigner return value
  //swap if esle
  if (tokenTransferSubmit != undefined) {
    console.log(`user success to send Hbar, He is now waiting his NFT`);
    return true;
  } else {
    console.log(`user refuse to accept the transaction`);
    return false;
  }
};

export const ReadTransaction = async (base64string) => {
  console.log("Read Transaction");

  let transactionbytes = base64ToBytes(base64string);
  //let id = new TransactionId.fromString(base64string);
  let transaction;
  let tokenTransferSubmit;
  try {
    transaction = TransferTransaction.fromBytes(transactionbytes);
    console.log(JSON.stringify(transaction));

    console.log("Read Transaction 1");

    const provider = hashconnect.getProvider(
      hashConnectString,
      saveData.topic,
      saveData.pairedAccounts[0]
    );
    const signer = hashconnect.getSigner(provider);

    //let transactionFreeze = await transaction.freezeWithSigner(signer);
    console.log("Read Transaction 2");
    tokenTransferSubmit = await transaction.executeWithSigner(signer);
  } catch (error) {
    console.log(error);
  }

  console.log(JSON.stringify(tokenTransferSubmit));
  //other solution query balance of user
  //depend of hashpack ask May Chan about ExecuteWithSigner return value
  //swap if esle
  if (tokenTransferSubmit != undefined) {
    console.log(`user success to send Hbar, He is now waiting his NFT`);
    //load();
    messageMint(
      "Mint Successful! You will receive your FRIDAs in less than 1 min."
    );
    //enterHeavenOfLegends(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
  } else {
    messageMint(`You refused the transaction.`);
  }
};

export const base64ToBytes = (base64String) => {
  const base64abc =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  const l = base64String.length;
  let i = 0;
  let bytes = new Uint8Array((l * 3) / 4);
  let byteIndex = 0;

  for (i = 0; i < l; i += 4) {
    let b1 = base64abc.indexOf(base64String[i]);
    let b2 = base64abc.indexOf(base64String[i + 1]);
    let b3 = base64abc.indexOf(base64String[i + 2]);
    let b4 = base64abc.indexOf(base64String[i + 3]);

    bytes[byteIndex++] = (b1 << 2) | (b2 >> 4);

    if (base64String[i + 2] !== "=") {
      bytes[byteIndex++] = ((b2 & 0xf) << 4) | (b3 >> 2);
    }

    if (base64String[i + 3] !== "=") {
      bytes[byteIndex++] = ((b3 & 0x3) << 6) | b4;
    }
  }

  return bytes.slice(0, byteIndex);
};
