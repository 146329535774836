import Header from "../../CommitteeCleo/CommitteeHeader";
import Menu from "../../CommitteeCleo/CommitteeMenu";
import "../../../styles/mint.css";
import Auth from "../../../utils/auth/utils.js";
import { useEffect, useState } from "react";
const axios = require("axios").default;
import convertToJSONString from "../../../convertToJSONString.js";
import convertEncodingQuote from "../../../convertEncodingChar.js";
import remainingTime from "../../../getRemainingTime.js";
import Options from "./Options";
import Result from "./Result";
import Vote from "./Vote";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import convertReturnLine from "../../../convertReturnLine.js";

const Champions = () => {
  const [user, setUser] = useState(Auth.getAccount());
  const [proposalStage, setProposalStage] = useState(false);
  const [voteStage, setVoteStage] = useState(false);
  const [resultStage, setResultStage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorServer, setErrorServer] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [options, setOptions] = useState([]);
  const [onGoingRepresentatives, setOnGoingRepresentatives] = useState([]);
  const [timer, setTimer] = useState({});
  const [serials, setSerials] = useState([]);
  const [serialsNotUsed, setSerialsNotUsed] = useState([]);
  const [result, setResult] = useState([]);
  const [maxVotesForOne, setMaxVotesForOne] = useState(0);
  const [numberOfVotes, setNumberOfVotes] = useState(0);
  const [userProposals, setUserProposals] = useState(0);
  const [maxProposals, setMaxProposals] = useState(1);
  const [seeOnlyGraph, setSeeOnlyGraph] = useState(true);

  function convertOctetsToMb(octets) {
    return (octets / (1024 * 1024)).toFixed(2);
  }
  function convertOctetsToMbNoFixed(octets) {
    return octets / (1024 * 1024);
  }

  async function CheckClientHas() {
    let isMainnet = true;

    let hashConnectString = "testnet";
    let mirrornodeString = "testnet";

    if (isMainnet) {
      hashConnectString = "mainnet";
      mirrornodeString = "mainnet-public";
    }
    let TokenId = "0.0.3853857";
    const url =
      `https://` +
      mirrornodeString +
      `.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${user}&order=asc&limit=50`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let serial = [];
    await axios
      .get(url)
      .then(function (response) {
        const jsonResponse = response.data;
        // output the from address and message stored in the event
        if (jsonResponse.nfts.length != 0) {
          for (let index = 0; index < jsonResponse.nfts.length; index++) {
            serial.push(jsonResponse.nfts[index].serial_number.toString());
          }
          // console.log(`Mirror event(s): ${serial}`);
        }
      })
      .catch(function (err) {
        console.error(err);
        alert(err.toString());
      });
    return serial;
  }

  const getOptions = async (id) => {
    const auth = Auth.getAuth();
    const account = Auth.getAccount();

    try {
      let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getRepresentativesOption`;

      const body = JSON.stringify({
        user: account,
        legend: "Cleo",
        auth: JSON.stringify(auth.userSignature),
        sign: JSON.stringify(auth.signedPayload),
        idvote: id,
      });

      const response = await axios.post(url, body);
      console.log(response);
      if (response.data.length > 0) {
        return JSON.parse(convertToJSONString(response.data));
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRepresentatives = async () => {
    const auth = Auth.getAuth();
    const account = Auth.getAccount();
    try {
      let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getRepresentatives`;

      const body = JSON.stringify({
        user: account,
        legend: "Cleo",
        auth: JSON.stringify(auth.userSignature),
        sign: JSON.stringify(auth.signedPayload),
      });

      const response = await axios.post(url, body);

      return JSON.parse(convertToJSONString(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  const RequestResult = async (id, options) => {
    let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=GetResultRepresentatives&idvote=${id}`;

    axios
      .get(url)
      .then(function (response) {
        console.log(response.data);
        response = JSON.parse(convertToJSONString(response.data));
        const elements = [];

        const tableauResultat = Object.entries(response).map(
          ([cle, valeur]) => {
            const nouvelObjet = {};
            nouvelObjet[parseInt(cle)] = valeur;
            elements.push(valeur);
            return nouvelObjet;
          }
        );
        setResult(tableauResultat);
        const total = elements.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        setNumberOfVotes(total);
        let valeurMax = -Infinity;

        const arrayOptionsWithVotes = [];
        for (let index = 0; index < options.length; index++) {
          const option = options[index];

          const vote = tableauResultat.find((obj) =>
            obj.hasOwnProperty(option.id)
          )[option.id];

          const optionWithVotes = {
            ...option,
            votes: vote,
          };
          arrayOptionsWithVotes.push(optionWithVotes);

          if (tableauResultat[index][index + 1] > valeurMax) {
            valeurMax = tableauResultat[index][index + 1];
          }
        }
        setOptions(arrayOptionsWithVotes);
        setMaxVotesForOne(valeurMax);
      })
      .catch(function (err) {
        console.log(err);
        //alert(err.toString());
      });
  };

  useEffect(() => {
    async function checkIfRepresentatives() {
      const response = await getRepresentatives();
      if (response === "!ok") {
        setOnGoingRepresentatives([]);
      } else {
        setOnGoingRepresentatives(response);
        const ser = await CheckClientHas();
        setSerials(ser);
        let urlCheck = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=CheckVoteRepresentatives&idvote=${response[0].id}&serials=${ser}`;
        const responseCheck = await axios.get(urlCheck);
        const serialsObject = JSON.parse(
          convertToJSONString(responseCheck.data)
        );
        const nouveauTableau = [];

        // Itérez sur les clés de l'objet
        Object.keys(serialsObject).forEach((cle, index) => {
          // Créez un nouvel objet avec les propriétés appropriées
          const nouvelObjet = {
            serial: parseInt(cle), // Supposons que "serial" doit être un nombre
            hasvoted: serialsObject[cle],
            index: index,
          };
          // Ajoutez le nouvel objet au tableau
          nouveauTableau.push(nouvelObjet);
        });
        const arrayNotVoted = nouveauTableau
          .filter((objet) => objet.hasvoted === false)
          .map((objet) => objet.serial);
        setSerialsNotUsed(arrayNotVoted);

        const optionsResponse = await getOptions(response[0].id);
        if (optionsResponse && optionsResponse.length > 0) {
          const modifiedOptions = optionsResponse.map((option) => {
            return {
              ...option,
              Argumentation: convertReturnLine(option.Argumentation),
            };
          });
          setOptions(modifiedOptions);
          RequestResult(response[0].id, modifiedOptions);
        }
        let urlCheckNumberProposals = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=checkProposalsNumber`;
        const bodyproposals = JSON.stringify({
          user: Auth.getAccount(),
          auth: JSON.stringify(Auth.getAuth().userSignature),
          sign: JSON.stringify(Auth.getAuth().signedPayload),
          idVote: response[0].id,
        });
        const responseCheckNumberProposals = await axios.post(
          urlCheckNumberProposals,
          bodyproposals
        );
        setUserProposals(responseCheckNumberProposals.data);
        setIsLoading(false);
      }
    }
    checkIfRepresentatives();
  }, [isPublished]);

  useEffect(() => {
    if (onGoingRepresentatives.length > 0) {
      const intervalId = setInterval(() => {
        if (
          onGoingRepresentatives[0].Vote > new Date().getTime() / 1000 &&
          onGoingRepresentatives[0].Vote <
            onGoingRepresentatives[0].Vote + onGoingRepresentatives[0].Time
        ) {
          setTimer(
            remainingTime(
              onGoingRepresentatives[0].Timestamp,
              onGoingRepresentatives[0].Vote -
                onGoingRepresentatives[0].Timestamp
            )
          );
          setProposalStage(true);
        } else if (
          onGoingRepresentatives[0].Vote < new Date().getTime() / 1000 &&
          new Date().getTime() / 1000 <
            onGoingRepresentatives[0].Vote + onGoingRepresentatives[0].Time
        ) {
          setTimer(
            remainingTime(
              onGoingRepresentatives[0].Vote,
              onGoingRepresentatives[0].Time
            )
          );
          setProposalStage(false);
          setVoteStage(true);
        } else {
          setTimer(
            remainingTime(
              onGoingRepresentatives[0].Vote,
              onGoingRepresentatives[0].Time
            )
          );
          setProposalStage(false);
          setVoteStage(false);
          setResultStage(true);
        }
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [onGoingRepresentatives]);

  return (
    <section className="overflow-hidden">
      <div className="App">
        <section
          className="relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] min-h-screen bg-cover"
          style={{ backgroundImage: `url(/assets/HeavenKey_Space.jpg)` }}
        >
          {/* LEGENDS OF THE PAST */}
          <Header />
          <figure className="flex flex-col h-[90vh] lg:h-[90vh] p-3 lg:p-5 w-screen">
            <figure className="relative flex flex-col h-full lg:h-full ring-[0.2rem] w-full backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-10 m-auto overflow-y-auto ">
              {!isLoading ? (
                <section>
                  <section className="h-auto w-full lg:w-[80%] lg:w-full m-auto justify-center items-center flex flex-col gap-0">
                    <h1 className="w-full text-radial-gradient uppercase italic font-bold text-center text-[1.5rem] lg:text-[1.7rem] z-50">
                      Champions{" "}
                      {onGoingRepresentatives
                        ? " : " + onGoingRepresentatives[0].Title
                        : ""}
                    </h1>
                    {onGoingRepresentatives.length > 0 && (
                      <div className="flex flex-col md:flex-row gap-2 items-center justify-center">
                        {!proposalStage && (
                          <p className="text-white italic font-bold text-[0.8rem] lg:text-[1rem]">
                            Time remaining to vote :{" "}
                            {timer?.days > 0
                              ? timer?.time
                              : timer?.hours >= 1
                              ? timer?.hours +
                                "h " +
                                timer?.min +
                                "m " +
                                timer?.seconds +
                                "s"
                              : timer?.min > 0
                              ? timer?.min + "m " + timer?.seconds + "s"
                              : timer?.seconds > 0
                              ? timer?.seconds + "s"
                              : "Vote closed"}
                          </p>
                        )}
                        {proposalStage && (
                          <p className="text-white italic font-bold text-[0.8rem] lg:text-[1rem]">
                            Time remaining to apply :{" "}
                            {timer?.days > 0
                              ? timer?.time
                              : timer?.hours >= 1
                              ? timer?.hours +
                                "h " +
                                timer?.min +
                                "m " +
                                timer?.seconds +
                                "s"
                              : timer?.min > 0
                              ? timer?.min + "m " + timer?.seconds + "s"
                              : timer?.seconds > 0 && timer?.seconds + "s"}
                          </p>
                        )}
                      </div>
                    )}
                  </section>

                  {serials.length !== 0 ? (
                    <section className="text-white h-[80%] lg:h-[75%] ">
                      {proposalStage && (
                        <section className={`flex flex-col lg:flex-row gap-5`}>
                          <Options
                            errorServer={errorServer}
                            setErrorServer={setErrorServer}
                            isPublished={isPublished}
                            setIsPublished={setIsPublished}
                            options={options}
                            onGoingRepresentatives={onGoingRepresentatives}
                            userProposals={userProposals}
                            maxProposals={maxProposals}
                          />
                        </section>
                      )}
                      {(resultStage || serialsNotUsed.length == 0) && (
                        <section
                          className={`flex flex-col h-full overflow-y-auto  w-[90%] lg:w-[70%] m-auto `}
                        >
                          <div className="flex justify-center">
                            {serialsNotUsed.length === 0 &&
                              serials.length &&
                              !resultStage > 0 && <p>You have already voted</p>}
                          </div>

                          <Result
                            result={result}
                            optionsArray={options}
                            numberOfVotes={numberOfVotes}
                            maxVotesForOne={maxVotesForOne}
                            seeOnlyGraph={seeOnlyGraph}
                            setSeeOnlyGraph={setSeeOnlyGraph}
                          />
                        </section>
                      )}
                      {voteStage && serialsNotUsed.length > 0 && (
                        <section>
                          <Vote
                            setSeeOnlyGraph={setSeeOnlyGraph}
                            seeOnlyGraph={seeOnlyGraph}
                            result={result}
                            optionsArray={options}
                            numberOfVotes={numberOfVotes}
                            setErrorServer={setErrorServer}
                            onGoingRepresentatives={onGoingRepresentatives}
                            serialsNotUsed={serialsNotUsed}
                            setIsPublished={setIsPublished}
                            isPublished={isPublished}
                            errorServer={errorServer}
                            maxVotesForOne={maxVotesForOne}
                          />
                        </section>
                      )}
                    </section>
                  ) : (
                    <div className="h-screen text-white flex justify-center items-center">
                      <p className=" text-[2rem]">
                        Vote page is only accessible for cleos holders
                      </p>
                    </div>
                  )}
                </section>
              ) : (
                <div className="h-full flex flex-col items-center justify-center p-5">
                  <FontAwesomeIcon
                    style={{ fontSize: "4rem", color: "#FFFFFF" }}
                    icon={faSpinner}
                    spin
                    className="w-full"
                  />
                  <p className="text-white italic font-bold">Loading...</p>
                </div>
              )}
            </figure>
          </figure>
        </section>
      </div>
      <Menu />
    </section>
  );
};

export default Champions;
