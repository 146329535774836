import React, { useRef, useState } from "react";
import "../../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";
import { bool } from "prop-types";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

let lights = [];
let bools = [false, false, false, false];

let is_init = false;

let string_show =
  "absolute w-[100%] h-[100%] m-auto left-0 right-0 top-0 bottom-0 z-40";
let string_hide =
  "hidden absolute w-[80%] lg:w-auto lg:h-[80%] m-auto left-0 right-0 -top-[14rem] lg:top-16 bottom-0 z-40";

const init = () => {
  lights.push(document.getElementById("light_1"));
  lights.push(document.getElementById("light_2"));
  lights.push(document.getElementById("light_3"));
  lights.push(document.getElementById("light_4"));
};

const switch_light = (index) => {
  if (!is_init) {
    init();
  }

  if (index === 0) {
    interaction_lightbulb(0);
    interaction_lightbulb(1);
    interaction_lightbulb(2);
  }
  if (index === 1) {
    interaction_lightbulb(0);
    interaction_lightbulb(1);
  }
  if (index === 2) {
    interaction_lightbulb(1);
    interaction_lightbulb(2);
  }
  if (index === 3) {
    interaction_lightbulb(1);
    interaction_lightbulb(3);
  }

  console.log(bools);

  check_if_win();
};

const interaction_lightbulb = (index) => {
  bools[index] = !bools[index];
  if (bools[index]) {
    lights[index].className = string_show;
  } else {
    lights[index].className = string_hide;
  }
};

const check_if_win = () => {
  if (bools[0] && bools[1] && bools[2] && bools[3]) {
    show_answer();
  }
};

const show_answer = () => {
  let element = document.getElementById("help_text");
  element.className =
    " absolute h-[20%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191EEE] m-auto left-0 right-0 bottom-[10rem] lg:bottom-[3rem]  z-50 ";
};

const Mint = () => {
  const [pairingString, setPairingString] = useState("");
  const controlsVeil = useAnimationControls();

  return (
    <section className="overflow-hidden">
      <div className="App">
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a>
            <h2 className="absolute text-white uppercase text-shadow-blue-low-mobile  italic font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50">
              🔥 Help Nikola Tesla !
            </h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>
          <figure className="absolute z-20 -bottom-20 -right-20">
            <img src="/assets/trame.svg" alt="" />
          </figure>
          <img
            src="/assets/Legends_Memories/base_tesla.jpg"
            className="
              ring-4 
              ring-[#E5AE20] 
              absolute 
              w-[80%] 
              lg:w-auto
              aspect-square
              lg:h-[80%] 
              m-auto 
              left-0 
              right-0 
              -top-[14rem] 
              lg:top-[10rem]
              lg:top-16 
              bottom-0 
              pointer-events-none"
          ></img>
          <div
            className=" 
                absolute 
                w-[80%] 
                lg:w-auto
                aspect-square
                lg:h-[80%] 
                m-auto 
                left-0 
                right-0 
                -top-[14rem] 
                lg:top-[10rem]
                lg:top-16 
                bottom-0 
                z-50"
          >
            <button
              id="parchemin_button"
              className="z-50"
              onClick={async () => {
                switch_light(0);
              }}
            >
              <img
                src="/assets/Legends_Memories/close.png"
                className=" 
                  absolute
                  opacity-0
                  w-[7%] 
                  lg:w-auto 
                  lg:h-[7%]  
                  left-[27%]
                  top-[35%]
                  z-50"
              ></img>
            </button>
            <button
              id="parchemin_button"
              className="z-50"
              onClick={async () => {
                switch_light(1);
              }}
            >
              <img
                src="/assets/Legends_Memories/close.png"
                className=" 
                  absolute
                  opacity-0
                  w-[7%] 
                  lg:w-auto 
                  lg:h-[7%]  
                  left-[40%]
                  top-[35%]
                  z-50"
              ></img>
            </button>
            <button
              id="parchemin_button"
              className="z-50"
              onClick={async () => {
                switch_light(2);
              }}
            >
              <img
                src="/assets/Legends_Memories/close.png"
                className=" 
                  absolute
                  opacity-0
                  w-[7%] 
                  lg:w-auto 
                  lg:h-[7%]  
                  left-[53%]
                  top-[35%]
                  z-50"
              ></img>
            </button>
            <button
              id="parchemin_button"
              className="z-50"
              onClick={async () => {
                switch_light(3);
              }}
            >
              <img
                src="/assets/Legends_Memories/close.png"
                className=" 
                  absolute
                  opacity-0
                  w-[7%] 
                  lg:w-auto 
                  lg:h-[7%]  
                  left-[67%]
                  top-[35%]
                  z-50"
              ></img>
            </button>

            <img
              id="light_1"
              src="/assets/Legends_Memories/lampe_1_tesla.png"
              className=" 
                    hidden
                    absolute 
                    w-[100%] 
                    h-[100%] 
                    m-auto 
                    left-0 
                    right-0 
                    -top-[14rem] 
                    bottom-0 
                    z-40"
            ></img>
            <img
              id="light_2"
              src="/assets/Legends_Memories/lampe_2_tesla.png"
              className="
                    hidden 
                    absolute 
                    w-[80%] 
                    lg:w-auto 
                    lg:h-[80%] 
                    m-auto 
                    left-0 
                    right-0 
                    -top-[14rem] 
                    lg:top-16 
                    bottom-0 
                    z-40"
            ></img>
            <img
              id="light_3"
              src="/assets/Legends_Memories/lampe_3_tesla.png"
              className="
                    hidden 
                    absolute 
                    w-[80%] 
                    lg:w-auto 
                    lg:h-[80%] 
                    m-auto 
                    left-0 
                    right-0 
                    -top-[14rem] 
                    lg:top-16 
                    bottom-0 
                    z-40"
            ></img>
            <img
              id="light_4"
              src="/assets/Legends_Memories/lampe_4_tesla.png"
              className="
                    hidden
                    absolute 
                    w-[80%] 
                    lg:w-auto 
                    lg:h-[80%] 
                    m-auto 
                    left-0 
                    right-0 
                    -top-[14rem] 
                    lg:top-16 
                    bottom-0 
                    z-40"
            ></img>
          </div>

          <div
            id="help_text"
            className="hidden absolute h-[20%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191EEE] m-auto left-0 right-0 bottom-[10rem] lg:bottom-[3rem]  z-50 "
          >
            <p className="w-full text-[0.8rem] lg:text-[0.9rem] 3xl:text-[1.1rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.2rem] 2xl:leading-[1.4rem]  3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
              Thank you for your assistance. These switches had me stumped!
              <br />
              <br />
              The Tesla password is: 369
            </p>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Mint;
