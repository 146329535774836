import React from "react";
// import { pairHashpack } from "../index";
import "../../../styles/mint.css";
import { motion } from "framer-motion";
import Auth from "../../../utils/auth/utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
const Header = ({saveData}) => {
  function ShowHideMenu() {
    let menu = document.getElementById("menu");

    if (menu.className.includes("hidden")) {
      menu.className =
        "absolute flex flex-col items-center justify-center ring-[0.2rem] w-[50%] lg:w-[20%] lg:gap-4 left-1 top-[5.2rem] backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-50";
    } else {
      menu.className = "hidden";
    }
  }
  const user = Auth.getAccount();

  return (
    <>
      <canvas
        className="confetti absolute h-screen w-screen z-10 pointer-events-none"
        id="canvas"
      ></canvas>
      {/* Header & Social networks */}
      <figure className="relative flex items-end top-0 w-full h-[80px] square bg-[#000000]  z-50">
        <input
          hidden=""
          className="check-icon"
          id="check-icon"
          name="check-icon"
          type="checkbox"
          onClick={ShowHideMenu}
        />
        <label
          className="icon-menu top-6 left-6 absolute bottom-0 m-auto"
          htmlFor="check-icon"
        >
          <div className="bar bar--1"></div>
          <div className="bar bar--2"></div>
          <div className="bar bar--3"></div>
        </label>

        <div className="flex gap-5 items-center justify-center italic absolute right-2 top-0 bottom-4 my-auto h-[60%]">
            <span
            id="accountid"
            className="text-radial-gradient lg:text-2xl font-bold"
          > {user}</span>

        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          className="flex items-center justify-center rounded-xl bg-[#8626EC] hover:bg-[#8611EC] p-2 z-50"
          onClick={() => {
            Auth.logout();
          }}
        >
          <FontAwesomeIcon icon={faRightFromBracket} style={{color: "#ffffff",}} />        
          </motion.button>
        </div>


        <figure className="absolute bottom-0 items-end w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
      </figure>
      {/* <img
        src="/assets/HeavenKey_Space.jpg"
        className="min-h-full lg:min-h-screen bg-repeat object-cover right-0 left-0 mx-auto lg:w-screen absolute z-0 pointer-events-none"
      ></img> */}
    </>
  );
};

export default Header;
