import React, { useEffect, useState } from "react";
import "../../../styles/mint.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Auth from "../../../utils/auth/utils.js";

const Menu = () => {
  const [haveRight, setHaveRight] = useState(false);
  const checkRight = async () => {
    try {
      const response = await Auth.checkRight();
      console.log(response);
      if (response === "ok") {
        setHaveRight(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkRight();
  }, []);
  return (
    <div id="menu" className="hidden">
      <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <Link to="/committee">
          <h2 className="text-radial-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
            Home
          </h2>
        </Link>
      </motion.button>
      <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <Link to="/champions">
          <h2 className="text-radial-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
            Champions
          </h2>
        </Link>
      </motion.button>
      <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <Link to="/reward">
      <h2 className="text-radial-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
        Rewards
      </h2>
      </Link>
      </motion.button>
      {/*<motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        <Link to="/governance">*/}
      <h2 className="text-gray-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
        Governance
      </h2>
      {/*</Link>
      </motion.button>*/}
      <h2 className="text-gray-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
        Vault
      </h2>
      <h2 className="text-gray-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
        Forge
      </h2>
      {haveRight && (
        <>
          {/* <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}> */}
          {/* <Link to="/publishArticle"> */}
          <h2 className="text-gray-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
            Publish article
          </h2>
          {/* </Link> */}
          {/* </motion.button> */}
          {/* <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}> */}
          {/* <Link to="/publishVote"> */}
          <h2 className="text-gray-gradient uppercase  italic font-bold right-0 left-0 mx-auto  lg:-top-14 text-[0.9rem] lg:text-[1.7rem] 2xl:text-3xl z-50">
            Publish Vote
          </h2>
          {/* </Link> */}
          {/* </motion.button> */}
        </>
      )}
    </div>
  );
};

export default Menu;
