import React, { useRef, useState } from 'react';
import {pairHashpack,OnClickGetKey} from '../JarManagement'
import '../styles/mint.css';
import { 
  motion,
  useAnimationControls
} from "framer-motion"

//async function load() {
//  let confetti = import('../components/confetti') // Module loaded (export default)!
//};

let controlsButtonUseKey = "";
let controlsKeyHoleJar = "";
let controlsBackground = "";
let controlsShadow = "";
let controlsBigShadow = "";

let welcome = "";
let gods = "";
let giftInfinityJar = "";

let gift = "";
let coinPile = "";
let pigCoin = "";
let shield = "";
let sword_1 = "";
let sword_2 = "";
let heaven_key = "";
let hbarCoin = "";

let infinityJar = "";


const delay = ms => new Promise(res => setTimeout(res, ms));

export const OnSendKey = async (controlsHeavenKey) =>{

  const response = document.getElementById('response');
  response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
  response.innerHTML = "5"
  await delay(1000);
  response.innerHTML = "4"
  await delay(1000);
  response.innerHTML = "3"
  await delay(1000);
  response.innerHTML = "2"
  await delay(1000);
  response.innerHTML = "1"
  await delay(1000);
  response.innerHTML = "Dear Seeker..."
  await delay(3000);
  response.innerHTML = "We are deeply grateful for your unwavering support..."
  await delay(3000);
  response.innerHTML = "To express our gratitude..."
  controlsHeavenKey.start({scale:1,transition:{duration:9}});
  await delay(3000);
  response.innerHTML = "We have a gift for you..."
  await delay(3000);
  response.innerHTML = "You can now proudly claim..."
  await delay(3000);
  response.innerHTML = "...To be one of the Heaven Aspirants! (check your wallet)"
  controlsHeavenKey.start({y:["0.5vw","-0.5vw","0.5vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
}

export const load = async () => {
  let confetti = import('../components/confetti') // Module loaded (export default)!
};

export const init_animation = async () => {
  console.log("InitAnimation");
  controlsKeyHoleJar.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
  //infinityJar.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
  controlsShadow.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:1}})
  controlsBigShadow.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:2}})
};



export const enterHeavenOfLegends = async() =>{
  load();
  const realGetKey = document.getElementById('buttonGetHK');
  realGetKey.className= "hidden";
  const response = document.getElementById('response');
  response.className = "hidden";
  welcome.start({scale:[0,0,1,1,0,0],transition:{times:[0,0.4,0.41,0.69,0.70,1],damping: 10 ,type:'spring',stiffness: 100,duration:15 }})
  gods.start({scale:[0,0,1,1,0],transition:{times:[0,0.7,0.71,0.9,0.91],damping: 10 ,type:'spring',stiffness: 100,duration:15 }})
  giftInfinityJar.start({scale:[0,0,1],transition:{times:[0,0.9,0.91],damping: 10 ,type:'spring',stiffness: 100,duration:15 }})

  gift.start({scale:1,transition:{delay:6,damping: 10 ,type:'spring',stiffness: 100,duration:1 }})
  coinPile.start({scale:1,transition:{delay:6.33,damping: 10,type:'spring',stiffness: 100,duration:1 }})
  pigCoin.start({scale:1,transition:{delay:6.66,damping: 10,type:'spring',stiffness: 100,duration:1 }})
  shield.start({x:"0vw",transition:{delay:7,damping: 10,type:'spring',stiffness: 100,duration:1 }})
  sword_1.start({x:"0vw",y:"0vw",transition:{delay:7.33,damping: 10,type:'spring',stiffness: 100,duration:1 }})
  sword_2.start({x:"0vw",y:"0vw",transition:{delay:7.40,damping: 10,type:'spring',stiffness: 100,duration:1 }})
  heaven_key.start({scale:1,transition:{delay:8,damping: 10,type:'spring',stiffness: 100,duration:1 }})
  hbarCoin.start({scale:1,transition:{delay:8.33,damping: 10,type:'spring',stiffness: 100,duration:1 }})

  infinityJar.start({y:"2vw",transition:{delay:14,duration:5}})

  controlsButtonUseKey.start({opacity:0, transition: { duration: 3 }})
  controlsBackground.start({opacity:[1,1,0,0],transition:{duration: 5,times:[0,0.7,0.9,1]}});
  controlsShadow.start({opacity:[0.5,0.5,0,0],transition:{duration: 5,times:[0,0.7,0.9,1]}});
  controlsBigShadow.start({opacity:[0.5,0.5,0,0],transition:{duration: 5,times:[0,0.7,0.9,1]}});
  controlsKeyHoleJar.start({opacity:[1,1,0.3,0],scale:[1,1,100,100],y:["0vw","-5vw","-300vw","-300vw"],transition:{duration: 5,times:[0,0.7,0.9,1]}});

  await delay(19000);
  infinityJar.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
}

const Mint = () => {

  controlsButtonUseKey = useAnimationControls();
  controlsKeyHoleJar = useAnimationControls();
  controlsBackground = useAnimationControls();
  controlsShadow = useAnimationControls();
  controlsBigShadow = useAnimationControls();
  welcome = useAnimationControls();
  gods = useAnimationControls();
  giftInfinityJar = useAnimationControls();
  gift = useAnimationControls();
  coinPile = useAnimationControls();
  pigCoin = useAnimationControls();
  shield = useAnimationControls();
  sword_1 = useAnimationControls();
  sword_2 = useAnimationControls();
  heaven_key = useAnimationControls();
  hbarCoin = useAnimationControls();
  infinityJar = useAnimationControls();

  const [pairingString, setPairingString] = useState('')
  const controlsHeavenKey = useAnimationControls();
  return (
    <section className="overflow-hidden">
      <div className="App" onload={init_animation()}>
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <canvas class="confetti absolute h-screen w-screen z-10" id="canvas"></canvas>
          {/* Header & Social networks */}
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-14 z-50 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a>
            <p className="text-white text-[0.6rem] lg:text-[1rem] top-2 lg:-top-10 right-[10rem] lg:right-[25rem] absolute " id='accountid'></p>
            {pairingString && 
            <p>{pairingString}</p>
            }  
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}  
              class="italic px-4 lg:px-16 py-1 lg:py-2 parrallelogram absolute bg-[#8626EC] hover:bg-[#8611EC] top-1.5 right-2 lg:right-10 lg:-top-16 z-50" 
              onClick={async () => {
                const saveData = await pairHashpack(controlsHeavenKey)
                //setPairingString(saveData.pairingString)
              }}>
              <span className="text-radial-gradient lg:text-2xl font-bold">Connect wallet</span>
            </motion.button>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
            </figure>

            <figure className="absolute z-20 -bottom-20 -right-20">
            <img src="/assets/trame.svg" alt="" />
            </figure>

            <motion.img 
          animate = {controlsBackground}
          src="/assets/Heaven.jpg" 
          className=" h-screen object-cover right-0 left-0 mx-auto lg:w-screen absolute z-10"></motion.img>

          <span  className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center">
            <motion.img 
            initial={{opacity:0.5}}
            animate = {controlsBigShadow}
            src="/assets/Big_Shadow.png" 
            className="  h-[20rem] lg:h-[50rem] 2xl:h-[55rem] 3xl:h-[60rem] z-20 "></motion.img>
          </span>

          <span  className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center">
            <motion.img
            initial={{opacity:0.5}}
            animate={controlsShadow}
            src="/assets/Little_Shadow.png" 
            className=" h-[20rem] lg:h-[40rem] 2xl:h-[45rem] 3xl:h-[50rem] z-30 "></motion.img>
          </span>

          <span  className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center">
            <motion.img 
            animate ={controlsKeyHoleJar}
            src="/assets/KEY_HOLE_JAR.png"
            className="h-[15rem] mt-10 lg:-mt-10 lg:h-[22rem] 2xl:h-[26rem] 3xl:h-[32rem] z-30 "></motion.img>
          </span>
        
          <motion.img 
          animate={welcome}
          initial={{scale:0}}
          src="/assets/Welcome.png"
          className='
            absolute
            right-0
            left-0
            mx-auto
            w-screen
            lg:w-[40rem]
            2xl:w-[70rem]
            3xl:w-[75rem]
            bottom-[19.5rem]
            lg:bottom-0
            2xl:-bottom-14
            3xl:bottom-0'>
          </motion.img>
          <motion.img 
          animate={gods}
          initial={{scale:0}}
          src="/assets/Gods.png"
          className='
            absolute
            right-0
            left-0
            mx-auto
            w-screen
            lg:w-[40rem]
            2xl:w-[70rem]
            3xl:w-[75rem]
            bottom-[19.5rem]
            lg:bottom-0
            2xl:-bottom-14
            3xl:bottom-0'>
          </motion.img>
          <motion.img 
          animate={giftInfinityJar}
          initial={{scale:0}}
          src="/assets/TheGiftInfinityJar.png"
          className='
            absolute
            right-0
            left-0
            mx-auto
            w-screen
            lg:w-[40rem]
            2xl:w-[70rem]
            3xl:w-[75rem]
            bottom-[19.5rem]
            lg:bottom-0
            2xl:-bottom-14
            3xl:bottom-0'>
          </motion.img>
          <motion.img 
            animate={infinityJar}
            initial={{y:"-100vw"}}
            src="/assets/Infinity jar.png" 
            className="
              h-[9rem]
              lg:h-[14rem]
              2xl:h-[22rem] 
              3xl:h-[22rem] 
              z-0 absolute 
              mx-auto 
              left-0 
              right-0 
              top-[11rem]
              lg:top-[10rem]
              2xl:top-40">
            </motion.img>
            <motion.img
            initial={{scale:0}}
            animate ={gift}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/gift.png">
          </motion.img>
          <motion.img
            initial={{scale:0}}
            animate ={coinPile}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] m-auto left-0 right-0'
            src="/assets/CoinPile.png">
          </motion.img>
          <motion.img
            initial={{y:"-80vw",x:"50vw"}}
            animate ={sword_2}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/Sword_22.png">
          </motion.img>
          <motion.img
            initial={{y:"-120vw",x:"-45vw"}}
            animate ={sword_1}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/Sword_1.png">
          </motion.img>
          <motion.img
            initial={{scale:0}}
            animate ={pigCoin}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/pig&coin.png">
          </motion.img>
          <motion.img
            initial={{x:"40vw"}}
            animate ={shield}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/shield.png">
          </motion.img>
          <motion.img
            initial={{scale:0}}
            animate ={heaven_key}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/HeavenKey.png">
          </motion.img>
          <motion.img
            initial={{scale:0}}
            animate ={hbarCoin}
            className='absolute bottom-[25rem] lg:bottom-[5rem] 2xl:bottom-[5rem] 3xl:bottom-[10rem] lg:w-[50rem]  2xl:w-[70rem] 3xl:w-[75rem] mx-auto left-0 right-0'
            src="/assets/HbarCoin.png">
          </motion.img>
            <figure className="grid place-content-center absolute bottom-0 h-[20%] w-screen"> 
                    <motion.button
                    id ='FakebuttonGetHK'
                    onClick=""
                    className="parrallelogram bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Get your Infinity Jar !
                        </h3>
                    </motion.button>
                    <motion.button
                    id ='buttonGetHK'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}  
                    onClick={async () => {OnClickGetKey()}}
                    className="hidden parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-radial-gradient z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Get your Infinity Jar !
                        </h3>
                    </motion.button>
                    <h3
                      className="italic opacity-0 text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold" 
                      id='response'> void
                    </h3>
                </figure>
          
          <figure className="absolute -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          
        </section>
      </div>
    </section>
  );
}

export default Mint;