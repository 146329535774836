import React, { useRef, useState } from 'react';
import '../../styles/mint.css';
import { 
  motion,
} from "framer-motion"



const Mint = () => {
    const [pairingString, setPairingString] = useState('')
    let posSword = 0;
    let trigger = false;
  return (
    <section className="overflow-hidden">
      <div className="App">    
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a> 
            <h2 className='absolute text-white uppercase text-shadow-blue-low-mobile  italic  font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50'>How many Infinity Jars have been forged?</h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>
          <figure className="absolute z-20 -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          <img src="/assets/Legends_Memories/Mathstalgia.png" className='ring-4 ring-[#E5AE20] absolute w-[80%] lg:w-auto lg:h-[80%] m-auto left-0 right-0 -top-[14rem] lg:top-16 bottom-0 pointer-events-none'></img>
          <button id="parchemin_button"  onClick={()=>{
                        
          }}>
            <motion.img 
            animate={{
              rotate:[10,-10,10] ,
              transition:{
                duration: 3,
                times:[0,0.5,1],
                repeatType: "loop",
                repeat: Infinity,
                type: "tween",
                damping: 25,
                stiffness: 500}}}
            drag
            dragConstraints={{
                top: -150,
                left: 50,
                right:50,
                bottom: 0,
            }}
            //dragTransition={{ bounceStiffness: 1500, bounceDamping: 500 }}
            //dragElastic={0.03}
            onDrag={
                (event, info) => {
                    if(posSword === 0 || posSword === undefined){
                        console.log("set sword");
                        posSword = info.point.x;
                    }
                    console.log(info.point.x);
                    console.log("sword: "+posSword.toString());
                    if(info.point.x >= posSword + 5 && trigger == false){
                        //show button
                        console.log("show button");
                        trigger = true;
                        let element= document.getElementById("help_text");
                        element.className = "lg:relative absolute h-[43%] w-[100%] lg:h-[50%] lg:w-[40%] 2xl:w-[45%]  bg-[#25191ECC] m-auto left-0 right-0  lg:top-[15rem] bottom-0";
                        //let element2 = document.getElementById("parchemin_button");
                        //element2.className = "hidden";
                    }
                }
                }
            whileTap={{ cursor: "grabbing" }}
            src="/assets/Legends_Memories/magnifying_glass_2.png" 
            className=' absolute h-[10%] w-auto lg:h-[20%] lg:w-auto m-auto left-0 right-0 top-[0rem] lg:top-[20rem] bottom-0'>
          </motion.img>
                </button>
          
          <div id="help_text" className='hidden lg:relative absolute h-[43%] w-[100%] lg:h-[50%] lg:w-[40%] 2xl:w-[45%]  bg-[#25191ECC] m-auto left-0 right-0  lg:top-[15rem] bottom-0 '>
            <button 
            className='hidden lg:block absolute right-2 top-2 lg:h-3 lg:w-3 2xl:h-5 2xl:w-5 '
            onClick={()=>{
                let element= document.getElementById("help_text");
                        element.className = "hidden lg:relative absolute h-[43%] w-[100%] lg:h-[50%] lg:w-[40%] 2xl:w-[45%]  bg-[#25191ECC] m-auto left-0 right-0  lg:top-[15rem] bottom-0";
            }}>
                <img
                src="/assets/Legends_Memories/close.png"
                className='h-[100%] w-[100%]'>

                </img>
            </button>
            <p className="w-full text-[0.8rem] lg:text-[0.9rem] 2xl:text-[1.2rem] lg:max-w-2xl text-white mx-auto  text-justify font-normal px-5 leading-[0.9rem] lg:leading-[1.2rem] 2xl:leading-[1.5rem]  3xl:px-0 mt-2 lg:pt-10 2xl:mt-6 "> 
            The Infinity Jar fascinates many mortals. Forged by the Gods, the Infinity Jar is the most valuable artifact (NFT) in the Heaven of Legends. It will send you Heaven Keys, gold, weapons, unique artifacts, VIP access and even discounts. But the number of jars is unknown, hidden in the Land of Mathstalgia by a mathematical riddle on a stone tablet.
            <br/><br/>
            On the stone tablet, you can read:<br/><br/>
            <p className='text-[0.9rem] lg:text-[1rem] 2xl:text-[1.3rem] leading-[0.9rem] lg:leading-[1.2rem] 2xl:leading-[1.5rem] font-medium italic'>"Add the first two prime numbers, then multiply by the smallest even number, then multiply the first step and the square of the second step to get the result."</p>
            </p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Mint;