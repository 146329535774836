import React from 'react';
import { Link } from 'react-router-dom';
import { 
  motion
} from "framer-motion"

let discordAlert = "The Council of Legends will soon open its doors to the privileged Heaven Keys holders 🗝";

const Footer = () => {
  return (
    <footer className="relative before:absolute before:md:w-full before:w-9/12 before:sm:max-w-lg before:left-0 before:-top-10 mt-10 before:border-t-[40px] before:border-r-[40px] before:border-t-black bg-black before:border-r-transparent text-white pt-16 pb-10 pl-10 sm:pl-20 pr-10">
      {/* Resume/Menus */}
      <aside className="flex flex-wrap items-end justify-between container mx-auto">
        {/* Resume */}
        <article className="w-full mb-5 lg:mb-10 ">
          <h2 className="text-2xl italic font-bold mb-10">
            Legends Of The Past
          </h2>
          <p>
            Legends of the Past is a mobile game based on the people who
            shaped History.
          </p>
        </article>
        {/* Social Networks */}
        <nav className="mb-10 order-2 lg:order-3">
          <ul className="flex space-x-3">
            {/* Discord */}
            <li>
              <a href="https://discord.gg/wYB7bsjVQ5">
                <figure>
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    src="/assets/social_networks/discord.svg"
                    alt="Discord"
                    title="Discord"
                    className="w-10"
                  />
                </figure>
              </a>
            </li>
            {/* Twitter */}
            <li>
              <a href="https://twitter.com/legends_of_past">
                <figure>
                  <motion.img
                    whileHover={{ scale: 1.1 }}
                    src="/assets/social_networks/twitter.svg"
                    alt="Twitter"
                    title="Twitter"
                    className="w-10"
                  />
                </figure>
              </a>
            </li>
          </ul>
        </nav>
        <a href="/">
          <motion.img
            whileHover={{ scale: 1.1 }}
            src="/assets/Icon_legends_of_the_past.svg"
            title="LEGENDS OF THE PAST"
            alt=""
            className="z-50 w-14 -top-1 lg:w-28 lg:-top-24 lg:left-0"
        /></a>
      </aside>
      <div className="!bg-[#34384C]/80 h-px container mx-auto" />
      {/* Licence */}
      <aside className="flex mt-10 flex-wrap justify-between container mx-auto">
        {/* Banner */}
        <p className="shrink-0">2023 ©. All rights reserved.</p>
        {/* Licence documentations */}
        <ul className="flex flex-wrap">
          <li className="border-r border-white pr-3 mr-3 sm:pr-6 sm:mr-6">
            <Link to="/">Terms</Link>
          </li>
          <li>
            <Link to="/">Privacy Policy</Link>
          </li>
        </ul>
      </aside>
    </footer>
  );
};

export default Footer;
