import React, { useRef, useState } from 'react';
import '../styles/mint.css';
import {pairHashpack,AskSupplyAndUpdateVisual,AskServerToSendKey,show_text} from '../GameManagement';
import { Helmet } from 'react-helmet';
import { 
  motion,
  useAnimationControls 
} from "framer-motion"
import { bool } from 'prop-types';

const delay = ms => new Promise(res => setTimeout(res, ms));

let tree=[];
let bools = [false,false,false,false];

let is_waiting=false;

let game_started = false;

let rand_1 ;

let buttonGKanim = "";

var blurred = false;

let find= false;

window.onblur = function() { blurred = true; };
window.onfocus = function() { 
  if(game_started && blurred && !find){
    location.reload()
  }
 };

export const load = async () => {
  let confetti = import('../components/confetti')
  tree[rand_1].src ="/assets/key3D.png" // Module loaded (export default)!
};

export const loss = async () =>{
  ShowEndGame();
}


const init = async () =>{
  
    let container_tree= document.getElementById("tree_container");
    for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 5; j++) {
            try {
              //<button id="parchemin_button" className='z-50'  onClick={ async ()=>{switch_light(0)}}>
              
              const newImg = document.createElement("img");
              newImg.id=`tree_${j+i*5}`;
              newImg.src="/assets/Game/tree.png"
              newImg.className=` opacity-100 w-20  lg:w-[10rem] aspect-square   z-50`
              newImg.onclick = () => switch_tree(j+i*5);
              console.log(`left-[${5+(j*20)}%] top-[${5+(i*20)}%]`);
              tree.push(newImg);
              container_tree.appendChild(newImg);
              await delay(100)
            } catch (error) {
              console.log(error);
            }
        }
    }


    rand_1 = getRandomInt(19);
    
    
    //const newImg = document.createElement("img");
    //newImg.src="/assets/key3D.png"
    //newImg.className=`absolute opacity-100 w-20  lg:w-auto  lg:h-20  left-[${5+rand_1*20}%] top-[${5+rand_2*20}%] z-40`
    //container_tree.appendChild(newImg);


}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

function launchGame(){
  let element= document.getElementById("hidder_game");
  element.className = "hidden";
  game_started = true;

}

let nft_available = 4;
export const GetNFTavailable =(number) => {
  nft_available = number;
  console.log(`front get ${nft_available} NFT`);
  if(nft_available <=0){
    ShowEndGame();
  }
}

const ShowEndGame=() => {
  let element= document.getElementById("end_game");
  element.className = "absolute  w-[100%]  lg:w-auto z-50 aspect-square lg:h-[100%]  bg-[#000000] m-auto  left-0  right-0  bottom-0";
}

export const WalletConnectedNoToken = () => {

  const response = document.getElementById('response');
  response.innerHTML = "You need to be a Founder Card Holder to play the game!"

}


let GameIsOk = false;
export const WalletConnectedTokenAssociated = () => {

  const buttonGetKey = document.getElementById('FakebuttonSG');
  const realGetKey = document.getElementById('buttonSG');
  const response = document.getElementById('response');

  GameIsOk = true;
  init();

  buttonGetKey.className= "hidden"
  realGetKey.className= "parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
  response.innerHTML = "Beware: changing tabs or apps resets the game!"
}

const switch_tree = (index) =>{

    if(is_waiting){
      return;
    }
    is_waiting = true;
    
    if (index === rand_1) {
      find = true;
      if (nft_available <= 0) {
        ShowEndGame();
      }
      else{
        if(GameIsOk){
          AskServerToSendKey()
        }
        //const buttonGetKey = document.getElementById('FakebuttonGetHK');
        //buttonGetKey.className= "hidden"
        //const realGetKey = document.getElementById('buttonGetHK');
        //realGetKey.className= "parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[100%] lg:h-[3.5rem] md:text-2xl font-bold mx-auto mt-10"
        //buttonGKanim.start({rotate:[-2, 2, -2],
        //  transition: {
        //    repeat: Infinity,
        //    duration: 0.4
        //  }})
      }
      
    }
    else{
      tree[index].className ="opacity-0 w-20 lg:w-[10rem] aspect-square z-50"
      waitingScreen();
    }
    
}

const waitingScreen = async ()=>{
  let element= document.getElementById("waiting_screen");
  element.className = 'absolute  z-50 bg-black opacity-80 absolute  w-[100%]  lg:w-auto z-50 aspect-square lg:h-[100%]  bg-[#000000] m-auto  left-0  right-0  bottom-0'

  let text = document.getElementById("waiting_text");

  for (let index = 10; index != 0; index--) {
    text.innerHTML= `Wait ${index} seconds before looking under another tree.`
    await delay(1000);
  }

  is_waiting = false;
  element.className = 'hidden absolute z-50 bg-black opacity-80 w-[80%] lg:w-auto aspect-square lg:h-[80%] m-auto left-0 right-0 -top-[14rem] lg:top-[10rem] lg:top-16 bottom-0'

}

const check_if_win = ()=>{
    if(true)
    {
        show_answer();
    }
}



const show_answer = ()=>{
    let element= document.getElementById("help_text");
    element.className = " absolute h-[20%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191EEE] m-auto left-0 right-0 bottom-[10rem] lg:bottom-[3rem]  z-50 ";
}

const Mint = () => {
    const [pairingString, setPairingString] = useState('')
    buttonGKanim = useAnimationControls();
    
  return (
    <section className="overflow-hidden" onLoad={AskSupplyAndUpdateVisual()}>
       <Helmet title="Legends Of The Past">
        {/*<!-- Google tag (gtag.js) -->*/}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-CZENPTTH1C"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          if( typeof window.dataLayer !== "undefined")
          {
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-CZENPTTH1C');
          }
          
          `}
          
        </script>
      </Helmet>
      <div className="App">    
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <canvas class="confetti absolute h-screen w-screen z-10 pointer-events-none" id="canvas"></canvas>
        <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a> 
            <h2 className='absolute text-white uppercase text-shadow-blue-low-mobile  italic font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50'>Earthlings X LOP</h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
            {pairingString && 
            <p>{pairingString}</p>
            }
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}  
              class="italic px-4 lg:px-16 py-1 lg:py-2 parrallelogram absolute z-50 bg-[#8626EC] hover:bg-[#8611EC] top-1.5 right-2 lg:right-10 lg:-top-16" 
              onClick={async () => {
                const saveData = await pairHashpack()
                //setPairingString(saveData.pairingString)
              }}>
              <span className="text-radial-gradient text-[0.5rem] lg:text-xl font-bold " id='accountid'>Connect wallet</span>
            </motion.button>
          </figure>
          <figure className="absolute z-10 -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          <div className='grid grid-cols-1 grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 h-screen w-screen lg:pt-20 place-items-center'>
            <div className="relative h-[20rem] w-[20rem] lg:h-[30rem] lg:w-[30rem] 2xl:h-[40rem] 2xl:w-[40rem] 3xl:h-[50rem] 3xl:w-[50rem]">
              <div 
            id="hidder_game"
            className='
              absolute 
              w-[100%] 
              lg:w-auto
              z-40
              aspect-square
              lg:h-[100%] 
              bg-[#000000]
              m-auto 
              left-0 
              right-0 
              bottom-0'>
                <figure className="grid col-1 gap-5 content-center h-full">
                    <motion.button
                    id ='FakebuttonSG'
                    onClick=""
                    className="parrallelogram bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Start the game!
                        </h3>
                    </motion.button>
                    <motion.button
                    id ='buttonSG'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}  
                    onClick={async () => {launchGame()}}
                    className="hidden parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic opacity-100 text-radial-gradient z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Start the game!
                        </h3>
                    </motion.button>
                    <h3
                      className="italic opacity-100 text-white z-50 mx-8 lg:mx-0 h-10 text-[0.8rem] lg:text-[1.2rem] font-bold" 
                      id='response'> Connect your wallet first
                    </h3>
                </figure>
              </div>
              <div 
            id="end_game"
            className='
              hidden
              absolute 
              w-[100%] 
              lg:w-auto
              z-50
              aspect-square
              lg:h-[100%] 
              bg-[#000000]
              m-auto 
              left-0 
              right-0 
              bottom-0'>
                <figure className="grid col-1 gap-5  h-full ">
                    <h3
                      className="italic place-self-center opacity-100 text-white z-50 mx-8 lg:mx-0 h-10 text-[0.8rem] lg:text-[1.2rem] font-bold" 
                      id='response'> The game has ended! All Heaven Key has been claimed! 
                    </h3>
                </figure>
              </div>
              <img 
            src="/assets/Game/earthlings_background.jpg" 
            className='
              ring-4 
              ring-[#E5AE20] 
              absolute 
              w-[100%] 
              lg:w-auto
              z-30
              aspect-square
              lg:h-[100%] 
              bg-[#000000]
              m-auto 
              left-0 
              right-0 
              bottom-0 
              pointer-events-none'>
              </img>
              <div className='
              absolute 
              w-[100%] 
              lg:w-auto
              z-40
              aspect-square
              lg:h-[100%] 
              m-auto 
              left-0 
              right-0 
              bottom-0 
              pointer-events-none'>
              <motion.img
              animate={{
                y:["0.5vw","-0.5vw","0.5vw"],
                transition:{
                  duration: 6,
                  times:[0,0.5,1],
                  repeatType: "loop",
                  repeat: Infinity,
                  type: "tween",
                  damping: 25,
                  stiffness: 500}
              }}
             src="/assets/Game/Earthlings_white.png"
             title=""
             alt=""
             className="w-24 lg:w-32 2xl:w-40   mx-auto z-40 relative top-2 lg:top-5 pointer-events-none"
              />
              </div>
              <div id="tree_container" className=' 
                absolute 
                w-[100%] 
                lg:w-auto
                aspect-square
                lg:h-[100%] 
                m-auto 
                left-0 
                z-30
                right-0 
                bottom-0 
                grid 
                grid-cols-5
                content-end
                '>
              </div>
              <div
            id="waiting_screen"
            className='
              hidden
              absolute 
              z-50
              bg-black
              opacity-50
              absolute 
              w-[100%] 
              lg:w-auto
              z-50
              aspect-square
              lg:h-[100%] 
              bg-[#000000]
              m-auto 
              left-0 
              right-0 
              bottom-0'>
              <figure className="grid col-1 gap-5 content-center h-full">
                <p id="waiting_text" className="w-full opacity-100 text-[1rem] lg:text-[1.2rem] 3xl:text-[1.3rem] lg:max-w-2xl text-white mx-auto text-center  font-normal px-5 leading-[1rem] lg:leading-[1.2rem] 2xl:leading-[1.4rem]  3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">  
                Wait X seconds before you can look under another tree. 
                </p>
              </figure>
              </div>
            </div>
            <figure className='z-40 mx-auto right-0 lg:my-auto lg:h-[80%] w-[80%] lg:w-[60%]  text-white place-self-start'>
           <img
             src="/assets/key3D.png"
             //src="/assets/HEAVEN_ASPIRANT_TRANSPARENT.png"
             title=""
             alt=""
             className="w-[8rem] lg:w-[12rem] 2xl:w-[18rem] 3xl:w-[22rem] mx-auto z-20 relative"
           />
           <figcaption className="text-center relative text-shadow-dark-gold tracking-wide text-lg lg:text-5xl font-bold z-10 top-10">
             Heaven Key
           </figcaption>
           <div class="flex justify-between mb-1 mt-10">
             <span class="text-base font-medium text-white-700 dark:text-white z-10">Supply</span>
             <span id="supplyText" class="text-sm font-medium text-white-700 dark:text-white z-10">15/15</span>
           </div>
           <div class="w-full relative bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 z-10">
             <div id="supplyLoadingBar"  class="bg-green-600 relative h-2.5 rounded-full z-10" style={{width:"100%"}}></div>
           </div>
           <motion.button
            id ='FakebuttonGetHK'
            onClick=""
            className="hidden parrallelogram bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[100%] lg:h-[3.5rem]  md:text-2xl font-bold mx-auto mt-10" >
                <h3
                id ='textGetHK'
                className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                    Collect the Key!
                </h3>
            </motion.button>
            <motion.button
            id ='buttonGetHK'
            animate = {buttonGKanim}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={async () => {}}
            className="hidden parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[100%] lg:h-[3.5rem] md:text-2xl font-bold mx-auto mt-10" >
                <h3
                id ='textGetHK'
                className="italic text-radial-gradient z-50 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                    Collect the Key!
                </h3>
            </motion.button>
            <h3
                      className="hidden italic opacity-100 text-white z-50 mx-0 lg:mx-0 h-10 text-[0.8rem] lg:text-[1.2rem] mt-4 font-bold" 
                      id='text_key'> all Heaven Key have already been claimed!
                    </h3>
          </figure>
          </div>
          
          
          
        </section>
      </div>
    </section>
  );
}

export default Mint;