import React, { useRef, useState } from 'react';
import '../../styles/mint.css';
import { 
  motion,
} from "framer-motion"



const Mint = () => {
    const [pairingString, setPairingString] = useState('')
    let posSword = 0;
  return (
    <section className="overflow-hidden">
      <div className="App">    
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a> 
            <h2 className='absolute text-white uppercase text-shadow-blue-low-mobile  italic  font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[1rem] lg:text-3xl z-50'>🔥 Help Julius Caesar !</h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>
          <figure className="absolute z-20 -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          <img src="/assets/Legends_Memories/JuliusCaesar.png" className='ring-4 ring-[#E5AE20] absolute w-[80%] lg:w-auto lg:h-[80%] m-auto left-0 right-0 -top-[14rem] lg:top-16 bottom-0 pointer-events-none'></img>
          <button id="parchemin_button"  onClick={()=>{
                        let element= document.getElementById("help_text");
                        element.className = " absolute h-[10%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191ECC] m-auto left-0 right-0 top-[25rem] lg:top-[20rem] bottom-0 ";
                        let element2 = document.getElementById("parchemin_button");
                        element2.className = "hidden";
          }}>
            <motion.img 
            animate={{
              rotate:[10,-10,10] ,
              transition:{
                duration: 3,
                times:[0,0.5,1],
                repeatType: "loop",
                repeat: Infinity,
                type: "tween",
                damping: 25,
                stiffness: 500}}}
            src="/assets/Legends_Memories/parchemin2.png" 
            className=' absolute h-[10%] w-auto lg:h-[20%] lg:w-auto m-auto left-0 right-0 top-[25rem] lg:top-[20rem] bottom-0'>
          </motion.img>
                </button>
          
          <div id="help_text" className='hidden absolute h-[10%] w-[100%] lg:h-[20%] lg:w-[40%] bg-[#25191ECC] m-auto left-0 right-0 top-[25rem] lg:top-[20rem] bottom-0 '>
            <p className="w-full text-[0.6rem] lg:text-[0.7rem] 3xl:text-[0.9rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]  3xl:px-0 mt-2 lg:mt-2 2xl:mt-6"> 
            Julius has just received a message by a pigeon from one of his spies:<br/>
              "wkh hqhpb kdv vwduwhg wr pryh, wkhb zloo eh khuh lq 2 gdbv"
            </p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Mint;