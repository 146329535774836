import {HashConnect} from 'hashconnect'

import {
    AccountId,
    TokenId,
    TopicId,
    ScheduleId,
    ScheduleInfoQuery,
    TokenAssociateTransaction,
    TopicMessageSubmitTransaction,
    TransferTransaction,
    ScheduleSignTransaction,
    Client,
    PrivateKey,
    Hbar
} from '@hashgraph/sdk'

//for trigger the confetti
import {load,enterHeavenOfLegends,showEnterHeavenButton} from './pages/mint';

const axios = require('axios').default;

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

//const keyTokenId = TokenId.fromString('0.0.48705603');
//const keyTokenId = TokenId.fromString('0.0.3388478');
//const amphoraTokenId = TokenId.fromString('0.0.3064593');
const keyTokenId = TokenId.fromString('0.0.1385462');
const amphoraTokenId = TokenId.fromString('0.0.1881189');

const price = 0.01;

//const treasuryId = AccountId.fromString("0.0.3064213");
//const operatorId = AccountId.fromString("0.0.3064213");
const treasuryId = AccountId.fromString("0.0.1380960");
const operatorId = AccountId.fromString("0.0.1380960");

let serial_number =-1

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}


//tx -> bytes -> base64 -> ... -> tx signer by back

//TODO make appear description
const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can exchange your Heaven Key against the Infinity jar, a jar that will give you lot of things.',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}



export const pairHashpack = async (keyButton = null) => {
    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic
    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = "Connected to: "+pairingData.accountIds[0];
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        if(keyButton != null){
            CheckAccountHasKeyForInfinityJar(keyButton);
        }
    })
    return initData;
}

//make the diff with last Heaven Key serial(335 => 350)
export const AskSupplyAndUpdateVisual= async () => 
{
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${amphoraTokenId}/nfts?account.id=${treasuryId}`
    //if we have a response => break transaction because we don't have NFT to give 

    axios
		.get(url)
		.then(function (response) {
            const jsonResponse = response.data;
            if(jsonResponse.nfts.length != 0){
                console.log(`${jsonResponse.nfts.length} NFT are still available`);
            }
            else{
                console.log('NFT are all sold');
            }
            document.getElementById("supplyLoadingBar").style.width =(jsonResponse.nfts.length*2).toString()+"%";
            document.getElementById("supplyText").innerHTML = jsonResponse.nfts.length.toString()+"/50";
		})
		.catch(function (err) {
            console.log(`error: ${err}`);
		})
    await new Promise(resolve => setTimeout(resolve, 5000)); //5 seconds
    AskSupplyAndUpdateVisual();
}

async function AskIfTokenAssociatedPrivate(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar)
{
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${amphoraTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken(false,welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
            }
            else{
                console.log(`token ${amphoraTokenId} is already associated`);
                TransferNFTAgainstNFTFunction(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

 //TODO profile to optimize max transactionFee for prod
 //test fee calculator
 //https://hedera.com/fees 
 //recuperer le prix du hbar en direct
async function AssociateToken(publicSales,welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar)
{
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(saveData.pairedAccounts[0])
            .setTokenIds([amphoraTokenId])
            .setMaxTransactionFee(new Hbar(150))
            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            if(publicSales){
                AskIfNftAvailable();
            }
            else{
                TransferNFTAgainstNFTFunction(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
            }
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
    
    
}

async function TransferNFTAgainstNFTFunction(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar)
{
    await CheckAccountHasKey();
    const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
    const signer = hashconnect.getSigner(provider)
    console.log(`serial: ${serial_number}`);
    
    let tokenTransfer = await new TransferTransaction()
        .addNftTransfer(keyTokenId,parseInt(serial_number),saveData.pairedAccounts[0],treasuryId)
        .addHbarTransfer(treasuryId, price)
        .addHbarTransfer(saveData.pairedAccounts[0], -price)
        .freezeWithSigner(signer);

    let tokenTransferSubmit = await tokenTransfer.executeWithSigner(signer);

    console.log(JSON.stringify(tokenTransferSubmit))
    //other solution query balance of user
    //depend of hashpack ask May Chan about ExecuteWithSigner return value
    //swap if esle
    if (tokenTransferSubmit != undefined){
        console.log(`user success to send Hbar, He is now waiting his NFT`);
        //load();
        enterHeavenOfLegends(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
    }
    else{
        console.log(`user refuse to accept the transaction`);
    }
}

export const privateSales = async(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar) =>{
    AskIfTokenAssociatedPrivate(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
}

export const CheckAccountHasKey = async() => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;

    
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length == 0){
                alert("Did you have a heaven Key?");
            }
            else{
                serial_number = jsonResponse.nfts[0].serial_number.toString()
                console.log(`Mirror event(s): ${serial_number}`);
                //CheckAccountHasEnoughHbar();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
}

export const CheckAccountHasKeyForInfinityJar = async(keyButton) => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;

    const responseHeavenKey = document.getElementById('response');
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length != 0){
                showEnterHeavenButton(keyButton);
            }
            else{
                alert(`You don't have a Heaven Key. You cannot enter.`);
                responseHeavenKey.innerHTML = "You don't have a Heaven Key. You cannot enter.";
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
    
    await new Promise(resolve => setTimeout(resolve, 5000));
    responseHeavenKey.innerHTML ="";
}

export const CheckAccountHasKeyForDiscord = async() => {
    if(saveData.pairedAccounts==0){
        alert("Connect wallet first !");
        return;
    }
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    console.log(`event discord`);
    const discordResponse = document.getElementById('discordResponse');
    let getKey = false;
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;

            if (jsonResponse.nfts.length != 0){
                console.log(`The discord Link`);
                AskDiscordLink();
                getKey = true;
            }
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});

    if(!getKey){
        const url2 = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${amphoraTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc`;
        await axios
        .get(url2)
        .then(function (response) {
            const jsonResponse = response.data;

            if (jsonResponse.nfts.length != 0){
                console.log(`The discord Link`);
                AskDiscordLink();
                getKey = true;
            }
            else{
                console.log(`Do you have a Heaven Key or an Infinity Jar?`);
                discordResponse.innerHTML = "Do you have a Heaven Key or an Infinity Jar?";
            }
        })
        .catch(function (err) {
            console.error(err);
            alert(err.toString());
        });
    }
    
    if(!getKey){
        await new Promise(resolve => setTimeout(resolve, 5000));
        discordResponse.innerHTML ="";
    }
    
}


const AskDiscordLink = async() =>{
    const url = `https://l71u2npns8.execute-api.eu-west-2.amazonaws.com/AskDiscordAccess?WalletId=${saveData.pairedAccounts[0]}`
    const discordResponse = document.getElementById('discordResponse');
    await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            console.log(response.data);
            //console.log(`The discord Link`);
            discordResponse.innerHTML = "https://discord.gg/"+jsonResponse;
            discordResponse.href = "https://discord.gg/"+jsonResponse;
            //getKey = true;
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
}

//#region NOT USE

const topicIdAccountWhoPay = TopicId.fromString("0.0.48966679");
// Big jar topic 0.0.48964106
// Big jar 2 topic 0.0.48966679

const operatorKey = PrivateKey.fromString("302e020100300506032b657004220420d1b5321b1caf7c1a6494f92ff1ae1678f0834474b73d333ced38262608ee1738");
const client = Client.forTestnet().setOperator(operatorId,operatorKey)
client.setMirrorNetwork("hcs.testnet.mirrornode.hedera.com:5600")

async function AskIfTokenAssociatedPublic()
{
    const url = `https://testnet.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${amphoraTokenId}`;

    //try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken(true);
            }
            else{
                console.log(`token ${amphoraTokenId} is already associated`);
                AskIfNftAvailable();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

async function AskIfNftAvailable(){
    const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${amphoraTokenId}/nfts?account.id=${treasuryId}`
    //if we have a response => break transaction because we don't have NFT to give 

    axios
		.get(url)
		.then(function (response) {
            const jsonResponse = response.data;
            if(jsonResponse.nfts.length != 0){
                TransferNFTAgainstHbarFunction();
                console.log(`${jsonResponse.nfts.length} NFT are still available`);
            }
            else{
                console.log('NFT are all sold');
            }
		})
		.catch(function (err) {
            console.log(`error: ${err}`);
			TransferNFTAgainstHbarFunction();
		})
}

//TODO make it a schedule transaction
//INFO I don't use a client to sign so it's became a secure function
async function TransferNFTAgainstHbarFunction()
{
    const provider = hashconnect.getProvider('testnet', saveData.topic, saveData.pairedAccounts[0])
    const signer = hashconnect.getSigner(provider)
    let tokenTransfer = await new TransferTransaction()
        .addHbarTransfer(treasuryId, price)
        .addHbarTransfer(saveData.pairedAccounts[0], -price)
        .freezeWithSigner(signer);

    let tokenTransferSubmit = await tokenTransfer.executeWithSigner(signer);

    console.log(JSON.stringify(tokenTransferSubmit))
    //other solution query balance of user
    //depend of hashpack ask May Chan about ExecuteWithSigner return value
    //swap if esle
    if (tokenTransferSubmit != undefined){
        console.log(`user success to send Hbar, He is now waiting his NFT`);
    }
    else{
        console.log(`user refuse to accept the transaction`);
    }
}

//WARNING schedule transaction in back and sign it
//front make same transaction and sign with client account to protect the back key
//schedule transaction
export async function ExecuteScheduleTransaction()
{
    const scheduleId = ScheduleId.fromString('0.0.49139903');

    const provider = hashconnect.getProvider('testnet', saveData.topic, saveData.pairedAccounts[0])
    const signer = hashconnect.getSigner(provider)

    const query0 = await new ScheduleInfoQuery()
    .setScheduleId(scheduleId)
    .execute(client);

   //Confirm the signature was added to the schedule   
   console.log(query0);

    //Create the transaction
    const transaction = await new ScheduleSignTransaction()
    .setScheduleId(scheduleId)
    .freezeWithSigner(signer);

    const txSubmit = await transaction.executeWithSigner(signer);

    if (txSubmit != undefined){
        console.log(`Seems Successfull`);
    }
    else{
        console.log(`Maybe a ERROR`);
    }

    const query1 = await new ScheduleInfoQuery()
     .setScheduleId(scheduleId)
     .execute(client);

    //Confirm the signature was added to the schedule   
    console.log(query1);
}

// ? if problem occur here  
//TODO try catch => delay and relaunch
//INFO want to don't use Topic anymore
//can be in back   
async function SubmitMessage(account_number){
    // Send one message
    let sendResponse = await new TopicMessageSubmitTransaction({
    	topicId: topicIdAccountWhoPay,
    	message: `${account_number} has payed ${price}Hbar`,
    }).execute(client);

    //Get the receipt of the transaction
    const getReceipt = await sendResponse.getReceipt(client);

    //Get the status of the transaction
    const transactionStatus = getReceipt.status
    console.log(`The message transaction status ${transactionStatus}`)
}

export const publicSales = async() =>{
    AskIfTokenAssociatedPublic();
}

async function CheckAccountHasEnoughHbar() 
{
    const url = `https://testnet.mirrornode.hedera.com/api/v1/balances?account.id=${saveData.pairedAccounts[0]}&account.balance=gte:${price*100000000}`;
    
	axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.balances.length == 0){
                alert(`Do you have at least ${price}Hbar on your account ?`);
                return false;
            }
            else{
                console.log(`enough Hbar to buy the nft`);
                return true;
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});

}

//#endregion








