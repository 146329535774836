import Header from "../../CommitteeCleo/CommitteeHeader";
import Menu from "../../CommitteeCleo/CommitteeMenu";
import "../../../styles/mint.css";
import Auth from "../../../utils/auth/utils.js";
import { useEffect, useState } from "react";
import crown from "../../../assets/crown.svg";
const axios = require("axios").default;
import convertToJSONString from "../../../convertToJSONString.js";
import { useRef } from "react";
import convertEncodingQuote from "../../../convertEncodingChar.js";


const PublishArticle = () => {
    const [articleTitle, setArticleTitle] = useState('');
    const [articleContent, setArticleContent] = useState('');
    const [articleImage, setArticleImage] = useState(null);
    const [errorEmpty, seterrorEmpty] = useState(false);
    const [errorCharacters, setErrorCharacters] = useState(false);
    const [errorTooLongTitle, setErrorTooLongTitle] = useState(false);
    const [errorTooLongContent, setErrorTooLongContent] = useState(false);
    const [errorInvalidFormat, setErrorInvalidFormat] = useState(false);
    const [errorImgHeavy, setErrorImgHeavy] = useState(false);
    const [errorServer, setErrorServer] = useState(false);
    const [tryAdd, setTryAdd] = useState(false);
    const [changeTryState, setChangeTryState] = useState(false);
    const [confirmModale, setConfirmModale] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [waitingConfirmation, setWaitingConfirmation] = useState(false);

    const imgRef = useRef(null)
    // const addArticle = async (title, content, image) => {
    //     setIsLoading(true);
    //     let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=publishArticle&title=${title}&content=${content}&legend=Cleo`;
    // axios
    //   .get(url)
    //   .then(function (response) {
    //     response = response.data;
    //     setIsLoading(false);

    //     if (response == "ok") {
    //       setIsPublished(true);
    //     } else {
    //       setErrorServer(true);
    //     }
    //   })
    //   .catch(function (err) {
    //     console.log(err);
    //     alert(err.toString());
    //   });
    // }
    const addArticle = async (title, content, image) => {
        setIsLoading(true);
        const auth = Auth.getAuth()
        const account = Auth.getAccount()

        try {
            if (image) {

                const formData = new FormData();
                formData.append('image', imgRef.current.files[0]);

                let urlImg = `https://xkpj2ofe2j.execute-api.eu-west-2.amazonaws.com/default/ImageServer?fileName=${imgRef.current.files[0].name}`
                const responseImg = await axios.post(urlImg, formData);

                let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=publishArticle`;

                const body = JSON.stringify({
                    title: convertEncodingQuote(title),
                    content: convertEncodingQuote(content),
                    visual: responseImg.data.IpfsHash,
                    user: account,
                    legend: "Cleo",
                    auth: JSON.stringify(auth.userSignature),
                    sign: JSON.stringify(auth.signedPayload),
                })

                const response = await axios.post(url, body);
                console.log(response.data)
                setIsLoading(false);
                if (response.data === 'ok' || response.status !== 200) {
                    setIsPublished(true);
                } else {
                    setErrorServer(true);
                }
            } else {
                let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=publishArticle`;

                const body = JSON.stringify({
                    title: convertEncodingQuote(title),
                    content: convertEncodingQuote(content),
                    visual: "",
                    user: account,
                    legend: "Cleo",
                    auth: JSON.stringify(auth.userSignature),
                    sign: JSON.stringify(auth.signedPayload),
                })

                const response = await axios.post(url, body);
                setIsLoading(false);
console.log(response.data)
                if (response.data === 'ok' || response.status !== 200) {
                    setIsPublished(true);
                } else {
                    setErrorServer(true);
                }
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            setErrorServer(true);
        }
    };

    
    function convertOctetsToMb(octets) {
        return (octets / (1024 * 1024)).toFixed(2);
    }
    function convertOctetsToMbNoFixed(octets) {
        return (octets / (1024 * 1024))
    }

    const handleTitleChange = (event) => {
        setArticleTitle(event.target.value);
        resetErrors();
      };
    
    const handleContentChange = (event) => {
        setArticleContent(event.target.value);
        resetErrors();
    };

    const handleImageChange = (event) => {
        setArticleImage(event.target.files[0]);
        resetErrors()
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setWaitingConfirmation(true);
        if (!articleTitle || !articleContent) {
            seterrorEmpty(true);
        } 
        if (articleTitle.length > 100) {
            setErrorTooLongTitle(true);
        }
        if (articleContent.length > 2000) {
            setErrorTooLongContent(true);
        }
        if (articleTitle.includes('&') || articleContent.includes('&')) {
            setErrorCharacters(true)
        }
        if (articleImage) {
            if (articleImage.type !== 'image/jpg' && articleImage.type !== 'image/png' && articleImage.type !== 'image/webp' && articleImage.type !== 'image/jpeg') {
                setErrorInvalidFormat(true);
            }

            if (convertOctetsToMbNoFixed(articleImage.size) > 5) {
                setErrorImgHeavy(true);     
            }
        }

        setChangeTryState(changeTryState ? false : true);
        setTryAdd(true);
      };

    const resetForm = () => {
        setArticleTitle('');
        setArticleContent('');
        setArticleImage(null);
        seterrorEmpty(false);
        setErrorTooLongTitle(false);
        setErrorTooLongContent(false);
        setErrorInvalidFormat(false);
        setErrorImgHeavy(false);
        setErrorCharacters(false);
        setErrorServer(false);
        setIsLoading(false);
        setIsPublished(false);
    };

    const resetErrors = () => {
        seterrorEmpty(false);
        setErrorTooLongTitle(false);
        setErrorTooLongContent(false);
        setErrorInvalidFormat(false);
        setErrorImgHeavy(false);
        setErrorServer(false);
        setErrorCharacters(false);
        setIsLoading(false);
        setIsPublished(false);
    }

    useEffect(() => {
        if(tryAdd) {
            if(!errorEmpty && !errorTooLongTitle && !errorInvalidFormat && !errorTooLongContent && !errorImgHeavy && !errorCharacters) {
                setConfirmModale(true)
            }
            setTryAdd(false);
        }
    }, [changeTryState]);

    return(
        <section className="overflow-hidden">
        <div className="App">
            <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] min-h-screen bg-cover" style={{ backgroundImage: `url(/assets/HeavenKey_Space.jpg)` }}>
            {/* LEGENDS OF THE PAST */}
            <Header/>
                <figure className="flex flex-col h-full lg:h-[90%] p-5 lg:p-10 w-screen">
                    <figure className="relative flex flex-col h-[100%] ring-[0.2rem] w-full backdrop-blur ring-[#E3CA46] bg-[#00000088] rounded-lg z-10 m-auto">
                        <span className="hidden absolute lg:block top-3 left-3 text-red-500 text-start">*Required</span>
                        <section className="w-[80%] lg:w-1/2 m-auto justify-center items-center">
                            <div className="flex justify-center">
                                <img src={crown} className="w-10 h-10 lg:w-20 lg:h-20"></img>
                            </div>
                            <h1 className="w-full text-radial-gradient uppercase italic font-bold text-center w-full text-[1.7rem] lg:text-[2rem] z-50">Publish Article</h1>
                            <div className="w-[100%] m-auto overflow-y-auto">
                                <form className="flex flex-col gap-5 lg:gap-10 p-4">
                                    <span className="lg:hidden top-3 left-3 text-red-500 text-start">*Obligatory</span>

                                    <div className="flex flex-col gap-3">
                                        <label htmlFor="title" className="text-start text-white text-[1.5rem]"><span className="text-red-500">*</span>Title:</label>
                                        <input placeholder="max: 100 characters, all characters allowed except '&'" id="title" className="text-black rounded-full p-3" type="text" maxLength="100" value={articleTitle} onChange={handleTitleChange} />
                                        {errorTooLongTitle && (
                                            <p className="text-red-500">Title too long : max 100 characters</p>
                                        )}
                                    </div>

                                    <div className="flex flex-col gap-3">
                                        <label htmlFor="content" className="text-white text-start text-[1.5rem]"><span className="text-red-500">*</span>Content:</label>
                                        <textarea placeholder="max: 2000 characters, all characters allowed except '&'" id="content" className="text-black rounded-lg p-5" rows="5" value={articleContent} maxLength="2000" onChange={handleContentChange} />
                                        {errorTooLongContent && (
                                            <p className="text-red-500">Content too long : max 2000 characters</p>
                                        )}
                                    </div>

                                    <div className="flex flex-col gap-3">
                                        <label htmlFor="image" className="p-5 w-full text-white border-2 border-white rounded-full bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg cursor-pointer">
                                            Import img (png, jpg, webp)
                                            <input ref={imgRef} lang="en" className="hidden" name='image' id="image" type="file" accept=".png,.jpg,.webp" onChange={handleImageChange} />
                                        </label>
                                        {articleImage && (
                                            <p className="text-white">{articleImage.name}, {convertOctetsToMb(articleImage.size) +'Mb'}</p>
                                        )}
                                        {errorInvalidFormat && (
                                            <p className="text-red-500">Invalid format, only jpg, png or webp</p>
                                        )}
                                        {errorImgHeavy && (
                                            <p className="text-red-500">Image too heavy: max 5Mb</p>
                                        )}
                                    </div>
                                    
                                    {errorEmpty && (
                                        <p className="text-red-500">Please fill in the required fields</p>
                                    )}
                                    {errorCharacters && (
                                        <p className="text-red-500">Invalid characters, all allowed except '&'</p>
                                    )}
                                    <div className="flex gap-6 justify-center">
                                        <button type="button" className="flex justify-center items-center w-[15rem] text-center text-white text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem] p-2 border-2 border-[#E3CA46] bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg" onClick={resetForm}>Reset</button>
                                        <button type="button" className="flex justify-center items-center w-[15rem] text-center text-white text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem] p-2 border-2 border-[#E3CA46] bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg" onClick={(e) => {handleSubmit(e)}}>Publish</button>
                                    </div>
                                    
                                </form>
                            </div>
                            
                        </section>
                        {confirmModale && <dialog className='absolute w-[100%] bg-[#25191ECC] m-auto flex flex-col justify-center gap-3 h-[100%]'>
                            {waitingConfirmation && <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                                <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">Are you sure you want to publish this article ?</p>
                                <div className="flex justify-center items-center gap-3">
                                    <button onClick={() => {setConfirmModale(false)}} className="w-full text-white border p-1 md:p-1.5 border-white text-[1.2rem] lg:text-[1.2rem]">Cancel</button>
                                    <button  className="w-full text-white border p-1 md:p-1.5 border-white text-[1.2rem] lg:text-[1.2rem]" onClick={() => {setWaitingConfirmation(false), addArticle(articleTitle, articleContent, articleImage)}}>Publish</button>
                                </div>
                            </div>}
                            {isLoading && <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                                <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">Loading...</p>
                            </div>}
                            {isPublished && !isLoading && <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                                <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">The article has been published successfully &#x2713;</p>
                                <div className="flex justify-center items-center gap-3">
                                    <button onClick={() => {setConfirmModale(false), resetForm()}} className="w-full text-white border p-1 md:p-1.5 border-white text-[1r.2em] lg:text-[1r.2em]">Close</button>
                                </div>
                            </div>}

                            {errorServer && !isLoading && <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                                <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">An error occurred, please try again</p>
                                <div className="flex justify-center items-center gap-3">
                                    <button onClick={() => {setConfirmModale(false), resetErrors()}} className="w-full text-white border p-1 md:p-1.5 border-white text-[1.2rem] lg:text-[1.2rem]">Close</button>
                                </div>
                            </div>}
                            </dialog>}
                    </figure>
                </figure>
            </section>
        </div>
        <Menu />
        </section>
    )
}

export default PublishArticle