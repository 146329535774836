import React, { useRef, useState } from 'react';
import '../../styles/mint.css';
import { 
  motion,
} from "framer-motion"



const Mint = () => {
    const [pairingString, setPairingString] = useState('')
    let posSword = 0;
  return (
    <section className="overflow-hidden">
      <div className="App">    
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a> 
            <h2 className='absolute text-white uppercase text-shadow-blue-low-mobile  italic  font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50'>🔥 Help King Arthur !</h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>
          <figure className="absolute z-20 -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          <img src="/assets/Legends_Memories/RockKingArthur.png" className='ring-4 ring-[#E5AE20] absolute w-[80%] lg:w-auto lg:h-[80%] m-auto left-0 right-0 -top-[14rem] lg:top-16 bottom-0 pointer-events-none'></img>
          <motion.img 
            src="/assets/Legends_Memories/swordClean.png" 
            className=' absolute w-[9%] lg:w-auto lg:h-[55%] m-auto left-0 right-0 -top-[14rem] lg:top-16 bottom-0'
            drag
            dragConstraints={{
              top: -150,
              left: 0,
              right:0,
              bottom: 0,
            }}
            dragTransition={{ bounceStiffness: 1500, bounceDamping: 500 }}
            dragElastic={0.03}
            onDrag={
                (event, info) => {
                    if(posSword === 0 || posSword === undefined){
                        console.log("set sword");
                        posSword = info.point.x;
                    }
                    console.log(info.point.x);
                    console.log("sword: "+posSword.toString());
                    if(info.point.x >= posSword + 5){
                        //show button
                        console.log("show button");
                        let element= document.getElementById("read_blade");
                        element.className = " absolute h-[30%] w-[100%] lg:h-[38%] lg:w-[40%] bg-[#25191ECC] m-auto left-0 right-0 top-[25rem] lg:top-[20rem] bottom-0  ";
                    }
                }
              }
            whileTap={{ cursor: "grabbing" }}>

          </motion.img>
          <img src="/assets/Legends_Memories/RockKingArthur_rock.png" className=' absolute w-[80%] lg:w-auto lg:h-[80%] m-auto left-0 right-0 -top-[14rem] lg:top-16 bottom-0 pointer-events-none'></img>
          <div id="read_blade" className='hidden absolute h-[30%] w-[100%] lg:h-[38%] lg:w-[40%] bg-[#25191ECC] m-auto left-0 right-0 top-[25rem] lg:top-[20rem] bottom-0 '>
            <p className="w-full text-[0.6rem] lg:text-[0.7rem] 3xl:text-[0.9rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]  3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                With a mighty heave and an incredible effort, you grasp the hilt of the legendary sword, Excalibur, firmly in your hands. Your muscles strain and bulge as you pull with all your might, feeling the resistance of the stone as it seems to cling to the blade. You grit your teeth, refusing to give up, and summon all the strength you can muster. Finally, with a triumphant ringing sound, the sword slides free of the stone, and you hold it aloft, basking in the glorious victory of your feat.
                <br></br> <br></br>
                As you take a moment to examine your prize, you notice that the blade is coated in a thick layer of dust and grime. With care, you wipe away the filth, revealing the sword's shining, polished surface. As you do, you notice a strange message etched into the metal of the blade. It reads: "Take me up, cast me away."
            </p>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Mint;