import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Index from "./pages";
import Mint from "./pages/mint";
import Discord from "./pages/discord";
import HeavenKey from "./pages/HeavenKey";
import HeavenAspirant from "./pages/HeavenAspirant";
import Airdrop from "./pages/airdrop";
import Rgpd from "./pages/Rgpd";
import Cleo from "./pages/Cleo";
import Committee from "./pages/CommitteeCleo/CommitteeCleo";
import Governance from "./pages/CommitteeCleo/Governance";
import Earthlings from "./pages/Earthlings";
import Kabila from "./pages/Kabila";
import HashPack from "./pages/Hashpack";
import Creamies from "./pages/Creamies";
import Test from "./pages/testAnim";
import InfinityJar from "./pages/InfinityJar";
import OpenScroll from "./pages/OpenScroll";
import NoMatch from "./pages/404";
import KingArthur from "./pages/legendsMemories/kingarthur";
import Confucius from "./pages/legendsMemories/Confucius";
import FridaKahlo from "./pages/legendsMemories/Fridakhalo";
import NikolaTesla from "./pages/legendsMemories/NikolaTesla";
import ADSC from "./pages/legendsMemories/Waiter";
import JuliusCaesar from "./pages/legendsMemories/JuliusCaesar";
import Mathstalgia from "./pages/legendsMemories/Mathstalgia";
import PuzzleGame from "./pages/legendsMemories/PuzzleHint";
import Template from "./pages/legendsMemories/Template";
import Connection from "./pages/CommitteeCleo/Connection";
import PublishArticle from "./pages/CommitteeCleo/PublishArticle";
import PublishVote from "./pages/CommitteeCleo/PublishVote";
import Guard from "./utils/auth/Guard";
import Coin from "./pages/Coin/Coin";
import Drop from "./pages/Coin/Drop";
import GuardDrop from "./pages/Coin/GuardDrop";
//import MagicLinkConnection from "./test/linkpage";
import Memory from "./pages/legendsMemories/Memory";
import GuardPublish from "./utils/auth/GuardPublish";
import Champions from "./pages/CommitteeCleo/Champions";
import Reward from "./pages/CommitteeCleo/Reward";

const App = () => {
  return (
    <BrowserRouter>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/" element={<Index />} />

        <Route path="/login" element={<Connection />} />
        <Route
          path="/committee"
          element={
            <Guard>
              <Committee />
            </Guard>
          }
        />
        {/* <Route path="/governance" element={<Guard><Governance /></Guard>}/>
        <Route path="/PublishArticle" element={<Guard><GuardPublish><PublishArticle /></GuardPublish></Guard>}/>
        <Route path="/PublishVote" element={<Guard><GuardPublish><PublishVote /></GuardPublish></Guard>}/>*/}
        <Route
          path="/champions"
          element={
            <Guard>
              <Champions />
            </Guard>
          }
        />
        <Route
          path="/reward"
          element={
            <Guard>
              <Reward />
            </Guard>
          }
        />
        {/*<Route path="/mint" element={<Cleo />} />*/}
        <Route path="/airdrop" element={<Airdrop />} />
        <Route path="/open" element={<OpenScroll />} />

        <Route
          path="/drop"
          element={
            <GuardDrop>
              <Drop />
            </GuardDrop>
          }
        />
        <Route path="/coin" element={<Coin />} />

        {/*<Route path="/test" element={<MagicLinkConnection />} />*/}

        <Route path="/mathstalgia" element={<Mathstalgia />} />
        <Route path="/sword" element={<KingArthur />} />
        <Route path="/virtue" element={<Confucius />} />
        <Route path="/painting" element={<FridaKahlo />} />
        <Route path="/tesla" element={<NikolaTesla />} />
        <Route path="/encodedmessage" element={<JuliusCaesar />} />
        <Route path="/puzzle" element={<PuzzleGame />} />
        <Route path="/template" element={<Template />} />
        <Route path="/memory" element={<Memory />} />
        <Route path="/privacy" element={<Rgpd />} />

        <Route path="/waiter" element={<ADSC />} />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

//
//

//<Route path="/mint" element={<Cleo />} />
//<Route path="/scroll" element={<OpenScroll/>} />

//<Route path="/cleoaspirant" element={<HeavenAspirant />} />
//<Route path="/heavenkey" element={<HeavenKey />} />
//<Route path="/mint" element={<Mint />} />
//<Route path="/earthlings" element={<Earthlings />} />
//<Route path="/kabila" element={<Kabila />} />
//<Route path="/hashpack" element={<HashPack />} />
//<Route path="/creamies" element={<Creamies />} />

//
//<Route path="/test" element={<Test />} />
//<Route path="/lightbulb" element={<NikolaTesla />} />

//<Route path="/infinityjar" element={<InfinityJar />} />
//<Route path="/heavenkey" element={<HeavenKey />} />
//
//<Route path="/discordaccess" element={<Discord />} />
//<Route path="/virtue" element={<Confucius />} />
//<Route path="/painting" element={<FridaKahlo />} />
//<Route path="/message" element={<JuliusCaesar />} />

//<Route path="/infinityjar" element={<InfinityJar />} />
//<Route path="/airdrop" element={<Airdrop />} />

//<Route path="/forest" element={<Earthlings />} />
