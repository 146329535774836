import Header from "../../CommitteeCleo/CommitteeHeader";
import Menu from "../../CommitteeCleo/CommitteeMenu";
import "../../../styles/mint.css";
import Auth from "../../../utils/auth/utils.js";
import { useEffect, useState } from "react";
import crown from "../../../assets/crown.svg";
const axios = require("axios").default;
import convertToJSONString from "../../../convertToJSONString.js";
import { useRef } from "react";
import convertEncodingQuote from "../../../convertEncodingChar.js";


const PublishVote = () => {
    const [voteTitle, setVoteTitle] = useState('');
    const [voteContent, setVoteContent] = useState('');
    const [voteDateStart, setVoteDateStart] = useState(new Date().toISOString().split("T")[0]);
    const [voteDuration, setVoteDuration] = useState('');

    const [optionImage, setOptionImage] = useState(null);
    const [optionTitle, setOptionTitle] = useState('');

    const [optionsImageArray, setOptionsImageArray] = useState([]);
    const [optionsTitleArray, setOptionsTitleArray] = useState([]);
    const [optionsImgSrcArray, setOptionsImgSrcArray] = useState([]);
    const [optionsImgName, setOptionsImgName] = useState([]);

    const [errorEmptyTitleOption, setErrorEmptyTitleOption] = useState(false);
    const [errorTooLongTitleOption, setErrorTooLongTitleOption] = useState(false);
    const [errorInvalidFormat, setErrorInvalidFormat] = useState(false);
    const [errorImgHeavy, setErrorImgHeavy] = useState(false);
    const [errorTooManyOptions, setErrorTooManyOptions] = useState(false);
    const [errorCharactersOption, setErrorCharactersOption] = useState(false);

    const [tryAddOption, setTryAddOption] = useState(false);
    const [changeTryStateOption, setChangeTryStateOption] = useState(false);

    const [errorEmpty, seterrorEmpty] = useState(false);
    const [errorTooLongTitle, setErrorTooLongTitle] = useState(false);
    const [errorTooLongContent, setErrorTooLongContent] = useState(false);
    const [errorServer, setErrorServer] = useState(false);
    const [errorInvalidDate, setErrorInvalidDate] = useState(false);
    const [errorInvalidTime, setErrorInvalidTime] = useState(false);
    const [errorNoOptions, setErrorNoOptions] = useState(false);
    const [errorCharacters, setErrorCharacters] = useState(false);

    const [tryAdd, setTryAdd] = useState(false);
    const [changeTryState, setChangeTryState] = useState(false);
    const [confirmModale, setConfirmModale] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [waitingConfirmation, setWaitingConfirmation] = useState(false);

    const imgRef = useRef(null)

    const addNewVote = async () => {
        const auth = Auth.getAuth()
        const account = Auth.getAccount()
        setIsLoading(true);
        let duration = voteDuration * 24 * 60 * 60;
        let start = new Date(voteDateStart)
        start.setHours(new Date().getHours());
        start.setMinutes(new Date().getMinutes());
        let dateStart = start.getTime()

        try {
            let result = false;
            const formDataArray = [];

            try {
                for (let i=0; i < optionsImageArray.length; i++) {
                    if (optionsImageArray[i] && optionsImageArray[i] !== '') {

                        const formData = new FormData();
                        formData.append('image', optionsImageArray[i]);
                        let imageUploadUrl = `https://xkpj2ofe2j.execute-api.eu-west-2.amazonaws.com/default/ImageServer?fileName=${optionsImgName[i].imgRef.current.files[0].name}`;

                        const responseImg = await axios.post(imageUploadUrl, formData);
                        formDataArray.push(responseImg.data.IpfsHash);

                        if (responseImg.data == '!ok') {
                            setErrorServer(true);
                        }
            
                    } else {
                        formDataArray.push('');
                    }
                }
            } catch (error) {
                setErrorServer(true);
                console.log(error);
            }
            try{
                let voteUrl = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=publishVote`;
                // let voteUrl = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=event`;
                const body = JSON.stringify({
                    title: convertEncodingQuote(voteTitle),
                    content: convertEncodingQuote(voteContent),
                    duration: duration,
                    start: Math.floor(dateStart / 1000),
                    legend: 'Cleo',
                    user: account,
                    auth: JSON.stringify(auth.userSignature),
                    sign: JSON.stringify(auth.signedPayload),
                    options: optionsTitleArray.map ((option) => convertEncodingQuote(option)),
                    image: formDataArray,
                })
                const responseVote = await axios.post(voteUrl, body);
                console.log(responseVote.data)  
                
                if (responseVote.data !== 'ok') {
                    setErrorServer(true);
                } else {
                    result = true
                }
                setIsLoading(false);
                        
                if (result) {
                    setIsPublished(true);
                }
            } catch (error) {
                console.log(error)
                setErrorServer(true);
            }
            // if (responseVote.data !== '!ok') {
            //     for (let i = 0; i < optionsTitleArray.length; i++) {
            //         if (optionsImageArray[i] !== null) {
            //             const formData = new FormData();
            //             formData.append('image', optionsImageArray[i]);
            //             let imageUploadUrl = `https://xkpj2ofe2j.execute-api.eu-west-2.amazonaws.com/default/ImageServer?fileName=${imgRef.current.files[0].name}`;
        
            //             try {
            //                 const responseImg = await axios.post(imageUploadUrl, formData);
        
            //                 if (responseImg.data === '!ok') {
            //                     throw new Error('Image upload failed');
            //                 }
        
            //                 let optionUrl = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=addOption&idOption=${i + 1}&option=${optionsTitleArray[i]}&idVote=${responseVote.data}&image=${responseImg.data.IpfsHash}&user=${account}&auth=${JSON.stringify(auth.userSignature)}&sign=${JSON.stringify(auth.signedPayload)}`;
            //                 const responseOption = await axios.get(optionUrl);
        
            //                 if (responseOption.data !== 'ok') {
            //                     setErrorServer(true);
            //                     result = false;
            //                     throw new Error('Option creation failed');
            //                 }
            //             } catch (error) {
            //                 console.log(error.message);
            //                 setErrorServer(true);
            //                 result = false;
            //                 // Rejetez explicitement la promesse pour que le bloc catch externe puisse capturer l'erreur
            //                 return Promise.reject(error);
            //             }
            //         } else {
            //             let optionUrl = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=addOption&idOption=${i + 1}&option=${optionsTitleArray[i]}&idVote=${responseVote.data}&image=&user=${account}&auth=${JSON.stringify(auth.userSignature)}&sign=${JSON.stringify(auth.signedPayload)}`;
            //             const responseOption = await axios.get(optionUrl);
        
            //             if (responseOption.data !== 'ok') {
            //                 setErrorServer(true);
            //                 result = false;
            //                 throw new Error('Option creation failed');
            //             }
            //         }
                // }
            // } else {
            //     setErrorServer(true);
            //     result = false;
            //     throw new Error('Vote creation failed');
            // }
    
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            setErrorServer(true);
        }
        
        
    };

    
    function convertOctetsToMb(octets) {
        return (octets / (1024 * 1024)).toFixed(2);
    }
    const handleVoteTitleChange = (event) => {
        setVoteTitle(event.target.value);
        resetErrors();
      };
    
    const handleVoteContentChange = (event) => {
        setVoteContent(event.target.value);
        resetErrors();
    };

    const handleVoteDateStartChange = (event) => {
        setVoteDateStart(event.target.value);
        resetErrors();
    };
    const handleVoteDurationChange = (event) => {
        setVoteDuration(event.target.value);
        resetErrors();
    };

    const handleOptionTitleChange = (event) => {
        setOptionTitle(event.target.value);
        setErrorEmptyTitleOption(false);
        setErrorTooLongTitleOption(false);
        resetErrors();
    };

    const handleOptionImageChange = (event) => {
        setOptionImage(event.target.files[0]);
        resetErrors();
    }

    const handleAddOption = () => {
        if (optionTitle === '') {
            setErrorEmptyTitleOption(true);
            return;
        }
        if(optionTitle.length > 100) {
            setErrorTooLongTitleOption(true);
            return;
        }
        if (optionImage !== null) {
            if (optionImage.type !== 'image/jpg' && optionImage.type !== 'image/png' && optionImage.type !== 'image/webp' && optionImage.type !== 'image/jpeg') {
                setErrorInvalidFormat(true);
            }

            if (convertOctetsToMb(optionImage.size) > 5) {
                setErrorImgHeavy(true);     
            }
        }
        if (optionTitle.includes('&')) {
            setErrorCharactersOption(true);
        }
        if (optionsTitleArray.length >= 10) {
            setErrorTooManyOptions(true);
        }
        setTryAddOption(true);
        setChangeTryStateOption(changeTryStateOption ? false : true);
    }

    const handleRemoveOption = (e, index) => {
        e.preventDefault();
        const newArrayOptionsTitle = [...optionsTitleArray];
        const newArrayOptionsImg = [...optionsImageArray];
        const newArrayOptionsImgSrc = [...optionsImgSrcArray];

        newArrayOptionsTitle.splice(index, 1);
        newArrayOptionsImg.splice(index, 1);
        newArrayOptionsImgSrc.splice(index, 1);

        setOptionsTitleArray(newArrayOptionsTitle);
        setOptionsImageArray(newArrayOptionsImg);    
        setOptionsImgSrcArray(newArrayOptionsImgSrc);
        resetErrors();
    }

    const resetOption = () => {
        setOptionTitle('');
        setOptionImage(null);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setWaitingConfirmation(true);
        if (voteTitle === "" || voteContent === "" || voteDateStart === "" || voteDuration === "") {
            seterrorEmpty(true);
        }
        if (voteTitle.includes('&') || voteContent.includes('&')) {
            setErrorCharacters(true);
        }
        if (!voteTitle || !voteContent) {
            seterrorEmpty(true);
        } 
        if (voteTitle.length > 100) {
            setErrorTooLongTitle(true);
        }
        if (voteContent.length > 500) {
            setErrorTooLongContent(true);
        }

        const now = new Date();
        const date = new Date(voteDateStart);
        now.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);

        if (date < now ) {
            setErrorInvalidDate(true);
        }

        if (voteDuration < 1 || voteDuration > 30) {
            setErrorInvalidTime(true);
        }

        if (optionsTitleArray.length < 2) {
            setErrorNoOptions(true);
        }
        setChangeTryState(changeTryState ? false : true);
        setTryAdd(true);
      };

    const resetForm = () => {
        setVoteTitle('');
        setVoteContent('');
        setVoteDateStart('');
        setVoteDuration('');
        setOptionImage(null);
        setOptionTitle('');
        setOptionsImageArray([]);
        setOptionsTitleArray([]);
        setOptionsImgSrcArray([]);
        setOptionsImgName([]);
        setTryAddOption(false);
        setErrorEmptyTitleOption(false);
        setErrorTooLongTitleOption(false);
        seterrorEmpty(false);
        setErrorTooLongTitle(false);
        setErrorTooLongContent(false);
        setErrorInvalidFormat(false);
        setErrorImgHeavy(false);
        setErrorServer(false);
        setErrorInvalidDate(false);
        setErrorInvalidTime(false);
        setErrorNoOptions(false);
        setErrorTooManyOptions(false);
        setErrorCharactersOption(false);
        setErrorCharacters(false);
        setIsLoading(false);
        setIsPublished(false);
    };

    const resetErrors = () => {
        seterrorEmpty(false);
        setErrorTooLongTitle(false);
        setErrorTooLongContent(false);
        setErrorInvalidFormat(false);
        setErrorImgHeavy(false);
        setErrorInvalidDate(false);
        setErrorInvalidTime(false);
        setErrorServer(false);
        setErrorNoOptions(false);
        setIsLoading(false);
        setIsPublished(false);
        setErrorTooManyOptions(false);
        setErrorCharactersOption(false);
        setErrorCharacters(false);
    }

    useEffect(() => {
        if (tryAddOption) {
            if (!errorEmptyTitleOption && !errorTooLongTitleOption && !errorInvalidFormat && !errorImgHeavy && !errorTooManyOptions) {
                // Créer de nouvelles références pour les tableaux
                const newArrayOptionsTitle = [...optionsTitleArray];
                const newArrayOptionsImg = [...optionsImageArray];
                const newArrayOptionsImgSrc = [...optionsImgSrcArray];
                const newArrayOptionsImgName = [...optionsImgName];
        
                // Ajouter de nouvelles valeurs aux tableaux
                newArrayOptionsTitle.push(optionTitle);
                const processOption = async () => {
                    if (optionImage !== null) {
                        const reader = new FileReader();
                        const readImage = () => {
                            return new Promise((resolve) => {
                                reader.onload = () => {
                                    resolve(reader.result);
                                };
                            });
                        };
        
                        reader.readAsDataURL(optionImage);
                        const imageSrc = await readImage();
                        newArrayOptionsImg.push(imgRef.current.files[0]);
                        newArrayOptionsImgSrc.push(imageSrc);
                        newArrayOptionsImgName.push(imgRef.current.files[0].name);
 
                    } else {
                        newArrayOptionsImgName.push(null);
                        newArrayOptionsImg.push(null);
                        newArrayOptionsImgSrc.push(null);
                    }
        
                    // Mettre à jour l'état avec les nouvelles références de manière asynchrone
                    setOptionsTitleArray(newArrayOptionsTitle);
                    setOptionsImgSrcArray(newArrayOptionsImgSrc);
                    setOptionsImageArray(newArrayOptionsImg);
                    setOptionsImgName(newArrayOptionsImgName);
        
                    resetOption();
                    setTryAddOption(false);
                };
        
                processOption();
            }
        }
        
    }, [changeTryStateOption]);

    useEffect(() => {
        if(tryAdd) {
            if(!errorEmpty && !errorTooLongTitle && !errorTooLongContent && !errorInvalidDate && !errorInvalidTime && !errorNoOptions) {
                setConfirmModale(true)
            }
            setTryAdd(false);
        }
    }, [changeTryState]);

    return(
        <section className="overflow-hidden">
        <div className="App">
            <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] min-h-screen bg-cover" style={{ backgroundImage: `url(/assets/HeavenKey_Space.jpg)` }}>
            {/* LEGENDS OF THE PAST */}
            <Header/>
                <figure className="flex flex-col h-full lg:h-[90%] p-5 lg:p-10 w-screen">
                    <figure className="relative flex flex-col h-[100%] ring-[0.2rem] w-full backdrop-blur ring-[#E3CA46] bg-[#00000088] rounded-lg z-10 m-auto">
                        <span className="absolute left-4 top-4 text-red-500 text-start">*Required </span>
                        <section className="w-full lg:w-[80%] lg:w-full m-auto justify-center items-center">
                            <div className="flex justify-center">
                                <img src={crown} className="w-10 h-10 lg:w-16 lg:h-16"></img>
                            </div>
                            <h1 className="w-full text-radial-gradient uppercase italic font-bold text-center text-[1.5rem] lg:text-[1.7rem] z-50">Publish Vote</h1>
                            <div className="w-[100%] m-auto overflow-y-auto">
                                <form className="w-full flex flex-col gap-5 p-4">
                                    <div className="flex flex-col lg:flex-row lg:flex-row w-full gap-5">
                                        <div className="flex flex-col gap-3 w-full lg:w-[50%]">
                                            <label htmlFor="title" className="text-start text-white text-[1.5rem]"><span className="text-red-500">*</span>Title:</label>
                                                <input placeholder="max: 100 characters, all characters allowed except '&'" id="title" className="text-black rounded-full p-3" type="text" maxLength="100" value={voteTitle} onChange={handleVoteTitleChange} />
                                            {errorTooLongTitle && (
                                                <p className="text-red-500">Title too long : max 100 characters</p>
                                            )}

                                            <div className="flex flex-col lg:flex-row gap-3 w-full">
                                                <div className="flex flex-col w-full lg:w-[50%]">
                                                    <label htmlFor="title" className="text-start text-white text-[1.5rem]"><span className="text-red-500">*</span>Date start:</label>
                                                        <input placeholder="DD/MM/YYYY" id="title" className="text-black rounded-full p-3" type="date" value={voteDateStart} min={new Date().toISOString().split("T")[0]} maxLength="100" onChange={handleVoteDateStartChange} />
                                                    {errorInvalidDate && (
                                                        <p className="text-red-500">The date must be in the future and at the format DD/MM/YYYY</p>
                                                    )}
                                                </div>
                                                <div className="flex flex-col w-full lg:w-[50%]">
                                                    <label htmlFor="title" className="text-start text-white text-[1.5rem]"><span className="text-red-500">*</span>Duration:</label>
                                                        <input placeholder="Numbers of days, max: 30" id="title" className="text-black rounded-full p-3" type="number" min={1} max={30} value={voteDuration} onChange={handleVoteDurationChange} />
                                                    {errorInvalidTime && (
                                                        <p className="text-red-500">The time must be between 1 and 30</p>
                                                    )}
                                                </div>
                                            </div>

                                        </div>

                                        <div className="flex flex-col gap-3 w-full lg:w-[50%]">
                                            <label htmlFor="content" className="text-white text-start text-[1.5rem]"><span className="text-red-500">*</span>Description:</label>
                                                <textarea placeholder="max: 500 characters, all characters allowed except '&'" id="content" className="text-black rounded-lg p-5" rows="4" value={voteContent} maxLength="500" onChange={handleVoteContentChange} />
                                            {errorTooLongContent && (
                                                <p className="text-red-500">Content too long : max 500 characters</p>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex flex-col h-[100%] ring-[0.2rem] w-full backdrop-blur ring-[#E3CA46] bg-[#00000088] rounded-lg z-10 m-auto">
                                        <div>
                                            <h2 className="w-full text-radial-gradient uppercase italic font-bold text-center text-[1.4rem] lg:text-[1.6rem] z-50">Add options</h2>

                                            <div className="flex flex-col lg:flex-row gap-3 p-3">
                                                <div className="flex flex-col gap-3 w-full lg:w-[50%]">
                                                    <div className="flex flex-col gap-3">
                                                        <label htmlFor="title" className="text-start text-white text-[1.5rem]"><span className="text-red-500">*</span>Option Title:</label>
                                                            <input placeholder="max: 1000 characters, all characters allowed except '&'" id="title" className="text-black rounded-full p-3" type="text" maxLength="100" value={optionTitle} onChange={handleOptionTitleChange} />
                                                        {errorTooLongTitleOption && (
                                                            <p className="text-red-500">Title too long : max 100 characters</p>
                                                        )}
                                                        {errorEmptyTitleOption && (
                                                            <p className="text-red-500">Title can't be empty, please add a title</p>
                                                        )}
                                                    </div>

                                                    <div className="flex flex-col gap-3">
                                                        <label htmlFor="image" className="p-5 w-full text-white border-2 border-white rounded-full bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg cursor-pointer">
                                                            Import img (png, jpg, webp)
                                                            <input ref={imgRef} lang="en" className="hidden" id="image" type="file" accept=".png,.jpg,.webp" onChange={handleOptionImageChange} />
                                                        </label>
                                                        {optionImage && (
                                                            <p className="text-white">{optionImage.name}, {convertOctetsToMb(optionImage.size) +'Mb'}</p>
                                                        )}
                                                        {errorInvalidFormat && (
                                                            <p className="text-red-500">Invalid format, only jpg, png or webp</p>
                                                        )}
                                                        {errorImgHeavy && (
                                                            <p className="text-red-500">Image too heavy : max 5Mb</p>
                                                        )}
                                                    </div>
                                                        {errorTooManyOptions && (
                                                            <p className="text-red-500">Too many options, max: 10</p>
                                                        )}
                                                        {errorCharactersOption && (
                                                            <p className="text-red-500">Invalid characters, all allowed except '&'</p>
                                                        )}
                                                    <div className="flex flex-col m-auto lg:flex-row gap-6 justify-center w-full">
                                                        <button type="button" className="flex justify-center items-center w-full lg:w-[15rem] text-center text-white text-[0.7rem] lg:text-[1.25rem]  p-2 border-2 border-[#E3CA46] bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg" onClick={resetOption}>Reset option</button>
                                                        <button type="button" className="flex justify-center items-center w-full lg:w-[15rem] text-center text-white text-[0.7rem] lg:text-[1.25rem]  p-2 border-2 border-[#E3CA46] bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg" onClick={(e) => {handleAddOption()}}>Add this option</button>
                                                    </div> 
                                    
                                                </div>
                                                <div className="flex flex-col gap-3 w-full lg:w-[50%] border-t-2 lg:border-t-0 lg:border-l-2 border-[#E3CA46]">
                                                    <h2 className="w-full text-radial-gradient uppercase italic font-bold text-center text-[1.4rem] lg:text-[1.6rem] z-50">Options List</h2>
                                                    <ul className="flex flex-col gap-3 w-full lg:w-[90%] m-auto overflow-y-auto lg:h-[12rem]">
                                                        {optionsTitleArray.map((item, index) => (
                                                            <li key={index} className="text-white text-[1.5rem] border-b-2 border-[#E3CA46] px-2 pb-2 flex flex-row gap-8 w-[100%] items-center">
                                                                <div className="flex justify-cenetr items-center">
                                                                    <button type="button" className="text-[1rem] lg:text-[1.5rem] border-2 border-white p-2 w-[100%]" onClick={(e) => handleRemoveOption(e, index)}>X</button>
                                                                </div>
                                                                <div className="flex flex-col items-start justify-center w-[85%]">
                                                                    <div className="flex">
                                                                        <p className="text-start text-white text-[1.5rem]">{index + 1 + '. ' + item}</p>
                                                                    </div>
                                                                        <div className="flex justify-center items-center max-w-[20rem]">
                                                                            <img key={index} src={optionsImgSrcArray[index]}></img>
                                                                        </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {errorEmpty && (
                                        <p className="text-red-500">Please fill in the required fields</p>
                                    )}
                                    {errorNoOptions && (
                                        <p className="text-red-500">Please add at least 2 options</p>
                                    )}
                                    {errorCharacters && (
                                        <p className="text-red-500">Invalid characters, all allowed except '&'</p>
                                    )}
                                    <div className="flex gap-6 justify-center">
                                        <button type="button" className="flex justify-center items-center w-[15rem] text-center text-white text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem] p-2 border-2 border-[#E3CA46] bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg" onClick={resetForm}>Reset</button>
                                        <button type="button" className="flex justify-center items-center w-[15rem] text-center text-white text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem] p-2 border-2 border-[#E3CA46] bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg" onClick={(e) => {handleSubmit(e)}}>Publish</button>
                                    </div> 
                                    
                                </form>
                            </div>
                            
                        </section>
                        {confirmModale && <dialog className='absolute w-[100%] bg-[#25191ECC] m-auto flex flex-col justify-center gap-3 h-[100%] z-[100]'>
                            {waitingConfirmation && <div className="w-[50%] m-auto">
                                <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">Are you sure you want to publish this vote ?</p>
                                <div className="flex justify-center items-center gap-3">
                                    <button onClick={() => {setConfirmModale(false)}} className="w-full text-white border p-1 md:p-1.5 border-white text-[1rem] lg:text-[1rem]">Cancel</button>
                                    <button  className="w-full text-white border p-1 md:p-1.5 border-white text-[1rem] lg:text-[1rem]" onClick={() => {setWaitingConfirmation(false), addNewVote()}}>Publish</button>
                                </div>
                            </div>}
                            {isLoading && <div className="w-[50%] m-auto">
                                <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">Loading...</p>
                            </div>}
                            {isPublished && !isLoading && <div className="w-[50%] m-auto">
                                <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">The vote has been published successfully &#x2713;</p>
                                <div className="flex justify-center items-center gap-3">
                                    <button onClick={() => {setConfirmModale(false), resetForm()}} className="w-full text-white border p-1 md:p-1.5 border-white text-[1rem] lg:text-[1rem]">Close</button>
                                </div>
                            </div>}

                            {errorServer && !isLoading && <div className="w-[50%] m-auto">
                                <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">An error occurred, please try again</p>
                                <div className="flex justify-center items-center gap-3">
                                    <button onClick={() => {setConfirmModale(false), resetErrors()}} className="w-full text-white border p-1 md:p-1.5 border-white text-[1rem] lg:text-[1rem]">Close</button>
                                </div>
                            </div>}
                        </dialog>}
                    </figure>
                </figure>
            </section>
        </div>
        <Menu />
        </section>
    )
}

export default PublishVote