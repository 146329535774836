import React, { useRef, useState, useEffect } from "react";
import "../../styles/mint.css";
import { motion } from "framer-motion";
import piece0 from "../../assets/memory/1.png";
import piece1 from "../../assets/memory/2.png";
import piece2 from "../../assets/memory/3.png";
import piece3 from "../../assets/memory/4.png";
import piece4 from "../../assets/memory/5.png";
import piece5 from "../../assets/memory/6.png";
import piece6 from "../../assets/memory/7.png";
import piece7 from "../../assets/memory/8.png";
import piece8 from "../../assets/memory/9.png";

const Mint = () => {
  const dialogRef = useRef(null);
  const victoryRef = useRef(null);
  const canvaRef = useRef(null);
  const [isStarted, setIsStarted] = useState(false);
  const [piecesClic, setPiecesClic] = useState([]);
  const [memoryCards, setMemoryCards] = useState([
    { i: 0, value: 0, src: piece0 },
    { i: 1, value: 1, src: piece1 },
    { i: 2, value: 2, src: piece2 },
    { i: 3, value: 3, src: piece3 },
    { i: 4, value: 4, src: piece4 },
    { i: 5, value: 5, src: piece5 },
    { i: 6, value: 6, src: piece6 },
    { i: 7, value: 7, src: piece7 },
    { i: 8, value: 8, src: piece8 },
  ]);
  const [displayCards, setDisplayCards] = useState(memoryCards);
  const [level, setLevel] = useState(1);
  const [winStep, setWinStep] = useState(false);
  const [series, setSeries] = useState([]);
  const [isReadyToDisplayImg, setIsReadyToDisplayImg] = useState(false);
  const [isInteractive, setIsInteractive] = useState(false);
  const [error, setError] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);

  function startCountdown() {
    const countdownContainer = document.getElementById("instruction");

    function displayCountdown(count) {
      countdownContainer.innerHTML = count;

      if (count >= 1) {
        setTimeout(() => {
          displayCountdown(count - 1);
        }, 800);
      } else {
        countdownContainer.innerHTML = "Go!";
        setTimeout(() => {
          displaySeries();
        }, 800);
      }
    }

    setTimeout(() => {
      displayCountdown(3);
    }, 800);
  }

  function randomSeries() {
    let seriesList = [];
    for (let i = 0; i < level + 1; i++) {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * 9);
      } while (seriesList.includes(randomIndex));
      seriesList.push(randomIndex);
    }
    setIsReadyToDisplayImg(true);
    setSeries(seriesList);
  }

  function displaySeries() {
    const container = document.getElementById("series");

    function displayImage(index) {
      const cardToClic = document.createElement("img");
      cardToClic.classList.add("h-auto", "w-[3rem]");
      cardToClic.src = memoryCards[series[index]].src;
      container.innerHTML = ""; // Efface le contenu actuel du conteneur
      container.appendChild(cardToClic);

      const instruction = document.getElementById("instruction");
      if (index < series.length - 1) {
        setTimeout(() => {
          displayImage(index + 1);
        }, 1500);
      } else {
        setTimeout(() => {
          container.innerHTML = "";
          setTimeout(() => {
            instruction.innerHTML =
              "It's your turn to play, repeat the sequence!";
            setIsInteractive(true);
            setIsStarted(true);
          }, 1000);
        }, 1000);
      }
    }

    displayImage(0);
  }
  function handleClick(pieceObject) {
    const piece = document.getElementById(pieceObject.i);
    piece.disabled = true;
    let length = piecesClic.length;
    setPiecesClic([...piecesClic, pieceObject]);

    if (pieceObject.i !== series[length]) {
      setIsInteractive(false);
      setError(true);
      setIsStarted(false);

      setTimeout(() => {
        piece.disabled = false;
        setTryAgain(true);
        setLevel(1);
        setPiecesClic([]);
      }, 1000);
    } else {
      if (length + 1 === series.length) {
        if (level === 5) {
          const title = document.getElementById("title");
          title.innerHTML = "🔥 Memory!";
          try {
            victoryRef.current.style.display = "block";
            canvaRef.current.style.height = "100%";
          } catch (error) {
            console.log(error);
          }
          try {
            loadConfetti();
          } catch (error) {
            console.log(error);
          }
        } else {
          setIsStarted(false);
          setIsInteractive(false);
          setLevel(level + 1);
          setWinStep(true);
        }
      }
    }
  }

  const loadConfetti = async () => {
    let confetti = import("../../components/confetti"); // Module loaded (export default)!
  };
  function handleClose() {
    setIsStarted(false);
    setWinStep(false);
    setError(false);
    setTryAgain(false);
    randomSeries();
    dialogRef.current.style.display = "none";
  }

  useEffect(() => {
    const uniqueValues = new Set();
    const updatedMemory = memoryCards
      .map((item) => {
        let randomValue;
        do {
          randomValue = Math.floor(Math.random() * 9); //
        } while (uniqueValues.has(randomValue));
        uniqueValues.add(randomValue);

        return {
          ...item,
          value: randomValue,
        };
      })
      .sort((a, b) => a.value - b.value);

    setDisplayCards(updatedMemory);
  }, []);

  useEffect(() => {
    if (isReadyToDisplayImg) {
      startCountdown();
    }
  }, [series]);

  useEffect(() => {
    if (winStep) {
      for (let i = 0; i < series.length; i++) {
        const piece = document.getElementById(piecesClic[i].i);
        piece.disabled = false;
      }
      setTimeout(() => {
        setPiecesClic([]);
      }, 1000);
    }
  }, [winStep]);

  console.log(isInteractive);
  return (
    <section className="overflow-hidden">
      <div className="App">
        {/* LEGENDS OF THE PAST */}
        <section>
          <div
            className="absolute top-0 bg-black w-full flex justify-center items-center h-[70px] lg:h-[90px]  border-b-[10px] lg:border-b-[15px] z-50 overflow-hidden"
            style={{
              borderImage:
                "linear-gradient(to right, #AC6A33, #E3DB5C, #E5AE20)",
              borderImageSlice: "1",
            }}
          >
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-20 z-10 top-[-12%] lg:top-[-20%] lg:w-28 left-0"
              />
            </a>
            <h2
              className="text-white uppercase text-shadow-blue-low-mobile  italic  font-bold mx-auto text-[1rem] lg:text-3xl z-50"
              id="title"
            >
              🔥 Memory!
            </h2>
          </div>

          <div className="relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] h-screen flex flex-col justify-center items-center pt-[70px] lg:pt-[90px]">
            <div className="flex flex-col m-auto justify-center items-center gap-4">
              <div
                className={`flex flex-col justify-center items-center w-[100%] m-auto bg-[#25191EFF] text-white p-4 ${
                  (series.length === 0 || winStep || error) && "opacity-50"
                }`}
              >
                <p id="instruction">Ready?</p>
                <p id="level"> Stage {level} / 5</p>
                <div
                  id="series"
                  className="flex justify-center items-center w-[20%] h-[4rem]"
                ></div>
              </div>

              <div
                className={`p-2 m-auto gap-2 flex justify-center items-center relative grid grid-cols-3 grid-rows-3 ${
                  !isInteractive && error && "ring-8 ring-red-600 opacity-50"
                } ${isInteractive && !error && "ring-8 ring-[#E5AE20]"} ${
                  !isInteractive &&
                  !error &&
                  !winStep &&
                  "ring-8 ring-[#25191ECC] opacity-50"
                } ${
                  !isInteractive &&
                  winStep &&
                  "ring-8 ring-green-600 opacity-50"
                }`}
              >
                {displayCards.map((card, index) => (
                  <button
                    id={card.i}
                    disabled={isStarted ? false : true}
                    key={index}
                    className={`m-auto p-0.5 w-20 h-20 lg:w-35 lg:h-35 z-[40]`}
                    style={{
                      border: piecesClic.some(
                        (item) => item.value === card.value
                      )
                        ? "3.5px solid #E5AE20"
                        : "none",
                    }}
                    onClick={() => handleClick(card)}
                  >
                    <img
                      src={card.src}
                      alt=""
                      className="piece w-full h-full"
                    />
                  </button>
                ))}
              </div>
            </div>

            <figure className="absolute z-20 -bottom-20 -right-20 z-[20]">
              <img src="/assets/trame.svg" alt="" />
            </figure>

            <div
              id="help_text"
              className="hidden absolute w-[80%] lg:w-[40%] bg-[#25191ECC] m-auto z-50 min-h-[20%] p-4"
              ref={victoryRef}
            >
              <p className="w-full text-[1rem] lg:text-[1rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                Congratulations, you've passed the game! The code you need is
                L4N2M6
              </p>
            </div>

            <div
              className="absolute w-[80%] lg:w-[40%] bg-[#25191EFF] mt-auto p-4 flex flex-col gap-6"
              ref={dialogRef}
            >
              <p className="w-full text-[1rem] lg:text-[1rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[2rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                Hi! I'm having some memory problems, please help me to reproduce
                the sequences that will appear by{" "}
                <span className="text-[#E5AE20] font-bold">
                  clicking on the same items you've seen in the right order
                </span>{" "}
                and I'll give you a clue to help you in your quest.
              </p>
              <button
                onClick={handleClose}
                className="text-[1rem] lg:text-[1rem] text-white mx-auto text-center font-normal px-5 py-2 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] ring-1 ring-[#FFFFFF] z-[50]"
              >
                Start the game
              </button>
            </div>

            {tryAgain && (
              <div
                className="absolute w-[80%] lg:w-[40%] bg-[#25191EFF] mt-auto p-4 flex flex-col gap-6"
                ref={dialogRef}
              >
                <p className="w-full text-[1rem] lg:text-[1rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                  Do you want to try again?
                </p>
                <button
                  onClick={handleClose}
                  className="text-[1rem] lg:text-[1rem] text-white mx-auto text-center font-normal px-5 py-2 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] ring-1 ring-[#FFFFFF] z-[50]"
                >
                  Start the game
                </button>
              </div>
            )}

            {winStep && (
              <div
                className="absolute w-[80%] lg:w-[40%] bg-[#25191EFF] mt-auto p-4 flex flex-col gap-6"
                ref={dialogRef}
              >
                <p className="w-full text-[1rem] lg:text-[1rem]  text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                  You completed the stage {level - 1}! Ready for the next stage?
                </p>
                <button
                  onClick={handleClose}
                  className="text-[1rem] lg:text-[1rem] text-white mx-auto text-center font-normal px-5 py-2 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] ring-1 ring-[#FFFFFF] z-[50]"
                >
                  Go!
                </button>
              </div>
            )}

            <canvas
              className="pointer-events-none confetti absolute bottom h-[2%] w-screen z-45"
              id="canvas"
              ref={canvaRef}
            ></canvas>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Mint;
