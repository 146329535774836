import {HashConnect} from 'hashconnect'

import {
    AccountId,
    TokenId,
    TokenAssociateTransaction,
    Hbar
} from '@hashgraph/sdk'

import {OnSendKey} from 'pages/airdrop'

const axios = require('axios').default;

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}

const infinityJarTokenId = TokenId.fromString('0.0.1881189');

const AirdropTokenId = TokenId.fromString('0.0.2692151');

let serial = 0;

//serial = X-60+1
//serial max 900
//const WL= ["0.0.1234567","0.0.2345678"]

const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}

export const pairHashpack = async () => {

    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic
    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = "Connected to: "+pairingData.accountIds[0];
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        checkWL(pairingData.accountIds[0])
    })
    return initData;
}

//make the button clickable or show error message "not whitelist"
export const checkWL = async(accountID) => {
    console.log("Check WL for: "+accountID);
    let inWL = await CheckClientHasInfinityJar();
    if(inWL){
        console.log(accountID+" is WL");
        //the account is WL => clickable button
        const buttonGetKey = document.getElementById('FakebuttonGetHK');
        buttonGetKey.className= "hidden"
        const realGetKey = document.getElementById('buttonGetHK');
        realGetKey.className= "parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
        //add onclick OnClickGetKey
        //realGetKey.onclick = async ()=>{OnClickGetKey()};
    }
    else{
        //show message "are you sure you are White List ?"
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        response.innerHTML = "Seeker of Treasures, you need to have an Infinity Jar!"
        alert("Do you have an Infinity Jar?");
    }

}

//manage multi airdrop
async function CheckClientHasInfinityJar(){
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${infinityJarTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc&limit=50`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let res= false;
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length == 0){
                
            }
            else{
                serial = []
                for (let index = 0; index < jsonResponse.nfts.length; index++) {
                    serial.push(jsonResponse.nfts[index].serial_number.toString())
                }
                console.log(`Mirror event(s): ${serial}`);
                res = true;
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
    return res
}

export const OnClickGetKey = async() => {
    //CheckAssociateToken();
    OnSendKey();
    //http://13.42.62.231:3000
    //localhost:4000
    
    
}

export const CheckAssociateToken = async() => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${AirdropTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken();
            }
            else{
                console.log(`token ${AirdropTokenId} is already associated`);
                CheckAccountHasAirdropMaster();
                console.log("launch airdrop master")
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

export const AssociateToken = async() => {
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(saveData.pairedAccounts[0])
            .setTokenIds([AirdropTokenId])
            .setMaxTransactionFee(new Hbar(150))
            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            CheckAccountHasAirdropMaster();
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
}


const CheckAccountHasAirdropMaster = async() => {
    
    let hasAirdropTab = []

    console.log(serial.length)

    for (let i = 0; i < serial.length; i++) {
        hasAirdropTab[i]=(await CheckAccountHasAirdrop(serial[i]))
        
    }
    console.log(hasAirdropTab)

    let serial_correct = []

    for (let i = 0; i < serial.length; i++) {
        if (hasAirdropTab[i]) {
            serial_correct.push(serial[i]);
        }
    }
    console.log(serial_correct)
    if (serial_correct.length != 0) {
        AskServerToSendAirdrop(serial_correct)
    }
    else{
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        response.innerHTML = "Your Infinity Jar(s) have already given you the Heavenly Gifts!"
    }
    /*if (jsonResponse.account_id === "0.0.1380960"){
        console.log(`We have the NFT & we send it now`);
        AskServerToSendAirdrop();
        //return(`we send it now`);
    }
    else{
        alert(`We already sent you the NFT`);
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        response.innerHTML = "We already sent you the NFT";
        //return(`We already sent you the NFT`);
        //CheckAccountHasEnoughHbar();
    }*/
}

/*const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.account_id === "0.0.1380960"){
                res = true;
            }*/
//TODO for all serial?
//send a request for each serial
const CheckAccountHasAirdrop= async(nb_serial) => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${AirdropTokenId}/nfts/${redirectionSerial(nb_serial)}`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    
    let res = false

    try {
        let response = await axios.get(url);
        const jsonResponse = response.data;
        // output the from address and message stored in the event
        if (jsonResponse.account_id === "0.0.1380960"){
            res = true;
        }
        console.log( nb_serial.toString() +" "+ res.toString())
    } catch (error) {
        console.log(error)
    }
    
    return res;
}

function redirectionSerial(serial){

    /*if(parseInt(serial) > 150 && parseInt(serial) < 166){
        return parseInt(serial) - 35
    }*/
    return serial 
}

let askServer = false;

export const AskServerToSendAirdrop = async(serial_numbers) => {
    //post request 
    //launch anim ?
    if (askServer) {
        console.log("already ask server !")
        return;
    }
    askServer = true;

    console.log("https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+saveData.pairedAccounts[0]+"&target=airdrop&serial="+serial_numbers)
    axios
        .get("https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+saveData.pairedAccounts[0]+"&target=airdrop&serial="+serial_numbers)
        .then(function (response) {
            console.log(response);
			const jsonResponse = response.data;
            console.log(jsonResponse);
            console.log(jsonResponse["status"]);

            OnSendKey();
            /*if (jsonResponse["status"] === "sending the key") {
                console.log("Receive Key");
            }*/
            
		})
		.catch(function (err) {
			console.log(err);
            alert(err.toString());
		});
}


