import {HashConnect} from 'hashconnect'
import { 
    motion,
    useAnimationControls 
  } from "framer-motion"

import {
    AccountId,
    TokenId,
    TokenAssociateTransaction,
    TopicMessageSubmitTransaction,
    TransferTransaction,
    ScheduleSignTransaction,
    Client,
    PrivateKey,
    Hbar
} from '@hashgraph/sdk'

import {OnSendKey} from 'pages/HeavenKey'
import { json } from 'react-router-dom';

const axios = require('axios').default;

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}

const infinityJarTokenId = TokenId.fromString('0.0.1881189');

const keyTokenId = TokenId.fromString('0.0.1385462');

const operatorId = AccountId.fromString("0.0.1380960");

let serial = 0;

//nb=X-57+1+150
const WL=[
    "0.0.832571",
    "0.0.3139484",
    "0.0.3045038",
    "0.0.1858109",
    "0.0.754655",
    "0.0.3708434",
    "0.0.1252706",
    "0.0.2642782",
    "0.0.1331485",
    "0.0.1248380",
    "0.0.2008237",
    "0.0.1110801",
    "0.0.1162198",
    "0.0.3319111",
    "0.0.1697283",
    "0.0.1904818",
    "0.0.708974",
    "0.0.3744989",
    "0.0.3711413",
    "0.0.1459587",
    "0.0.3696422",
    "0.0.830192",
    "0.0.0000",
    "0.0.0000",
    "0.0.0000",
    "0.0.0000",
    "0.0.1859911",
    "0.0.499732",
    "0.0.1235097",
    "0.0.3698981",
    "0.0.1868252",
    "0.0.480627",
    "0.0.2970223",
    "0.0.3704032",
    "0.0.1824621",
    "0.0.3828411",
    "0.0.2214757",
    "0.0.1288238",
    "0.0.3845044",
    "0.0.1458671",
    "0.0.737900",
    "0.0.555599",
    "0.0.3705669",
    "0.0.2020815",
    "0.0.3804093",
    "0.0.2126174",
    "0.0.619065",
    "0.0.2089983",
    "0.0.3000235",
    "00000",
    "0.0.3688208",
    "0.0.789038",
    "0.0.1185746",
    "0.0.2202652",
    "0.0.845200",
    "0.0.3114678"
]

    //end 279 so row 185

    
    //nb=X-57+1+150
    //+73
    
    
    
    
    
    

//0.0.657657,2,"[66,81]"
//0.0.2126174,1,[90]
//0.0.1186598,1,[53]
//0.0.2159570,1,[148]

//const WL= ["0.0.1234567","0.0.2345678"]

const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can exchange your Heaven Key against the Infinity jar, a jar that will give you lot of things.',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}

export const pairHashpack = async () => {
    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic
    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = pairingData.accountIds[0];
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        checkWL(pairingData.accountIds[0])
    })
    return initData;
}

//make the button clickable or show error message "not whitelist"
export const checkWL = async(accountID) => {
    console.log("Check WL for: "+accountID);
    let inWL = await CheckClientHasInfinityJar();

    if(!inWL){
        if(WL.includes(accountID)){
            inWL=true;
        }
    }
   
    /*let index = 0;
    for (let i = 0; i < WL.length; i++) {
        if (WL[i] === accountID) {
            inWL=true;
            index = i;
            break;
        }
    }
    serial = index +1+150;
    if(serial >= 280){
        serial = serial + 73 
    }*/

    console.log("inWL : "+inWL);
    if(inWL){
        console.log(accountID+" is WL");
        //the account is WL => clickable button
        const buttonGetKey = document.getElementById('FakebuttonGetHK');
        buttonGetKey.className= "hidden"
        const realGetKey = document.getElementById('buttonGetHK');
        realGetKey.className= "parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
        //add onclick OnClickGetKey
        //realGetKey.onclick = async ()=>{OnClickGetKey()};
    }
    else{
        //show message "are you sure you are White List ?"
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        response.innerHTML = accountID+", You need a Infinity Jar or you need to be WL!"
        //alert(accountID+" is not Whitelisted");
    }

}

//manage multi airdrop
async function CheckClientHasInfinityJar(){
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${infinityJarTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc&limit=50`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let res= false;
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length == 0){
                
            }
            else{
                serial = []
                for (let index = 0; index < jsonResponse.nfts.length; index++) {
                    serial.push(jsonResponse.nfts[index].serial_number.toString())
                }
                console.log(`Mirror event(s): ${serial}`);
                res = true;
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
    return res
}

export const OnClickGetKey = async() => {
    CheckAssociateToken();
    //http://13.42.62.231:3000
    //localhost:4000
    //OnSendKey();
    
}

export const CheckAssociateToken = async() => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${keyTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken();
            }
            else{
                console.log(`token ${keyTokenId} is already associated`);
                CheckAccountHasKeyMaster();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

export const AssociateToken = async() => {
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(saveData.pairedAccounts[0])
            .setTokenIds([keyTokenId])
            .setMaxTransactionFee(new Hbar(150))
            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            CheckAccountHasKeyMaster();
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
}

const CheckAccountHasKeyMaster = async() => {
    
    let hasAirdropTab = []

    console.log(serial.length)

    for (let i = 0; i < serial.length; i++) {
        hasAirdropTab[i]=(await CheckAccountHasKey(serial[i]))
        
    }
    console.log(hasAirdropTab)

    let serial_correct = []

    for (let i = 0; i < serial.length; i++) {
        if (hasAirdropTab[i]) {
            serial_correct.push(serial[i]);
        }
    }
    console.log(serial_correct)
    if (serial_correct.length != 0 || WL.includes(saveData.pairedAccounts[0])) {
        AskServerToSendKey(serial_correct)
    }
    else{
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        response.innerHTML = "Your Infinity Jar(s) have already given you the heavenly gifts!"
    }
}

async function CheckAccountHasKey(nb_serial){
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts/${redirectionSerial(nb_serial)}`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;

	let res = false

    try {
        let response = await axios.get(url);
        const jsonResponse = response.data;
        // output the from address and message stored in the event
        if (jsonResponse.account_id === "0.0.1380960"){
            res = true;
        }
        console.log( nb_serial.toString() +" "+ res.toString())
    } catch (error) {
        console.log(error)
    }
    
    return res;
}

function redirectionSerial(serial){

    return parseInt(serial) + 400
}

let askServer = false;

export const AskServerToSendKey = async(serial_numbers) => {
    //post request 
    if (askServer) {
        console.log("already ask server !")
        return;
    }
    askServer = true;
    //launch anim ?
    if(serial_numbers.length ==0){
        serial_numbers.push("0")
    }
    axios
        .get("https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+saveData.pairedAccounts[0]+"&target=key&serial="+serial_numbers)
        .then(function (response) {
            console.log(response);
			const jsonResponse = response.data;
            console.log(jsonResponse);
            console.log(jsonResponse["status"]);

            if (jsonResponse.toString().includes("are not WL")) {
                const response = document.getElementById('response');
                response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
                response.innerHTML = "You have already received your heavenly gifts!"
            }
            else{
                OnSendKey();
            }
            
		})
		.catch(function (err) {
			console.log(err);
            alert(err.toString());
		});
}


