import {HashConnect} from 'hashconnect'
import { 
    motion,
    useAnimationControls 
  } from "framer-motion"

  import {
    AccountId,
    TokenId,
    TokenAssociateTransaction,
    TopicMessageSubmitTransaction,
    TransferTransaction,
    ScheduleSignTransaction,
    Client,
    PrivateKey,
    Hbar
} from '@hashgraph/sdk'

import {WalletConnectedNoToken,WalletConnectedTokenAssociated,GetNFTavailable,load,loss} from 'pages/Earthlings'
import { json } from 'react-router-dom';

const axios = require('axios').default;

let hashconnect = new HashConnect();
const delay = ms => new Promise(res => setTimeout(res, ms));

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}

const keyTokenId = TokenId.fromString('0.0.1385462');
const heavenaspirantTokenId = TokenId.fromString('0.0.2125331');
const infinityjarTokenId = TokenId.fromString('0.0.1881189');
const foundercardTokenId = TokenId.fromString('0.0.1097737');

const tokengatedID = foundercardTokenId;


const operatorId = AccountId.fromString("0.0.1380960");

let account_save = ""

let serial = 0;

const WLlist = [
    "0.0.1157105",
    "0.0.1266223",
    "0.0.1742830"
]

const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can exchange your Heaven Key against the Infinity jar, a jar that will give you lot of things.',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}

let connected = false;
export const pairHashpack = async () => {

    if(connected){
        return;
    }

    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic

    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = pairingData.accountIds[0];
        account_save = pairingData.accountIds[0];
        connected = true;
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        CheckAccountHasHeavenAspirant();
        //checkWL(pairingData.accountIds[0])
    })
    
    return initData;
}

export const CheckAccountHasHeavenAspirant = async() => {

    if(WLlist.includes(account_save)){
        console.log("WL user");
        CheckAssociateToken();
        return;
    }


    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${tokengatedID}/nfts?account.id=${account_save}&order=asc`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let res= false;
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length == 0){
                WalletConnectedNoToken();
            }
            else{
                CheckAssociateToken();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
    return res
}

let nft_available = 1;

//make the diff with last Heaven Key serial(325 => 310) 310 exclu 15 Heaven Key
//make the diff with last Heaven Key serial(334 => 325) 325 exclu 9 Heaven Key
//make the diff with last Heaven Key serial(335 => 350) 335 exclu
//make the diff with last Heaven Key serial(330 => 335) 330 exclu
//make the diff with last Heaven Key serial(346 => 350) 335 exclu
//896,897,898,899 Heaven Aspirant (899 => 895) 895 exclu
//895,894,893,892,891,890,889,888,887,886,885,884,883,882,881 Heaven Aspirant (895 => 880) 880 exclu
export const AskSupplyAndUpdateVisual= async () => 
{
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=${operatorId}`
    //if we have a response => break transaction because we don't have NFT to give 

    let last_serial = 325;
    axios
		.get(url)
		.then(function (response) {
            const jsonResponse = response.data;

            last_serial = parseInt(jsonResponse.nfts[0]["serial_number"])
            //console.log(`${last_serial} last serial`);
            nft_available = last_serial - 310;
            GetNFTavailable(nft_available);
            

            if(nft_available >= 0){
                //console.log(`${nft_available} NFT are still available`);
            }
            else{
                //console.log('NFT are all sold');
            }
            document.getElementById("supplyLoadingBar").style.width =(nft_available*100/15).toString()+"%";
            document.getElementById("supplyText").innerHTML = nft_available.toString()+"/15";
		})
		.catch(function (err) {
            console.log(`error: ${err}`);
		})
    await delay(1000); //5 seconds
    AskSupplyAndUpdateVisual();
}

const TimeStart = async () =>{
    const startGameDate = new Date(2023,6-1,13,18,0,0,0);
    const url = "https://sh71h1oegb.execute-api.eu-west-2.amazonaws.com/default/GetTime";

    console.log("startGameDate: "+startGameDate);

    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            console.log("date: "+jsonResponse);
            let month = jsonResponse.substring(0,2)
            let day = jsonResponse.substring(3,5)
            const dateAnswer = new Date(day+"/"+month+jsonResponse.substring(5));
            console.log("date format: "+dateAnswer.toString());

            let diff = startGameDate - dateAnswer
            console.log("diff: "+diff)

            if(diff<= 1000){
                WalletConnectedTokenAssociated();
            }
            else{
                startGameTimer(diff);
            }

            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
  
    //WalletConnectedTokenAssociated();
  
  }

const startGameTimer = async(diff) => {

    const response = document.getElementById('response');
    while(diff > 1000){
        
        response.innerHTML = `${formatTime(diff/1000)} seconds before the game start`;
        await delay(1000);
        diff = diff -1000
    }

    WalletConnectedTokenAssociated();

}

function formatTime(seconds) {
    var days = Math.floor(seconds / (24*60*60));
    var hours = Math.floor(seconds % (24*60*60) / (60*60));
    var minutes = Math.floor(seconds % (60*60) / 60);
    var seconds = Math.floor(seconds % 60);

    var result = "";
    if(days > 0) {
        if (days < 10) days = "0" + days;
        result += days + ":";
    }

    if(hours > 0 || days > 0) {
        if (hours < 10) hours = "0" + hours;
        result += hours + ":";
    }

    if (minutes < 10) minutes = "0" + minutes;
    result += minutes + ":";

    if (seconds < 10) seconds = "0" + seconds;
    result += seconds;

    return result;
}

export const CheckAssociateToken = async(bool) => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${account_save}/tokens?token.id=${keyTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken();
            }
            else{
                console.log(`token ${heavenaspirantTokenId} is already associated`);
                TimeStart();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

export const AssociateToken = async() => {
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, AccountId.fromString(account_save))
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(AccountId.fromString(account_save))
            .setTokenIds([keyTokenId])
            .setMaxTransactionFee(new Hbar(150))

            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            WalletConnectedTokenAssociated();
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
}

let askServer = false;
export const AskServerToSendKey = async() => {
    //post request 
    if (askServer) {
        delay(2000);
        show_text("already ask server!")
        return;
    }
    askServer = true;
    //launch anim ?

    if (nft_available <= 0) {
        loss();
        return;
    }

    console.log("walletid="+account_save);

    axios
        .get("https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+account_save+"&target=game")
        .then(function (response) {
            console.log(response);
			const jsonResponse = response.data;
            console.log(jsonResponse);
            show_text(JSON.parse(jsonResponse.replace(/'/g,'"'))["status"]);
            //OnSendKey();
            /*if (jsonResponse["status"] === "sending the key") {
                console.log("Receive Key");
                
            }*/
            
		})
		.catch(function (err) {
			console.log(err);
            alert(err.toString());
		});
}

export const show_text = (message)=>{

    if (message.includes("wallet")) {
        load();
    }
    else{
        loss();
    }

    let text = document.getElementById("text_key");
    text.className = "italic opacity-100 text-white z-50 mx-8 lg:mx-0 h-10 text-[0.8rem] lg:text-[1.2rem] mt-4 font-bold";
    text.innerHTML = message;
}