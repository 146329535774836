import React, { useRef, useState } from 'react';
import {pairHashpack,OnClickGetKey} from '../AirdropManagement'
import '../styles/mint.css';
import { 
  motion,
  useAnimationControls 
} from "framer-motion"

let controlsButtonUseKey = "";
let controlsKeyHoleJar = "";
let controlsBackground = "";
let controlsShadow = "";
let controlsBigShadow = "";

let infinityJar = "";
let scroll =""


const delay = ms => new Promise(res => setTimeout(res, ms));

export const OnSendKey = async () =>{
  //load();
  const realGetKey = document.getElementById('buttonGetHK');
  realGetKey.className= "hidden";
  const response = document.getElementById('response');
  response.className = "hidden";
  const scroll_img = document.getElementById('scroll');
  
  infinityJar.start({y:"2vw",transition:{delay:2,duration:5}})

  controlsButtonUseKey.start({opacity:0, transition: { duration: 3 }})
  controlsBackground.start({opacity:[1,1,0,0],transition:{duration: 5,times:[0,0.7,0.9,1]}});
  controlsShadow.start({opacity:[0.5,0.5,0,0],transition:{duration: 5,times:[0,0.7,0.9,1]}});
  controlsBigShadow.start({opacity:[0.5,0.5,0,0],transition:{duration: 5,times:[0,0.7,0.9,1]}});
  controlsKeyHoleJar.start({opacity:[1,1,0.3,0],scale:[1,1,100,100],y:["0vw","-5vw","-300vw","-300vw"],transition:{duration: 5,times:[0,0.7,0.9,1]}});

  
  infinityJar.start({rotate:[-5, 5, -5],
    transition: {
      repeat: Infinity,
      duration: 0.25
    }})

    await delay(5000);
  response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
  response.innerHTML = "3"
  await delay(1000);
  response.innerHTML = "2"
  await delay(1000);
  response.innerHTML = "1"
  await delay(1000);
  response.innerHTML = "Dear Jar Bearer..."
  await delay(2000);
  response.innerHTML = "CLEO has entered the Legend Arena"
  await delay(3000);
  response.innerHTML = "Are you ready to meet her next week?"
  await delay(3500);
  response.innerHTML = "The gods in the Heaven of Legends have a celestial gift for you."
  await delay(4000);
  response.innerHTML = "Once forged, it will be highly useful in the Legend Arena."
  await delay(4000);
  response.innerHTML = "You can now proudly claim ..."
  
  

  infinityJar.start({scaleY:0.75,scaleX:0.9,
    transition: {
      duration: 1
    }})

  await delay(1000)

  infinityJar.start({scaleY:1.2,scaleX:1.1,
    transition: {
      duration: 0.2
    }})
  
    await delay(300)
  
    infinityJar.start({scaleY:1,scaleX:1,rotate:0,
      transition: {
        duration: 0.1,
        type: "tween",
        damping: 25,
        stiffness: 500
      }})

    scroll.start({scale:1.3,
    transition: {
      duration: 1
    }})

    scroll.start({y:"-40vh",
      transition: {
        duration: 0.5
      }})
    
    await delay(500)
    scroll_img.className= "h-[30rem] lg:h-[14rem] 2xl:h-[22rem] 3xl:h-[22rem] z-20 absolute m-auto left-0 right-0 top-0 bottom-0";


    scroll.start({y:"2vh",
      transition: {
        duration: 0.5
      }})

    await delay(500)
    scroll.start( {y:["2vh","-2vh","2vh"] ,transition:{duration: 7,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
    infinityJar.start({opacity:0,
      transition: {
        duration: 1
      }})


  
    response.innerHTML = "your Heaven Scroll"
    await delay(3000);
    response.innerHTML = "which will turn into an epic artifact! (Check your wallet.)"
  //controlsHeavenKey.start({y:["0.5vw","-0.5vw","0.5vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
}

export const load = async () => {
  let confetti = import('../components/confetti') // Module loaded (export default)!
};

export const init_animation = async () => {
  console.log("InitAnimation");
  controlsKeyHoleJar.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
  //infinityJar.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
  controlsShadow.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:1}})
  controlsBigShadow.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:2}})
  infinityJar.start({rotate:[-2, 2, -2],
    transition: {
      repeat: Infinity,
      duration: 0.4
    }})
};



export const enterHeavenOfLegends = async() =>{
 
}

const Mint = () => {

  controlsButtonUseKey = useAnimationControls();
  controlsKeyHoleJar = useAnimationControls();
  controlsBackground = useAnimationControls();
  controlsShadow = useAnimationControls();
  controlsBigShadow = useAnimationControls();
 
  infinityJar = useAnimationControls();
  scroll = useAnimationControls();

  const [pairingString, setPairingString] = useState('')
  const controlsHeavenKey = useAnimationControls();
  return (
    <section className="overflow-hidden">
      <div className="App" onload={init_animation()}>
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <canvas class="confetti absolute h-screen w-screen z-10" id="canvas"></canvas>
          {/* Header & Social networks */}
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-14 z-50 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a>
            <p className="text-white text-[0.6rem] lg:text-[1rem] top-2 lg:-top-10 right-[10rem] lg:right-[25rem] absolute " id='accountid'></p>
            {pairingString && 
            <p>{pairingString}</p>
            }  
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}  
              class="italic px-4 lg:px-16 py-1 lg:py-2 parrallelogram absolute bg-[#8626EC] hover:bg-[#8611EC] top-1.5 right-2 lg:right-10 lg:-top-16 z-50" 
              onClick={async () => {
                const saveData = await pairHashpack()
                //setPairingString(saveData.pairingString)
              }}>
              <span className="text-radial-gradient lg:text-2xl font-bold">Connect wallet</span>
            </motion.button>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
            </figure>

            <figure className="absolute z-20 -bottom-20 -right-20">
            <img src="/assets/trame.svg" alt="" />
            </figure>

            <motion.img 
          animate = {controlsBackground}
          src="/assets/Heaven.jpg" 
          className=" h-screen object-cover right-0 left-0 mx-auto lg:w-screen absolute z-20"></motion.img>

          <span  className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center">
            <motion.img 
            initial={{opacity:0.5}}
            animate = {controlsBigShadow}
            src="/assets/Big_Shadow.png" 
            className="  h-[30rem] lg:h-[50rem] 2xl:h-[55rem] 3xl:h-[60rem] z-30 "></motion.img>
          </span>

          <span  className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center">
            <motion.img
            initial={{opacity:0.5}}
            animate={controlsShadow}
            src="/assets/Little_Shadow.png" 
            className=" h-[30rem] lg:h-[40rem] 2xl:h-[45rem] 3xl:h-[50rem] z-40 "></motion.img>
          </span>

          <span  className="w-screen h-screen absolute top-0 grid gap-y-6 justify-items-center place-content-center">
            <motion.img 
            animate ={controlsKeyHoleJar}
            src="/assets/KEY_HOLE_JAR.png"
            className="h-[20rem] mt-10 lg:-mt-10 lg:h-[22rem] 2xl:h-[26rem] 3xl:h-[32rem] z-40 "></motion.img>
          </span>
        
          <motion.img 
              animate={infinityJar}
              initial={{y:"0vw"}}
              src="/assets/Infinity jar.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[22rem] 
                3xl:h-[22rem] 
                z-10 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
              <motion.img 
              id="scroll"
              animate={scroll}
              initial={{scale:0.2}}
              src="/assets/PARCHEMIN_FERME.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[22rem] 
                3xl:h-[22rem] 
                z-0 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
            <figure className="grid place-content-center absolute bottom-0 h-[20%] w-screen z-50"> 
                    <motion.button
                    id ='FakebuttonGetHK'
                    onClick=""
                    className="hidden parrallelogram bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Get your Airdrop!
                        </h3>
                    </motion.button>
                    <motion.button
                    id ='buttonGetHK'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}  
                    onClick={async () => {OnClickGetKey()}}
                    className="parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-radial-gradient z-50 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Get your Airdrop!
                        </h3>
                    </motion.button>
                    <h3
                      className="italic opacity-0 text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold" 
                      id='response'> void
                    </h3>
                </figure>
          
          <figure className="absolute -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          
        </section>
      </div>
    </section>
  );
}

export default Mint;