import React, { useRef, useState } from 'react';
import {pairHashpack,OnClickGetKey} from '../OpenScrollManagement'
import '../styles/mint.css';
import { 
  motion,
  useAnimationControls 
} from "framer-motion"

let controlsButtonUseKey = "";
let controlsKeyHoleJar = "";
let controlsSceaux = "";
let controlsScrollLeft = "";
let controlsScrollRight = "";
let controlsScrollMiddle = "";

let infinityJar = "";
let scroll =""
let flash="";


const delay = ms => new Promise(res => setTimeout(res, ms));

export const OnSendKey = async () =>{
  //load();
  const realGetKey = document.getElementById('buttonGetHK');
  realGetKey.className= "hidden";
  
  const scroll_img = document.getElementById('scroll');

  await delay(3000);
  text_anim();
  await delay(3000);
  
  controlsSceaux.start( {y:"0vw" ,transition:{duration: 1}})
  controlsScrollLeft.start( {y:"0vw" ,transition:{duration: 1}})
  controlsScrollRight.start( {y:"0vw" ,transition:{duration: 1}})

  await delay(1000);

  controlsSceaux.start( {scale:1.5,opacity:0 ,transition:{duration: 2}})

  await delay(2000);
  
  controlsScrollLeft.start( {x:"-5vw" ,transition:{duration: 2}})
  controlsScrollRight.start( {x:"5vw" ,transition:{duration: 2}})
  controlsScrollMiddle.start( {opacity:1 ,transition:{duration: 2}})

  await delay(1500);
  flash.start( {opacity:1 ,transition:{duration: 0.4}})
  await delay(300);

  controlsScrollLeft.start( {opacity:0 ,transition:{duration: 0.1}})
  controlsScrollRight.start( {opacity:0 ,transition:{duration: 0.1}})
  controlsScrollMiddle.start( {opacity:0 ,transition:{duration: 0.1}})

  scroll.start({scale:1.2 ,transition:{duration: 0.1}})

  await delay(200);
  flash.start( {opacity:0,transition:{duration: 0.1}})

  scroll.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:1}})

  

  //controlsHeavenKey.start({y:["0.5vw","-0.5vw","0.5vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500}})
}

const text_anim = async() =>{

  const response = document.getElementById('response');
  response.className="hidden"
  await delay(1000);
  response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
  response.innerHTML = "3"
  await delay(1000);
  response.innerHTML = "2"
  await delay(1000);
  response.innerHTML = "1"
  await delay(1000);
  response.innerHTML = "This protocol contains the blueprint to forge Excalibur"
  await delay(3000);
  response.innerHTML = "The sword of unmatched power, once wielded by the great King Arthur."
  await delay(3000);
  response.innerHTML = "The crafted Excalibur unlocks enormous strength in the Arena"
  await delay(3000);
  response.innerHTML = "So, stay alert when the Legend Forge opens...."
  await delay(3000);
  response.innerHTML = "To craft this mythical sword... (Check & reload your wallet 😉)"
}

export const load = async () => {
  let confetti = import('../components/confetti') // Module loaded (export default)!
};

export const init_animation = async () => {
  console.log("InitAnimation");
  controlsSceaux.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:1}})
  controlsScrollLeft.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:1}})
  controlsScrollRight.start( {y:["2vw","-2vw","2vw"] ,transition:{duration: 10,times:[0,0.5,1],repeatType: "loop",repeat: Infinity,type: "tween",damping: 25,stiffness: 500,delay:1}})
  
  /*infinityJar.start({rotate:[-2, 2, -2],
    transition: {
      repeat: Infinity,
      duration: 0.4
    }})*/
};



export const enterHeavenOfLegends = async() =>{
 
}

const Mint = () => {

  controlsButtonUseKey = useAnimationControls();
  controlsKeyHoleJar = useAnimationControls();
  controlsSceaux = useAnimationControls();
  controlsScrollLeft = useAnimationControls();
  controlsScrollRight = useAnimationControls();
  controlsScrollMiddle = useAnimationControls();
 
  infinityJar = useAnimationControls();
  scroll = useAnimationControls();
  flash = useAnimationControls();

  const [pairingString, setPairingString] = useState('')
  const controlsHeavenKey = useAnimationControls();
  return (
    <section className="overflow-hidden">
      <div className="App" onload={init_animation()}>
        {/* LEGENDS OF THE PAST */}
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  h-screen">
        <canvas class="confetti absolute h-screen w-screen z-10" id="canvas"></canvas>
          {/* Header & Social networks */}
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a> 
            <h2 className='absolute text-white uppercase text-shadow-blue-low-mobile  italic font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50'>HEAVEN SCROLL REVEAL</h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
            {pairingString && 
            <p>{pairingString}</p>
            }
            <motion.button 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}  
              class="italic px-4 lg:px-16 py-1 lg:py-2 parrallelogram absolute z-50 bg-[#8626EC] hover:bg-[#8611EC] top-1.5 right-2 lg:right-10 lg:-top-16" 
              onClick={async () => {
                const saveData = await pairHashpack()
                //setPairingString(saveData.pairingString)
              }}>
              <span className="text-radial-gradient text-[0.5rem] lg:text-xl font-bold " id='accountid'>Connect wallet</span>
            </motion.button>
          </figure>

            <figure className="absolute z-20 -bottom-20 -right-20">
            <img src="/assets/trame.svg" alt="" />
            </figure>
            <motion.img 
              animate={controlsScrollMiddle}
              initial={{y:"0vw",opacity:0}}
              src="/assets/scroll_middle.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[30rem] 
                3xl:h-[35rem] 
                z-10 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
          <motion.img 
              animate={controlsScrollLeft}
              initial={{y:"0vw"}}
              src="/assets/scroll_left.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[30rem] 
                3xl:h-[35rem] 
                z-10 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
              <motion.img 
              animate={controlsScrollRight}
              initial={{y:"0vw"}}
              src="/assets/scroll_right.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[30rem] 
                3xl:h-[35rem] 
                z-10 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
              <motion.img 
              animate={controlsSceaux}
              initial={{y:"0vw"}}
              src="/assets/sceaux.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[30rem] 
                3xl:h-[35rem] 
                z-10 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
              <motion.img 
              id="scroll"
              animate={scroll}
              initial={{scale:0.2}}
              src="/assets/scroll_open.png" 
              className="
                h-[20rem]
                lg:h-[14rem]
                2xl:h-[30rem] 
                3xl:h-[35rem] 
                z-0 absolute 
                m-auto 
                left-0 
                right-0 
                top-0
                bottom-0">
              </motion.img>
              <motion.div id="flash" animate={flash} initial={{opacity:0}} className='bg-[#ffffff] absolute h-screen w-screen z-40' ></motion.div>
            <figure className="grid place-content-center absolute bottom-0 h-[20%] w-screen z-50"> 
                    <motion.button
                    id ='FakebuttonGetHK'
                    onClick=""
                    className="hidden parrallelogram bg-[#adadad] opacity-50 z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-[#4a4a4a] z-20 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Open your scroll!
                        </h3>
                    </motion.button>
                    <motion.button
                    id ='buttonGetHK'
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}  
                    onClick={async () => {OnClickGetKey()/*OnSendKey()*/}}
                    className=" parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto" >
                        <h3
                        id ='textGetHK'
                        className="italic text-radial-gradient z-50 w-[100%] mx-auto text-[0.8rem] lg:text-[1.2rem] font-bold ">
                            Open your scroll!
                        </h3>
                    </motion.button>
                    <h3
                      className="italic opacity-0 text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold" 
                      id='response'> void
                    </h3>
                </figure>
          
          <figure className="absolute -bottom-20 -right-20">
          <img src="/assets/trame.svg" alt="" />
          </figure>
          
        </section>
      </div>
    </section>
  );
}

export default Mint;