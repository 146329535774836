import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { renderMatches, useNavigate } from "react-router-dom";
import {
  pairHashpack,
  // RequestLast5Articles,
  requestQuizz,
  requestQuizzOptions,
  addScore,
  checkQuizz,
  getLeaderboard,
  intro,
  loadPieChart,
  getPseudo,
} from "../../CommitteeCleoManagement";
import "../../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";
import Auth from "../../utils/auth/utils.js";
import Menu from "./CommitteeMenu";
import flamme1 from "../../assets/flamme/flamme1.png";
import flamme2 from "../../assets/flamme/flamme2.png";
import flamme3 from "../../assets/flamme/flamme3.png";
import staticflamme from "../../assets/flamme/flamme_statique.png";
import "./CommitteeCleoAnimation.css";
import Header from "./CommitteeHeader";
import revealArtist from "../../assets/reveal_artist.jpg";
import convertToJSONString from "../../convertToJSONString.js";

import convertEncodingChar from "convertEncodingChar";
const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const axios = require("axios").default;

function ShowHideMenu() {
  let menu = document.getElementById("menu");

  if (menu.className.includes("hidden")) {
    menu.className =
      "absolute flex flex-col items-center justify-center ring-[0.2rem] w-[50%] lg:w-[20%] lg:gap-4 left-1 top-[5.2rem] backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-50";
  } else {
    menu.className = "hidden";
  }
}

export function showHideAnswerFaq(index) {
  console.log("click on article " + index);
  let element = document.getElementById("text_" + index.toString());
  let img = document.getElementById("img_" + index.toString());
  let resume = document.getElementById("resume_" + index.toString());
  let cell = document.getElementById("cell_faq_" + index.toString());
  console.log("element: " + element.className);
  if (element.className.includes("hidden")) {
    element.className = "show";
    img.className = "show";
    cell.className = "button-63 text-white p-4 lg:p-8";
    resume.className = "hidden";
    /*for (let i = 1; i < ; i++) {
      if (!(i === index)) {
        document.getElementById("text_" + i.toString()).class = "hidden";
        document.getElementById("resume_" + i.toString()).class = "flex h-8";
        document.getElementById("cell_faq_" + i.toString()).class = "button-63 text-white";
      }
    }*/
  } else {
    element.className = "hidden";
    img.className = "hidden";
    cell.className = "button-63 text-white p-4 lg:p-8";
    resume.className = "flex h-8";
  }
}

const Mint = ({ saveData }) => {
  const [availableQuizz, setAvailableQuizz] = useState(false);
  const [showQuizz, setShowQuizz] = useState(false);
  const [quizz, setQuizz] = useState({ 1: "", 2: "", 3: "" });
  const [quizzOptions, setQuizzOptions] = useState({});
  const [finishedQuizz, setFinishedQuizz] = useState(false);
  const [displayQuizzOptions, setDisplayQuizzOptions] = useState([
    { optionName: "", step: 1 },
    { optionName: "", step: 1 },
    { optionName: "", step: 1 },
    { optionName: "", step: 1 },
  ]);
  const [step, setStep] = useState(1);
  const [totalStep, setTotalStep] = useState(1);
  const [answerCheck, setAnswerCheck] = useState(false);
  const [tryOption, setTryOption] = useState(false);
  const [optionClicked, setOptionClicked] = useState("");
  const [leaderboard, setLeaderboard] = useState([{ pseudo: "", score: 0 }]);
  const [isHint, setIsHint] = useState(false);
  const [displayHint, setDisplayHint] = useState(false);
  const [hintQuizz, setHintQuizz] = useState(false);
  const [tryOptionHint, setTryOptionHint] = useState(false);
  const [optionClickedHint, setOptionClickedHint] = useState("");
  const [answerCheckHint, setAnswerCheckHint] = useState(false);
  const [hintTitle, setHintTitle] = useState("Hint");
  const [hintText, setHintText] = useState(
    "WE HAVE A SPECIAL HINT JUST FOR YOU REGARDING OUR SECOND LEGEND"
  );
  const [displayQuizzOptionsHint, setDisplayQuizzOptionsHint] = useState([
    { optionName: "Soldier", answer: false },
    { optionName: "Artist", answer: true },
    { optionName: "Scientist", answer: false },
  ]);
  const user = Auth.getAccount();

  const loadPseudo = async () => {
    const pseudo = await getPseudo();
    document.getElementById("pseudo").innerHTML = pseudo;
  };

  const loadKeys = async () => {
    const keys = await Auth.getKeys();
    document.getElementById("keys").innerHTML = keys + " Cleos";
    loadPieChart(keys);
  };
  // const [pairingString, setPairingString] = useState("");

  function checkAnswer(option) {
    setOptionClicked(option);
    setTryOption(true);
  }

  function checkAnswerHint(option) {
    setOptionClickedHint(option);
    setTryOptionHint(true);
  }
  async function getQuizz() {
    const quizzResponse = await requestQuizz();
    setQuizz(quizzResponse);
  }

  async function quizzCompleted() {
    const winPoint = await addScore();
    const quizz = document.getElementById("quizzStatus");

    if (winPoint.data === "ok") {
      const lead = await getLeaderboard();
      if (lead) {
        setLeaderboard(lead);
      }
      setShowQuizz(false);
      setFinishedQuizz(true);
      const win = `You are now an aware Hbarbarian. See you next week for further news. Stay ahead of the game!`;
      quizz.textContent = win;
      quizz.className =
        "flex flex-col gap-4 text-white  rounded-lg p-2 h-auto leading-7 lg: leading-auto";
    } else {
      const error = `An error occured, please try later.`;
      quizz.textContent = error;
      quizz.className =
        "flex flex-col gap-4 text-white rounded-lg p-2 h-auto leading-7 lg: leading-auto";
    }
  }

  function resetQuizz() {
    setStep(1);
    setAnswerCheck(false);
    setTryOption(false);
    setOptionClicked("");
  }

  function resetHintQuizz() {
    setAnswerCheckHint(false);
    setDisplayHint(false);
    setOptionClickedHint("");
    setTryOptionHint(false);
  }

  const navigate = useNavigate();

  async function UpdateTask() {
    console.log("UpdateTask()");

    //demander state to lambda
    // V 1. elect champions vote part
    // 2. vote governance
    // 3. reward
    // V 4. elect champions apply part
    // 5. quizz
    // V 6. nothing
    let state = 6;
    //champions elect check
    let currentElection = await Auth.getRepresentatives();
    let serial = await Auth.getSerials();
    if (
      currentElection[0].Vote + currentElection[0].Time >
        new Date().getTime() / 1000 &&
      currentElection[0].Vote < new Date().getTime() / 1000
    ) {
      state = 1;
      //check if player already vote
      
      console.log(serial);
      let votes = await Auth.NotVoted(currentElection[0].id, serial);
      console.log("votes: " + votes);
      if (!Object.values(votes).includes(false)) {
        state = 6;
      }
    }
    //governance choice
    //reward
    if(state == 6){
      let reward =0;
      let url =
      `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Reward&command=GetInfo&serials=` +
      serial.join(",");
      let cleos = await axios.post(url)
      cleos = JSON.parse(convertToJSONString(cleos.data));
      for (let index = 0; index < cleos["Items"].length; index++) {
        reward += cleos["Items"][index]["RewardUnclaimed"];
      }
      if(reward >=2){
        state =3;
      }
    }
    //apply champions
    if (state == 6) {
      console.log(currentElection[0]);
      if (
        currentElection[0].Vote > new Date().getTime() / 1000 &&
        currentElection[0].Timestamp < new Date().getTime() / 1000
      ) {
        state = 4;
        //check is player already apply
        let options = await Auth.getOptions(currentElection[0].id);
        let speudo = await Auth.getPseudo();
        for (let index = 0; index < options.length; index++) {
          if (options[index].Player == speudo) {
            state = 6;
          }
        }
      }
    }
    //quizz

    const task_text = document.getElementById("task_text");
    const button_task = document.getElementById("button_task");
    const button_task_text = document.getElementById("button_task_text");

    const classname_button_task =
      "items-center justify-center rounded-xl p-1 2xl:p-2 z-50 drop-shadow-2xl justify-center bg-[#8626EC] hover:bg-[#8611EC]";
    const classname_button_task_hidden =
      "hidden items-center justify-center rounded-xl p-1 2xl:p-2 z-50 drop-shadow-2xl justify-center bg-[#8626EC] hover:bg-[#8611EC]";

    switch (state) {
      case 1:
        task_text.innerHTML = "Elect the champions for the next event";
        button_task.className = classname_button_task;
        button_task.onclick = () => navigate(`/champions`);
        button_task_text.innerHTML = "Elect Champions";
        break;
      case 2:
        break;
      case 3:
        task_text.innerHTML = "Your reward awaits you:";
        button_task.className = classname_button_task;
        button_task.onclick = () => navigate(`/reward`);
        button_task_text.innerHTML = "Claim";
        break;
      case 4:
        task_text.innerHTML =
          "Apply to be one of the two Champions in the next Tournament";
        button_task.className = classname_button_task;
        button_task.onclick = () => navigate(`/champions`);
        button_task_text.innerHTML = "Apply";
        break;
      case 5:
        task_text.innerHTML =
          "Answer the quizz on the right to stay an aware Hbarbarian!";
        button_task.className = classname_button_task_hidden;
        break;
      case 6:
        task_text.innerHTML =
          "Stay aware of the next event on discord and twitter";
        button_task.className = classname_button_task_hidden;
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    UpdateTask();
  }, []);

  useEffect(() => {
    // RequestLast5Articles();
    async function start() {
      const quizz = await checkQuizz();
      if (quizz === "ok") {
        setAvailableQuizz(true);
        getQuizz();
      }
      const lead = await getLeaderboard();
      if (lead) {
        setLeaderboard(lead);
      }

      loadPseudo();
      loadKeys();
    }

    start();
  }, []);

  useEffect(() => {
    if (quizz?.quizzID) {
      // if(step < 4){
      async function getQuizzOptions() {
        const optionsQuizz = await requestQuizzOptions(quizz.quizzID);
        setQuizzOptions(optionsQuizz);

        const quizzToDisplay = optionsQuizz.filter(
          (option) => option.step === step
        );
        const randomComparator = () => Math.random() - 0.5;
        const shuffledQuizz = [...quizzToDisplay].sort(randomComparator);

        setDisplayQuizzOptions(shuffledQuizz);
      }
      getQuizzOptions();
      // }
    }
  }, [quizz, step]);

  useEffect(() => {
    let hightValue = 0;

    // Parcourez tous les objets dans le tableau
    for (let i = 0; i < quizzOptions.length; i++) {
      // Comparez la valeur actuelle de step avec la plus haute valeur actuelle
      if (quizzOptions[i].step > hightValue) {
        // Mettez à jour la plus haute valeur si la valeur actuelle est plus grande
        hightValue = quizzOptions[i].step;
      }
    }
    setTotalStep(hightValue);
  }, [quizzOptions]);

  useEffect(() => {
    if (step <= totalStep) {
      if (tryOption) {
        if (optionClicked.answer) {
          setAnswerCheck(true);
        } else {
          setTimeout(() => {
            setAnswerCheck(false);
            setTryOption(false);
            setOptionClicked("");
          }, 1500);
        }
      }
    }
  }, [tryOption]);

  useEffect(() => {
    if (tryOptionHint) {
      if (optionClickedHint.answer) {
        setAnswerCheckHint(true);
      } else {
        setTimeout(() => {
          setAnswerCheckHint(false);
          setTryOptionHint(false);
          setOptionClickedHint("");
        }, 1500);
      }
    }
  }, [tryOptionHint]);

  useEffect(() => {
    if (tryOption) {
      if (answerCheck) {
        setTimeout(() => {
          if (step != totalStep) {
            setStep(step + 1);
            setTryOption(false);
            setAnswerCheck(false);
            setOptionClicked("");
          } else {
            isHint ? setDisplayHint(true) : quizzCompleted();
          }
        }, 1500);
      } else {
        setTimeout(() => {
          setTryOption(false);
          setAnswerCheck(false);
          setOptionClicked("");
        }, 2000);
      }
    }
  }, [answerCheck]);

  useEffect(() => {
    if (tryOptionHint) {
      if (answerCheckHint) {
        quizzCompleted();
      }
    }
  }, [answerCheckHint]);

  return (
    <section className="overflow-hidden">
      <section id="main">
        <div className="App">
          {/* LEGENDS OF THE PAST */}
          <section
            className="relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] h-screen bg-cover"
            style={{ backgroundImage: `url(/assets/HeavenKey_Space.jpg)` }}
          >
            <canvas
              className="confetti absolute h-screen w-screen z-10 pointer-events-none"
              id="canvas"
            ></canvas>
            {/* Header & Social networks */}
            <Header />

            <figure
              id="scroll"
              className="overflow-y-auto h-[90%] grid lg:grid-cols-4 lg:grid-rows-4 gap-4 lg:gap-6 lg:h-[92%] p-4 lg:p-10 w-screen relative"
            >
              <figure className="flex flex-wrap items-center px-4 justify-center ring-[0.2rem] w-full row-span-1 lg:row-span-1 lg:col-span-2 backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-10">
                <h2 className=" text-radial-gradient  uppercase w-[100%]  italic  font-bold text-[1.25rem] lg:m-1 lg:text-2xl 2xl:m-2 2xl:text-3xl z-50 pointer-events-none">
                  Profile
                </h2>
                <img
                  src="/assets/Trophy.png"
                  className="w-[4.5rem] lg:w-[5rem] 2xl:w-[5.75rem] 3xl:w-[7rem] aspect-square rounded-lg p-1 mr-2"
                ></img>
                <figure className="grid grid-rows-1 justify-between grow lg:grid-cols-1 grid-flow-col gap-x-3 lg:gap-x-4 content-center m-0 lf:m-2 2xl:m-6">
                  <div className="grid grid-rows-1">
                    <span
                      id="time"
                      className=" text-white text-left leading-1 text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem]"
                    >
                      CLEO committee
                    </span>
                    <span
                      id="time"
                      className=" text-[#dee2e6] text-left text-[0.6rem] lg:text-[1.05rem] 3xl:text-[1.75rem]"
                    >
                      lvl.1
                    </span>
                  </div>

                  <div className="grid grid-rows-2 lg:gap-2 2xl:gap-4">
                    <span
                      id="pseudo"
                      className="text-white text-left text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem]"
                    >
                      CLEO Member
                    </span>
                    <span
                      id="keys"
                      className=" text-white text-left text-[0.7rem] lg:text-[1.25rem] 3xl:text-[2rem]"
                    >
                      0 CLEO
                    </span>
                  </div>
                </figure>
              </figure>
              <figure className="flex flex-wrap items-center px-4 justify-center ring-[0.2rem] w-full row-span-1 row-start-2 lg:col-span-2 backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-10">
                <h2 className=" text-radial-gradient  uppercase w-[100%]  italic  font-bold text-[1.25rem] lg:m-1 lg:text-2xl 2xl:m-2 2xl:text-3xl z-50 pointer-events-none">
                  My Tasks
                </h2>
                <figure className="flex flex-col justify-between lg:grid-cols-1 lg:gap-y-1 2xl:gap-y-4 content-center py-2 lg:py-0 lg:-mt-4 2xl:-mt-10 ">
                  <span
                    id="task_text"
                    className=" text-white text-left leading-1 text-[0.7rem] lg:text-[1rem] 2xl:text-[1.25rem]"
                  >
                    Loading...
                  </span>

                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    id="button_task"
                    className="hidden items-center justify-center rounded-xl p-1 2xl:p-2 z-50 drop-shadow-2xl justify-center bg-[#8626EC] hover:bg-[#8611EC]"
                  >
                    <span
                      id="button_task_text"
                      class="text-radial-gradient uppercase text-[0.9rem] md:text-[1rem] italic font-bold"
                    >
                      Elect next Champions
                    </span>
                  </motion.button>
                </figure>
              </figure>
              {/*<img
                src="/assets/CARD-CLEO-min.gif"
                className=" lg:row-span-2 lg:row-start-3  h-[100%] lg:h-auto lg:w-[100%] 2xl:w-[100%] 3xl:w-[100%] aspect-square ring-4 ring-[#00000033] rounded-lg z-50"
              ></img>*/}
              <div className="flex flex-col lg:row-start-3 lg:row-span-2 items-center justify-center ring-[0.2rem] w-full backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-10">
                <figure className=" flex flex-col items-center justify-start">
                  <h2 className=" text-radial-gradient lg:mt-4  uppercase  italic  font-bold text-[1.25rem] lg:p-0.5 lg:text-2xl 2xl:p-1 2xl:text-3xl z-50 pointer-events-none">
                    My Ownership
                  </h2>
                  <div className="flex flex-col justify-center content-center gap-4 ">
                    <span
                      id="statusHolders"
                      className=" hidden grow text-white text-[1.25rem] lg:text-[1rem] 2xl:text-[1.3rem] 3xl:text-[1.5rem]"
                    >
                      Reward : ???
                    </span>
                    <span
                      id="statusListed"
                      className="  grow text-left lg:leading-5 2xl:leading-7  text-white text-[1.25rem] mx-2 2xl:mx-4 lg:text-[1rem] 2xl:text-[1.3rem] 3xl:text-[1.5rem]"
                    >
                      Voting Power & <br /> Token Rewards Booster:
                    </span>
                    <span
                      id="x_value"
                      className="text-radial-gradient lg:-mt-[1rem] 2xl:-mt-0 leading-[3rem] 2xl:leading-[4.5rem] flex-none text-center  text-white text-[3rem] lg:text-[3rem] 2xl:text-[4rem] 3xl:text-[5rem]"
                    >
                      ???x
                    </span>
                    <img
                      id="arrow_piechart"
                      src="/assets/arrow-down.png"
                      className="hidden flex-none my-auto w-[1.5rem] h-[1.5rem] right-0"
                    ></img>
                  </div>
                  <div
                    id="piechart"
                    className=" -mt-[1rem] lg:-mt-[3.6rem] 2xl:-mt-[5rem] lg:block flex-grow relative flex items-center justify-center w-[18rem] h-[10rem] lg:w-[15rem] lg:h-[13rem] 2xl:w-[18rem] 2xl:h-[15rem] 3xl:w-[22rem] 3xl:h-[17rem]"
                  ></div>
                </figure>
              </div>

              <figure
                id="quizz_container"
                className="ring-[0.2rem] lg:col-span-2 w-full row-span-4 backdrop-blur ring-[#FFFFFF33] bg-[#000000] rounded-lg z-10 p-2 flex flex-col justify-center"
              >
                {showQuizz && availableQuizz && (
                  <figure
                    id="quizz_container"
                    className="r w-full h-full absolute top-0 left-0 right-0 bottom-0 z-[150] backdrop-blur ring-[#FFFFFF33] rounded-lg flex flex-col justify-center overflow-x-hidden"
                    style={{
                      backgroundImage: `url(/assets/HeavenKey_Space.jpg)`,
                      backgroundSize: "cover",
                    }}
                  >
                    {!displayHint && !hintQuizz && (
                      <div
                        id="quizz"
                        className="flex flex-col gap-4 text-white  h-full p-2 lg:p-24 overflow-y-auto justify-center backdrop-blur bg-[#00000088] rounded-lg  overflow-x-hidden"
                      >
                        <div className="flex flex-col w-full">
                          <div className="flex w-full justify-start mb-8">
                            <button
                              onClick={() => {
                                setShowQuizz(false), resetQuizz();
                              }}
                              className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg"
                            >
                              Return
                            </button>
                          </div>
                          <div>
                            <p className="text-radial-gradient uppercase text-2xl italic font-bold">
                              {step == 1
                                ? "Global Crypto"
                                : step == 2
                                ? "Hedera News"
                                : step == 3
                                ? "Legends Of The Past updates"
                                : ""}
                            </p>
                            <p>{step + " / " + totalStep}</p>
                          </div>
                        </div>
                        <h1 className="font-bold leading-8 text-[0.8rem] md:text-[1rem]">
                          {quizz[step]}
                        </h1>
                        <div className="w-full gap-4 flex flex-col overflow-x-hidden">
                          {displayQuizzOptions?.map((option, index) => (
                            <button
                              disabled={tryOption ? true : false}
                              key={index}
                              onClick={() => checkAnswer(option)}
                              className={`text-[0.8rem] md:text-[1rem] leading-8 w-full p-5 text-center rounded-lg ${
                                tryOption
                                  ? optionClicked.optionName ==
                                    option.optionName
                                    ? answerCheck
                                      ? "bg-green-600 good-response"
                                      : "bg-red-600 bad-response"
                                    : "bg-gradient-to-br from-[#000000] to-[#555555]"
                                  : "bg-gradient-to-br from-[#000000] to-[#555555] md:hover:bg-gradient-to-br md:hover:from-[#555555] md:hover:text-[1.03rem] md:hover:bg-gradient-to-br md:hover:from-[#555555] md:hover:text-[1.03rem]"
                              }  `}
                            >
                              {option.optionName}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    {displayHint && !hintQuizz && (
                      <div
                        id="quizz"
                        className="flex  flex-col gap-4 text-white  h-full p-2 lg:p-24 overflow-y-auto justify-center backdrop-blur bg-[#00000088] rounded-lg  overflow-x-hidden"
                      >
                        <div className="flex w-full justify-start">
                          <button
                            onClick={() => {
                              setShowQuizz(false),
                                resetQuizz(),
                                setDisplayHint(false),
                                setHintQuizz(false),
                                resetHintQuizz();
                            }}
                            className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg"
                          >
                            Return
                          </button>
                        </div>
                        <div className="flex flex-col w-full gap-4">
                          <div>
                            <p className="text-radial-gradient uppercase text-2xl italic font-bold">
                              {hintTitle}
                            </p>
                            <p className="text-white leading-5 uppercase lg:text-xl 2xl:text-2xl italic">
                              {hintText}
                            </p>
                          </div>
                          <div className="w-full flex justify-center items-center m-auto">
                            <img
                              src={revealArtist}
                              className="w-full h-auto"
                              rel="preload"
                            ></img>
                          </div>
                          <div className="w-full flex justify-end items-center m-auto">
                            <motion.button
                              whileHover={{ scale: 1.1 }}
                              whileTap={{ scale: 0.9 }}
                              className="flex items-center justify-center rounded-xl p-2 z-50 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]"
                              onClick={() => {
                                setHintQuizz(true), setDisplayHint(false);
                              }}
                            >
                              <span class="text-radial-gradient uppercase px-4 py-1 text-[1.25rem] md:text-[1.75rem] italic font-bold">
                                Next
                              </span>
                            </motion.button>
                          </div>
                        </div>
                      </div>
                    )}
                    {hintQuizz && (
                      <div
                        id="quizz"
                        className="flex  flex-col gap-4 text-white  h-full p-2 lg:p-24 overflow-y-auto justify-center backdrop-blur bg-[#00000088] rounded-lg  overflow-x-hidden"
                      >
                        <div className="flex w-full justify-start">
                          <button
                            onClick={() => {
                              setShowQuizz(false),
                                resetQuizz(),
                                setDisplayHint(false),
                                setHintQuizz(false),
                                resetHintQuizz();
                            }}
                            className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg"
                          >
                            Return
                          </button>
                        </div>
                        <div className="flex flex-col w-full gap-5">
                          <h1 className="font-bold leading-6 text-[1.2rem] md:text-[2rem] 2xl:text-[2.5rem] w-[90%] m-auto ">
                            What is the heritage of our Second Legend?
                          </h1>
                          <div className="w-full gap-6 flex flex-col">
                            {displayQuizzOptionsHint?.map((option, index) => (
                              <button
                                disabled={tryOptionHint ? true : false}
                                key={index}
                                onClick={() => checkAnswerHint(option)}
                                className={`text-[0.8rem] md:text-[1rem] leading-8 w-2/5 m-auto  p-5 text-center rounded-lg ${
                                  tryOptionHint
                                    ? optionClickedHint.optionName ==
                                      option.optionName
                                      ? answerCheckHint
                                        ? "bg-green-600 good-response"
                                        : "bg-red-600 bad-response"
                                      : "bg-gradient-to-br from-[#000000] to-[#555555]"
                                    : "bg-gradient-to-br from-[#000000] to-[#555555] md:hover:bg-gradient-to-br md:hover:from-[#555555] md:hover:text-[1.03rem] md:hover:bg-gradient-to-br"
                                }  `}
                              >
                                {option.optionName}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </figure>
                )}
                <h2 className="text-radial-gradient  uppercase  italic  font-bold text-[1.25rem] lg:m-1 lg:text-2xl 2xl:m-2 2xl:text-3xl z-50 pointer-events-none pt-8">
                  Aware Hbarbarians
                </h2>
                <div
                  id="article_container"
                  className="lg:h-full relative flex flex-col gap-7 p-4 lg:p-4 justify-between"
                >
                  <div
                    id="leaderboard"
                    className={`flex flex-col gap-3 rounded-lg text-white p-2 h-[40vh] md:h-auto overflow-y-auto`}
                  >
                    <h2 className="text-[0.9rem] md:text-[1rem] leading-6 lg: leading-auto">
                      To stay ahead in the fast-evolving landscape of web3 and
                      to understand price volatility, staying informed is not
                      just beneficial— it's essential.{" "}
                    </h2>
                    <ol className="flex flex-col gap-0">
                      {leaderboard?.map((player, index) => (
                        <li
                          className="flex flex-row justify-between items-center border-b-2 border-slate-800	"
                          key={index}
                        >
                          <p className="w-[80%] p-1 px-10 text-start">
                            {player.pseudo}
                          </p>
                          <div className="w-[20%] flex flex-row gap-2 justify-center">
                            <p className=" text-end w-[50%]">{player.score}</p>
                            <div className="h-[2rem] w-auto w-[50%]">
                              <img
                                className="h-[2rem] w-auto"
                                src={
                                  player.score > 0
                                    ? index === 0
                                      ? flamme1
                                      : index === 1
                                      ? flamme2
                                      : index === 2
                                      ? flamme3
                                      : staticflamme
                                    : ""
                                }
                              ></img>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ol>
                  </div>

                  <div
                    id="quizz"
                    className="h-min flex flex-col gap-4 justify-end"
                  >
                    <p
                      id="quizzStatus"
                      className={
                        !availableQuizz &&
                        "flex flex-col gap-4 text-white rounded-lg p-2 h-auto leading-7 lg: leading-auto"
                      }
                    >
                      {!availableQuizz &&
                        "You are now an aware Hbarbarian. See you next week for further news. Stay ahead of the game!"}
                    </p>
                    {availableQuizz && !finishedQuizz && (
                      <div className="w-full flex flex-col justify-center items-center">
                        <p className="text-white text-left p-2 text-[0.9rem] md:text-[1rem] leading-6 lg: leading-auto">
                          Elevate your knowledge each week: 3 questions on
                          global crypto, Hedera news, and Legends Of The Past
                          updates
                        </p>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          className="flex items-center justify-center rounded-xl p-2 z-50 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]"
                          onClick={() => {
                            setShowQuizz(true);
                            const scrollableDiv =
                              document.getElementById("scroll");
                            if (scrollableDiv) {
                              scrollableDiv.scrollTop = 0;
                            }
                          }}
                        >
                          <span class="text-radial-gradient uppercase px-4 py-1 text-[0.9rem] md:text-[1rem] italic font-bold">
                            Start
                          </span>
                        </motion.button>
                      </div>
                    )}
                  </div>

                  <div></div>
                </div>
              </figure>
              {showQuizz && availableQuizz && (
                <div
                  id="quizz"
                  className="flex h-full absolute top-0 left-0 right-0  z-[51] overflow-y-auto"
                  style={{
                    backgroundImage: `url(/assets/HeavenKey_Space.jpg)`,
                  }}
                >
                  {!displayHint && !hintQuizz && (
                    <div className="w-full h-full backdrop-blur bg-[#00000088] flex flex-col gap-6 text-white p-2 lg:px-32 lg:py-16 xl:py-8 xl:pb-16 2xl:py-40">
                      <div className="flex flex-col w-full">
                        <div className="flex w-full justify-start">
                          <button
                            onClick={() => {
                              setShowQuizz(false), resetQuizz();
                            }}
                            className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg"
                          >
                            Return
                          </button>
                        </div>
                        <div>
                          <p className="text-radial-gradient uppercase text-2xl italic font-bold">
                            {step == 1
                              ? "Global Crypto"
                              : step == 2
                              ? "Hedera News"
                              : step == 3
                              ? "Legends Of The Past updates"
                              : ""}
                          </p>
                          <p>{step + " / " + totalStep}</p>
                        </div>
                      </div>

                      <h1 className="font-bold leading-8 text-[0.8rem] md:text-[1rem] 2xl:text-[1.5rem] w-2/3 m-auto ">
                        {quizz[step]}
                      </h1>
                      <div className="w-full gap-6 flex flex-col">
                        {displayQuizzOptions?.map((option, index) => (
                          <button
                            disabled={tryOption ? true : false}
                            key={index}
                            onClick={() => checkAnswer(option)}
                            className={`text-[0.8rem] md:text-[1rem] leading-8 w-2/5 m-auto  p-5 text-center rounded-lg ${
                              tryOption
                                ? optionClicked.optionName == option.optionName
                                  ? answerCheck
                                    ? "bg-green-600 good-response"
                                    : "bg-red-600 bad-response"
                                  : "bg-gradient-to-br from-[#000000] to-[#555555]"
                                : "bg-gradient-to-br from-[#000000] to-[#555555] md:hover:bg-gradient-to-br md:hover:from-[#555555] md:hover:text-[1.03rem] md:hover:bg-gradient-to-br md:hover:from-[#555555] md:hover:text-[1.03rem]"
                            }  `}
                          >
                            {option.optionName}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {displayHint && !hintQuizz && (
                    <div
                      id="quizz"
                      className="flex flex-col  text-white h-full w-full overflow-y-auto justify-center backdrop-blur bg-[#00000088] rounded-lg  overflow-x-hidden"
                    >
                      <div className="flex w-full justify-start">
                        <button
                          onClick={() => {
                            setShowQuizz(false),
                              resetQuizz(),
                              setDisplayHint(false),
                              setHintQuizz(false),
                              resetHintQuizz();
                          }}
                          className="flex justify-center mx-5 items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg"
                        >
                          Return
                        </button>
                      </div>
                      <div className="flex flex-col w-full gap-4">
                        <div>
                          <p className="text-radial-gradient uppercase lg:text-[3rem] 2xl:leading-[5rem] 2xl:text-[4rem] italic font-bold">
                            {hintTitle}
                          </p>
                          <p className="text-white uppercase lg:text-xl 2xl:text-2xl italic">
                            {hintText}
                          </p>
                        </div>
                        <div className="w-1/2 flex justify-center items-center m-auto">
                          <img
                            src={revealArtist}
                            className="w-full h-auto"
                            rel="preload"
                          ></img>
                        </div>
                        <div className="w-1/2 flex justify-end items-center m-auto">
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            className="flex items-center justify-center rounded-xl p-2 z-50 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]"
                            onClick={() => {
                              setHintQuizz(true), setDisplayHint(false);
                            }}
                          >
                            <span class="text-radial-gradient uppercase px-4 py-1 text-[1.25rem] md:text-[1.75rem] italic font-bold">
                              Next
                            </span>
                          </motion.button>
                        </div>
                      </div>
                    </div>
                  )}
                  {hintQuizz && (
                    <div
                      id="quizz"
                      className="flex flex-col gap-8 text-white  h-full w-full overflow-y-auto justify-center backdrop-blur bg-[#00000088] rounded-lg  overflow-x-hidden"
                    >
                      <div className="flex w-full justify-start">
                        <button
                          onClick={() => {
                            setShowQuizz(false),
                              resetQuizz(),
                              setDisplayHint(false),
                              setHintQuizz(false),
                              resetHintQuizz();
                          }}
                          className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg"
                        >
                          Return
                        </button>
                      </div>
                      <div className="flex flex-col w-full gap-5">
                        <h1 className="font-bold leading-10 text-[0.8rem] md:text-[1.6rem] 2xl:text-[2rem] w-2/3 m-auto ">
                          What is the heritage of our Second Legend?
                        </h1>
                        <div className="w-full gap-6 flex flex-col">
                          {displayQuizzOptionsHint?.map((option, index) => (
                            <button
                              disabled={tryOptionHint ? true : false}
                              key={index}
                              onClick={() => checkAnswerHint(option)}
                              className={`text-[0.8rem] md:text-[1.2rem] 2xl:text-[1.5rem] leading-8 w-2/5 m-auto  p-5 text-center rounded-lg ${
                                tryOptionHint
                                  ? optionClickedHint.optionName ==
                                    option.optionName
                                    ? answerCheckHint
                                      ? "bg-green-600 good-response"
                                      : "bg-red-600 bad-response"
                                    : "bg-gradient-to-br from-[#000000] to-[#555555]"
                                  : "bg-gradient-to-br from-[#000000] to-[#555555] md:hover:bg-gradient-to-br md:hover:from-[#555555] md:hover:text-[1.35rem] 2xl:hover:text-[1.65rem]"
                              }  `}
                            >
                              {option.optionName}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </figure>
          </section>
        </div>
        <Menu />
      </section>
    </section>
  );
};

export default Mint;
