import { HashConnect } from "hashconnect";

import { AccountId, TokenId } from "@hashgraph/sdk";

import { showHideAnswerFaq } from "pages/CommitteeCleo/CommitteeCleo";
import convertToJSONString from "./convertToJSONString.js";
import "particles.js/particles";
import Auth from "./utils/auth/utils.js";
const axios = require("axios").default;
var Highcharts = require("highcharts");

export function LoadParticles() {
  const particlesJS = window.particlesJS;
  let clause = window.innerWidth < 768;
  config.particles.number.value = clause ? 50 : 100;
  particlesJS("particle", config);
}

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
  console.log(`connexion status: ${connectionStatus}`);
});

let saveData = {
  topic: "",
  pairingString: "",
  privateKey: "",
  pairedWalletData: null,
  pairedAccounts: [],
};

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if (isMainnet) {
  hashConnectString = "mainnet";
  mirrornodeString = "mainnet-public";
}

const cleoTokenId = TokenId.fromString("0.0.3853857");

let nb_cleo = 1;
let nb_cleo_front;

const appMetaData = {
  name: "Legends of the past",
  description:
    "Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar",
  icon: "https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk",
};

export const pairHashpack = async () => {
  let initData = await hashconnect.init(appMetaData, hashConnectString, false);
  saveData.privateKey = initData.privKey;
  saveData.topic = initData.topic;
  console.log("initData");
  console.log(initData);
  console.log(`topic: ${initData.topic}`);

  hashconnect.foundExtensionEvent.once((walletMetadata) => {
    hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
  });

  hashconnect.pairingEvent.once((pairingData) => {
    console.log(`wallet paired`);
    // HideIntro();
    AskCleo();

    console.log(pairingData);

    const accountId = document.getElementById("accountid");
    accountId.innerHTML = pairingData.accountIds[0];
    saveData.pairedAccounts.push(pairingData.accountIds[0]);
    //Update nb Cleo and name
    //checkWL(pairingData.accountIds[0])
  });
  return initData;
};

export const getPseudo = async () => {
  const auth = Auth.getAuth();
  const account = Auth.getAccount();

  const body = JSON.stringify({
    userId: account,
    auth: JSON.stringify(auth.userSignature),
    sign: JSON.stringify(auth.signedPayload),
  });

  let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getPseudo`;
  const response = await axios.post(url, body);
  return convertToJSONString(response.data);
};
async function AskCleo() {
  number_cleo = await CheckClientHas(cleoTokenId);
  //const nb_cleo_text = document.getElementById("number_cleo");
  //nb_cleo_text.innerHTML = number_cleo.toString() + " Cleos";
}

export const RequestLast5Articles = async () => {
  let url =
    "https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Article";
  axios
    .get(url)
    .then(function (response) {
      // console.log(response.data);
      response = JSON.parse(convertToJSONString(response.data));
      for (let index = 0; index < response.length; index++) {
        // console.log(response[index]["Title"]);
        // console.log(response[index]["Timestamp"]);
        // console.log(response[index]["Text"]);
        AddArticle(
          response[index]["Title"],
          response[index]["Timestamp"],
          response[index]["Text"],
          response[index]["Visual"]
        );
      }
    })
    .catch(function (err) {
      // console.log(err);
      // alert(err.toString());
    });
};

let number_Article = 1;
const AddArticle = (title, timestamp, text, visual) => {
  const article_container = document.getElementById("article_container");

  let date = formatTimestamp(parseInt(timestamp));
  let resume = text.substring(0, 30) + "...";

  const tempElement = document.createElement("div");

  tempElement.innerHTML = `<button
            id="cell_faq_${number_Article}"
            class='button-63 text-white p-4 lg:p-8'>
            <div class="w-full h-full grid gap-2">
                <div class='flex h-8 gap-2'>
                    <span class=' text-left  grow my-auto  uppercase text-[0.9rem] lg:text-[1.1rem] 2xl:text-[1.1rem]'>${title} </span>
                    <span class=' text-right  grow my-auto  uppercase text-[0.6rem] lg:text-[0.8rem] 2xl:text-[0.9rem]'>${date} </span>
                </div>
                <div id="resume_${number_Article}" class='flex h-8'>
                    <span class='text-left  grow my-auto text-[0.6rem] lg:text-[0.8rem] 2xl:text-[1.2rem]'>${resume}</span>
                    <img src="/assets/arrow-down.png" class=' flex-none my-auto w-[1.5rem] h-[1.5rem] right-0'></img>
                </div>
    
                <div id="text_${number_Article}" class='hidden'>
                    <p class='text-left text-[0.6rem] lg:text-[0.8rem] 2xl:text-[1.2rem] mx-1 lg:mx-6 2xl:mx-10 leading-6 lg:leading-6 2xl:leading-10'>
                    ${text}
                    </p>
                </div>

                ${
                  visual
                    ? `<div id="img_${number_Article}" class='hidden flex justify-center items-center max-w-[100%]'>
                <img src="${visual}" class='m-auto max-w-max max-h-[200px] md:max-h-[300px]' />
            </div>`
                    : ""
                }
            </div>
        </button>`;

  let cell = tempElement.firstChild;

  let art = number_Article;
  cell.onclick = () => showHideAnswerFaq(art);

  // Ajoutez le bouton au conteneur des articles.
  article_container.appendChild(cell);

  number_Article++;
};

function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000); // Multiplié par 1000 car JavaScript s'attend à un timestamp en millisecondes

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois vont de 0 à 11
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${hours}:${minutes} ${month}/${day}/${year}`;
}

var config = {
  particles: {
    number: {
      value: 100,
      density: {
        enable: true,
        value_area: 786,
      },
    },
    color: {
      value: ["#ffffff", "#ffba08", "#faa307", "#f48c06", "#ff8fa3", "#ff758f"],
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#000000",
      },
      polygon: {
        nb_sides: 4,
      },
      image: {
        src: "",
        width: 100,
        height: 100,
      },
    },
    opacity: {
      value: 0.7,
      random: true,
      anim: {
        enable: true,
        speed: 1,
        opacity_min: 0,
        sync: false,
      },
    },
    size: {
      value: 5,
      random: true,
      anim: {
        enable: true,
        speed: 3,
        size_min: 0.3,
        sync: false,
      },
    },
    line_linked: {
      enable: false,
      distance: 100,
      color: "#fff",
      opacity: 0.023674428,
      width: 1,
    },
    move: {
      enable: true,
      speed: 0.4,
      direction: "top",
      random: true,
      straight: false,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: true,
        rotateX: 2082.2488,
        rotateY: 3363.6328,
      },
    },
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: "grab",
      },
      onclick: {
        enable: true,
        mode: "repulse",
      },
      resize: true,
    },
    modes: {
      grab: {
        distance: 70,
        line_linked: {
          opacity: 0.25,
        },
      },
      bubble: {
        distance: 100,
        size: 5,
        duration: 8.598243,
        opacity: 0,
        speed: 3,
      },
      repulse: {
        distance: 150,
        duration: 0.8,
      },
      push: {
        particles_nb: 4,
      },
      remove: {
        particles_nb: 2,
      },
    },
  },
  retina_detect: true,
};
/*window.onload = async function () {
  loadPieChart();
};*/

export async function loadPieChart(keys) {
  var vendorChartElement = document.getElementById("piechart");
  let nb_cleo_listed = keys;
  let y = 0;
  let x = 0;

  const holders = document.getElementById("statusHolders");
  const listed = document.getElementById("x_value");
  holders.innerHTML = "Holders : " + (1000 - nb_cleo_listed).toString();
  listed.innerHTML = `<b>${nb_cleo_listed + "x"}</b>`;
  y = nb_cleo_listed / 10.0;
  if (y < 3) {
    y = 3;
  }
  x = 100 - y;

  //console.log(y);
  //console.log(x);
  if (vendorChartElement) {
    Highcharts.chart(vendorChartElement, {
      chart: {
        backgroundColor: "transparent",
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: "",
      },
      tooltip: {
        /*pointFormat: "<b>{point.percentage:.1f}%</b>",*/
        pointFormat: "",
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
          startAngle: -90,
          endAngle: 90,
          size: 200,
          center: ["50%", "95%"],
        },
      },
      series: [
        {
          type: "pie",
          innerSize: "40%",
          data: [
            {
              name: "You: <b>" + keys + " CLEO</b>",
              color: "rgba(26, 188, 156,0.75)",
              y: y,
            },
            {
              name: "Other: <b>" + (1000 - keys) + " CLEO</b>",
              color: "rgba(200, 200, 200,1)",
              y: x,
            },
          ],
        },
      ],
    });
  }
}

async function CheckClientHas(TokenId) {
  const url =
    `https://` +
    mirrornodeString +
    `.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc&limit=50`;
  //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
  let res = 0;
  await axios
    .get(url)
    .then(function (response) {
      const jsonResponse = response.data;
      // output the from address and message stored in the event
      if (jsonResponse.nfts.length != 0) {
        res = jsonResponse.nfts.length;
        serial = [];
        for (let index = 0; index < jsonResponse.nfts.length; index++) {
          serial.push(jsonResponse.nfts[index].serial_number.toString());
        }
        console.log(`Mirror event(s): ${serial}`);
      }
    })
    .catch(function (err) {
      console.error(err);
      alert(err.toString());
    });
  return res;
}

export const requestQuizz = async () => {
  const url =
    "https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getQuizz";
  try {
    const response = await axios.get(url);
    const quizz = JSON.parse(convertToJSONString(response.data));
    return quizz[0];
  } catch (err) {
    console.log(err);
  }
};

export const requestQuizzOptions = async (id) => {
  const url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getQuizzOptions&quizzID=${id}`;

  try {
    const response = await axios.get(url);
    console.log(convertToJSONString(response.data));
    const quizzOptions = JSON.parse(convertToJSONString(response.data));
    return quizzOptions;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export async function addScore() {
  try {
    const url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=winQuizz`;
    const auth = Auth.getAuth();
    const account = Auth.getAccount();

    const body = JSON.stringify({
      userId: account,
      auth: JSON.stringify(auth.userSignature),
      sign: JSON.stringify(auth.signedPayload),
    });

    const response = await axios.post(url, body);
    return response;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const checkQuizz = async () => {
  try {
    const account = Auth.getAccount();
    const url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getQuizzParticipation&accountid=${account}`;
    const response = await axios.get(url);

    const availableQuizz = await response.data;
    return availableQuizz;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getLeaderboard = async () => {
  try {
    const url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=getLeaderboardQuizz`;
    const response = await axios.get(url);
    console.log(response.data);
    const leaderboard = await JSON.parse(convertToJSONString(response.data));
    return leaderboard;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
