import { Navigate } from "react-router-dom";
import { useEffect, useState } from 'react';

const Guard = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const result = sessionStorage.getItem("Account")
      if (!result) {
        setIsAuthenticated(false);
      } else {
        setIsAuthenticated(true);
      }
    };

    checkAuth();
  }, []);
  if (isAuthenticated === null) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/coin" />;
  } else {
    return children;
  }
};

export default Guard;