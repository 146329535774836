import React, { useEffect, useRef, useState } from "react";
import { HashConnect } from "hashconnect";
import { renderMatches, useNavigate } from "react-router-dom";
import "../../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";
import { Magic } from "magic-sdk";
import { HederaExtension } from "@magic-ext/hedera";
import { MagicWallet } from "../../test/MagicWallet";
import { MagicProvider } from "../../test/MagicProvider";
import {
  AccountId,
  TokenId,
  TokenAssociateTransaction,
  TopicMessageSubmitTransaction,
  TransferTransaction,
  ScheduleSignTransaction,
  Client,
  PrivateKey,
  Hbar,
} from "@hashgraph/sdk";
import { set } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import convertToJSONString from "../../convertToJSONString";
const axios = require("axios").default;

let useMagic = false;

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
// var userAgent = navigator.userAgent;
const Connexion = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogLoad, setDialogLoad] = useState(false);
  const [dialogOne, setDialogOne] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let isCoin = true;

  const navigate = useNavigate();
  let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts: [],
  };

  let hashconnect = new HashConnect();
  let isMainnet = true;

  let hashConnectString = "testnet";
  let mirrornodeString = "testnet";

  if (isMainnet) {
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public";
  }

  const appMetaData = {
    name: "Legends of the past",
    description:
      "Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar",
    icon: "https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk",
  };

  const pairHashpack = async () => {
    console.log("Begin to connect");
    let initData = await hashconnect.init(
      appMetaData,
      hashConnectString,
      false
    );

    saveData.privateKey = initData.privKey;
    saveData.topic = initData.topic;

    hashconnect.foundExtensionEvent.once((walletMetadata) => {
      hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
    });

    hashconnect.pairingEvent.once((pairingData) => {
      saveData.pairedAccounts.push(pairingData.accountIds[0]);
      console.log("Connected to :" + pairingData.accountIds[0]);
      HashpackFlow(pairingData.accountIds[0]);
      //localStorage.setItem("Account", pairingData.accountIds[0]);
    });

    /*setIsLoading(true);
      setIsDialogOpen(true);
      setDialogLoad(true);*/
    return initData;
  };

  const HashpackFlow = async (account) => {
    //Check association
    console.log("assoiate by hahspack");
    let response = await CheckAssociateToken(account);
    //Give Coin
    if (response == "SUCCESS") {
      console.log("getCoin by hahspack");
      let responseCoin = await GetCoin(account);
      let dataCoin = convertToJSONString(responseCoin.data);
      dataCoin = JSON.parse(dataCoin);
      console.log(dataCoin);
      if (dataCoin.status == "SUCCESS") {
        navigate(`/drop?iscoin=${isCoin}`);
      } else {
        const responsetext = document.getElementById("response");
        responsetext.className =
          "italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold";
        responsetext.innerHTML = dataCoin.status;
      }
    }
    //Navigate
  };

  const CoinTokenId = TokenId.fromString("0.0.4328145");

  let magic = new Magic("pk_live_DE3AA2DBCC175C93", {
    extensions: [
      new HederaExtension({
        network: "mainnet", // 'mainnet' or 'testnet'
      }),
    ],
  });

  /* 3️⃣ Implement Render Function */
  const render = async () => {
    const isLoggedIn = await magic.user.isLoggedIn();

    /* Show login form if user is not logged in */
    /* le "onSubmit" ne s'ajoute pas"*/
    let html = `
  <h1>Please sign up or login</h1>
  <form onSubmit="{(event)=>handleLogin(event)}">
    <input type="email" name="email" required="required" placeholder="Enter your email" />
    <button type="submit">Send</button>
  </form>
  `;

    if (isLoggedIn) {
      useMagic = true;
      /* Get user metadata including email */
      const userMetadata = await magic.user.getMetadata();
      console.log(userMetadata);

      console.log(userMetadata.publicAddress);
      sessionStorage.setItem("Account", userMetadata.publicAddress);

      setIsLoading(true);
      setDialogOne(false);
      setDialogLoad(true);
    }
  };

  /* 4️⃣ Implement Login Handler */
  const handleLogin = async (event) => {
    console.log("handleLogin");
    event.preventDefault(); // Prevent the default form submission behavior
    const email = new FormData(event.target).get("email");
    if (email) {
      /* One-liner login with email OTP 🤯 */
      await magic.auth.loginWithEmailOTP({ email });
      render();
    }
  };

  /* 5️⃣ Implement Logout Handler */
  const handleLogout = async () => {
    await magic.user.logout();
    render();
  };

  async function CheckAssociateToken(publicaddress) {
    const url =
      `https://` +
      mirrornodeString +
      `.mirrornode.hedera.com/api/v1/accounts/${publicaddress}/tokens?token.id=${CoinTokenId}`;
    console.log("CheckAssociateToken " + publicaddress);
    //TODO try catch
    let response = await axios.get(url);
    const jsonResponse = response.data;
    if (jsonResponse.tokens.length == 0) {
      console.log(`token ${CoinTokenId} is not associated`);
      if (useMagic) {
        return await AssociateTokenMagic(publicaddress);
      }
      return await AssociateToken(publicaddress);
    } else {
      console.log(`token ${CoinTokenId} is already associated`);
      return "SUCCESS";
    }
  }

  const GetCoin = async (account) => {
    let urlCoin = `https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid=${account}&target=coin&magiclink=${useMagic}`;
    return await axios.get(urlCoin);
  };

  const AssociateToken = async (account) => {
    try {
      const provider = hashconnect.getProvider(
        hashConnectString,
        saveData.topic,
        account
      );
      const signer = hashconnect.getSigner(provider);
      let associateLocalWalletTx = await new TokenAssociateTransaction()
        .setAccountId(account)
        .setTokenIds([CoinTokenId])
        .setMaxTransactionFee(new Hbar(150))
        .freezeWithSigner(signer);

      let associateLocalWalletTxSubmit =
        await associateLocalWalletTx.executeWithSigner(signer);
      if (associateLocalWalletTxSubmit == undefined) {
        console.log(`user refuse to associate token`);
        return "ERROR";
      } else {
        return "SUCCESS";
      }
    } catch (error) {
      console.log(`error token don't associated: ${error}`);
      return "ERROR";
    }
  };

  const AssociateTokenMagic = async (publicaddress) => {
    console.log("Need to associate");
    try {
      const { publicKeyDer } = await magic.hedera.getPublicKey();

      const magicSign = (message) => magic.hedera.sign(message);
      const magicWallet = new MagicWallet(
        publicaddress,
        new MagicProvider("mainnet"),
        publicKeyDer,
        magicSign
      );

      let associateLocalWalletTx = await new TokenAssociateTransaction()
        .setAccountId(AccountId.fromString(publicaddress))
        .setTokenIds([CoinTokenId])
        .setMaxTransactionFee(new Hbar(150))
        .freezeWithSigner(magicWallet);

      let associateLocalWalletTxSubmit =
        await associateLocalWalletTx.executeWithSigner(magicWallet);
      if (associateLocalWalletTxSubmit == undefined) {
        console.log(`user refuse to associate token`);
        return "ERROR";
      } else {
        return "SUCCESS";
      }
    } catch (error) {
      console.log(`error token don't associated: ${error}`);
      return "ERROR";
    }
  };

  async function load() {
    // const div = document.getElementById("app");
    // const container= document.getElementById("container");
    // container.classList.add("gap-8");

    // while (div.firstChild) {
    //   div.removeChild(div.firstChild);
    // }
    // div.innerHTML = `
    // <div class="flex flex-row gap-4 m-auto justify-center">
    //   <span id="step1" class="w-[75px] h-[20px] border-solid border-4 border-white"></span>
    //   <span id="step2" class="w-[75px] h-[20px] border-solid border-4 border-white"></span>
    //   <span id="step3" class="w-[75px] h-[20px] border-solid border-4 border-white"></span>
    // </div>
    // <p id="status" class="text-center text-white text-[1.5rem]"></p>
    // `

    const status = document.getElementById("status");
    const step1 = document.getElementById("step1");
    const step2 = document.getElementById("step2");
    const step3 = document.getElementById("step3");

    setTimeout(() => {
      status.innerHTML = "Initialize wallet";
    }, 0);

    const account = sessionStorage.getItem("Account");

    let url = `https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid=${account}&target=hbar`;

    const response = await axios.get(url);
    let data = convertToJSONString(response.data);
    data = JSON.parse(data);
    console.log("reponse Hbar :");
    console.log(data.status);
    //const response = "SUCCESS";

    if (data.status == "SUCCESS") {
      step1.style.backgroundColor = "white";
      status.innerHTML = "Associate token";

      //associate token
      //let responseAssociate = await CheckAssociateToken(account);
      await delay(2000);
      let responseAssociate = "SUCCESS";
      console.log("reponse Associate :");
      console.log(responseAssociate);

      if (responseAssociate /*.data.status*/ == "SUCCESS") {
        step2.style.backgroundColor = "white";
        status.innerHTML = "Give coin";

        const responseCoin = await GetCoin(account);
        let dataCoin = convertToJSONString(responseCoin.data);
        dataCoin = JSON.parse(dataCoin);

        console.log("reponse Coin :");
        console.log(responseCoin);

        if (dataCoin.status == "SUCCESS") {
          step3.style.backgroundColor = "white";
          await delay(1000);
          navigate(`/drop?iscoin=${isCoin}`);
        } else {
          status.innerHTML = dataCoin.status;
          step1.style.display = "none";
          step2.style.display = "none";
          step3.style.display = "none";
          setIsLoading(false);

          setTimeout(() => {
            setIsDialogOpen(false);
            setDialogLoad(false);
            setDialogOne(false);
          }, 3000);
        }
      } else {
        status.innerHTML = `associate token ${CoinTokenId} to ${account}: Failed`;
        step1.style.display = "none";
        step2.style.display = "none";
        step3.style.display = "none";
        setIsLoading(false);

        setTimeout(() => {
          setIsDialogOpen(false);
          setDialogLoad(false);
          setDialogOne(false);
        }, 3000);
      }
    } else {
      status.innerHTML = data.status;
      step1.style.display = "none";
      step2.style.display = "none";
      step3.style.display = "none";
      setIsLoading(false);

      setTimeout(() => {
        setIsDialogOpen(false);
        setDialogLoad(false);
        setDialogOne(false);
      }, 3000);
    }
  }

  useEffect(() => {
    if (isDialogOpen) {
      console.log("open");
      if (dialogLoad) {
        load();
      }
    }
  }, [isDialogOpen, dialogLoad]);
  return (
    <section className="overflow-hidden">
      <div className="App">
        {/* LEGENDS OF THE PAST */}
        <section
          className="relative h-screen"
          style={{
            backgroundImage: `url(/assets/HeavenKey_Space.jpg)`,
            backgroundPosition: "center center",
          }}
        >
          {/* Header & Social networks */}
          <figure className="lg:absolute items-end top-0 w-full border-t-[50px] lg:border-t-[80px] border-t-black z-50">
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-14 z-10 -top-1 lg:w-28 lg:-top-24 lg:left-0"
              />
            </a>
            <h2 className="absolute text-white uppercase text-shadow-blue-low-mobile  italic font-bold right-0 left-0 mx-auto top-2 lg:-top-14 text-[0.7rem] lg:text-3xl z-50">
              COIN
            </h2>
            <figure className="lg:absolute items-end top-0 w-full h-[15px] bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          </figure>

          <div className="absolute top-1/3  w-[100%]  flex flex-col justify-center items-center gap-5">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="italic w-[300px] md:w-[700px] py-[1rem] lg:py-[3rem] rounded-xl z-50 bg-[#8626EC] hover:bg-[#8611EC] flex justify-center"
              onClick={async () => {
                {
                  setIsDialogOpen(true),
                    setDialogOne(true),
                    setTimeout(() => render(), 1000);
                }
              }}
            >
              <span
                className="text-radial-gradient text-[1.5rem] lg:text-[2.5rem] font-bold text-center"
                id="accountid"
              >
                Connect in 1 click
              </span>
            </motion.button>
            <p
              className="text-shadow-blue text-white text-center text-[2rem] bold italic"
              style={{ textShadow: "2px 6px 3px #000000" }}
            >
              OR
            </p>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="italic w-[225px] px-[0.5rem] md:px-auto md:w-[400px] py-[1rem] lg:py-[2rem] rounded-xl z-50 bg-[#8626EC] hover:bg-[#8611EC] flex justify-center"
              onClick={async () => {
                await pairHashpack();
              }}
            >
              <span
                className="text-radial-gradient text-[1rem] lg:text-[1.75rem] font-bold text-center"
                id="accountid"
              >
                Connect with HashPack
              </span>
            </motion.button>
            <h3
              className="hidden italic text-white z-50 h-10 text-[1rem] lg:text-[1.5rem] 3xl:text-[2rem] font-bold"
              id="response"
            >
              {" "}
              Void
            </h3>
          </div>
          {isDialogOpen && (
            <dialog
              open
              className="absolute rounded-xl w-[90%] lg:w-[50%] bg-[#25191EEE] m-auto flex flex-col justify-center gap-3 h-[50%] z-[100] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
              onLoad={() => render()}
            >
              <div className="flex flex-col pt-16 pb-12">
                <div className="mx-auto flex w-full max-w-7xl grow flex-col justify-center px-4 sm:px-6 lg:px-8 text-white">
                  <div className="py-16">
                    <div id="container" className="text-center flex flex-col">
                      {/* <p className="text-[2rem] font-bold uppercase tracking-wide">
                                      Please, enter your email :
                                    </p> */}
                      {/* <h1 className="mt-2 text-4xl font-extrabold tracking-tight sm:text-5xl">
                                      create you wallet with magic link 
                                    </h1> */}
                      <div id="app" className="flex flex-col gap-5">
                        {dialogOne && (
                          <>
                            <h1 className="text-[2rem]">
                              Please sign up or login
                            </h1>
                            <form onSubmit={(event) => handleLogin(event)}>
                              <input
                                type="email"
                                name="email"
                                required="required"
                                placeholder="Enter your email"
                                className="text-black"
                              />
                              <button
                                className=" px-1  bg-[#8626EC] hover:bg-[#8611EC] "
                                type="submit"
                              >
                                Send
                              </button>
                            </form>
                          </>
                        )}
                        {dialogLoad && (
                          <>
                            {isLoading && (
                              <div className="w-full flex justify-center">
                                <div>
                                  <FontAwesomeIcon
                                    style={{ fontSize: "4rem" }}
                                    icon={faSpinner}
                                    spin
                                    className="w-full"
                                  />
                                </div>
                              </div>
                            )}
                            <div class="flex flex-row gap-4 m-auto justify-center">
                              <span
                                id="step1"
                                class="w-[75px] h-[20px] border-solid border-4 border-white"
                              ></span>
                              <span
                                id="step2"
                                class="w-[75px] h-[20px] border-solid border-4 border-white"
                              ></span>
                              <span
                                id="step3"
                                class="w-[75px] h-[20px] border-solid border-4 border-white"
                              ></span>
                            </div>
                            <p
                              id="status"
                              class="text-center text-white text-[1.5rem]"
                            ></p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dialog>
          )}
        </section>
      </div>
    </section>
  );
};

export default Connexion;
