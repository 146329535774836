import React, { useRef, useState, useEffect } from 'react';
import '../../styles/mint.css';
import { 
  motion,
} from "framer-motion"


const Mint = () => {
   const dialogRef = useRef(null);
   const victoryRef = useRef(null);
   const canvaRef = useRef(null);
   const [isStarted , setIsStarted] = useState(false);

   const loadConfetti = async () => {
    let confetti = import('../../components/confetti') // Module loaded (export default)!
  };
   function handleClose () {
     setIsStarted(true);
     dialogRef.current.style.display = 'none';
   }

   function winHandle () {
    const title = document.getElementById('title');
    title.innerHTML = 'XXXXXXXX! Hint: XXXXX!';
    try {
      victoryRef.current.style.display = 'block';
      canvaRef.current.style.height = '100%';
    } catch (error) {
      console.log(error);
    } 
    try {
      loadConfetti()
    } catch (error) {
      console.log(error);
    }
   }
   
  return (
    <section className="overflow-hidden">
      <div className="App">    
        {/* LEGENDS OF THE PAST */}
        <section>
          <div className="absolute top-0 bg-black w-full flex justify-center items-center h-[70px] lg:h-[90px]  border-b-[10px] lg:border-b-[15px] z-50 overflow-hidden" style={{borderImage: 'linear-gradient(to right, #AC6A33, #E3DB5C, #E5AE20)', borderImageSlice: '1'}}>
              <a href="/">
                  <motion.img
                  whileHover={{ scale: 1.1 }}
                  src="/assets/Icon_legends_of_the_past.svg"
                  title="LEGENDS OF THE PAST"
                  alt=""
                  className="absolute w-20 z-10 top-[-12%] lg:top-[-20%] lg:w-28 left-0" 
                  />
              </a> 
              <h2 className='text-white uppercase text-shadow-blue-low-mobile  italic  font-bold mx-auto text-[1rem] lg:text-3xl z-50' id='title'>🔥 Your title !</h2>
          </div>


          <div className='relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] h-screen f flex justify-center items-center pt-[70px] lg:pt-[90px]'>
            <div className='ring-4 ring-[#E5AE20] m-auto w-[300px] h-[300px] flex justify-center items-center relative'>
                <button className='bg-[#25191ECC] text-white p-4' disabled={isStarted ? false : true} onClick={winHandle}>button</button>
              </div>

              <figure className="absolute z-20 -bottom-20 -right-20">
                <img src="/assets/trame.svg" alt="" />
              </figure>

              <div id="help_text" className='hidden absolute w-[80%] lg:w-[40%] bg-[#25191ECC] m-auto z-50 min-h-[20%] p-4' ref={victoryRef}>
                  <p className="w-full text-[0.6rem] lg:text-[0.7rem] 3xl:text-[0.9rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]"> 
                      Congratulations, you've passed the game !
                  </p>
              </div>

              <div className='absolute w-[80%] lg:w-[40%] bg-[#25191ECC] mt-auto p-4' ref={dialogRef}>
                <p className="w-full text-[0.6rem] lg:text-[0.7rem] 3xl:text-[0.9rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">Rules of the game</p>
                <button onClick={handleClose} className=' text-[0.6rem] lg:text-[0.7rem] 3xl:text-[0.9rem] lg:max-w-2xl text-white mx-auto text-center font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] ring-1 ring-[#FFFFFF]'>Start the game</button>
              </div>

              <canvas className="pointer-events-none confetti absolute bottom h-[2%] w-screen z-45" id='canvas' ref={canvaRef}></canvas>

          </div> 
        </section> 
      </div>
    </section>
  );
}

export default Mint;