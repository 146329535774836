import React, { useEffect, useState, useRef } from "react";
import "../../../../styles/mint.css";
import { Bar } from "react-chartjs-2";
import { chart } from "highcharts";
import { motion, useAnimationControls } from "framer-motion";
const axios = require("axios").default;

const Result = ({
  result,
  optionsArray,
  numberOfVotes,
  maxVotesForOne,
  seeOnlyGraph,
}) => {
  const [optionsList, setOptionsList] = useState(optionsArray);
  const [optionsResult, setOptionsResult] = useState(
    seeOnlyGraph ? false : true
  );
  const [graphResult, setGraphResult] = useState(seeOnlyGraph);

  const backgroundColors = [
    "#FD7493",
    "#E4CD4A",
    "#8626EC",
    "#04BAFD",
    "#FF206D",
    "#F4960E",
    "#00007A",
    "#4B1D77",
    "#FFE6F0",
    "#C6402C",
  ];
  const data = {
    // labels: optionsArray?.map((option, index) => option.Player) || [],
    labels: result.map((item, index) => {
      const matchingOption = optionsArray.find(
        (option) => option.id == Object.keys(item)[0]
      );
      return matchingOption?.Player || [];
    }),
    datasets: [
      {
        label: "",
        backgroundColor: backgroundColors,
        borderWidth: 1,
        data: result.map((item, index) => item[Object.keys(item)[0]]) || [],
      },
    ],
  };
  console.log(result);
  console.log(optionsArray);
  console.log(data);

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          color: "white",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
        },
      },
    },
    plugins: {
      // tooltip: {
      //     callbacks: {
      //         label: (context) => {
      //             const label = `${optionsArray[context.dataIndex]?.Argumentation}` || '';
      //             return label;
      //         },
      //     },
      // },
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Reward&command=GetCleoActive`;
    axios
      .get(url)
      .then(function (response) {
        console.log(response.data);
        const cleoActive = document.getElementById("cleoActive");
        cleoActive.innerHTML = response.data + " / 1000 has voted";
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });
  }, []);

  useEffect(() => {
    const optionsMap = optionsList.map((option, index) => ({ ...option }));
    setOptionsList(optionsMap.sort((a, b) => b.votes - a.votes));
  }, [optionsArray]);

  function unicodeToEmoji(str) {
    return str
      .split(" ")
      .map((code) => {
        try {
          return String.fromCodePoint(parseInt(code.replace("U+", ""), 16));
        } catch (error) {
          console.log(`Erreur de conversion pour le code: ${code}`, error);
          return ""; // Retourne une chaîne vide ou une représentation d'erreur
        }
      })
      .join("");
  }

  return (
    <section className="flex w-full h-[60vh] lg:h-full lg:m-auto">
      <div className="flex flex-col align-center w-full">
        <div className="flex flex-col md:flex-row justify-between w-full p-3">
          <h2
            id="cleoActive"
            className="text-white text-left text-[1rem] lg:text-[1.25rem] 3xl:text-[1.2rem] text-center"
          >
            ??? / 1000 has voted
          </h2>
          {!seeOnlyGraph && (
            <motion.button
              whileHover={{ scale: optionsResult ? 1.1 : 1 }}
              whileTap={{ scale: optionsResult ? 0.9 : 1 }}
              className={`${
                optionsResult
                  ? "flex items-center justify-center rounded-xl p-2 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]"
                  : "flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
              } `}
              onClick={() => {
                optionsResult
                  ? (setOptionsResult(false), setGraphResult(true))
                  : (setGraphResult(false), setOptionsResult(true));
              }}
            >
              <span
                class={`${
                  optionsResult
                    ? "text-radial-gradient uppercase px-4 py-1 italic font-bold"
                    : "text-white"
                } text-[0.9rem] md:text-[1.2rem]`}
              >
                {optionsResult ? "Results" : "Return"}
              </span>
            </motion.button>
          )}
        </div>
        {graphResult && (
          <div className="flex justify-center h-[70vh] lg:h-[200vh] items-center border-0 border-[#FFFFFF33] p-1 lg:p-2">
          <div className="block h-full w-full">
              <Bar
                data={data}
                height={"400%"}
                options={options}
                className=" max-h-max"
              />
            </div>
          </div>
        )}
        {optionsResult && (
          <div className="flex flex-col gap-6 h-full md:h-[55%] overflow-y-auto p-3">
            {optionsList.map((option, index) => (
              <li
                id={"option" + index}
                key={index}
                className={`gap-4 border-2 ${
                  option.votes === maxVotesForOne
                    ? "border-[#E3CA46]"
                    : "border-slate-800"
                } rounded-lg p-3 flex flex-col-reverse md:flex-row`}
              >
                {/*<div className=" rounded-lg overflow-hidden max-w-[100px] h-auto justify-center items-center flex m-auto">
                  <img
                    src="https://silver-shy-opossum-325.mypinata.cloud/ipfs/QmPbpFLCbxesBcAV1mtPH99GJBm7BX9NPZMjw7mWrQkvfp?pinataGatewayToken=ao0CprRM8OEANQYS610pDZ77KIYdE4ZLfR-cLGqdpPKMT04vqnaSaTaZPzrqq01L"
                    alt=""
              ></img>
              </div>*/}
                <div className="flex flex-col gap-2 p-5 w-full">
                  <div className="flex flex-col lg:flex-row gap-2 justify-between">
                    <p>Player proposed : {option?.Player}</p>
                    <p>by: {option?.Author}</p>
                  </div>
                  <div className="text-start flex-wrap overflow-x-auto">
                    {option?.Argumentation.map((argumentation, index) => (
                      <p className="flex-wrap" key={index}>
                        {unicodeToEmoji(argumentation)}
                      </p>
                    ))}
                  </div>
                </div>
              </li>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Result;
