import {HashConnect} from 'hashconnect'
import { 
    motion,
    useAnimationControls 
  } from "framer-motion"

import {
    AccountId,
    TokenId,
    TokenAssociateTransaction,
    TopicMessageSubmitTransaction,
    TransferTransaction,
    ScheduleSignTransaction,
    Client,
    PrivateKey,
    Hbar
} from '@hashgraph/sdk'

import {OnSendKey} from 'pages/HeavenAspirant'
import { json } from 'react-router-dom';

const axios = require('axios').default;

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}

//const keyTokenId = TokenId.fromString('0.0.1385462');

const heavenAspirantTokenId = TokenId.fromString('0.0.3093449');

//const operatorId = AccountId.fromString("0.0.1380960");

let bufferAnimControls ="";

let form;


const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can exchange your Heaven Key against the Infinity jar, a jar that will give you lot of things.',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}

export const pairHashpack = async (controlsHeavenKey) => {
    bufferAnimControls = controlsHeavenKey;

    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic
    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = pairingData.accountIds[0].toString();
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        checkWL(pairingData.accountIds[0])
    })
    return initData;
}

//make the button clickable or show error message "not whitelist"
export const checkWL = async(accountID) => {
        console.log(accountID+" is WL");
        //the account is WL => clickable button
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        response.innerHTML = "Sorry 😢... all the Cleo Aspirant have already been claimed !"

        //const buttonGetKey = document.getElementById('FakebuttonGetHK');
        //buttonGetKey.className= "hidden"
        //const realGetKey = document.getElementById('buttonGetHK');
        //realGetKey.className= "parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-10 h-[2.8rem] w-[17rem] lg:h-[3.5rem] lg:w-[25rem] md:text-2xl font-bold mx-auto"
        //add onclick OnClickGetKey
        //realGetKey.onclick = async ()=>{OnClickGetKey()};

        //const response = document.getElementById('response');
        //response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
        //response.innerHTML = accountID+" is not Whitelisted"
        //alert(accountID+" is not Whitelisted");


}

export const OnClickGetKey = async() => {
    //CheckAssociateToken();
    checkWL();

    
    //OnSendKey(bufferAnimControls);
    //http://13.42.62.231:3000
    //localhost:4000
    
    
}

export const CheckAssociateToken = async() => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${heavenAspirantTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken();
            }
            else{
                console.log(`token ${heavenAspirantTokenId} is already associated`);
                AskServerToSendKey();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

export const AssociateToken = async() => {
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(saveData.pairedAccounts[0])
            .setTokenIds([heavenAspirantTokenId])
            .setMaxTransactionFee(new Hbar(150))
            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            AskServerToSendKey();
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
}

let askServer = false;

export const AskServerToSendKey = async() => {
    //post request 
    //launch anim ?
    if (askServer) {
        console.log("already ask server !")
        return;
    }
    askServer = true;

    axios
        .get("https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+saveData.pairedAccounts[0]+"&target=badge")
        .then(function (response) {
            console.log(response);
			const jsonResponse = response.data;
            const message = JSON.parse(jsonResponse.replace(/'/g,'"'))["status"];
            console.log(jsonResponse);
            console.log(message);
            
            if (message.includes("Reload & check your wallet")) {
                OnSendKey(bufferAnimControls);
            }
            else{
                const response = document.getElementById('response');
                response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[2rem] font-bold"
                response.innerHTML = message
            }
            
		})
		.catch(function (err) {
			console.log(err);
            alert(err.toString());
		});
}


