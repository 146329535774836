import Result from "../Result";
import React, { useEffect, useRef, useState } from "react";
import Auth from "../../../../utils/auth/utils.js";
const axios = require("axios").default;
import { motion, useAnimationControls } from "framer-motion";

const Vote = ({
  result,
  numberOfVotes,
  optionsArray,
  onGoingRepresentatives,
  serialsNotUsed,
  setErrorServer,
  isPublished,
  setIsPublished,
  errorServer,
  maxVotesForOne,
  setSeeOnlyGraph,
  seeOnlyGraph,
}) => {
  const [viewVotes, setViewVotes] = useState(true);
  const [viewResult, setViewResult] = useState(false);
  const [confirmModaleVote, setConfirmModaleVote] = useState(false);
  const [errorNoSelect, setErrorNoSelect] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [publishOk, setPublishOk] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };

  const RequestToVote = async (idVote, idOption) => {
    try {
      const auth = Auth.getAuth();
      const account = Auth.getAccount();

      const body = JSON.stringify({
        userId: account,
        auth: JSON.stringify(auth.userSignature),
        sign: JSON.stringify(auth.signedPayload),
        serials: serialsNotUsed,
        idVote: onGoingRepresentatives[0]?.id,
        idOption: selectedOption.id,
      });

      const url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=PutVoteActionRepresentatives`;
      let response = await axios.post(url, body);
      response = response.data;
      response === "True"
        ? (setPublishOk(true), setIsLoading(false), setSeeOnlyGraph(true))
        : (setErrorServer(true),
          setMsgError(`An error occurred, ${response}`),
          setIsLoading(false));
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setErrorServer(true);
      setMsgError(`An error occurred, ${error}`);
    }
  };

  function handleVote(e) {
    e.preventDefault();
    selectedOption ? setConfirmModaleVote(true) : setErrorNoSelect(true);
  }

  function unicodeToEmoji(str) {
    return str
      .split(" ")
      .map((code) => {
        try {
          return String.fromCodePoint(parseInt(code.replace("U+", ""), 16));
        } catch (error) {
          console.log(`Erreur de conversion pour le code: ${code}`, error);
          return ""; // Retourne une chaîne vide ou une représentation d'erreur
        }
      })
      .join("");
  }

  //console.log(optionsArray);
  return (
    <>
      {viewVotes && (
        <section className="h-full md:h-[60vh]">
          <section
            className={`flex flex-col gap-3 w-[90%] lg:w-[70%] m-auto h-full`}
          >
            <div className="flex justify-start p-3">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={`flex items-center justify-center rounded-xl p-2 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]`}
                onClick={() => {
                  setSelectedOption(null),
                    setViewResult(true),
                    setViewVotes(false);
                }}
              >
                <span class="text-radial-gradient uppercase px-4 py-1 text-[0.9rem] md:text-[1.2rem] italic font-bold">
                  Results
                </span>
              </motion.button>
            </div>
            <div className="h-full lg:h-[95%] 2xl:h-full lg:py-2 2xl:p-0">
              <form className="h-full flex flex-col gap-3">
                <ul className="flex flex-col gap-2 h-[45vh] md:h-full overflow-y-auto p-3">
                  {onGoingRepresentatives.length > 0 ? (
                    optionsArray.length > 0 ? (
                      optionsArray.map((option, index) => (
                        <li
                          id={index + 1}
                          key={index}
                          className={`w-full gap-4 rounded-lg flex flex-col-reverse lg:flex-row bg-gradient-to-br from-[#000000] to-[#555555] text-white ${
                            selectedOption?.id == option.id
                              ? "border-4 border-[#E3CA46]"
                              : "border-2 border-[#FFFFFF33] "
                          }`}
                        >
                          <label
                            className={`flex flex-col-reverse md:flex-row text-left text-[0.7rem] lg:text-[1.25rem] 3xl:text-[1.2rem] cursor-pointer lg:p-2 w-full`}
                          >
                            <input
                              type="radio"
                              name={"options"}
                              value={"option" + index + 1}
                              onChange={() => {
                                handleRadioChange(option),
                                  setErrorNoSelect(false);
                              }}
                              className="mr-2 invisible"
                            />
                            {/*<div className=" rounded-lg overflow-hidden max-w-[100px] h-auto justify-center items-center flex m-auto">
                              <img
                                src="https://silver-shy-opossum-325.mypinata.cloud/ipfs/QmPbpFLCbxesBcAV1mtPH99GJBm7BX9NPZMjw7mWrQkvfp?pinataGatewayToken=ao0CprRM8OEANQYS610pDZ77KIYdE4ZLfR-cLGqdpPKMT04vqnaSaTaZPzrqq01L"
                                alt=""
                              ></img>
                            </div>*/}
                            <div className="flex flex-col py-1 px-2 lg:p-0 w-full">
                              <div className="flex flex-row gap-2 justify-between">
                                <p classname="text-[1.5rem] lg:text-[1.5rem]">
                                  {option.Player}
                                </p>
                                {/* <p>by: {option.Author}</p> */}
                              </div>
                              <div className="text-start flex-wrap overflow-x-auto">
                                {option?.Argumentation.map(
                                  (argumentation, index) => (
                                    <p
                                      className="flex-wrap leading-[1.5rem]"
                                      key={index}
                                    >
                                      {unicodeToEmoji(argumentation)}
                                    </p>
                                  )
                                )}
                              </div>
                            </div>
                          </label>
                        </li>
                      ))
                    ) : (
                      <p>No options yet added</p>
                    )
                  ) : (
                    <p>No vote ongoing</p>
                  )}
                </ul>
                <div className="p-2 flex flex-col justify-center items-center">
                  {errorNoSelect && (
                    <p className="text-red-500">
                      Please, select an option to vote
                    </p>
                  )}
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className={`flex items-center justify-center rounded-xl p-2 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC] `}
                    onClick={(e) => {
                      handleVote(e);
                    }}
                  >
                    <span class="text-radial-gradient uppercase px-4 py-1 text-[0.9rem] md:text-[1.2rem] italic font-bold">
                      Vote
                    </span>
                  </motion.button>
                </div>
              </form>
            </div>
            {confirmModaleVote && (
              <dialog className="absolute w-[100%] bg-[#25191ECC] m-auto flex flex-col justify-center gap-3 h-[70vh] z-50">
                {!isLoading && !publishOk && !errorServer && (
                  <>
                    {" "}
                    <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                      Are you sure you want to vote for {selectedOption.Player}?
                    </p>
                    <div className="flex justify-center items-center gap-3 flex-col lg:flex-row">
                      <button
                        type="button"
                        onClick={() => {
                          setConfirmModaleVote(false);
                        }}
                        className="w-[12rem] flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
                      >
                        Cancel
                      </button>

                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        className={`w-[12rem] flex items-center justify-center rounded-xl p-2 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]`}
                        onClick={() => {
                          setIsLoading(true), RequestToVote();
                        }}
                      >
                        <span class="text-radial-gradient uppercase px-4 py-0 text-[0.9rem] md:text-[1.2rem] italic font-bold">
                          Vote
                        </span>
                      </motion.button>
                    </div>{" "}
                  </>
                )}

                {isLoading && (
                  <div className="w-[90%] lg:w-[50%] m-auto flex gap-2 justify-center">
                    <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                      Loading...
                    </p>
                  </div>
                )}
                {publishOk && !isLoading && (
                  <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2 justify-center items-center">
                    <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                      The Vote has been published successfully &#x2713;
                    </p>
                    <div className="flex justify-center items-center gap-3 w-[10rem]">
                      <button
                        type="button"
                        onClick={() => {
                          setIsPublished(true),
                            setConfirmModaleVote(false),
                            setViewResult(true),
                            setViewVotes(false);
                        }}
                        className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
                {errorServer && !isLoading && (
                  <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                    <p className="w-full text-[1.4rem] lg:text-[1.4rem] text-white mx-auto text-center font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                      {msgError}
                    </p>
                    <div className="flex justify-center items-center gap-3 w-[10rem]">
                      <button
                        type="button"
                        onClick={() => {
                          setConfirmModaleVote(false);
                        }}
                        className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </dialog>
            )}
          </section>
        </section>
      )}
      {viewResult && (
        <div className="w-full flex flex-col items-center w-[90%] lg:w-[70%] m-auto">
          <div className="flex justify-start items-center w-full">
            <button
              type="button"
              onClick={() => {
                setViewResult(false), setViewVotes(true);
              }}
              className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
            >
              Return
            </button>
          </div>
          <Result
            setViewResult={setViewResult}
            result={result}
            optionsArray={optionsArray}
            numberOfVotes={numberOfVotes}
            maxVotesForOne={maxVotesForOne}
            seeOnlyGraph={seeOnlyGraph}
          />
        </div>
      )}
    </>
  );
};

export default Vote;
