import { useRef } from "react";
import convertEncodingQuote from "../../../../convertEncodingChar.js";
import convertToJSONString from "../../../../convertToJSONString.js";
import { useEffect, useState } from "react";
import "../../../../styles/mint.css";
import Auth from "../../../../utils/auth/utils.js";
const axios = require("axios").default;
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import convertReturnLine from "../../../../convertReturnLine.js";
import { motion, useAnimationControls } from "framer-motion";

const Options = ({
  errorServer,
  setErrorServer,
  isPublished,
  setIsPublished,
  options,
  onGoingRepresentatives,
  userProposals,
  maxProposals,
}) => {
  const [addProposal, setAddProposal] = useState(false);
  const [author, setAuthor] = useState("");
  // Remettre player name à une chaine vide pour laisser un utilisateur rentrer une proposition
  const [playerName, setPlayerName] = useState("");
  const [playerArgumentation, setPlayerArgumentation] = useState("");
  const [playerImage, setPlayerImage] = useState(null);
  const [confirmModaleProposal, setConfirmModaleProposal] = useState(false);
  const [errorEmpty, setErrorEmpty] = useState(false);
  const [errorTooLongName, setErrorTooLongName] = useState(false);
  const [errorTooLongArgumentation, setErrorTooLongArgumentation] =
    useState(false);
  const [errorInvalidFormat, setErrorInvalidFormat] = useState(false);
  const [erorTooMuchLineBreak, setErorTooMuchLineBreak] = useState(false);
  const [errorImgHeavy, setErrorImgHeavy] = useState(false);
  const [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [msgError, setMsgError] = useState("");
  const imgRef = useRef(null);

  function resetForm() {
    setPlayerName("");
    setPlayerArgumentation("");
    setPlayerImage(null);
    setErrorServer(false);
    setIsLoadingPublish(false);
    setIsPublished(false);
  }

  function resetErrors() {
    setErrorEmpty(false);
    setErrorTooLongName(false);
    setErrorTooLongArgumentation(false);
    setErrorInvalidFormat(false);
    setErrorImgHeavy(false);
    setErrorServer(false);
    setErorTooMuchLineBreak(false);
    setMsgError("");
  }

  function emojiToUnicode(str) {
    return Array.from(str).map(char => {
      try {
        return 'U+' + char.codePointAt(0).toString(16).toUpperCase();
      } catch (error) {
        console.log(`Erreur de conversion pour le caractère: ${char}`, error);
        return ''; // Retourne une chaîne vide ou une représentation d'erreur
      }
    }).join(' ');
  }

  function unicodeToEmoji(str) {
    return str.split(' ').map(code => {
      try {
        return String.fromCodePoint(parseInt(code.replace('U+', ''), 16));
      } catch (error) {
        console.log(`Erreur de conversion pour le code: ${code}`, error);
        return ''; // Retourne une chaîne vide ou une représentation d'erreur
      }
    }).join('');
  }

  const addOption = async () => {
    const auth = Auth.getAuth();
    const account = Auth.getAccount();

    try {
      if (playerImage) {
        const formData = new FormData();
        formData.append("image", imgRef.current.files[0]);

        let urlImg = `https://xkpj2ofe2j.execute-api.eu-west-2.amazonaws.com/default/ImageServer?fileName=${imgRef.current.files[0].name}`;
        const responseImg = await axios.post(urlImg, formData);

        let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=publishRepresentativesOption`;
        const body = JSON.stringify({
          // remettre playerName a la place de author pour laisser un utilisateur rentrer une proposition
          player: convertEncodingQuote(author),
          argumentation: convertEncodingQuote(emojiToUnicode(playerArgumentation)),
          visual:
            "https://silver-shy-opossum-325.mypinata.cloud/ipfs/QmPbpFLCbxesBcAV1mtPH99GJBm7BX9NPZMjw7mWrQkvfp?pinataGatewayToken=ao0CprRM8OEANQYS610pDZ77KIYdE4ZLfR-cLGqdpPKMT04vqnaSaTaZPzrqq01L",
          /*responseImg.data.IpfsHash*/
          user: account,
          legend: "Cleo",
          auth: JSON.stringify(auth.userSignature),
          sign: JSON.stringify(auth.signedPayload),
        });

        const response = await axios.post(url, body);
        setIsLoadingPublish(false);
        if (response.data === "ok") {
          setIsPublished(true);
        } else {
          setMsgError(`An error occurred, ${response.data}`);
          setErrorServer(true);
        }
      } else {
        let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=publishRepresentativesOption`;

        const body = JSON.stringify({
          // remettre playerName a la place de author pour laisser un utilisateur rentrer une proposition
          player: convertEncodingQuote(author),
          argumentation: convertEncodingQuote(emojiToUnicode(playerArgumentation)),
          visual: "",
          user: account,
          legend: "Cleo",
          auth: JSON.stringify(auth.userSignature),
          sign: JSON.stringify(auth.signedPayload),
        });

        const response = await axios.post(url, body);
        console.log(response.data);
        setIsLoadingPublish(false);

        if (response.data === "ok") {
          setIsPublished(true);
        } else {
          setMsgError(`${response.data}`);
          setErrorServer(true);
        }
      }
    } catch (error) {
      console.error(error);
      setIsLoadingPublish(false);
      setErrorServer(true);
    }
  };
  function handleSubmit(e) {
    e.preventDefault();
    let error = false;
    // if(!playerName || !playerArgumentation){
    //     setErrorEmpty(true);
    //     error = true;
    // }
    // remettre la vérification du dessus et supprimer celle du dessous pour ajouter d'autres utilisateurs
    if (!playerArgumentation) {
      setErrorEmpty(true);
      error = true;
    }

    // remettre la vérification pour ajouter d'autres utilisateurs
    // if(playerName.length > 100){
    //     setErrorTooLongName(true);
    //     error = true;
    // }

    if (playerArgumentation.length > 300) {
      setErrorTooLongArgumentation(true);
      error = true;
    }

    const nbLineBreak = (playerArgumentation.match(/\n/g) || []).length;
    if (nbLineBreak > 5) {
      setErorTooMuchLineBreak(true);
      error = true;
    }

    if (playerImage) {
      if (
        playerImage.type !== "image/jpg" &&
        playerImage.type !== "image/png" &&
        playerImage.type !== "image/webp" &&
        playerImage.type !== "image/jpeg"
      ) {
        setErrorInvalidFormat(true);
        error = true;
      }

      if (convertOctetsToMbNoFixed(playerImage.size) > 5) {
        setErrorImgHeavy(true);
        error = true;
      }
    }

    if (!error) {
      setConfirmModaleProposal(true);
    }
  }

  useEffect(() => {
    async function pseudo() {
      const getAuth = await Auth.getPseudo();
      setAuthor(getAuth);
    }

    pseudo();
  }, []);

  return (
    <>
      {addProposal && (
        <section className="w-full lg:w-[60%] p-5 lg:h-[66.5vh]">
          <div className="relative border-2 border-[#FFFFFF33] rounded-lg h-full overflow-y-auto">
            {confirmModaleProposal && (
              <dialog className="absolute w-full bg-[#25191ECC] m-auto flex flex-col justify-center gap-3 h-full lg:h-[66.5vh] z-50">
                {!isLoadingPublish && !isPublished && !errorServer && (
                  <>
                    {" "}
                    <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                      Are you sure you want to apply ?
                    </p>
                    <div className="flex justify-center items-center gap-3 flex-col lg:flex-row">
                      <button
                        onClick={() => {
                          setConfirmModaleProposal(false);
                        }}
                        className="w-[12rem] flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
                      >
                        Cancel
                      </button>
                      <button
                        className="w-[12rem] flex items-center justify-center rounded-xl p-2 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC]"
                        onClick={() => {
                          setIsLoadingPublish(true), addOption();
                        }}
                      >
                        <span class="text-radial-gradient uppercase px-4 py-0 text-[0.9rem] md:text-[1.2rem] italic font-bold">
                          Apply
                        </span>
                      </button>
                    </div>{" "}
                  </>
                )}

                {isLoadingPublish && (
                  <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                    <FontAwesomeIcon
                      style={{ fontSize: "4rem", color: "#FFFFFF" }}
                      icon={faSpinner}
                      spin
                      className="w-full"
                    />
                    <p className="text-white italic font-bold">Loading...</p>
                  </div>
                )}
                {isPublished && !isLoadingPublish && (
                  <div className="w-[95%] lg:w-[60%] m-auto flex flex-col gap-2">
                    <p className="w-full text-[1.2rem] lg:text-[1.4rem] text-white mx-auto text-center font-normal leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 ">
                      You applied successfully!
                    </p>
                    <div className="flex justify-center items-center gap-3">
                      <button
                        onClick={() => {
                          setConfirmModaleProposal(false),
                            resetForm(),
                            resetErrors(),
                            setAddProposal(false);
                        }}
                        className="w-[12rem] flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
                {errorServer && !isLoadingPublish && (
                  <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-2">
                    <p className="w-full text-[1.7rem] lg:text-[1.4rem] text-white mx-auto text-center md:text-justify font-normal px-5 leading-[2.5rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] 3xl:px-0 mt-2 lg:mt-2 2xl:mt-6">
                      {msgError}
                    </p>
                    <div className="flex justify-center items-center gap-3">
                      <button
                        onClick={() => {
                          setConfirmModaleProposal(false), resetErrors();
                        }}
                        className="w-full lg:w-[10rem] text-white border p-1 md:p-1.5 border-white text-[1.2rem] lg:text-[1.2rem]"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </dialog>
            )}
            <form className="flex flex-col gap-3 p-5 h-full justify-between">
              <div className="flex justify-start items-center ">
                Legends of Hedera is a game that requires luck, strategy, and
                decision-making.
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col w-full gap-4">
                  {/* Champs à réactiver pour permettre de rentrer le pseudo ou le walletID de n'importe quel joueur. */}
                  {/* <label htmlFor="title" className="text-start text-white text-[1.5rem]"><span className="text-red-500">*</span>Player : </label>
                                <input placeholder="Nickname or walletID" id="title" className="text-black rounded-full p-3" type="text" maxLength="30" value={playerName} onChange={(e) => {setPlayerName(e.target.value), resetErrors()}} />
                                {errorTooLongName && (
                                    <p className="text-red-500">Player name or walletID too long : max 30 characters</p>
                                )} */}
                  <p className=" text-start text-white text-[1rem] md:text-[1.2rem] font-medium">
                    Champion : {author}
                  </p>
                </div>

                <div className="flex flex-col w-full gap-4">
                  <label
                    htmlFor="argumentation"
                    className="text-start text-white text-[1rem] md:text-[1.2rem]"
                  >
                    Champion Motivation :{" "}
                  </label>
                  <textarea
                    placeholder="Write your unique qualities and reasons why the Committee should choose you as their Champion for this event. This is your stage to shine 🌟"
                    id="argumentation"
                    className="text-black rounded-lg p-3"
                    type="text"
                    maxLength="300"
                    value={playerArgumentation}
                    onChange={(e) => {
                      setPlayerArgumentation(e.target.value), resetErrors();
                    }}
                  />
                  {errorTooLongArgumentation && (
                    <p className="text-red-500">
                      Argumentation too long : max 3000 characters
                    </p>
                  )}
                  {erorTooMuchLineBreak && (
                    <p className="text-red-500">Too much line break : max 5</p>
                  )}
                </div>

                <div className="flex flex-col w-full">
                  <label
                    htmlFor="image"
                    className="hidden border-2 border-white flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem] p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-lg cursor-pointer text-[1rem] md:text-[1.2rem]"
                  >
                    Import img (png, jpg, webp)
                    <input
                      ref={imgRef}
                      lang="en"
                      className="hidden"
                      name="image"
                      id="image"
                      type="file"
                      accept=".png,.jpg,.webp"
                      onChange={(e) => {
                        setPlayerImage(e.target.files[0]), resetErrors();
                      }}
                    />
                  </label>
                  {playerImage && (
                    <p className="text-white">
                      {playerImage.name},{" "}
                      {convertOctetsToMb(playerImage.size) + "Mb"}
                    </p>
                  )}
                  {errorInvalidFormat && (
                    <p className="text-red-500">
                      Invalid format, only jpg, png or webp
                    </p>
                  )}
                  {errorImgHeavy && (
                    <p className="text-red-500">Image too heavy: max 5Mb</p>
                  )}
                </div>
              </div>
              {errorEmpty && (
                <p className="text-red-500">
                  Please fill all the required fields
                </p>
              )}
              <div className="flex gap-6 justify-center align-center flex-col lg:flex-row w-full">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className={`flex items-center justify-center rounded-xl p-2 drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC] ${
                    userProposals >= maxProposals ? "opacity-50" : ""
                  }`}
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <span class="text-radial-gradient uppercase px-4 py-1 text-[0.9rem] md:text-[1.2rem] italic font-bold">
                    Apply
                  </span>
                </motion.button>
              </div>
            </form>
          </div>
        </section>
      )}
      <section
        className={`flex flex-col gap-1 lg:gap-0 ${
          addProposal ? "w-full lg:w-[40%]" : "w-[90%] lg:w-[70%] m-auto"
        }`}
      >
        {!addProposal && onGoingRepresentatives.length > 0 && (
          <div className="flex gap-5 justify-center lg:justify-end items-center px-5">
            <img className="h-16 w-16" src="/assets/Trophy.png" alt=""></img>
            <motion.button
              disabled={userProposals >= maxProposals}
              whileHover={{ scale: userProposals >= maxProposals ? 1 : 1.1 }}
              whileTap={{ scale: userProposals >= maxProposals ? 1 : 0.9 }}
              className={`flex items-center justify-center rounded-xl p-2  drop-shadow-2xl flex justify-center bg-[#8626EC] hover:bg-[#8611EC] ${
                userProposals >= maxProposals ? "opacity-50" : ""
              }`}
              onClick={() => setAddProposal(true)}
            >
              <span class="text-radial-gradient uppercase px-4 py-1 text-[0.9rem] md:text-[1.2rem] italic font-bold">
                Apply
              </span>
            </motion.button>
            <img
              className="h-16 w-16 lg:hidden"
              src="/assets/Trophy.png"
              alt=""
            ></img>
          </div>
        )}
        <div className="h-full lg:p-5 lg:p-0">
          <ul className="flex flex-col gap-2 h-[58vh] lg:h-[58vh] 2xl:h-[65vh] overflow-y-auto">
            {onGoingRepresentatives.length > 0 ? (
              options.length > 0 ? (
                options.map((option, index) => (
                  <li
                    id={"option" + index}
                    key={index}
                    className={`gap-3 border-2 border-[#FFFFFF33] rounded-lg  flex ${
                      addProposal
                        ? "flex-col-reverse xl:flex-row"
                        : "flex-col-reverse xl:flex-row"
                    }`}
                  >
                    {/*<div className="rounded-lg overflow-hidden max-w-[100px] xl:max-w-[100px] h-auto justify-center items-center flex m-auto">
                      <img
                        src="https://silver-shy-opossum-325.mypinata.cloud/ipfs/QmPbpFLCbxesBcAV1mtPH99GJBm7BX9NPZMjw7mWrQkvfp?pinataGatewayToken=ao0CprRM8OEANQYS610pDZ77KIYdE4ZLfR-cLGqdpPKMT04vqnaSaTaZPzrqq01L"
                        alt=""
                      ></img>
                    </div>*/}
                    <div className="flex flex-col lg:px-3 w-full">
                      <div className="flex flex-col lg:flex-row gap-2 justify-between">
                        <p className="text-[1.25rem] lg:text-[1.5rem]">
                          {option?.Player}
                        </p>
                        {/* <p>by: {option?.Author}</p> */}
                      </div>
                      <div className="text-start flex-wrap overflow-x-auto px-2 lg:px-0 lg:py-1 lg:-mt-3">
                        {option?.Argumentation.map((argumentation, index) => (
                          <p
                            className="flex-wrap text-[0.8rem] lg:text-[1rem] leading-[1.2rem] lg:leading-[1.5rem]"
                            key={index}
                          >
                            {unicodeToEmoji(argumentation)}
                          </p>
                        ))}
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p>No options added yet</p>
              )
            ) : (
              <p>No vote ongoing</p>
            )}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Options;
