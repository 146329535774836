import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { inject } from "@vercel/analytics";
import * as Yup from "yup";
import { motion } from "framer-motion";

import Characters from "../components/Characters";
import DecorationFlash from "../components/DecorationFlash";
import Footer from "../components/Footer";

async function load() {
  let confetti = import("../components/confetti"); // Module loaded (export default)!
}

let discordAlert =
  "The Council of Legends will soon open its doors to the privileged Heaven Keys holders 🗝";

const ContactSchema = Yup.object().shape({
  email: Yup.string().email("Email incorrect").required("Adresse email requis"),
});
const Index = () => {
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const characterRef = useRef();
  const largeCharacterRef = useRef();

  const dropInRocket = {
    hidden: {
      y: "25vh",
      x: "-25vw",
    },
    visible: {
      y: "0vh",
      x: "0vw",
      transition: {
        duration: 5,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
  };

  const dropInTeam = {
    hidden: {
      y: "25vh",
    },
    visible: {
      y: "0vh",
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
  };

  const dropInWhiteStripeGame = {
    hidden: {
      x: "-25vw",
      y: "-25vw",
    },
    visible: {
      x: "0vw",
      y: "0vw",
      transition: {
        delay: 1,
        duration: 0.5,
        type: "spring",
        damping: 30,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  };

  const dropInPartner = {
    hidden: {
      x: "50vw",
    },
    visible: {
      x: "0vw",
      transition: {
        duration: 0.1,
        type: "spring",
        damping: 30,
        stiffness: 100,
        restDelta: 0.001,
      },
    },
  };

  const dropInDontMissOut = {
    hidden: {
      x: "-50vw",
    },
    visible: {
      x: "0vw",
      transition: {
        delay: 0.3,
        duration: 0.1,
        type: "spring",
        damping: 25,
        stiffness: 250,
      },
    },
  };

  const characters = [
    {
      id: 1,
      name: "Cleo",
      role: "Queen Of Egypt",
      description: (
        <>
          She is not the Cleopatra you studied in school.
          <br />
          <br />
          Cleo was a badass muay thai boxer. After winning the great Roman
          pugilist tournament, she knocked out Julius Caesar and then Marcus
          Antonius to rule Egypt by herself.
        </>
      ),
      values: ["Strong", "Independent", "Determined", "Tenacious"],
      colors: ["#FC9B0E", "#FC9B0E", "#FC9B0E", "#FC9B0E"],
      image: "/assets/cleop2.webp",
      jar: "/assets/shadow_golden_vase.webp",
    },
    {
      id: 2,
      name: "*****",
      role: "Coming soon...",
      description: <></>,
      values: ["****"],
      colors: ["#003A88"],
      image: "/assets/Question_Mark.svg",
      jar: "/assets/mystery_vase.svg",
    },
  ];

  const listPartners = {
    main: [],
    others: [
      {
        logo: "/assets/partners//station_f.svg",
        name: "STATION F",
      },
      {
        logo: "/assets/partners//bpifrance.svg",
        name: "bpifrance",
      },
      {
        logo: "/assets/partners//paris_co.svg",
        name: "Paris&Co",
      },
    ],
  };

  const team = [
    {
      image: "marvin_gregor.webp",
      role: (
        <>
          Game <span className="text-[#5535C7] italic"> Designer</span>
        </>
      ),
      name: "Marvin Gregor",
      username: "Ubisoft Square Enix",
    },
    {
      image: "kim_ettinoff.webp",
      role: (
        <>
          Creative & Art{" "}
          <span className="text-[#5535C7] italic"> Director</span>
        </>
      ),
      name: "Kim Ettinoff",
      username: "Supercell Ankama",
    },
    {
      image: "pierre_coneau.webp",
      role: (
        <>
          3D <span className="text-[#5535C7] italic"> Artist</span>
        </>
      ),
      name: "Pierre Coneau",
      username: "Ynov Bordeaux Laval 3Di",
    },
    {
      image: "pixel.webp",
      role: (
        <>
          DA <span className="text-[#5535C7] italic"> assistant</span>
        </>
      ),
      name: "Pixel",
      username: "Runs after the mailman",
    },
    {
      image: "pia.webp",
      role: (
        <>
          Pet <span className="text-[#5535C7] italic"> Director</span>
        </>
      ),
      name: "Pia",
      username: "Running outside",
    },
    {
      image: "marta_zienkowska.webp",
      role: (
        <>
          Strategic <span className="text-[#5535C7] italic"> Advisor</span>
        </>
      ),
      name: "Marta Zienkowska",
      username: (
        <span>
          Investor <br /> 11 years gaming M&A
        </span>
      ),
    },
    {
      image: "markus_dalka.webp",
      role: (
        <>
          Marketing <span className="text-[#5535C7] italic"> Architect</span>
        </>
      ),
      name: "Markus Dalka",
      username: (
        <span>
          strategist <br /> Community Architect
        </span>
      ),
    },
    {
      image: "neal_lausson.webp",
      role: (
        <>
          CTO <span className="text-[#5535C7] italic"> Co-founder</span>
        </>
      ),
      name: "Neal Lausson",
      username: "Playlearner 1914",
    },
    {
      image: "basile_masson.webp",
      role: (
        <>
          CEO <span className="text-[#5535C7] italic"> Co-founder</span>
        </>
      ),
      name: "Basile Masson",
      username: "Playlearner 1914",
    },
  ];

  function showHideAnswerFaq(index) {
    let element = document.getElementById("answer_faq_" + index.toString());
    let cell = document.getElementById("cell_faq_" + index.toString());
    if (element.className.includes("hidden")) {
      element.className = "show";
      cell.className = "button-63-selected text-black";
      for (let i = 1; i < 6; i++) {
        if (!(i === index)) {
          document.getElementById("answer_faq_" + i.toString()).className =
            "hidden";
          document.getElementById("cell_faq_" + i.toString()).className =
            "button-63 text-white";
        }
      }
    } else {
      element.className = "hidden";
      cell.className = "button-63 text-white";
    }
  }

  inject();

  return (
    <section className="overflow-hidden">
      <Helmet title="Legends Of The Past">
        {/*<!-- Google tag (gtag.js) -->*/}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-CZENPTTH1C"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          if( typeof window.dataLayer !== "undefined")
          {
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-CZENPTTH1C');
          }
          
          `}
        </script>
      </Helmet>
      {/* full + autoplay */}
      <section className="bg-gradient-to-br text-white from-[#014EFB] to-[#2AAEF5] z-50">
        <figure className="lg:absolute lg:flex z-50 items-end top-0 w-full border-t-[40px] lg:border-t-[80px] border-t-black">
          {/*<motion.a
            whileHover={{ scale: 1.1 }}
            href="https://neallausson.gitbook.io/litepaper-legends-of-the-past/intro/the-legends-of-the-past"
            target="_blank"
            className="
              text-white 
              lg:text-2xl
              z-50
              absolute 
              top-1
              right-[2rem]
              lg:-top-14 
              lg:right-[2rem] "
          >
            Whitepaper
          </motion.a>*/}
          
          {/*<motion.a
            whileHover={{ scale: 1.1 }}
            href="https://sentx.io/nft-marketplace/creators/legends-of-the-past"
            target="_blank"
            className="
              text-white 
              lg:text-2xl
              z-50
              absolute 
              top-1
              right-[8rem]
              lg:-top-14 
              lg:right-[12rem] "
          >
            Marketplace
          </motion.a>*/}
          <a href="/">
            <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="absolute z-50 w-14 -top-1 lg:w-28 lg:-top-24 lg:left-0"
            />
          </a>
        </figure>
        <iframe
          className="z-50 w-full aspect-video"
          src="https://www.youtube.com/embed/CboJDeUteLU?si=ahMJ2ju77iGFIyPM&autoplay=1&loop=1&mute=1&playlist=CboJDeUteLU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; loop; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </section>
      {/* LEGENDS OF THE PAST */}
      <section className=" relative bg-gradient-to-b from-[#FD6E8D] to-[#FFACD0] ">
        {/* Header & Social networks */}
        <figure className="lg:absolute lg:flex z-50 items-end top-0 w-full border-t-[0px] lg:border-t-[0px] border-t-black">
          <motion.img
            animate={{
              opacity: [0, 0, 1, 1],
            }}
            transition={{ duration: 3, times: [0, 0.72, 0.77, 1] }}
            src="/assets/decorations/golden_stripe.svg"
            title="LEGENDS OF THE PAST"
            alt=""
            className="mb-20 md:w-auto hidden lg:block  md:mb-0 md:w-[50rem] mb:-ml-12 lg:w-[70rem] lg:-ml-24 2xl:w-[85rem] 2xl:-ml-0"
          />
          <figure className="lg:hidden items-end top-0 w-full h-[10px]  bg-gradient-to-r from-[#AC6A33] via-[#E3DB5C] to-[#E5AE20] z-10"></figure>
          <img
            src="/assets/White_Legends_of_rhe_past_2line.svg"
            className="legend_title italic text-white mx-auto font-extrabold w-[18rem] lg:hidden mt-3 mb-2"
          ></img>
          {/* Social networks */}
          <motion.ul
            animate={{
              opacity: [0, 0, 1, 1],
            }}
            transition={{ duration: 3, times: [0, 0.72, 0.77, 1] }}
            className="bg-cover header_social_networks lg:-ml-36 pl-3 pr-12 py-2 lg:flex items-center"
            style={{
              backgroundImage: "url(/assets/decorations/curved_trapezium.svg",
            }}
          >
            <li className="mr-3">
              <a href="https://discord.gg/wYB7bsjVQ5" target="_blank">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  animate={{
                    x: ["5vw", "5vw", "5vw", "0vw"],
                    y: ["5vw", "5vw", "5vw", "0vw"],
                    rotate: [30, 30, 30, 12],
                    opacity: [0, 0, 1, 1],
                  }}
                  transition={{ duration: 6, times: [0, 0.35, 0.36, 0.6] }}
                  src="/assets/social_networks/discord_button.svg"
                  alt="Discord"
                  title="Discord"
                  className="discord z-50 h-10 lg:h-auto absolute right-40 sm:right-32 top-76 sm:top-60 lg:relative lg:top-0 lg:right-0 rotate-12 lg:rotate-0"
                />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/legends_of_past" target="_blank">
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  animate={{
                    x: ["8vw", "8vw", "8vw", "0vw"],
                    y: ["8vw", "8vw", "8vw", "0vw"],
                    rotate: [50, 50, 50, 30],
                    opacity: [0, 0, 1, 1],
                  }}
                  transition={{ duration: 6, times: [0, 0.35, 0.36, 0.6] }}
                  src="/assets/social_networks/twitter_button.svg"
                  alt="Twitter"
                  title="Twitter"
                  className="twitter z-50 h-10 lg:h-auto absolute right-8 sm:right-24 top-64 sm:top-[21rem] lg:relative lg:top-0 lg:right-0 rotate-[30deg] lg:rotate-0"
                />
              </a>
            </li>
          </motion.ul>
        </figure>
        {/* Decoration */}
        <motion.span
          animate={{ opacity: [0, 0, 1, 0, 0] }}
          transition={{ duration: 3, times: [0, 0.65, 0.7, 0.75, 1] }}
          className="bg-[#FFF0F7] z-40 absolute left-0 top-0 w-full h-screen "
        ></motion.span>
        <figure className="w-auto lg:w-1/2 hidden lg:flex justify-end  relative lg:pr-20 -mb-10 z-40 lg:-mb-34 xl:-mb-64 -mt-6 md:mt-0">
          <motion.img
            animate={{
              x: ["-5vw", "-5vw", "-5vw", "0vw"],
              opacity: [0, 0, 1, 1],
            }}
            transition={{ duration: 3, times: [0, 0.68, 0.69, 0.71] }}
            src="/assets/decorations/multi_particle.svg"
            title="LEGENDS OF THE PAST"
            alt=""
            className="
              relative 
              hidden 
              lg:block 
              z-10 
              h-52 
              top-32 
              xl:top-2 
              2xl:top-2
              
              -left-20 
              md:left-[7rem]
              2xl:left-[9rem]
              3xl:left-[12rem]

              md:h-[24rem] 
              2xl:h-[32rem]
              3xl:h-[37rem]
              3xl:-mb-[4rem]"
          />
          <motion.img
            animate={{
              x: ["3vw", "3vw", "3vw", "0vw"],
              y: ["3vw", "3vw", "3vw", "0vw"],
              rotate: [30, 30, 30, 0],
              opacity: [0, 0, 1, 1],
            }}
            transition={{ duration: 6, times: [0, 0.35, 0.36, 0.6] }}
            src="/assets/decorations/arrow_left_top.svg"
            title="LEGENDS OF THE PAST"
            alt=""
            className="
              absolute 
              top-36 
              md:top-52 
              xl:top-32 
              h-auto 
              right-16 
              md:right-36 
              lg:right-10 
              2xl:right-20"
          />
          <motion.img
            animate={{
              x: ["3vw", "3vw", "3vw", "0vw"],
              y: ["3vw", "3vw", "3vw", "0vw"],
              rotate: [30, 30, 30, 0],
              opacity: [0, 0, 1, 1],
            }}
            transition={{ duration: 6, times: [0, 0.35, 0.36, 0.6] }}
            src="/assets/decorations/arrow_bottom.svg"
            title="LEGENDS OF THE PAST"
            alt=""
            className="
              absolute 
              top-44 
              md:top-36 
              lg:top-44 
              x:top-44 
              h-10
              xl:h-auto 
              right-10 
              md:right-28 
              lg:right-32 
              xl:-right-20
              2xl:-right-36"
          />
        </figure>
        <motion.span
          animate={{ x: ["-120vw", "-120vw", "-120vw", "-50vw"] }}
          transition={{ duration: 3, times: [0, 0.2, 0.45, 0.6] }}
          className="bg-[#FFF0F7]/90 
            -left-[20rem]
            absolute 
            hidden
            w-[130%] 
            md:h-[8rem]
            2xl:h-[10rem]
            3xl:h-[12.5rem]
            bulk-parrallelogram 
            lg:-left-[15rem]
            2xl:-left-[17rem]
            3xl:-left-[21rem]
            -bottom-20
            xl:block
            xl:bottom-[12rem]
            2xl:bottom-[15rem]
            3xl:bottom-[18.5rem]"
        />
        {/* Cleo Boxing */}
        <aside className="flex relative justify-between items-center lg:-mt-0">
          <motion.div
            animate={{
              x: ["-5vw", "-5vw", "-5vw", "0vw"],
              opacity: [0, 0, 1, 1],
            }}
            transition={{ duration: 3, times: [0, 0.75, 0.76, 0.9] }}
            className="absolute hidden lg:block w-28 md:w-56 left-2/4 md:left-1/4 h-full content[''] bg-[url('../assets/particle-2.svg')] bg-contain bg-no-repeat bg-bottom bottom-0 md:bottom-28 lg:bottom-10 xl:bottom-20 z-30"
          ></motion.div>
          <figure className="relative flex items-start z-40 -mb-8 mt-28 sm:mt-20 w-auto">
            <motion.img
              initial={{ x: "-150vw", scale: 2 }}
              animate={{ x: "0vw", scale: 1 }}
              transition={{ delay: 1.8, duration: 0.4 }}
              src="/assets/cleop_boxing.png"
              alt="Cleop boxing"
              title="LEGENDS OF THE PAST"
              className="
                hidden
                lg:inline-flex
                z-10
                w-[40rem]
                lg:w-[45rem]
                2xl:w-[54rem]
                3xl:w-[63rem]
                lg:max-width-[100%]
                mt-10
                lg:-mt-20
                -ml-6
                lg:-ml-8 
                lg:-mb-[2rem]
                2xl:-ml-10
                2xl:-mt-[8rem]
                2xl:-mb-[2rem]
                3xl:-mb-[2rem]
                3xl:-mt-[8rem]"
            />
            <motion.img
              initial={{ x: "-150vw", scale: 2 }}
              animate={{ x: "0vw", scale: 1.2 }}
              transition={{ delay: 1.8, duration: 0.4 }}
              src="/assets/cleop_boxing.webp"
              alt="Cleop boxing"
              title="LEGENDS OF THE PAST"
              className="
                z-10
                w-[25rem]
                mt-10
                -ml-6
                mb-5
                lg:hidden"
            />
            <motion.img
              animate={{
                x: ["-15vw", "-15vw", "-15vw", "0vw"],
                opacity: [0, 0, 1, 1],
              }}
              transition={{ duration: 3, times: [0, 0.45, 0.46, 0.55] }}
              src="/assets/decorations/multi_particle_mobile.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="w-full lg:hidden absolute -right-4 -top-[9rem]"
            />
          </figure>
          <motion.span
            initial={{ x: "-150vw" }}
            animate={{ x: "0vw" }}
            transition={{ delay: 0.6, duration: 0.4 }}
            className="bg-[#FFF0F7]/90 right-0 absolute w-full bottom-1/3 h-20 lg:hidden"
          />
          <motion.span
            initial={{ x: "-150vw" }}
            animate={{ x: "0vw" }}
            transition={{ delay: 0.8, duration: 0.4 }}
            className="bg-[#FFF0F7]/90 right-0 absolute w-full h-20 lg:hidden bottom-6 md:bottom-3"
          />
          {/* Content */}
          <div className="absolute hidden lg:block top-40 w-full lg:w-auto text-center lg:top-0 lg:static">
            <h1 className="legend_title italic text-white lg:text-black font-bold text-6xl 2xl:text-8xl text-center lg:text-right text-shadow--teal lg:mt-12 xl:mt-20 2xl:mt-20">
              <motion.span
                initial={{ x: "-150vw", scale: 0.2 }}
                animate={{ x: "0vw", scale: 1 }}
                transition={{ delay: 1.7, duration: 0.4 }}
                className="
                  block 
                  bg-cover 
                  h-[9.12rem]
                  w-[26.448rem]
                  -mt-[6rem]
                  mr-[4rem]
                  2xl:mr-[2.5rem]
                  2xl:h-[11.88rem]
                  2xl:w-[34.65rem]
                  2xl:-mt-[14rem]
                  3xl:mr-[4rem]
                  3xl:h-[14.4rem]
                  3xl:w-[42rem]
                  3xl:-mt-[15rem]"
                style={{
                  backgroundImage:
                    "url(/assets/White_Legends_of_rhe_past_2line.svg)",
                }}
              ></motion.span>
              <motion.span
                initial={{ x: "-150vw" }}
                animate={{ x: "0vw" }}
                transition={{ delay: 1.55, duration: 0.4 }}
                className="
                  block 
                  lg:absolute 
                  lg:bottom-3 
                  2xl:bottom-4
                  3xl:bottom-5
                  w-full  
                  lg:p-6 
                  lg:bg-[#FFF0F7]/90 
                  lg:left-0 
                  grid 
                  justify-end"
              >
                <motion.img
                  animate={{
                    x: ["-5vw", "-5vw", "-3.125vw", "0vw"],
                    opacity: [0, 0, 0, 1],
                  }}
                  transition={{ duration: 7, times: [0, 0.2, 0.35, 0.6] }}
                  src="/assets/RewriteHistory.webp"
                  className="
                  w-[38rem] 
                  -mb-[2.25rem] 
                  -mt-[0.25rem] 
                  mr-[7rem]
                  2xl:w-[50rem]
                  2xl:-mb-[2.5rem] 
                  2xl:-mt-[0.5rem] 
                  3xl:w-[62rem] 
                  3xl:-mb-[2.8rem] 
                  3xl:mt-[0rem]"
                ></motion.img>
              </motion.span>
            </h1>
          </div>
        </aside>
      </section>
      <DecorationFlash
        pseudoElements="before:border-b-[#FFACD0] after:bg-[#FFACD0]"
        figureBackground="bg-[#251015] lg:bg-black"
      />

      {/* Aspire to a legendary life */}
      <section className="flex relative md:pb-0 bg-black z-40 -mt-1  justify-end text-xl lg:text-3xl font-bold md:pr-5 w-full">
        <div className="is-legendary mt-6 2xl:mt-10 before:absolute after:absolute bottom-4 md:-bottom-6 -right-8 sm:right-4 lg:right-28 z-30 scale-[0.8] lg:scale-110 xl:scale-125 2xl:scale-150 ml-auto relative max-w-sm lg:w-full before:right-3/4 before:bottom-[4.9rem] before:w-full before:h-14 before:content[''] before:bg-[url('../assets/trame-2.svg')] before:bg-contain before:bg-no-repeat before:bg-bottom ">
          <h2 className="italic px-10 lg:px-16 py-2 lg:py-4 parrallelogram relative z-10 bg-[#8626EC] flex flex-col lg:-top-4">
            <span className="text-radial-gradient">Aspire to a</span>
            <span className="mt-12 lg:mt-12 text-radial-gradient">
              Rewrite History!
            </span>
          </h2>
          <figure
            className="lg:top-20 top-12 bg-cover text-right absolute w-full max-w-xl right-7 italic lg:right-10 z-10"
            style={{
              backgroundImage: "url(/assets/decorations/trame.svg)",
            }}
          >
            <span className="parrallelogram bg-[#4B1D77] pr-8 lg:pr-16 lg:pl-28 pl-8 py-2 inline-block lg:absolute lg:-top-10 lg:-right-6   lg:ml-auto ">
              <span className="text-radial-gradient">legendary life.</span>
            </span>
          </figure>
        </div>
      </section>

      {/* GET YOUR LEGENDS NFTS */}
      <section className="relative pt-16 overflow-hidden primary-radial-gradient-background border-b-[10px] border-b-white italic text-white px-3 lg:pt-10 lg:px-16">
        {/* Decorations */}
        <canvas
          class="confetti absolute h-full w-full z-20 left-0 top-0"
          id="canvas"
          onLoad={load()}
        ></canvas>
        <aside className="font-bold z-20 relative inline-flex flex-col items-center lg:pl-24 3xl:pl-48 lg:items-start w-full">
          {/* Legend */}
          <figure className="flex flex-row-reverse items-center -mt-4 sm:mt-20 md:mt-24 lg:mt-0">
            <img
              src="/assets/legend_cleop.webp"
              title="Cleop"
              alt="cleop"
              className="lg:hidden relative w-[13rem] -right-2"
            />
            <figcaption className="text-shadow-purple relative -top-8 mt-28 lg:mt-8 text-3xl w-1/2 -mr-10 lg:mr-0 lg:top-0 lg:w-auto text-center text-[2rem] lg:text-4xl 2xl:text-5xl 2xl:ml-8">
              <span className="relative z-10">
                GET YOUR <br className="lg:hidden" /> LEGENDS
              </span>
              {/* Confetti mobile */}
            </figcaption>
          </figure>
          <span className="2xl:text-2xl text-sm lg:text-lg curved-parrallelogram bg-purple-900 lg:mt-5 pl-5 lg:pl-16 pr-3 lg:pr-8 py-1 lg:py-3">
            After Cleo, who will be our next Legend ?
          </span>
        </aside>
        {/* Legend */}
        {/* Treasure */}
        {/* Rays */}
        <motion.figure
          animate={{ rotate: 360 }}
          transition={{ ease: "linear", duration: 60, repeat: Infinity }}
          className="
          z-0 
          bg-cover 
          bg-center 
          -left-32 
          bg-no-repeat 
          absolute 
          w-[40rem] 
          top-[6rem]  
          h-[40rem]  
          lg:h-[120rem] 
          lg:-top-[38rem]
          lg:w-[120rem] 
          lg:-left-[35rem] 
          2xl:-top-[27rem] 
          2xl:-left-[29rem]
          3xl:-top-[25rem]
          3xl:-left-[23rem]"
          style={{ backgroundImage: "url(/assets/rays.png)" }}
        />
        <aside className="flex justify-center relative lg:justify-between mt-10 lg:mt-0 px-10 lg:px-0 lg:pl-28">
          {/* vase */}
          <ul className="flex items-end mb-10 2xl:w-1/2 justify-center relative z-10">
            <li>
              <figure>
                <img
                  src="/assets/mystery_vase.webp"
                  title=""
                  alt=""
                  className="w-28 mx-auto sm:w-32 2xl:w-44"
                />
                <figcaption className="bg-[#26D6EA] pt-5 pl-3 -mt-5 -z-10 pr-10 hidden relative italic">
                  <span className="relative z-10 block text-lg lg:text-1xl -mb-1">
                    00:00
                  </span>
                  <span className="trapezoid block absolute left-0 w-full h-6 bg-red-500 bottom-0" />
                  <span className="absolute w-full h-full border-b-4 border-r-4 border-b-white border-r-white top-1 left-0" />
                </figcaption>
              </figure>
            </li>
            <li className="lg:mx-10 xl!mx-20 2xl:mx-16 mx-6">
              <figure>
                <img
                  src="/assets/golden_vase.webp"
                  title=""
                  alt=""
                  className="w-24 sm:w-32 2xl:w-48"
                />
                <span
                  className="
                parrallelogram 
                block 
                relative 
                italic 
                text-lg 
                lg:text-4xl
                2xl:text-5xl 
                font-bold 
                text-white 
                bg-primary 
                py-0.5 
                md:py-3 
                lg:py-1.5 
                2xl:py-3 
                text-center"
                >
                  CLEO
                </span>
              </figure>
            </li>
            <li>
              <figure>
                <img
                  src="/assets/mystery_vase.webp"
                  title=""
                  alt=""
                  className="w-28 mx-auto sm:w-32 2xl:w-44"
                />
                <figcaption className="bg-[#26D6EA] pt-5 pr-3 -mt-5 -z-10 pl-10 hidden relative italic text-right">
                  <span className="relative z-10 block text-lg lg:text-1xl -mb-1">
                    00:00
                  </span>
                  <span className="trapezoid-reverse block absolute left-0 w-full h-6 bg-green-500 bottom-0" />
                  <span className="absolute w-full h-full border-b-4 border-r-4 border-b-white border-r-white top-1 left-0" />
                </figcaption>
              </figure>
            </li>
          </ul>
          {/* Legend */}
          <figure className="absolute hidden lg:block bottom-0 w-40 z-10 right-0 lg:relative lg:w-[25rem] lg:-mt-16 lg:-mr-20 2xl:w-[35rem] 3xl:w-[38rem] 2xl:-mt-8 2xl:-mr-20 lg:-left-40 ">
            <img
              className="bg-contain"
              src="/assets/legend_cleop.webp"
              title="Cleop"
              alt="cleop"
            />
          </figure>
        </aside>
        {/* Golden bar */}
        <figure>
          <img
            src="/assets/gold_bar_rectangle.svg"
            alt=""
            title="GET YOUR LEGENDS "
            className="absolute w-full  lg:hidden left-0 top-0 z-20"
          />
          <img
            src="/assets/gold_bar_rectangle_large.svg"
            alt=""
            title="GET YOUR LEGENDS "
            className="absolute w-full lg:block hidden left-0 top-0 z-20"
          />
          <img
            src="/assets/decorations/golden_bar.svg"
            alt=""
            title="GET YOUR LEGENDS "
            className="absolute hidden z-20 lg:block bottom-2 w-44 lg:w-auto lg:bottom-10 -right-6 lg:right-0"
          />
        </figure>
      </section>
      <aside className="border-b-[20px] md:border-b-[40px] border-b-[#251015] border-t-[20px] md:border-t-[40px] border-t-[#00007A]">
        <DecorationFlash
          pseudoElements="before:border-b-[#00007A] after:bg-[#00007A] before:border-b-[20px] before:md:border-b-[40px] md:h-24"
          figureBackground="bg-[#251015]"
        />
      </aside>

      {/* What is Legends of the Past ? */}
      <section className="relative z-10 flex flex-col-reverse items-center lg:items-start lg:flex-row overflow-hidden lg:justify-between bg-gradient-to-br text-white from-[#014EFB] to-[#2AAEF5]">
        <aside className="relative z-50 md:-z-0">
          <figure className="relative overflow-hidden -mb-4">
            <img
              src="/assets/boxing.webp"
              title="What is Legends of the Past ?"
              alt="boxing"
              className="z-20 relative w-96 hidden lg:block lg:w-auto 2xl:left-[15rem]"
            />
            <img
              src="/assets/boxing.webp"
              title="What is Legends of the Past ?"
              alt="boxing"
              className="z-20 relative w-96 block lg:hidden left-20"
            />
          </figure>
          <span
            className="bg-right-top hidden lg:block top-[18rem] z-10 w-full h-full bg-no-repeat absolute left-8 xl:left-[9rem]  2xl:top-[23rem] 2xl:left-[18rem]"
            style={{
              backgroundImage: "url(/assets/decorations/vertical_gold_red.svg)",
            }}
          />
          <span
            className="
            bg-right-top 
            bg-contain 
            hidden 
            lg:block 
            -top-[6rem] 
            z-10 
            w-full  
            h-[2200px] 
            bg-no-repeat 
            absolute 
            xl:left-44 
            2xl:left-[20rem] "
            style={{
              backgroundImage: "url(/assets/decorations/vertical_gold_red.svg)",
            }}
          />
        </aside>

        <span
          className="bg-right-bottom lg:hidden top-24 z-10 w-40 left-0 h-full bg-no-repeat absolute"
          style={{
            backgroundImage: "url(/assets/striped-shape.svg)",
          }}
        />
        <article className="flex flex-col pb-10 relative sm:pr-8">
          {/* Top decoration */}
          <figure
            className="h-40 -top-20 w-full -right-10 sm:-right-20 lg:-right-40 absolute bg-contain 2xl:h-[15rem]"
            style={{
              backgroundImage: "url(/assets/decorations/Trame_amphore.svg)",
            }}
          />
          {/* Title underlined */}
          <figure
            className="h-[180px] sm:h-[400px] -top-16 sm:-top-20 -left-24 sm:-left-24 lg:-top-28 bg-no-repeat w-full sm:w-2/3 absolute bg-contain"
            style={{
              backgroundImage: "url(/assets/decorations/elbow_gold_red.svg)",
            }}
          />
          <h2
            className="
            is-title 
            max-w-4xl 
            mx-auto 
            uppercase 
            text-shadow-blue 
            italic 
            text-3xl 
            text-center 
            lg:max-w-7xl 
            mb-20 
            sm:mb-28 
            lg:text-4xl 
            2xl:text-[2.6rem]
            3xl:text-5xl 
            font-bold 
            pl-0 
            md:pl-16 
            md:pr-5 
            pt-2 
            sm:pt-8
            lg:mt-6 
            z-20 
            2xl:pl-32
            2xl:pt-32
            md:mt-2
            2xl:-mt-0
            3xl:-mt-16"
          >
            What is Legends of the Past?
          </h2>
          <aside className="sm:pl-44 pt-10 lg:pt-0 xl:pt-10 2xl:pt-24 px-3 sm:pr-0 text-base md:text-xl 2xl:text-2xl">
            <p className="lg:text-[1.3rem] lg:leading-8 w-full sm:max-w-2xl mx-auto text-center md:text-justify font-normal">
              <strong>Legends Of The Past</strong> is an exciting mobile{" "}
              <strong> PVP action brawler </strong>
              where <strong>historical Legends</strong> engage in a lively
              battle, with free-to-play mechanics enriched by{" "}
              <strong>rewards</strong>.
              <br />
              <br />
              Do you have what it takes to <strong>rewrite History</strong>?
            </p>
          </aside>
        </article>
      </section>
      <DecorationFlash
        pseudoElements="before:border-b-violet-700 after:bg-violet-700 relative z-[5]"
        figureBackground="bg-[#251015]"
      />

      {/*CLEOP*/}
      <section className="w-full relative primary-radial-gradient-background-carousel pb-10 lg:pb-0 2xl:-mb-10 before:md:block before:absolute lg:before:-top-24 before:-top-12 before:md:-top-0 before:left-[50%] before:md:left-[70%] lg:before:left-[12%] z-0 before:w-2/3 before:md:w-1/3 before:h-full before:content['']  before:bg-contain before:bg-no-repeat before:bg-top">
        <h2 className="uppercase text-[4rem] -left-16 pt-16 md:pt-0 lg:text-[10rem] relative lg:absolute lg:right-10 lg:top-24 z-10 text-[#57B3FA] text-opacity-80 p-4 lg:text-right font-bold italic">
          {characters[index].name}
        </h2>
        <div className="hidden lg:flex w-full relative z-30 lg:-mt-8">
          <div className="w-1/12 item-center justify-center m-auto">
            <button
              type="button"
              onClick={() => largeCharacterRef.current.scrollPrev()}
              className="w-full"
            >
              <figure className="m-auto">
                <img src="/assets/arrow-left.svg" alt="" className="m-auto" />
              </figure>
            </button>
          </div>

          <div className="w-10/12 lg:flex">
            <div className="max-w-lg 2xl:ml-[6rem] 3xl:ml-[15rem] lg:mt-[6rem] 2xl:mt-[10rem] 3xl:mt-[10rem]">
              <div className="flex relative flex-row text-shadow--blue bg-[#FF6BBB] py-8 px-8 md:py-2 md:px-8  before:absolute before:border-y-[56px] before:md:border-y-[36px] before:2xl:border-y-[48px] before:border-r-[56px] before:border-y-transparent before:border-r-[#FF6BBB] before:-left-12 before:w-0 before:h-0 ">
                <figure className="-mt-16 lg:-mt-14 bottom-0">
                  <img
                    src={characters[index].jar}
                    title=""
                    alt=""
                    className=" w-3 sm:w-16"
                  />
                </figure>
                <h2 className="uppercase md:text-7xl  2xl:text-8xl text-white font-bold italic ">
                  {characters[index].name}
                </h2>
              </div>
              <div className="bg-[#0605454F] bg-opacity-30 text-white pb-4 px-4 lg:px-10 lg:pb-10 relative">
                <figure className="absolute bottom-44 -left-5">
                  <img src="/assets/purple_star.svg" alt="" className="" />
                </figure>
                <div className="relative -right-24 -top-2">
                  <div className="semi-curved-parrallelogram relative bg-[#4B1D77] z-10">
                    <h3 className="italic text-white relative z-20 w-3/4 ml-auto mr-8 text-2xl font-bold py-4">
                      {characters[index].role}
                    </h3>
                  </div>
                  <div className="italic bg-[#F5E748] text-white semi-curved-parrallelogram absolute z-0 top-2 left-4 h-full w-full ml-auto mr-8 md:text-2xl font-bold py-4" />
                </div>
                <p className="mt-2 text-justify">
                  {characters[index].description}
                </p>
                VALUES
                <br />
                <div className="flex flex-wrap">
                  {characters[index].values.map((value, i) => (
                    <span className="curved-parrallelogram-right px-10 md:px-12 py-2 mr-6 mt-4 bg-black relative">
                      <div
                        className="absolute inset-[2px] curved-parrallelogram-right bg-[#F5E748]"
                        style={{
                          background: characters[index].colors[i],
                        }}
                      />
                      <span className="-ml-6 relative z-10">{value}</span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <Characters
              ref={largeCharacterRef}
              characters={characters}
              setIndex={setIndex}
            />
          </div>
          <div className="w-1/12 item-center justify-center m-auto">
            <button
              type="button"
              onClick={() => largeCharacterRef.current.scrollNext()}
              className="w-full"
            >
              <figure className="m-auto">
                <img src="/assets/arrow-right.svg" alt="" className="m-auto" />
              </figure>
            </button>
          </div>
        </div>

        <div className="block lg:hidden">
          <div className="flex w-full relative z-40 -mb-20">
            <div className="w-1/12 item-center justify-center m-auto">
              <button
                type="button"
                onClick={() => characterRef.current.scrollPrev()}
                className="w-full"
              >
                <figure className="m-auto">
                  <img src="/assets/arrow-left.svg" alt="" className="m-auto" />
                </figure>
              </button>
            </div>

            <div className="w-10/12 flex">
              <Characters
                ref={characterRef}
                characters={characters}
                setIndex={setIndex}
              />
            </div>
            <div className="w-1/12 item-center justify-center m-auto">
              <button
                type="button"
                onClick={() => characterRef.current.scrollNext()}
                className="w-full"
              >
                <figure className="m-auto">
                  <img
                    src="/assets/arrow-right.svg"
                    alt=""
                    className="m-auto"
                  />
                </figure>
              </button>
            </div>
          </div>
          <div className="mx-10">
            <div className="max-w-lg mx-auto relative z-30">
              <div className="flex relative items-center flex-row text-shadow--blue bg-[#FF6BBB] pt-8 sm:pb-8 px-8  before:-mt-5 before:sm:mt-0 before:absolute before:sm:border-y-[56px] before:border-y-[20px] before:border-r-[20px] before:sm:border-r-[56px] before:border-y-transparent before:border-r-[#FF6BBB]  before:sm:-left-12 before:-left-5 before:w-0 before:h-0 ">
                <figure className="-mt-16">
                  <img
                    src={characters[index].jar}
                    title=""
                    alt=""
                    className="w-[3rem] sm:w-[4rem]"
                  />
                </figure>
                <h2 className="uppercase -mt-5 sm:mt-0 text-6xl sm:text-8xl text-white font-bold italic">
                  {characters[index].name}
                </h2>
              </div>
              <div className="bg-[#0605454F] bg-opacity-30 text-white pb-4 px-4 lg:px-10 lg:pb-10 relative">
                <figure className="hidden md:block absolute bottom-44 -left-5">
                  <img src="/assets/purple_star.svg" alt="" className="" />
                </figure>
                <div className="relative -right-6">
                  <div className="semi-curved-parrallelogram relative bg-[#4B1D77] z-10">
                    <h3 className="italic text-white relative z-20 md:w-1/2 md:ml-auto ml-14 md:mr-8 text-lg md:text-2xl font-bold py-1 md:py-4">
                      {characters[index].role}
                    </h3>
                  </div>
                  <div className="italic bg-[#F5E748] text-white semi-curved-parrallelogram absolute z-0 top-0.5 md:top-2 left-1 md:left-4 h-full w-full ml-auto mr-8 text-2xl font-bold py-4" />
                </div>
                <p className="font-montserrat mt-8 md:mt-16 text-sm md:text-base">
                  {characters[index].description}
                </p>
                <br />
                <h4 className="text-sm md:text-base">VALUES</h4>
                <br />
                <div className="flex flex-wrap">
                  {characters[index].values.map((value, i) => (
                    <span className="curved-parrallelogram-right px-10 md:px-12 py-2 mr-2 md:mr-6 mt-4 bg-black relative">
                      <div
                        className="absolute inset-[2px] curved-parrallelogram-right bg-[#F5E748]"
                        style={{
                          background: characters[index].colors[i],
                        }}
                      />
                      <span className="-ml-6 relative z-10 text-xs md:text-base">
                        {value}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <figure className="absolute bottom-0 right-0">
          <img src="/assets/trame.svg" alt="" />
        </figure>
      </section>

      <DecorationFlash
        pseudoElements="before:border-b-violet-700 after:bg-violet-700 relative z-[5]"
        figureBackground="bg-[#251015]"
      />

      {/* Infinity Jar */}
      <section className="w-full relative  pb-10 lg:pb-0 2xl:-mb-10 before:md:block before:absolute lg:before:-top-24 before:-top-12 before:md:-top-0 before:left-[50%] before:md:left-[70%] lg:before:left-[12%] z-0 before:w-2/3 before:md:w-1/3 before:h-full before:content['']  before:bg-contain before:bg-no-repeat before:bg-top">
        <img
          src="/assets/INFINTY_JAR_BACKGROUND.jpg"
          className="
          lg:-ml-[2rem]
          lg:h-[35rem]
          2xl:-ml-[15rem]
          2xl:h-[50rem] 
          3xl:-ml-[4rem]
          w-auto 
          top-0"
        ></img>

        <motion.img
          src="/assets/INFINTY_JAR.png"
          animate={{
            y: ["1vw", "-3vw", "1vw"],
            transition: {
              duration: 10,
              times: [0, 0.5, 1],
              repeatType: "loop",
              repeat: Infinity,
              type: "tween",
              damping: 25,
              stiffness: 500,
            },
          }}
          className="
          absolute
          lg:-ml-[2rem]
          lg:h-[35rem]
          2xl:-ml-[15rem]
          2xl:h-[50rem] 
          3xl:-ml-[4rem]
          w-auto 
          top-0"
        ></motion.img>

        <div className="relative lg:absolute top-0 right-0 z-30 2xl:mr-14 3xl:mr-28 3xl:mt-10">
          <h2
            className="
              is-title 
              text-white
              uppercase 
              text-shadow-blue 
              italic 
              text-3xl 
              top-0
              right-0
              font-bold 
              mx-10
              mt-4
              2xl:text-4xl"
          >
            What is an Infinity Jar?
          </h2>
          <aside className="text-base text-white top-0 right-0">
            <p className="font-montserrat w-full sm:max-w-2xl mx-auto text-center md:text-justify font-normal px-14 2xl:text-[1.3rem] 2xl:px-2 mt-20">
              Imbued with the power of the gods, the Infinity Jar is the most
              valuable artifact in the Heaven of Legends.
              <br />
              <br />
              The gods have forged only 500 of them. Not a single one more, to
              preserve their heavenly power.
              <br />
              <br />
              Owning this Jar makes you truly special and offers many benefits,
              such as receiving Heaven Keys for each sale, gold, weapons, rare
              items, VIP access, and even discounts.
            </p>
          </aside>
        </div>
        <div className="trapezeInfinityJar absolute bg-[#25191E] w-full h-full top-0 hidden lg:block"></div>
        <div className="trapezePlusInfinityJar absolute bg-[#25191E] w-full h-full top-0 hidden lg:block"></div>
        <div className="trapezeInfinityJarMobile absolute bg-[#25191E] w-full h-full top-0 lg:hidden "></div>
        <div className="trapezePlusInfinityJarMobile absolute bg-[#25191E] w-full h-full top-0 lg:hidden "></div>
      </section>

      {/* OUR GOAL */}
      <section className="primary-gradient-background border-b-8 border-b-black relative before:hidden before:md:block before:absolute before:top-0 before:right-0 z-0 before:w-1/3 before:h-full before:content[''] before:bg-[url('../assets/boxed_black.svg')] before:bg-cover before:bg-no-repeat before:bg-center">
        <figure className="relative z-40 lg:z-0 lg:-mb-8 after:border-b-[40px] after:border-b-black  before:absolute before:bottom-12 before:lg:bottom- before:z-10 before:w-full before:h-8 before:left-0 before:bg-black after:bg-[#00007A] after:absolute after:w-full after:h-full after:top-0 after:left-0">
          <span
            className="bg-cover hidden z-20 bg-no-repeat absolute w-full h-full"
            style={{
              backgroundImage: "url(/assets/decorations/curved_gold_line.svg)",
            }}
          />
          <img
            src="/assets/decorations/curved_gold_line.svg"
            title="OUR GOAL"
            alt="rocket"
            className="w-full hidden lg:block relative z-20"
          />
          <img
            src="/assets/decorations/curved_gold_line_mobile.svg"
            title="OUR GOAL"
            alt="rocket"
            className="w-full lg:hidden relative z-20"
          />
        </figure>
        {/* Title */}
        <figure
          className="
          text-shadow-blue 
          border-t-black 
          before:-z-0 
          relative 
          bg-black 
          before:absolute 
          block
          w-[70%]
          md:w-[45%] 
          2xl:w-[40%]
          before:w-0 
          italic 
          font-bold 
          text-white 
          text-xl 
          sm:text-4xl 
          pl-10 
          sm:pr-10  
          sm:pl-20 
          lg:pr-40 
          before:-top-6 
          lg:before:top-0 
          before:-right-20 
          py-5 
          before:border-t-[100px] 
          before:border-t-transparent 
          before:border-l-[100px] 
          before:border-l-black"
        >
          <motion.img
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            variants={dropInRocket}
            src="/assets/rocket.webp"
            title="OUR GOAL"
            alt="rocket"
            className="h-[7rem] lg:h-[9rem] 2xl:h-[11rem]  absolute top-2 -right-28  lg:-right-20  lg:-top-3 z-10 "
          />
          <figcaption>
            <h2 className="z-50 relative -left-8 -mr-10 text-5xl lg:text-6xl lg:pr-0 2xl:text-7xl lg:-mr-10">
              OUR GOAL
            </h2>
          </figcaption>
        </figure>
        <div className="mx-auto container relative pl-12 pr-12 pb-12 pt-4 ">
          <figure
            className="absolute h-full md:hidden w-36 -right-16 opacity-10"
            style={{
              backgroundImage: "url(/assets/decorations/filter_shape.svg)",
            }}
          />
          <ul className="max-w-8xl mx-auto grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 space-between gap-y-5 lg:max-w-5xl 2xl:max-w-8xl lg:gap-16 2xl:gap-2 lg:-mt-12 2xl:mt-0">
            <motion.li
              whileHover={{ scale: 1.1 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="max-w-sm relative mx-auto mt-32 w-full secondary-gradient-background-shadow text-white border-r-4 border-b-2 md:border-r-[10px] md:border-b-8 border-b-black border-r-black shadow-black shadow-lg"
            >
              <div className="px-5 h-full py-12 before:-top-24 before:sm:-top-32 before:right-5 secondary-gradient-background before:absolute before:md:-top-44 before:2xl:-top-56 before:md:right-0 z-0 before:w-11/12 sm:before:w-8/12 before:lg:w-9/12 before:h-full before:content[''] before:bg-[url('../assets/phone.svg')] before:bg-contain before:bg-no-repeat before:bg-top before:md:bg-center">
                <div className="flex bg-[#117FF5] p-2 md:p-4">
                  <img
                    className="mr-4"
                    src="/assets/purple_star.svg"
                    alt="purple_star"
                  />
                  <h3 className="font-extrabold italic text-base md:text-2xl lg:text-lg 2xl:text-2xl">
                    Epic battles on your mobile
                  </h3>
                </div>
                <p className="mt-4 text-sm lg:text-sm 2xl:text-base">
                  With talents coming from the best gaming studios, our mission
                  is to deliver an innovative, fun and sustainable gameplay!
                  <br />
                  <br />A video game must be fun.
                </p>
              </div>
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.1 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1, transition: { delay: 0.2 } }}
              viewport={{ once: true }}
              className="max-w-sm mx-auto relative mt-32 w-full secondary-gradient-background-shadow text-white border-r-4 border-b-2 md:border-r-[10px] md:border-b-8 border-b-black border-r-black shadow-black shadow-lg"
            >
              <div className="px-5 h-full py-12 before:-top-32 before:right-5 secondary-gradient-background before:absolute before:md:-top-44 before:2xl:-top-56 before:md:right-0 z-0 before:w-11/12 sm:before:w-8/12 before:lg:w-9/12 before:h-full before:content[''] before:bg-[url('../assets/shield.svg')] before:bg-contain before:bg-no-repeat before:bg-top before:md:bg-center">
                <div className="flex bg-[#117FF5] p-2 md:p-4">
                  <img
                    className="mr-4"
                    src="/assets/purple_star.svg"
                    alt="purple_star"
                  />
                  <h3 className="font-extrabold italic text-base md:text-2xl lg:text-lg 2xl:text-2xl">
                    Fostering communities
                  </h3>
                </div>
                <p className="mt-4 text-sm lg:text-sm 2xl:text-base">
                  Bringing the players together in communities in which they
                  recognize themselves and want to be involved.
                  <br />
                  <br />
                  For which Legend would you fight?
                </p>
              </div>
            </motion.li>
            <motion.li
              whileHover={{ scale: 1.1 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1, transition: { delay: 0.4 } }}
              viewport={{ once: true }}
              className="max-w-sm mx-auto relative mt-32 w-full secondary-gradient-background-shadow text-white border-r-4 border-b-2 md:border-r-[10px] md:border-b-8 border-b-black border-r-black shadow-black shadow-lg"
            >
              <div className="px-5 h-full py-12 before:-top-32 before:right-5 secondary-gradient-background before:absolute before:md:-top-44 before:2xl:-top-56 before:md:right-0 z-0 before:w-11/12 sm:before:w-8/12 before:lg:w-9/12 before:h-full before:content[''] before:bg-[url('../assets/award.svg')] before:bg-contain before:bg-no-repeat before:bg-top before:md:bg-center">
                <div className="flex bg-[#117FF5] p-2 md:p-4">
                  <img
                    className="mr-4"
                    src="/assets/purple_star.svg"
                    alt="purple_star"
                  />
                  <h3 className="font-extrabold italic text-base md:text-2xl lg:text-lg 2xl:text-2xl mt-2 py-3">
                    Play again & again
                  </h3>
                </div>
                <p className="mt-4 text-sm lg:text-sm 2xl:text-base">
                  Players will unlock rewards by progressing through the game.
                  <br />
                  <br />
                  What will you do when you unlock Excalibur?
                </p>
              </div>
            </motion.li>
          </ul>
        </div>
        {/* Decoration */}
        <figure className="relative after:absolute after:h-[3px] after:bg-black after:w-full after:top-3 after:z-20 after:left-0 before:absolute before:md:w-1/2 before:w-7/12 before:left-0 before:top-0 before:z-10 mt-3 before:border-b-[20px] before:border-r-[20px] before:border-b-[#FFC112] bg-black before:border-r-transparent text-white h-14 pl-10 sm:pl-20 pr-10" />
      </section>

      {/* GAME */}
      <section className="relative z-20 border-b-[80px] border-b-black flex flex-col-reverse lg:flex-row overflow-hidden justify-between game_background  pt-10 lg:pt-5 2xl:pt-10 3xl:pt-20 text-white">
        {/* Girl power */}
        <motion.span
          className="
          bg-left-top 
          -top-40 
          lg:-top-4 
          2xl:-top-24 
          3xl:-top-20
          z-10 
          w-full 
          h-full 
          bg-no-repeat 
          absolute 
          hidden 
          lg:block 
          2xl:w-[120%] 
          2xl:h-[120%] 
          lg:right-[8rem]
          2xl:-right-[8rem]
          3xl:-right-[22rem]
          -right-16"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={dropInWhiteStripeGame}
          style={{
            backgroundImage: "url(/assets/decorations/white_small_bench.svg)",
          }}
        />
        <motion.span
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={dropInWhiteStripeGame}
          className="
          bg-left-top 
          -top-40
          z-10 
          w-full 
          h-full 
          bg-no-repeat 
          absolute 
          hidden 
          lg:block 
          right-[1rem]
          xl:-right-[4rem] 
          2xl:-right-[2rem] 
          3xl:-right-[12rem] 
          "
          style={{
            backgroundImage: "url(/assets/decorations/white_large_bench.svg)",
          }}
        />
        <span
          className="block lg:hidden bg-left-bottom  z-10 w-[120%] h-[120%] bg-no-repeat absolute -bottom-[3rem] left-0"
          style={{
            backgroundImage: "url(/assets/decorations/white-large-mobile.svg)",
          }}
        />
        <span
          className="block lg:hidden bg-left-bottom  z-10 w-full h-full bg-no-repeat absolute -bottom-[15rem] -left-0"
          style={{
            backgroundImage: "url(/assets/decorations/white-large-mobile.svg)",
          }}
        />
        <aside className="flex items-end shrink-0">
          <img
            src="/assets/girl_force.webp"
            alt="jEU DE FORCE"
            title="GAME"
            className="w-96 -left-0 lg:w-auto lg:h-[666px] 2xl:h-[800px] 2xl:-mt-10 self-end relative z-30 mx-auto lg:mx-0 xl:left-6 2xl:left-10 3xl:left-40"
          />
          {/* Gold bench */}
          <img
            src="/assets/gold_bench.svg"
            alt=""
            title="GAME"
            className="absolute left-0 -bottom-1 z-20 md:-bottom-8 lg:-bottom-20 xl:-bottom-16"
          />
          {/* White bench */}
          <img
            src="/assets/white_bench.svg"
            alt=""
            title="GAME"
            className="absolute left-0 -bottom-1 z-20 md:-bottom-8 lg:-bottom-20 xl:-bottom-11"
          />
          {/* Decorations */}
          {/* <figure className="before:w-20 before:h-[1400px] before:-z-0 before:-top-20 before:bg-pink-100 before:-rotate-45 before:absolute after:w-28 after:h-[1024px] after:-z-0 after:-top-28 after:bg-pink-100 after:-rotate-45 after:absolute" /> */}
        </aside>
        {/* Description */}
        <article className="flex lg:ml-auto z-10 lg:mx-0 max-w-8xl flex-col items-start lg:w-7/12 pb-10 lg:pb-0 lg:mb-10">
          <h2 className="curved-trapezium mx-auto lg:mr-0 lg:-ml-20 2xl:-ml-10 text-6xl md:text-8xl inline-block pt-1 pb-3 bg-[#25191E] text-shadow-blue px-14 md:px-20  md:py-4 font-bold italic">
            GAME
          </h2>
          <aside className="right-trapezium mx-auto w-full text-sm md:text-base flex flex-col justify-between bg-[#FF7BA300] xl:-mt-6 lg:bg-[#25191E] grow xl:mb-8 lg:pl-28 2xl:pl-48 pt-12 pb-6 lg:pr-20">
            <div className="mx-auto max-w-xl px-10 text-justify">
              <p className="font-montserrat">
                People who have accomplished great things during their lives are
                sent to the{" "}
                <span className="font-montserrat font-bold lg:font-normal">
                  Heaven of Legends
                </span>
                .
              </p>
              <figure className="flex absolute right-0 lg:hidden w-2/3 mt-3">
                <span className="w-3 h-1 bg-black" />
                <span className="w-3 mx-1 h-1 bg-black" />
                <span className="grow h-1 bg-black" />
              </figure>
              <p className="font-montserrat mt-8 lg:my-6 ">
                At first, everyone was happy to be reunited between Legends. But
                they realised that if everyone was legendary, no one was
                legendary. Legends’ egos were hurt. So, they quickly found a way
                to rank each other.
              </p>
              <figure className="lg:hidden absolute right-0 w-100">
                <img
                  src="/assets/box.webp"
                  title="Game"
                  alt="box"
                  className="ml-auto w-80"
                />
              </figure>
              <p className="font-montserrat mt-44  lg:mt-0">
                Daedalus and Da Vinci built an arena in which Legends could
                finally compete together. The battles in the arena soon became
                the new Roman Colosseum Fights.
              </p>
              <p className="font-montserrat my-6">
                All soldiers, scientists, artists and even philosophers became
                obsessed with the race for glory in the arena.
              </p>
              <p className="font-montserrat">
                Since then, they all aspire to the title of Legend among
                Legends.
              </p>
            </div>
          </aside>
        </article>
        {/* Right particle */}
        <img
          src="/assets/particle.svg"
          alt="flash"
          title="Game"
          className="absolute right-0 -top-20 h-52 lg:h-80 z-20"
        />
      </section>

      {/* Team */}
      <section className="pb-28 relative bg-gradient-to-r from-[#107EEB] via-[#19B3FB] to-[#107EEB]">
        {/* Decoration */}
        <figure className="relative before:absolute before:md:w-1/2 before:w-7/12 before:right-0 before:top-0 before:border-t-[40px] before:border-l-[40px] before:border-transparent before:border-t-black before:border-r-transparent h-10 pl-10 sm:pl-20 pr-10" />
        <figure className="flex items-end relative justify-start mb-10 ml-6">
          <figcaption className="pt-5 z-10 md:pt-6 lg:pt-8 italic font-bold text-xl md:text-4xl flex">
            <h2 className="relative parrallelogram z-20 text-6xl lg:text-7xl bg-primary py-4 lg:py-5 px-10 lg:px-24 text-white text-shadow-fushia before:hidden before:md:block before:absolute before:top-0 before:-right-20 before:w-20 before:h-full before:content[''] before:bg-[url('../assets/horizontal_blue_grids.svg')] before:bg-cover before:bg-no-repeat before:bg-center">
              TEAM
            </h2>
            <img
              src="/assets/decorations/filter_shape.svg"
              alt="Team"
              title="team"
              className="z-10 h-30 absolute left-1/2 md:left-80 -top-2 lg:static lg:h-[112px] hidden lg:block -ml-20 md:-ml-40"
            />
          </figcaption>
          <img
            src="/assets/decorations/multi_particle.svg"
            alt="Team"
            title="team"
            className="absolute w-1/2 sm:w-96 -top-28 sm:left-20 sm:-top-72 lg:left-44 lg:-top-64 z-0"
          />
        </figure>
        {/* Principal partners */}

        <div className="container max-w-[12rem] md:max-w-xl lg:max-w-5xl 3xl:max-w-6xl mx-auto grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 space-between lg:space-x:20 md:gap-10 2xl:gap-8 gap-y-8 lg:gap-y-16">
          {team.map((member, index) => (
            <div key={member.name}>
              <motion.div
                initial="hidden"
                //whileInView="visible"
                whileHover={{ scale: 1.1 }}
                whileInView={{
                  y: "0vh",
                  transition: {
                    delay: 0.1 + 0.1 * (index % 4),
                    duration: 0.1,
                    type: "spring",
                    damping: 25,
                    stiffness: 500,
                  },
                }}
                viewport={{ once: true }}
                variants={dropInTeam}
              >
                <figure className="flex flex-col items-center">
                  <img
                    src={`/assets/team/${member.image}`}
                    alt="basile masson"
                    title="basile masson"
                    className=""
                  />
                  <figcaption className="text-white text-xl md:text-xl 2xl:text-2xl font-extrabold mt-4 whitespace-nowrap">
                    {member.role}
                  </figcaption>
                </figure>

                <h3 className="before:border-x-[15px] before:md:border-x-[30px] mt-6 md:mt-8 before:border-b-[15px] before:md:border-b-[30px] before:border-b-primary before:border-x-transparent before:absolute before:left-14 before:md:left-24 before:-top-3 before:md:-top-6 relative">
                  <span className="parrallelogram block relative italic text-sm sm:text-xl md:text-[1rem] 2xl:text-xl font-bold text-white bg-primary py-2 md:py-3 lg:py-1.5 2xl:py-3 pl-8 text-left">
                    {member.name}
                  </span>
                </h3>

                <p className="has-multiple-gradient-background ml-4 w-full text-sm md:text-[0.8rem] 2xl:text-lg text-white pt-2 pb-2 md:pb-2 pl-4 text-left relative">
                  <span className="relative z-20">{member.username}</span>
                </p>
              </motion.div>
            </div>
          ))}
        </div>
      </section>

      {/* Partners */}
      <section className="pb-28 relative -mb-10 bg-gradient-to-r from-[#107EEB] via-[#19B3FB] to-[#107EEB]">
        <motion.figure
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={dropInPartner}
          className="flex relative justify-end mb-10 ml-6  "
        >
          <figcaption className="pt-1 md:pt-6 lg:pt-12 2xl:pt-16 italic font-bold text-xl md:text-4xl">
            <h2 className="parrallelogram -mr-16 sm:-mr-20 md:mt-2 2xl:mr-0 text-5xl 2xl:text-7xl bg-[#B92791] py-3 2xl:py-5 px-5 lg:px-8 2xl:px-12 text-white text-shadow-blue">
              PARTNERS
            </h2>
          </figcaption>
          <img
            src="/assets/underline_left_gold.svg"
            title="PARTNERS"
            alt="PARTNERS"
            className="absolute right-0 h-16 md:h-32 2xl:h-48 w-auto top-8"
          />
          <img
            src="/assets/left_pinned.svg"
            title="PARTNERS"
            alt="PARTNERS"
            className="h-32 md:h-64 2xl:h-96 w-auto -ml-28 sm:-ml-20 lg:ml-0"
          />
        </motion.figure>
        {/* Principal partners */}
        <ul className="md:flex md:space-x-20 space-y-6 md:space-y-0 justify-center flex-wrap">
          {listPartners.main.map((partner, index) => {
            return (
              <motion.li
                initial={{ opacity: 0 }}
                whileInView={{
                  opacity: 1,
                  transition: { delay: 0.5 + 0.1 * index },
                }}
                viewport={{ once: true }}
              >
                <figure>
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    title={partner.name}
                    className="h-10 mx-auto lg:h-16 2xl:h-24"
                  />
                </figure>
              </motion.li>
            );
          })}
        </ul>
        {/* Others partners */}
        <ul className="md:flex justify-center md:space-x-20 mt-6 space-y-6 md:space-y-0 md:mt-10 flex-wrap">
          {listPartners.others.map((partner, index) => {
            return (
              <motion.li
                initial={{ opacity: 0 }}
                whileInView={{
                  opacity: 1,
                  transition: { delay: 0.7 + 0.1 * index },
                }}
                viewport={{ once: true }}
              >
                <figure>
                  <img
                    src={partner.logo}
                    alt={partner.name}
                    title={partner.name}
                    className="h-10 mx-auto lg:h-16 2xl:h-24"
                  />
                </figure>
              </motion.li>
            );
          })}
        </ul>
        {/* Decoration */}
        <figure
          style={{ backgroundImage: "url(/assets/grids.webp)" }}
          className="h-16 opacity-75 absolute bottom-0 w-full"
        />
      </section>

      {/* Stay Connected */}
      <section className="relative -mb-10 before:absolute before:md:w-full before:w-9/12 before:sm:max-w-lg before:left-0 before:top-[-40px] mt-10 before:border-t-[40px] before:border-r-[40px] before:border-t-[#171630] bg-gradient-to-r from-[#171630] to-[#03215A] before:border-r-transparent text-white pt-16 pb-20 px-6">
        <div className="relative mt-10 grid grid-cols-1 lg:grid-cols-2 mb-20">
          <div className="">
            <h3 className="font-montserrat text-3xl mb-10 uppercase ">
              Frequently Asked Questions
            </h3>
            <motion.img
              src="/assets/Infinity jar.png"
              animate={{
                y: ["2vw", "-2vw", "2vw"],
                transition: {
                  duration: 10,
                  times: [0, 0.5, 1],
                  repeatType: "loop",
                  repeat: Infinity,
                  type: "tween",
                  damping: 25,
                  stiffness: 500,
                },
              }}
              className="h-[15rem] mx-auto"
            ></motion.img>
          </div>
          <div className="mt-10 lg:mr-10 grid grid-cols-1 gap-y-4">
            <button
              id="cell_faq_1"
              className="button-63 text-white"
              onClick={() => showHideAnswerFaq(1)}
            >
              <div className="w-full h-full grid gap-6">
                <div className="flex h-8">
                  <span className="font-montserrat left-0 grow my-auto  uppercase text-[0.9rem] lg:text-[1.3rem] 2xl:text-[1.8rem]">
                    Why enter the Heaven of Legends?{" "}
                  </span>
                  <img
                    src="/assets/purple_star.svg"
                    className=" flex-none my-auto w-[2rem] h-[2rem] right-0"
                  ></img>
                </div>
                <div id="answer_faq_1" className="hidden">
                  <p className="p-faq text-justify text-black text-[0.8rem] lg:text-[1rem] 2xl:text-[1.4rem] mx-1 lg:mx-6 2xl:mx-10 leading-6 lg:leading-6 2xl:leading-10">
                    The Heaven of Legends offers long-term value to our
                    community of mortals to ensure the sustanaibility of LOP
                    over the long run. To this end, we are commited to providing
                    value to community members.
                  </p>
                </div>
              </div>
            </button>
            <button
              id="cell_faq_2"
              className="button-63 text-white"
              onClick={() => showHideAnswerFaq(2)}
            >
              <div className="w-full h-full grid gap-6">
                <div className="flex h-8">
                  <span className="font-montserrat left-0 grow my-auto  uppercase text-[0.9rem] lg:text-[1.3rem] 2xl:text-[1.8rem]">
                    What is a Heaven Key?{" "}
                  </span>
                  <img
                    src="/assets/purple_star.svg"
                    className=" flex-none my-auto w-[2rem] h-[2rem] right-0"
                  ></img>
                </div>
                <div id="answer_faq_2" className="hidden">
                  <p className="p-faq text-justify text-black text-[0.8rem] lg:text-[1rem] 2xl:text-[1.4rem] mx-1 lg:mx-6 2xl:mx-10 leading-6 lg:leading-6 2xl:leading-10">
                    The Heaven Key lets you join special event in Heaven of
                    Legends. Without it, you can't take part in these event.
                    <br />
                    <br />
                    Join quests on Crew3 and win a Heaven Key to secure your
                    spot in Heaven of Legends!
                  </p>
                </div>
              </div>
            </button>
            <button
              id="cell_faq_3"
              className="button-63 text-white"
              onClick={() => showHideAnswerFaq(3)}
            >
              <div className="w-full grid gap-6">
                <div className="flex h-8">
                  <span className="font-montserrat left-0 grow my-auto uppercase text-[0.9rem] lg:text-[1.3rem] 2xl:text-[1.8rem]">
                    What is an Infinity Jar?{" "}
                  </span>
                  <img
                    src="/assets/purple_star.svg"
                    className=" flex-none my-auto w-[2rem] h-[2rem] right-0"
                  ></img>
                </div>
                <div id="answer_faq_3" className="hidden">
                  <p className="p-faq text-justify text-[0.8rem] lg:text-[1rem] 2xl:text-[1.4rem] mx-1 lg:mx-6 2xl:mx-10 leading-6 lg:leading-6 2xl:leading-10">
                    Imbued with the power of the gods, the Infinity Jar is the
                    most valuable artifact in the Heaven of Legends.
                    <br />
                    <br />
                    The gods have forged only 500 of them. Not a single one
                    more, to preserve their heavenly power.
                    <br />
                    <br />
                    Owning this Jar makes you truly special and offers many
                    benefits, such as receiving Heaven Keys for each sale, gold,
                    weapons, rare items, VIP access, and even discounts.
                  </p>
                </div>
              </div>
            </button>
            <button
              id="cell_faq_4"
              className="button-63 text-white"
              onClick={() => showHideAnswerFaq(4)}
            >
              <div className="w-full grid gap-6">
                <div className="flex h-8 mb-8 2xl:mb-14">
                  <span className="font-montserrat left-0 grow my-auto uppercase text-[0.9rem] lg:text-[1.3rem] 2xl:text-[1.8rem] leading-6 lg:leading-8 2xl:leading-10">
                    Is the chance of winning a Heaven Key equal for all mortals?
                  </span>
                  <img
                    src="/assets/purple_star.svg"
                    className=" flex-none my-auto w-[2rem] h-[2rem] right-0"
                  ></img>
                </div>
                <div id="answer_faq_4" className="hidden">
                  <p className="p-faq text-justify text-[0.8rem] lg:text-[1rem] 2xl:text-[1.4rem] mx-1 lg:mx-6 2xl:mx-10 leading-6 lg:leading-8 2xl:leading-10">
                    No. As you complete quests and accumulate points, you can
                    level up and move into a higher status among mortals. (The
                    Seekers - The Believers - The Enlightened - The Awakened).
                    <br />
                    <br />
                    As you progress from one group to the next, you will:
                    <br />
                    <p
                      STYLE="padding:0 0 0 20px;"
                      className="p-faq text-[0.8rem] lg:text-[1rem] 2xl:text-[1.4rem] leading-6 lg:leading-6 2xl:leading-10"
                    >
                      - increase your chance x2 of getting picked for a Heaven
                      Key (weekly draw every Monday)
                    </p>
                    <p
                      STYLE="padding:0 0 0 20px;"
                      className="p-faq text-[0.8rem] lg:text-[1rem] 2xl:text-[1.4rem] leading-6 lg:leading-6 2xl:leading-10"
                    >
                      - increase your chance of getting an Airdrop
                    </p>
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={dropInDontMissOut}
          className="max-w-xl mx-auto text-4xl md:text-6xl py-3 lg:py-5 px-5 lg:px-12 drop-shadow-md italic font-bold text-shadow-fushia bg-primary parrallelogram text-center"
        >
          Don’t miss out!
        </motion.h2>
        <div className="relative mx-auto max-w-xl p-6 bg-[#14101B] mt-10 before:hidden before:md:block before:absolute before:top-0 before:-right-20 before:w-20 before:h-full before:content[''] before:bg-[url('../assets/vertical-blue-grids.svg')] before:bg-cover before:bg-no-repeat before:bg-center">
          <figure>
            <motion.img
              animate={{ rotate: [0, 30, -30, 0] }}
              transition={{
                duration: 1,
                times: [0, 0.25, 0.75, 1],
                repeatType: "loop",
                repeat: Infinity,
                repeatDelay: 0,
                type: "spring",
                damping: 25,
                stiffness: 500,
              }}
              src="/assets/vase.webp"
              title="Legends of the past club"
              alt="Vase"
              className="mx-auto"
            />
          </figure>
          <h3 className="text-center font-extrabold text-2xl md:text-4xl italic">
            Legends of the Past club
          </h3>
          <p className="italic mb-4 mt-4 text-center font-medium md:text-lg">
            Updates on game development, Sneak Peeks, Challenges
          </p>
          <div className="flex -mb-20 mt-8 ">
            <iframe
              src="https://embeds.beehiiv.com/c801ff60-ffe5-47ab-9578-573b39500340?slim=true"
              data-test-id="beehiiv-embed"
              frameborder="0"
              scrolling="no"
              width="100%"
            ></iframe>
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default Index;
