import React, { useRef, useState, useEffect } from "react";
import "../../styles/mint.css";
import { motion } from "framer-motion";
import piece0 from "../../assets/puzzle/Hint/artist_1.jpg";
import piece1 from "../../assets/puzzle/Hint/artist_2.jpg";
import piece2 from "../../assets/puzzle/Hint/artist_3.jpg";
import piece3 from "../../assets/puzzle/Hint/artist_4.jpg";
import piece4 from "../../assets/puzzle/Hint/artist_5.jpg";
import piece5 from "../../assets/puzzle/Hint/artist_6.jpg";
import piece6 from "../../assets/puzzle/Hint/artist_7.jpg";
import piece7 from "../../assets/puzzle/Hint/artist_8.jpg";
import piece8 from "../../assets/puzzle/Hint/artist_9.jpg";
import piece9 from "../../assets/puzzle/Hint/artist_10.jpg";
import piece10 from "../../assets/puzzle/Hint/artist_11.jpg";
import piece11 from "../../assets/puzzle/Hint/artist_12.jpg";
import piece12 from "../../assets/puzzle/Hint/artist_13.jpg";
import piece13 from "../../assets/puzzle/Hint/artist_14.jpg";
import piece14 from "../../assets/puzzle/Hint/artist_15.jpg";

const Mint = () => {
  console.log("start");
  const dialogRef = useRef(null);
  const [isStarted, setIsStarted] = useState(false);
  const [piecesClic, setPiecesClic] = useState([]);
  const [counterClic, setCounterClic] = useState(0);
  const victoryRef = useRef(null);
  const canvaRef = useRef(null);
  const [puzzle, setPuzzle] = useState([
    { i: 0, r: 0, value: 0, src: piece0 },
    { i: 1, r: 0, value: 1, src: piece1 },
    { i: 2, r: 0, value: 2, src: piece2 },
    { i: 3, r: 0, value: 3, src: piece3 },
    { i: 4, r: 0, value: 4, src: piece4 },
    { i: 5, r: 0, value: 5, src: piece5 },
    { i: 6, r: 0, value: 6, src: piece6 },
    { i: 7, r: 0, value: 7, src: piece7 },
    { i: 8, r: 0, value: 8, src: piece8 },
    { i: 9, r: 0, value: 9, src: piece9 },
    { i: 10, r: 0, value: 10, src: piece10 },
    { i: 11, r: 0, value: 11, src: piece11 },
    { i: 12, r: 0, value: 12, src: piece12 },
    { i: 13, r: 0, value: 13, src: piece13 },
    { i: 14, r: 0, value: 14, src: piece14 },
  ]);
  const [displayPuzzle, setDisplayPuzzle] = useState(puzzle);

  function handleClose() {
    dialogRef.current.style.display = "none";
    setIsStarted(true);
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const loadConfetti = async () => {
    let confetti = import("../../components/confetti"); // Module loaded (export default)!
  };

  window.addEventListener("click", handleClickOut);

  function handleClick(pieceObject) {
    setCounterClic(counterClic + 1);
    setPiecesClic([...piecesClic, pieceObject]);
  }

  function checkPuzzle() {
    let result = false;

    for (let i = 0; i < displayPuzzle.length; i++) {
      if (
        displayPuzzle[i].value === puzzle[i].value &&
        displayPuzzle[i].i === puzzle[i].i &&
        displayPuzzle[i].r === puzzle[i].r
      ) {
        result = true;
      } else {
        result = false;
        break;
      }
    }

    if (result) {
      //const title = document.getElementById("Title");
      //title.innerHTML = "🔥 Help Johannes Vermeer! Hint: J1665";
      try {
        victoryRef.current.style.display = "block";
        canvaRef.current.style.height = "100%";
      } catch (error) {}
      try {
        loadConfetti();
      } catch (error) {}
    }
  }

  async function puzzleAction() {
    if (piecesClic[0].value === piecesClic[1].value) {
      const updatedPuzzle = displayPuzzle.map((puzzleItem) => {
        if (
          puzzleItem.i === piecesClic[0].i ||
          puzzleItem.i === piecesClic[1].i
        ) {
          // Mettre à jour display en fonction de la valeur de r
          return {
            ...puzzleItem,
            r: puzzleItem.r === 180 ? -90 : puzzleItem.r + 90,
          };
        }
        return puzzleItem;
      });
      setDisplayPuzzle(updatedPuzzle);
    } else {
      const updatedPuzzle = displayPuzzle.map((puzzleItem) => {
        if (puzzleItem.i === piecesClic[0].i) {
          return {
            ...puzzleItem,
            r: piecesClic[1].r,
            i: piecesClic[1].i,
            src: piecesClic[1].src,
          };
        }

        if (puzzleItem.i === piecesClic[1].i) {
          return {
            ...puzzleItem,
            r: piecesClic[0].r,
            i: piecesClic[0].i,
            src: piecesClic[0].src,
          };
        }

        return puzzleItem;
      });

      setDisplayPuzzle(updatedPuzzle);
    }
    // Réinitialiser piecesClic après chaque action
    // await delay(300)
    setIsStarted(false);
    setTimeout(() => {
      setPiecesClic([]);
      setIsStarted(true);
    }, 300);
  }

  function handleClickOut(e) {
    if (!e.target.className.includes("piece")) {
      setPiecesClic([]);
    }
  }

  useEffect(() => {
    piecesClic.length === 2 && puzzleAction();
  }, [piecesClic]);

  useEffect(() => {
    if (counterClic >= 2) {
      checkPuzzle();
    }
  }, [displayPuzzle]);

  useEffect(() => {
    const uniqueValues = new Set(); // Utiliser un ensemble pour garantir des valeurs uniques
    uniqueValues.add(0);
    uniqueValues.add(4);
    uniqueValues.add(10);
    uniqueValues.add(14);
    const updatedPuzzle = puzzle
      .map((puzzleItem) => {
        let randomValue;
        if ([0, 4, 10, 14].includes(puzzleItem.value)) {
          return {
            ...puzzleItem,
          };
        }
        do {
          randomValue = Math.floor(Math.random() * puzzle.length); // Générer une nouvelle valeur
        } while (uniqueValues.has(randomValue)); // Répéter tant que la valeur est déjà présente
        uniqueValues.add(randomValue); // Ajouter la valeur à l'ensemble des valeurs uniques

        return {
          ...puzzleItem,
          value: randomValue,
          r: [0, -90, 90, 180][Math.floor(Math.random() * 4)],
        };
      })
      .sort((a, b) => a.value - b.value);

    setDisplayPuzzle(updatedPuzzle);
  }, []);

  return (
    <section className="overflow-hidden">
      <div className="App">
        {/* LEGENDS OF THE PAST */}
        <section>
          <div
            className="absolute top-0 bg-black w-full flex justify-center items-center h-[70px] lg:h-[90px]  border-b-[10px] lg:border-b-[15px] z-50 overflow-hidden"
            style={{
              borderImage:
                "linear-gradient(to right, #AC6A33, #E3DB5C, #E5AE20)",
              borderImageSlice: "1",
            }}
          >
            <a href="/">
              <motion.img
                whileHover={{ scale: 1.1 }}
                src="/assets/Icon_legends_of_the_past.svg"
                title="LEGENDS OF THE PAST"
                alt=""
                className="absolute w-20 z-10 top-[-12%] lg:top-[-20%] lg:w-28 left-0"
              />
            </a>
            <h2
              id="Title"
              className="text-white uppercase text-shadow-blue-low-mobile  italic  font-bold mx-auto text-[1rem] lg:text-3xl z-50"
            >
              🔥 Hint 2nd Legend!
            </h2>
          </div>

          <div className="relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] h-screen flex justify-center items-center pt-[70px] lg:pt-[90px]">
            <div className="ring-0 ring-[#FFFFFF] gap-0 m-auto  flex justify-center items-center relative grid grid-cols-5 grid-rows-3">
              {displayPuzzle.map((puzzleItem, index) => (
                <button
                  disabled={isStarted ? false : true}
                  key={index}
                  className="m-auto bg-[#E5AE20] w-16 h-16 md:w-40 md:h-40  2xl:w-52 2xl:h-52"
                  style={{
                    rotate: `${puzzleItem.r}deg`,
                    border: piecesClic.some(
                      (item) => item.value === puzzleItem.value
                    )
                      ? "3.5px solid #FF206D"
                      : "none",
                  }}
                  onClick={() => handleClick(puzzleItem)}
                >
                  <img
                    src={puzzleItem.src}
                    alt=""
                    className="piece w-full h-full"
                  />
                </button>
              ))}
            </div>

            <figure className="pointer-events-none absolute z-20 -bottom-20 -right-20">
              <img src="/assets/trame.svg" alt="" />
            </figure>

            <div
              id="help_text"
              className="hidden absolute w-[100%] h-[95%] lg:w-[100%] bg-[#25191EAA] m-auto min-h-[20%] z-50 p-4"
              ref={victoryRef}
            >
              <p className=" mt-10 lg:mt-5 w-full text-[0.8rem] lg:text-[1.5rem] 3xl:text-[1.5rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                You have solved the puzzle in {counterClic / 2} moves.
                Impressive!
                <br /> Comment your guess on our{" "}
                <a
                  target="_blank"
                  className="text-[#ADD8E6] underline"
                  href="https://twitter.com/legends_of_past"
                >
                  X/Twitter post!
                </a>
              </p>
            </div>

            <div
              className="flex flex-col justify-center content-center  gap-3 absolute w-[100%] h-full bg-[#25191EEE] mt-auto p-4"
              ref={dialogRef}
            >
              <p className="w-full text-[0.8rem] lg:text-[1rem] 3xl:text-[1rem] lg:max-w-2xl text-white mx-auto text-center md:text-justify font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                <span className="text-[1.2rem] lg:text-[1.5rem]">Rules:</span>{" "}
                <span className="">
                  <br />- Double-click a piece to rotate it.
                </span>
                <br />{" "}
                <span className="">- Click two pieces to swap them.</span>
                <br />
                To help you, we already placed the corner pieces correctly.
              </p>
              <button
                onClick={handleClose}
                className=" text-[1rem] lg:text-[1rem] lg:max-w-2xl text-white mx-auto text-center font-normal px-8 py-2 lg:px-8 lg:py-2 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] ring-1 ring-[#FFFFFF]"
              >
                Start the game
              </button>
            </div>
            <canvas
              className="pointer-events-none confetti absolute bottom h-[2%] w-screen z-45"
              id="canvas"
              ref={canvaRef}
            ></canvas>
          </div>
        </section>
      </div>
    </section>
  );
};

export default Mint;
