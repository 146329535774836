import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HashConnect } from "hashconnect";
import "../../../styles/mint.css";
import ListVote from "./ListVote";
import DetailCard from "./DetailCard";
import Header from "../../CommitteeCleo/CommitteeHeader";
import Menu from "../../CommitteeCleo/CommitteeMenu";
import remainingTime from "../../../getRemainingTime.js";
import convertToJSONString from "../../../convertToJSONString.js";
import { list } from "postcss";
import Auth from "../../../utils/auth/utils.js";

const axios = require("axios").default;

// let saveData = {
//   topic: "",
//   pairingString: "",
//   privateKey: "",
//   pairedWalletData: null,git a
//   pairedAccounts: [],
// };

// let hashconnect = new HashConnect();
let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if (isMainnet) {
  hashConnectString = "mainnet";
  mirrornodeString = "mainnet-public";
}

// const appMetaData = {
//   name: "Legends of the past",
//   description:
//     "Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar",
//   icon: "https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk",
// };

// export const pairHashpack = async () => {
//   let initData = await hashconnect.init(appMetaData, hashConnectString, false);
//   saveData.privateKey = initData.privKey;
//   saveData.topic = initData.topic;
//   console.log("initData");
//   console.log(initData);
//   console.log(`topic: ${initData.topic}`);

//   hashconnect.foundExtensionEvent.once((walletMetadata) => {
//     hashconnect.connectToLocalWallet(initData.pairingString, walletMetadata);
//   });

//   hashconnect.pairingEvent.once((pairingData) => {
//     console.log(`wallet paired`);
//     console.log(pairingData);

//     const accountId = document.getElementById("accountid");
//     accountId.innerHTML = pairingData.accountIds[0];
//     saveData.pairedAccounts.push(pairingData.accountIds[0]);
//     CheckClientHas();
//     //Update nb Cleo and name
//     //checkWL(pairingData.accountIds[0])
//   });
//   return initData;
// };



const Mint = () => {
  const [listVotes, setListVotes] = useState([]);
  const [displayVote, setDisplayVote] = useState([]);
  const [numberOfVotes, setNumberOfVotes] = useState(0);
  const [maxVotesForOne, setMaxVotesForOne] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [timer, setTimer] = useState({});
  const [result, setResult] = useState([{}]);
  const [confirmModale, setConfirmModale] = useState(false);
  const [errorEmptySelect, setErrorEmptySelect] = useState(false);
  const [voteIsValid, setVoteIsValid] = useState(false);
  const [actionVote, setActionVote] = useState(false);
  const [seeResult, setSeeResult] = useState(false);
  const [seeOptions, setSeeOptions] = useState(false);
  const [goVote, setGoVote] = useState(false);
  const [idCurrentVote, setIdCurrentVote] = useState();
  const [loadingData, setLoadingData] = useState(false);
  const [hasSerials, setHasSerials] = useState(true);

  const navigate = useNavigate();
  const user = Auth.getAccount() 

  async function CheckClientHas() {
    let TokenId = "0.0.3853857";
    const url =
      `https://` +
      mirrornodeString +
      `.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${user}&order=asc&limit=50`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let serial = [];
    await axios
      .get(url)
      .then(function (response) {
        const jsonResponse = response.data;
        // output the from address and message stored in the event
        if (jsonResponse.nfts.length != 0) {
          for (let index = 0; index < jsonResponse.nfts.length; index++) {
            serial.push(jsonResponse.nfts[index].serial_number.toString());
          }
          // console.log(`Mirror event(s): ${serial}`);
        }
      })
      .catch(function (err) {
        console.error(err);
        alert(err.toString());
      });
    return serial;
    const numberOfNumbers = Math.floor(Math.random() * 5) + 1; // Entre 1 et 5 nombres
    const uniqueNumbers = new Set();
  
    while (uniqueNumbers.size < numberOfNumbers) {
      const randomNumber = Math.floor(Math.random() * 1001); // Entre 0 et 1000
      uniqueNumbers.add(randomNumber);
    }
  
    return Array.from(uniqueNumbers);
  }
  const RequestAllVotes = async () => {
    const serials = await CheckClientHas();
    let formatedAll = [];
    let idVoteArray = [];
    let response;
    let url =
      "https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Vote&command=GetVote";
    await axios
      .get(url)
      .then(async function (responseVotes) {
        response = JSON.parse(convertToJSONString(responseVotes.data));
        for (let index = 0; index < response.length; index++) {
          idVoteArray.push(
            `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Vote&command=CheckVote&idvote=${response[index].Id}&serials=${serials}`
          );
        }
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });

    let arrayHasVoted = [];
    const resHasVoted = await Promise.all(
      idVoteArray.map((req) => axios.get(req))
    ).then((values) => {
      for (let index = 0; index < values.length; index++) {
        arrayHasVoted.push(JSON.parse(convertToJSONString(values[index].data)));
      }
      return values;
    });

    for (let index = 0; index < arrayHasVoted.length; index++) {
      let userCanVote = false;
      let userNumberCanVote = 0;
      const hasVotededArray = [];

      for (const cle in arrayHasVoted[index]) {
        const valeur = arrayHasVoted[index][cle];
        if (valeur === false) {
          userCanVote = true;
          userNumberCanVote += 1;
        }
        hasVotededArray.push({ [cle]: valeur });
      }
      formatedAll.push({
        hasvoted: hasVotededArray,
        canVote: userCanVote,
        userNumberCanVote: userNumberCanVote,
        name: response[index].Title,
        start: response[index].Timestamp,
        time: response[index].Time,
        description: response[index].Resume,
        legend: response[index].Legend,
        id: response[index].Id,
        state: remainingTime(response[index].Timestamp, response[index].Time),
      });
    }

    setListVotes(formatedAll);
  };

  const RequestOptions = async (id) => {
    let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Vote&command=GetOption&idvote=${id}`;
    axios
      .get(url)
      .then(async function (responseReq) {
        let response = JSON.parse(convertToJSONString(responseReq.data));

        let index;
        for (let i = 0; i < listVotes.length; i++) {
          if (listVotes[i].id === id) {
            index = i;
            break;
          }
        }
        const formatedOptions = [];
        const elements = [];

        if (listVotes[index].state.state === "Closed") {
          let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Vote&command=GetResult&idvote=${id}`;
          axios;
          await axios.get(url).then(function (responseResult) {
            let responseOfResults = JSON.parse(
              convertToJSONString(responseResult.data)
            );

            const tableauResultat = Object.entries(responseOfResults).map(
              ([cle, valeur]) => {
                const nouvelObjet = {};
                nouvelObjet[parseInt(cle)] = valeur;
                elements.push(valeur);
                return nouvelObjet;
              }
            );

            for (let i = 0; i < response.length; i++) {
              formatedOptions.push({
                idVote: id,
                idOption: response[i].SubId,
                visual: response[i].Visual,
                name: response[i].option,
                votes: elements[i],
              });
            }
          });
        } else {
          for (let i = 0; i < response.length; i++) {
            formatedOptions.push({
              idVote: id,
              idOption: response[i].SubId,
              visual: response[i].Visual,
              name: response[i].option,
            });
          }
        }

        setDisplayVote([
          {
            vote: listVotes[index],
            options: formatedOptions,
          },
        ]);
        setLoadingData(false);

      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });
  };

  const RequestToVote = async (idVote, idOption) => {
    const serials = await CheckClientHas();

    const auth = Auth.getAuth()
    const account = Auth.getAccount()

    const body = JSON.stringify({
      userId: account,
      auth: JSON.stringify(auth.userSignature),
      sign: JSON.stringify(auth.signedPayload),
      serials: serials,
      idVote : idVote,
      idOption : idOption,
  })

    let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Vote&command=PutVoteAction`;
    axios;
    await axios
      .post(url, body)
      .then(async function (response) {
        response = response.data;
        console.log(response)
        setVoteIsValid(
          response == 'True' ? true : false
        );
        setActionVote(actionVote ? false : true);
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });
  };

  const RequestResult = async (id) => {
    let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Vote&command=GetResult&idvote=${id}`;
    axios
      .get(url)
      .then(function (response) {
        response = JSON.parse(convertToJSONString(response.data));
        const elements = [];

        const tableauResultat = Object.entries(response).map(
          ([cle, valeur]) => {
            const nouvelObjet = {};
            nouvelObjet[parseInt(cle)] = valeur;
            elements.push(valeur);
            return nouvelObjet;
          }
        );
        setResult(tableauResultat);
        const total = elements.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        setNumberOfVotes(total);
        setMaxVotesForOne(Math.max(...elements));
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });
  };

  function handleChangeVote(id) {
    RequestOptions(id);
  }

  // const backgroundColors = ['#17BE9F'];
  const handleRadioChange = (index) => {
    setSelectedOption(index + 1);
    setErrorEmptySelect(false);
  };

  function handleSeeResult(id) {
    RequestResult(id);
  }
  useEffect(() => {
    if (displayVote.length > 0) {
      const intervalId = setInterval(() => {
        setTimer(
          remainingTime(displayVote[0].vote.start, displayVote[0].vote.time)
        );
      }, 10);

      const intervalTime = setInterval(() => {
        setListVotes((prevListVotes) => {
          const updatedListVotes = prevListVotes.map((vote) => ({
            ...vote,
            state: remainingTime(vote.start, vote.time),
          }));

          return updatedListVotes;
        });
      }, 1000);

      return () => {
        clearInterval(intervalId);
        clearInterval(intervalTime);
      };
    }
  }, [displayVote]);

  useEffect(() => {
    const fetchSerials = async () => {
      const serials = await CheckClientHas();
    
      if (serials.length > 0) {
        setLoadingData(true);
        RequestAllVotes();
      } else {
        setHasSerials(false)
      }
    }
    fetchSerials()
  }, []);

  useEffect(() => {
    if (listVotes.length > 0 && displayVote.length === 0) {
      const ongoingVote = listVotes.filter(
        (vote) => vote.state.state === "Ongoing"
      );
      const lastOngoingVote =
        ongoingVote.length > 1 ? ongoingVote[ongoingVote.length - 1] : ongoingVote[0];

      const programmedVote = listVotes.filter(
        (vote) => vote.state.state === "Programmed"
      );
      const firstProgrammedVote =
        programmedVote.length > 1
          ? programmedVote[programmedVote.length - 1]
          : programmedVote[0]

      const closedVote = listVotes.filter(
        (vote) => vote.state.state === "Closed"
      );
      const lastClosedVote = closedVote[0];

      if (lastOngoingVote) {
        RequestOptions(lastOngoingVote.id);
        RequestResult(lastOngoingVote.id);
        setIdCurrentVote(lastOngoingVote.id);
      } else if (firstProgrammedVote) {
        RequestOptions(firstProgrammedVote.id);
        RequestResult(firstProgrammedVote.id);
        setIdCurrentVote(firstProgrammedVote.id);
      } else if (lastClosedVote) {
        RequestOptions(lastClosedVote.id);
        RequestResult(lastClosedVote.id);
        setIdCurrentVote(lastClosedVote.id);
      }
    }
  }, [listVotes]);
  return (
    <section className="overflow-hidden">
      <div className="App">
        <section className=" relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB]  lg:h-screen bg-cover"  style={{ backgroundImage: `url(/assets/HeavenKey_Space.jpg)` }}>
          {/* LEGENDS OF THE PAST */}
          <Header/>
          {listVotes && (
            <figure className="overflow-y-auto flex flex-col h-full lg:h-[90%] lg:grid lg:grid-cols-3 lg:grid-rows-1 gap-4 lg:gap-6 p-4 lg:p-10 w-screen">
              <DetailCard
                hasSerials={hasSerials}
                loadingData={loadingData}
                RequestOptions={RequestOptions}
                RequestAllVotes={RequestAllVotes}
                idCurrentVote={idCurrentVote}
                RequestToVote={RequestToVote}
                actionVote={actionVote}
                setActionVote={setActionVote}
                setGoVote={setGoVote}
                goVote={goVote}
                setSeeOptions={setSeeOptions}
                seeOptions={seeOptions}
                setSeeResult={setSeeResult}
                seeResult={seeResult}
                CheckClientHas={CheckClientHas}
                RequestResult={RequestResult}
                setVoteIsValid={setVoteIsValid}
                voteIsValid={voteIsValid}
                result={result}
                handleRadioChange={handleRadioChange}
                errorEmptySelect={errorEmptySelect}
                selectedOption={selectedOption}
                confirmModale={confirmModale}
                numberOfVotes={numberOfVotes}
                maxVotesForOne={maxVotesForOne}
                displayVote={displayVote}
                timer={timer}
                listVotes={listVotes}
                handleChangeVote={handleChangeVote}
                setSelectedOption={setSelectedOption}
                setConfirmModale={setConfirmModale}
                setErrorEmptySelect={setErrorEmptySelect}
              />
              <ListVote
                setIdCurrentVote={setIdCurrentVote}
                idCurrentVote={idCurrentVote}
                setGoVote={setGoVote}
                setSeeOptions={setSeeOptions}
                setSeeResult={setSeeResult}
                handleSeeResult={handleSeeResult}
                listVotes={listVotes}
                handleChangeVote={handleChangeVote}
                setSelectedOption={setSelectedOption}
                setConfirmModale={setConfirmModale}
                setErrorEmptySelect={setErrorEmptySelect}
              />
            </figure>
          )}
        </section>
      </div>
      <Menu />
    </section>
  );
};

export default Mint;
