import {HashConnect} from 'hashconnect'
import { 
    motion,
    useAnimationControls 
  } from "framer-motion"

import {
    AccountId,
    TokenId,
    TokenAssociateTransaction,
    TopicMessageSubmitTransaction,
    TransferTransaction,
    TransactionId,
    ScheduleSignTransaction,
    Client,
    PrivateKey,
    Hbar
} from '@hashgraph/sdk'

import {enterHeavenOfLegends,showbutton,show_text} from 'pages/mint'
import { json } from 'react-router-dom';

const axios = require('axios').default;

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
    console.log(`connexion status: ${connectionStatus}`)
})

let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts:[]
}

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if(isMainnet){
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public"; 
}

const keyTokenId = TokenId.fromString('0.0.1385462');

const infinityJarTokenId = TokenId.fromString('0.0.1881189');

let bufferAnimControls ="";

let serial_number = 0;

let OneAtTime = false;

const appMetaData = {
    name: 'Legends of the past',
    description: 'Welcome mortals to the heaven of Legends, hope you are well, here you can exchange your Heaven Key against the Infinity jar, a jar that will give you lot of things.',
    icon:'https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk'
}

export const pairHashpack = async (controlsHeavenKey) => {
    bufferAnimControls = controlsHeavenKey;

    let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic
    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)

    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)
        showbutton();

        const accountId = document.getElementById('accountid');
        accountId.innerHTML = pairingData.accountIds[0];
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        checkWL(pairingData.accountIds[0])
        //AskServerToSendKey()
    })
    return initData;
}

//make the button clickable or show error message "not whitelist"
export const checkWL = async(accountID) => {
    console.log("Check WL for: "+accountID);
    let inWL = await CheckAccountHasKey();
    //let inWL = await checkWL2();
    if(inWL){
        console.log(accountID+" is WL");
        //the account is WL => clickable button
        const buttonGetKey = document.getElementById('FakebuttonGetHK');
        buttonGetKey.className= "hidden"
        const realGetKey = document.getElementById('buttonGetHK');
        realGetKey.className= "italic h-12 z-50  parrallelogram bg-[#8626EC] hover:bg-[#8611EC] z-50 bottom-24 px-7 py-1 mt-[0rem]"
        //add onclick OnClickGetKey
        //realGetKey.onclick = async ()=>{OnClickGetKey()};
    }
    else{
        //show message "are you sure you are White List ?"
        const response = document.getElementById('response');
        response.className="italic text-white z-50 mx-8 lg:mx-0 h-10 text-[1rem] lg:text-[1.5rem] 3xl:mt-4 3xl:text-[2rem] font-bold"
        response.innerHTML = accountID+" need a Heaven Key"
    }

}

export const checkWL2 = async(accountID) =>{
    return true
}

export const OnClickGetKey = async() => {
    CheckAssociateToken();
    //OnSendKey(bufferAnimControls);
    //http://13.42.62.231:3000
    //localhost:4000
    
    
    
}

export const CheckAssociateToken = async() => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/accounts/${saveData.pairedAccounts[0]}/tokens?token.id=${infinityJarTokenId}`;

    //TODO try catch
    axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            if (jsonResponse.tokens.length == 0){
                return AssociateToken();
            }
            else{
                console.log(`token ${infinityJarTokenId} is already associated`);
                AskServerToSendKey();
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		}); 
}

export const AssociateToken = async() => {
    try {
        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
        const signer = hashconnect.getSigner(provider)
        let associateLocalWalletTx = await new TokenAssociateTransaction()
            .setAccountId(saveData.pairedAccounts[0])
            .setTokenIds([infinityJarTokenId])
            .setMaxTransactionFee(new Hbar(150))
            .freezeWithSigner(signer);

        let associateLocalWalletTxSubmit = await associateLocalWalletTx.executeWithSigner(signer);
        if (associateLocalWalletTxSubmit == undefined){
            console.log(`user refuse to associate token`);
        }
        else{
            AskServerToSendKey();
        }
    } catch (error) {
        console.log(`error token don't associated: ${error}`);
    }
}

let hasKey = false;

export const CheckAccountHasKey = async() => {
    const url = `https://`+mirrornodeString+`.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let res= false;
	await axios
		.get(url)
		.then(function (response) {
			const jsonResponse = response.data;
            // output the from address and message stored in the event
            if (jsonResponse.nfts.length == 0){
                //alert("Did you have a heaven Key?");
            }
            else{
                serial_number = []
                for (let index = 0; index < jsonResponse.nfts.length; index++) {
                    serial_number.push(jsonResponse.nfts[index].serial_number.toString())
                }
                console.log(`Mirror event(s): ${serial_number}`);
                hasKey = true;

                res = true;
            }
            
		})
		.catch(function (err) {
			console.error(err);
            alert(err.toString());
		});
    return res
}

let askServer = false;

export const changeOneAtTime = (value) => {
    OneAtTime = value;
    console.log(`OneAtTime : ${OneAtTime}`)
}

export const AskServerToSendKey = async() => {
    //post request 
    //launch anim ?
    if (askServer) {
        show_text("already ask server !")
        return;
    }

    if(!hasKey){
        show_text("You need a Heaven key to enter")
        return;
    }
    askServer = true;

    let url = "https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+saveData.pairedAccounts[0]+"&serial="+serial_number+"&target=jar"
    //let url = "https://localhost:4000?accountid="+saveData.pairedAccounts[0]+"&serial="+serial_number

    if(OneAtTime){
        url = "https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?walletid="+saveData.pairedAccounts[0]+"&serial="+serial_number[0]+"&target=jar"
    }
    
    axios
        .get(url)
        .then(function (response) {
            console.log(response);
			const jsonResponse = JSON.parse(response.data.replace(/'/g,'"'));
            //console.log(jsonResponse);
            console.log(jsonResponse["status"]);

            ReadTransaction(jsonResponse["status"])
            
		})
		.catch(function (err) {
			console.log(err);
            alert(err.toString());
		});
}

export const ReadTransaction = async(base64string) =>{
    console.log("Read Transaction")

    let transactionbytes = base64ToBytes(base64string);
    //let id = new TransactionId.fromString(base64string);
    let transaction;
    let tokenTransferSubmit;
    try {
        transaction = TransferTransaction.fromBytes(transactionbytes);
        console.log(JSON.stringify(transaction))

        console.log("Read Transaction 1");

        const provider = hashconnect.getProvider(hashConnectString, saveData.topic, saveData.pairedAccounts[0])
        const signer = hashconnect.getSigner(provider)

        //let transactionFreeze = await transaction.freezeWithSigner(signer);
        console.log("Read Transaction 2");
        tokenTransferSubmit = await transaction.executeWithSigner(signer);
    } catch (error) {
        console.log(error);
    }
    
    
    console.log(JSON.stringify(tokenTransferSubmit))
    //other solution query balance of user
    //depend of hashpack ask May Chan about ExecuteWithSigner return value
    //swap if esle
    if (tokenTransferSubmit != undefined){
        console.log(`user success to send Hbar, He is now waiting his NFT`);
        //load();
        enterHeavenOfLegends();
        //enterHeavenOfLegends(welcome,gods,giftInfinityJar,gift,coinPile,pigCoin ,shield,sword_1,sword_2,heaven_key,hbarCoin,infinityJar,controlsButtonUseKey,controlsBackground,controlsShadow,controlsBigShadow,controlsKeyHoleJar);
    }
    else{
        console.log(`user refuse to accept the transaction`);
    }
}


export const base64ToBytes = (base64String) => {
    const base64abc = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    const l = base64String.length;
    let i = 0;
    let bytes = new Uint8Array((l * 3) / 4);
    let byteIndex = 0;

    for (i = 0; i < l; i += 4) {
        let b1 = base64abc.indexOf(base64String[i]);
        let b2 = base64abc.indexOf(base64String[i + 1]);
        let b3 = base64abc.indexOf(base64String[i + 2]);
        let b4 = base64abc.indexOf(base64String[i + 3]);

        bytes[byteIndex++] = (b1 << 2) | (b2 >> 4);

        if (base64String[i + 2] !== '=') {
            bytes[byteIndex++] = ((b2 & 0xf) << 4) | (b3 >> 2);
        }

        if (base64String[i + 3] !== '=') {
            bytes[byteIndex++] = ((b3 & 0x3) << 6) | b4;
        }
    }

    return bytes.slice(0, byteIndex);
};







