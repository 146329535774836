import Header from "../../CommitteeCleo/CommitteeHeader";
import Menu from "../../CommitteeCleo/CommitteeMenu";
import "../../../styles/mint.css";
import Auth from "../../../utils/auth/utils.js";
import convertToJSONString from "../../../convertToJSONString.js";
import { motion, useAnimationControls } from "framer-motion";
import { HashConnect } from "hashconnect";
import React, { useRef, useState, useEffect } from "react";

import {
  AccountId,
  TokenId,
  TransferTransaction,
  TokenAssociateTransaction,
  Hbar,
} from "@hashgraph/sdk";

let hashconnect = new HashConnect();

localStorage.clear();
hashconnect.connectionStatusChangeEvent.once((connectionStatus) => {
  console.log(`connexion status: ${connectionStatus}`);
});
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

let saveData = {
  topic: "",
  pairingString: "",
  privateKey: "",
  pairedWalletData: null,
  pairedAccounts: [],
};

let isMainnet = true;

let hashConnectString = "testnet";
let mirrornodeString = "testnet";

if (isMainnet) {
  hashConnectString = "mainnet";
  mirrornodeString = "mainnet-public";
}

const appMetaData = {
  name: "Legends of the past",
  description:
    "Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar",
  icon: "https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk",
};

const axios = require("axios").default;

const Reward = () => {
  const [user, setUser] = useState(Auth.getAccount());
  const canvaRef = useRef(null);

  const loadConfetti = async () => {
    canvaRef.current.style.height = "100%";
    let confetti = import("../../../components/HbarRain"); // Module loaded (export default)!
  };

  function convertOctetsToMb(octets) {
    return (octets / (1024 * 1024)).toFixed(2);
  }
  function convertOctetsToMbNoFixed(octets) {
    return octets / (1024 * 1024);
  }

  let hashconnect = "";

  const pairHashpack = async () => {
    saveData = Auth.getData();
    console.log(saveData);
    hashconnect = Auth.getConnection();

    ClaimReward();

    /*let initData = await hashconnect.init(appMetaData,hashConnectString,false)
    saveData.privateKey = initData.privKey
    saveData.topic = initData.topic
    console.log('initData')
    console.log(initData)
    console.log(`topic: ${initData.topic}`)
  
    hashconnect.foundExtensionEvent.once((walletMetadata)=>{
        hashconnect.connectToLocalWallet(initData.pairingString,walletMetadata)
    })

    
  
    hashconnect.pairingEvent.once((pairingData) => {
        console.log(`wallet paired`)
        console.log(pairingData)
  
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        
        
    })
    return initData;*/
  };

  async function CheckClientHas() {
    let isMainnet = true;

    let hashConnectString = "testnet";
    let mirrornodeString = "testnet";

    if (isMainnet) {
      hashConnectString = "mainnet";
      mirrornodeString = "mainnet-public";
    }
    let TokenId = "0.0.3853857";
    const url =
      `https://` +
      mirrornodeString +
      `.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${user}&order=asc&limit=50`;
    //const url = `https://testnet.mirrornode.hedera.com/api/v1/tokens/${keyTokenId}/nfts?account.id=0.0.48508695`;
    let serial = [];
    await axios
      .get(url)
      .then(function (response) {
        const jsonResponse = response.data;
        // output the from address and message stored in the event
        if (jsonResponse.nfts.length != 0) {
          for (let index = 0; index < jsonResponse.nfts.length; index++) {
            serial.push(jsonResponse.nfts[index].serial_number.toString());
          }
          // console.log(`Mirror event(s): ${serial}`);
        }
      })
      .catch(function (err) {
        console.error(err);
        alert(err.toString());
      });
    return serial;
  }

  async function GetInfoCleos() {
    //requete lambda dynamodb pour chaque serial :
    // - activated
    // - total reward
    // - reward unclaimed
    // - reward claimed
    // - reward missed
    reward_unclaimed = 0;

    let serials = await Auth.getSerials();
    console.log(serials);
    let url =
      `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Reward&command=GetInfo&serials=` +
      serials.join(",");
    await axios
      .post(url)
      .then(async function (response) {
        console.log(response.data);
        console.log(convertToJSONString(response.data));
        response = JSON.parse(convertToJSONString(response.data));
        UpdateInfoCleos(response);
        //console.log(response["Items"][0]["Serial"]);
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });
    /*let url2 =
      `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Reward&command=GetRewardClaimed&account=` +
      Auth.getAccount();
    await axios
      .post(url2)
      .then(async function (response) {
        console.log(response.data);
        //const reward_claimed_text = document.getElementById("reward_claimed");
        //reward_claimed_text.innerHTML =
        //  "Reward claimed: " + response.data + " HBAR";
        //response = JSON.parse(convertToJSONString(response.data));
        //UpdateInfoCleos(response);
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });*/
  }

  let piggybank = "";

  let reward_unclaimed = 0;
  function UpdateInfoCleos(info) {
    let nb_cleo = info["Items"].length;
    let active_cleo = 0;
    for (let index = 0; index < info["Items"].length; index++) {
      if (info["Items"][index]["Activated"]) {
        active_cleo++;
      }
    }
    console.log(active_cleo + "/" + nb_cleo);
    //const cleo_activate = document.getElementById("cleo_activate");
    //cleo_activate.innerHTML =
    //  active_cleo + "/" + nb_cleo + " CLEO eligible to reward for next event";

    for (let index = 0; index < info["Items"].length; index++) {
      reward_unclaimed += info["Items"][index]["RewardUnclaimed"];
    }
    const reward_unclaimed_text = document.getElementById("reward_unclaimed");
    reward_unclaimed_text.innerHTML = reward_unclaimed.toFixed(2);
    if (reward_unclaimed != 0) {
      piggybank.start({
        rotate: [-1, 1, -1],
        transition: {
          repeat: Infinity,
          duration: 0.25,
        },
      });
    } else {
      piggybank.stop();
    }

    //modifier bouton claim
    const claim_button = document.getElementById("claim_button");
    const claim_text = document.getElementById("claim_text");
    if (reward_unclaimed >= 2) {
      claim_button.className =
        "rounded-xl p-2 z-40 drop-shadow-2xl bg-[#8626EC] hover:bg-[#8611EC]";
      claim_text.className =
        "text-radial-gradient uppercase px-3 py-1 text-[1.3rem] md:text-[1.5rem] 2xl:text-[1.8rem] italic font-bold";
    } else {
      claim_button.className =
        "rounded-xl p-2 z-40 drop-shadow-2xl bg-[#9B9B9B]";
      claim_text.className =
        "text-gray-gradient uppercase px-3 py-1 text-[1.3rem] md:text-[1.5rem] 2xl:text-[1.8rem] italic font-bold";
    }
  }

  async function GetDicoListed() {
    let TokenId = "0.0.3853857";
    let next = true;

    let url = `https://mainnet-public.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${saveData.pairedAccounts[0]}&order=asc&limit=50`;
    let dico_serial = {};
    while (next) {
      //console.log(url);
      await axios
        .get(url)
        .then(function (response) {
          const jsonResponse = response.data;
          // output the from address and message stored in the event
          if (jsonResponse.nfts.length != 0) {
            for (let index = 0; index < jsonResponse.nfts.length; index++) {
              dico_serial[jsonResponse.nfts[index].serial_number] =
                jsonResponse.nfts[index].spender;
            }
            // console.log(`Mirror event(s): ${serial}`);
          }
          if (jsonResponse.links.next != null) {
            url =
              `https://mainnet-public.mirrornode.hedera.com` +
              jsonResponse.links.next;
          } else {
            next = false;
          }
        })
        .catch(function (err) {
          console.error(err);
          alert(err.toString());
        });
    }
    return dico_serial;
  }

  async function CheckIfCleoListed(serial) {
    let dicoSerialListed = await GetDicoListed();
    console.log(serial);
    console.log(dicoSerialListed);
    for (let i = 0; i < serial.length; i++) {
      if (dicoSerialListed[parseInt(serial[i])] != null) {
        serial.splice(i, 1);
        i--;
      }
    }
    console.log(serial);
    return serial;
  }

  async function ClaimReward() {
    console.log("Claim Reward");
    let serial = await Auth.getSerials();
    serial = await CheckIfCleoListed(serial);
    //requete lambda ec2
    let url =
      "https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Reward&command=ClaimReward&walletid=" +
      saveData.pairedAccounts[0] +
      "&serial=" +
      serial;

    console.log("URL : " + url);
    axios
      .post(url)
      .then(function (response) {
        console.log(response);

        if (response.data.includes("unlist")) {
          const info_text = document.getElementById("info");
          info_text.innerHTML = response.data;
          //console.log("You have already bought 5 CLEOs.");
        } else {
          const jsonResponse = JSON.parse(response.data.replace(/'/g, '"'));
          //console.log(jsonResponse);
          console.log(jsonResponse["status"]);
          ReadTransaction(jsonResponse["status"]);
        }
      })
      .catch(function (err) {
        console.log(err);
        alert(err.toString());
      });

    //crée une transaction unclaimed reward hbar avec les signatures owner CLEO et treasury ( requete back verif owner)
    //demander signature owner
    //requete success -> modifier unclaimed reward in dynamo db
    return true;
  }

  const ReadTransaction = async (base64string) => {
    console.log("Read Transaction");

    let transactionbytes = base64ToBytes(base64string);
    //let id = new TransactionId.fromString(base64string);
    let transaction;
    let tokenTransferSubmit;
    try {
      transaction = TransferTransaction.fromBytes(transactionbytes);
      console.log(JSON.stringify(transaction));

      console.log("Read Transaction 1");

      const provider = hashconnect.getProvider(
        hashConnectString,
        saveData.topic,
        saveData.pairedAccounts[0]
      );
      const signer = hashconnect.getSigner(provider);

      //let transactionFreeze = await transaction.freezeWithSigner(signer);
      console.log("Read Transaction 2");
      tokenTransferSubmit = await transaction.executeWithSigner(signer);
    } catch (error) {
      console.log(error);
    }

    console.log(JSON.stringify(tokenTransferSubmit));
    //other solution query balance of user
    //depend of hashpack ask May Chan about ExecuteWithSigner return value
    //swap if esle
    if (tokenTransferSubmit != undefined) {
      console.log(`user accepted the transaction`);
      let serial = await Auth.getSerials();
      //requete lambda ec2
      let url =
        "https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=Reward&command=ReceiveReward&walletid=" +
        saveData.pairedAccounts[0] +
        "&serial=" +
        serial;

      console.log("URL : " + url);
      axios
        .post(url)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (err) {
          console.log(err);
          alert(err.toString());
        });
      loadConfetti();
      await delay(5000);
      GetInfoCleos();
    } else {
      console.log(`user refused to accept the transaction.`);
      //messageMint(`You refused to accept the transaction.`);
    }
  };

  const base64ToBytes = (base64String) => {
    const base64abc =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    const l = base64String.length;
    let i = 0;
    let bytes = new Uint8Array((l * 3) / 4);
    let byteIndex = 0;

    for (i = 0; i < l; i += 4) {
      let b1 = base64abc.indexOf(base64String[i]);
      let b2 = base64abc.indexOf(base64String[i + 1]);
      let b3 = base64abc.indexOf(base64String[i + 2]);
      let b4 = base64abc.indexOf(base64String[i + 3]);

      bytes[byteIndex++] = (b1 << 2) | (b2 >> 4);

      if (base64String[i + 2] !== "=") {
        bytes[byteIndex++] = ((b2 & 0xf) << 4) | (b3 >> 2);
      }

      if (base64String[i + 3] !== "=") {
        bytes[byteIndex++] = ((b3 & 0x3) << 6) | b4;
      }
    }

    return bytes.slice(0, byteIndex);
  };

  piggybank = useAnimationControls();

  return (
    <section className="overflow-hidden ">
      <div className="App relative" onLoad={GetInfoCleos()}>
        <canvas
          className="pointer-events-none confetti absolute bottom h-[2%] w-screen z-50"
          id="canvas"
          ref={canvaRef}
        ></canvas>
        <section
          className="relative bg-gradient-to-br from-[#2AAEF5] to-[#014EFB] min-h-screen bg-cover"
          style={{ backgroundImage: `url(/assets/HeavenKey_Space.jpg)` }}
        >
          {/* LEGENDS OF THE PAST */}
          <Header />
          <figure className="overflow-y-auto h-[45rem] flex flex-col lg:flex-row gap-4 lg:gap-6 lg:h-[36rem] 2xl:h-[48rem] 3xl:h-[60rem] p-4 lg:p-6 w-screen relative">
            <div className="flex flex-col lg:row-start-2 lg:row-span-2 items-center justify-center ring-[0.2rem] h-full w-full backdrop-blur ring-[#FFFFFF33] bg-[#00000088] rounded-lg z-10">
              <figure className=" flex flex-col lg:h-full items-center justify-start gap-3 lg:gap-4 2xl:gap-6 px-4 mb-4 z-40">
                <div className="flex items-center text-radial-gradient uppercase italic font-bold z-40">
                  <img
                    src="/assets/Trophy.png"
                    alt="Tournament Trophy"
                    className="grow-0 shrink-0 flex-none h-[3rem] lg:h-[3rem] 2xl:h-[5rem] aspect-square rounded-lg mr-4"
                  />
                  <h2 className="text-[1.25rem] lg:text-2xl 2xl:text-3xl">
                    Tournament
                  </h2>
                  <img
                    src="/assets/Trophy.png"
                    alt="Tournament Trophy"
                    className="grow-0 shrink-0 flex-none h-[3rem] lg:h-[3rem] 2xl:h-[5rem] aspect-square rounded-lg ml-4"
                  />
                </div>

                <div className="grid grid-cols-1 gap-1 lg:gap-4 h-full">
                  <p className="leading-10 text-white text-[0.75rem]  lg:text-[1rem] 2xl:text-[1.3rem] 3xl:text-[1.5rem] lg:mb-1">
                    Hurray, the CLEO Committee won the first event!
                  </p>
                  <div className="leading-10 text-left text-white font-bold text-[0.9rem] lg:text-[1.25rem] 3xl:text-[2rem] lg:mb-2">
                    CHAMPION REWARDS:{" "}
                    <span className="text-radial-gradient uppercase italic font-bold text-[1.25rem] lg:text-2xl 2xl:text-3xl">
                      1,000
                    </span>
                    <img
                      src="/assets/HBAR.png"
                      alt="HBAR"
                      className="ml-2 inline-block h-[1.5rem] lg:h-[2rem] 2xl:h-[2rem] aspect-square"
                    />
                  </div>
                  <div className="leading-10 text-left text-white font-bold text-[0.9rem] lg:text-[1.25rem] 3xl:text-[2rem] ">
                    YOUR REWARDS:{" "}
                    <span
                      id="reward_unclaimed"
                      className="text-radial-gradient uppercase italic font-bold text-[1.25rem] lg:text-2xl 2xl:text-3xl"
                    >
                      00{" "}
                    </span>
                    <img
                      src="/assets/HBAR.png"
                      alt="HBAR"
                      className="ml-2 inline-block h-[1.5rem] lg:h-[2rem] 2xl:h-[2rem] aspect-square"
                    />
                  </div>
                  <p className="leading-5 text-left text-white text-[0.7rem]  lg:text-[0.85rem] 2xl:text-[1rem] mb-4 lg:mb-2 -mt-3">
                    Based on your ownership in the Committee.
                  </p>

                  <div className="  z-40 flex justify-center items-center mb-2">
                    <motion.img
                      animate={piggybank}
                      src="/assets/PiggyBank.png"
                      className="object-cover aspect-w-1 aspect-h-1 w-[8rem]  lg:w-[8rem] 2xl:w-[10rem]"
                      alt="Piggy Bank"
                    />
                  </div>

                  <div className="flex justify-center ">
                    <motion.button
                      id="claim_button"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      className="rounded-xl p-2 z-40 drop-shadow-2xl bg-[#8626EC] hover:bg-[#8611EC]"
                      onClick={() => {
                        if (reward_unclaimed < 2) {
                          //message ("hbar insuffusant")
                          return;
                        }
                        pairHashpack();
                        //window.open("https://twitter.com/intent/tweet?text=Without%20participating%20in%20the%20tournament,%20I%20earned%20"+reward_unclaimed.toString()+"%20HBARs%20as%20a%20proud%20CLEO%20Committee%20member.%0AThis%20is%20just%20the%20beginning%20for%20LOP!", '_blank');
                        //window.focus();
                      }}
                    >
                      <span
                        id="claim_text"
                        className="text-radial-gradient uppercase px-3 py-1 text-[1.3rem] md:text-[1.5rem] 2xl:text-[1.8rem] italic font-bold"
                      >
                        Claim
                        <img
                          src="/assets/HBAR.png"
                          alt="HBAR"
                          className="ml-2 inline-block h-[2rem] lg:h-[2rem] 2xl:h-[2rem] aspect-square"
                        />
                      </span>
                    </motion.button>
                  </div>

                  <p
                    id="info"
                    className="leading-5 text-white text-[0.7rem] mx-2 2xl:mx-4 lg:text-[0.85rem] 2xl:text-[1rem]"
                  >
                    Prize Pool will increase over time.
                  </p>
                </div>
              </figure>
            </div>
          </figure>
        </section>
      </div>
      <Menu />
    </section>
  );
};

export default Reward;
