import React, { useEffect, useRef, useState } from "react";
import "../../../../../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";
import Chart from 'chart.js/auto';
import { Bar } from 'react-chartjs-2'
import crown from "../../../../../assets/crown.svg";

const axios = require("axios").default;

const Result = ({isLoading, numberOfVotes, result, setSeeOptions, setGoVote, setSeeResult, timer, displayVote}) => {
    const backgroundColors = ['#FD7493', '#E4CD4A', '#8626EC', '#04BAFD', '#FF206D', '#F4960E', '#00007A', '#4B1D77', '#FFE6F0', '#C6402C'];
    const data = {
      labels: displayVote[0]?.options?.map((option, index) => index + 1) || [],
      datasets: [
          {
              label: '',
              backgroundColor: backgroundColors,
              borderWidth: 1,
              data: result.map((item, index ) => item[index + 1]) || [],
          }     
      ],
  };
  const options = {
      indexAxis: 'y',
      scales: {
          x: {
              beginAtZero: true,
              ticks: {
                color: 'white',
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: 'white',
          },
        },

      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = `Votes : ${result[context?.dataIndex][context?.dataIndex + 1] || ''} - ${displayVote[0]?.options[context.dataIndex]?.name}` || '';
              return label;
            },
          },
        },
        legend: {
          display: false  // Cette ligne désactive la légende
      }
      },
  };
  
  const optionsMobile = {
    indexAxis: 'x',
    scales: {
        x: {
            beginAtZero: true,
        },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = `Votes : ${result[context?.dataIndex][context?.dataIndex + 1] || ''} - ${displayVote[0]?.options[context.dataIndex]?.name}` || '';
            return label;
          },
        },
      },
      legend: {
        display: false  // Cette ligne désactive la légende
    }
    },
  };
  
  return (
    <section className="flex flex-wrap flex-col gap-5 lg:gap-0 items-center justify-between w-full h-full z-10 p-2 lg:p-10">
            <div>
                <h2 className="w-full text-radial-gradient uppercase  italic font-bold text-center w-full right-0 left-0 mx-auto lg:-top-14 text-[1.2rem] lg:text-[1.7rem] 2xl:text-3xl z-50">{displayVote[0]?.vote?.name}</h2>
                <p className="text-white text-left text-center text-[0.7rem] lg:text-[1.25rem] 3xl:text-[1.7rem]">{timer.days > 0 ? timer.time : timer.hours >= 1 ? timer.hours + 'h ' + timer.min + 'm ' + timer.seconds + 's' : timer.min > 0 ? timer.min + 'm ' + timer.seconds + 's': timer.seconds > 0 ? timer.seconds + 's' : 'Vote closed' }</p>
            </div>
            
            <div className="lg:w-[70%] flex flex-col align-center justify-center">
                <div>
                <h2 className=" text-white text-left text-[0.7rem] lg:text-[1.25rem] 3xl:text-[1.2rem] w-full text-center">{numberOfVotes} / 1000 has voted</h2>
                </div>
                <div className="hidden lg:flex justify-center w-full">
                <Bar data={data} options={options} className="w-full m-auto"/>
                </div>
                <div className="flex justify-center lg:hidden w-full h-full">
                <Bar data={data} options={optionsMobile} className="w-full m-auto h-full"/>
                </div>
            
            </div>
            
            <div className="flex flex-col gap-6 lg:flex-row lg:gap-3">
                {(timer.state === 'Ongoing' && displayVote[0]?.vote.canVote == true) && <button className="flex justify-center items-center text-center text-white text-[0.7rem] lg:text-[1.2rem]  p-2 px-4 bg-gradient-to-br from-[#000000] to-[#555555] rounded-xl" onClick={() => {setSeeResult(false), setGoVote(true)}}>Return</button>}               
            </div>

            {isLoading && <dialog className='absolute w-[100%] bg-[#25191ECC] m-auto flex flex-col justify-center h-full top-0 rounded-lg'> 
                <div className="flex justify-center items-center gap-3">
                    <p className="w-full text-white border p-1 md:p-1.5 border-white text-[1rem] lg:text-[1rem]">Loading...</p>
                </div>
            </dialog>}
      </section>
  );
};

export default Result;