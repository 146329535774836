import React, { useEffect, useRef, useState } from "react";
import { HashConnect } from "hashconnect";
import { useNavigate } from "react-router-dom";
import "../../../styles/mint.css";
import { motion, useAnimationControls } from "framer-motion";
import Auth from "../../../utils/auth/utils.js";
import { getPseudo, LoadParticles } from "../../../CommitteeCleoManagement.js";
import convertEncodingQuote from "../../../convertEncodingChar";
import convertToJSONString from "../../../convertToJSONString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const axios = require("axios").default;

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
var userAgent = navigator.userAgent;
const Connection = () => {
  const [addPseudo, setAddPseudo] = useState(false);
  const [pseudo, setPseudo] = useState("");
  const [errorPseudo, setErrorPseudo] = useState(false);
  const [errorPseudoExist, setErrorPseudoExist] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [welcome, setWelcome] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [welcomeBack, setWelcomeBack] = useState(false);
  const [userNotCleo, setUserNotCleo] = useState(false);
  let user;

  const checkPseudo = async () => {
    const auth = Auth.getAuth();
    const account = Auth.getAccount();

    const body = JSON.stringify({
      userId: account,
      auth: JSON.stringify(auth.userSignature),
      sign: JSON.stringify(auth.signedPayload),
    });

    let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=checkPseudo`;
    const response = await axios.post(url, body);
    return response.data;
  };

  const addNewPseudo = async () => {
    if (pseudo.length < 3 || pseudo.length > 30) {
      setHasError(true);
    } else {
      setIsLoading(true);
      const auth = Auth.getAuth();
      const account = Auth.getAccount();

      const body = JSON.stringify({
        userId: account,
        auth: JSON.stringify(auth.userSignature),
        sign: JSON.stringify(auth.signedPayload),
        pseudo: convertEncodingQuote(pseudo),
      });
      let url = `https://whvd3dvccg.execute-api.eu-west-2.amazonaws.com/default/Committee_Function?target=addPseudo`;
      const response = await axios.post(url, body);
      if (response.data === "added") {
        setIsLoading(false);
        setIsAdded(true);
      } else if (response.data == "yet used") {
        setIsLoading(false);
        setErrorPseudoExist(true);
      } else {
        setIsLoading(false);
        setErrorServer(true);
      }
    }
  };

  async function CheckClientHas() {
    let TokenId = "0.0.3853857";
    let next = true;

    let url =
      `https://` +
      mirrornodeString +
      `.mirrornode.hedera.com/api/v1/tokens/${TokenId}/nfts?account.id=${user}&order=asc&limit=50`;
    let serial = [];
    while (next) {
      //console.log(url);
      await axios
        .get(url)
        .then(function (response) {
          const jsonResponse = response.data;
          // output the from address and message stored in the event
          if (jsonResponse.nfts.length != 0) {
            for (let index = 0; index < jsonResponse.nfts.length; index++) {
              serial.push(jsonResponse.nfts[index].serial_number.toString());
            }
            // console.log(`Mirror event(s): ${serial}`);
          }
          if (jsonResponse.links.next != null) {
            url =
              `https://mainnet-public.mirrornode.hedera.com` +
              jsonResponse.links.next;
          } else {
            next = false;
          }
        })
        .catch(function (err) {
          console.error(err);
          alert(err.toString());
        });
    }
    return serial;
  }

  const navigate = useNavigate();
  let saveData = {
    topic: "",
    pairingString: "",
    privateKey: "",
    pairedWalletData: null,
    pairedAccounts: [],
  };

  let hashconnect = new HashConnect();
  let isMainnet = true;

  let hashConnectString = "testnet";
  let mirrornodeString = "testnet";

  if (isMainnet) {
    hashConnectString = "mainnet";
    mirrornodeString = "mainnet-public";
  }

  const appMetaData = {
    name: "Legends of the past",
    description:
      "Welcome mortals to the heaven of Legends, hope you are well, here you can get the first airdrop of the Infinity Jar",
    icon: "https://gateway.pinata.cloud/ipfs/QmT3CfPxyfW381YaMXJqdqYsVvAyrszj5TMiYGucMVZ6Wk",
  };

  const pairHashpack = async () => {
    let initData = await hashconnect.init(
      appMetaData,
      hashConnectString,
      false
    );
    saveData.privateKey = initData.privKey;
    saveData.topic = initData.topic;
    // console.log("initData");
    // console.log(`topic: ${initData.topic}`);

    new Promise((resolve, reject) => {
      hashconnect.foundExtensionEvent.once((walletMetadata) => {
        hashconnect.connectToLocalWallet(
          initData.pairingString,
          walletMetadata
        );
        resolve();
      });
    });

    new Promise((resolve, reject) => {
      hashconnect.pairingEvent.once((pairingData) => {
        saveData.pairedAccounts.push(pairingData.accountIds[0]);
        user = pairingData.accountIds[0];
        resolve();
        authenticateUser();
      });
    });

    return initData;
  };
  const authenticateUser = async () => {
    const hashconnectData = JSON.parse(window.localStorage.hashconnectData);
    Auth.saveAccount(
      JSON.stringify(hashconnectData.pairingData[0].accountIds[0])
    );
    Auth.saveConnection(hashconnect)
    Auth.saveData(saveData)
    try {
      let payload = {
        url: "13.42.62.231",
        data: { token: "fufhr9e84hf9w8fehw9e8fhwo9e8fw938fw3o98fhjw3of" },
      };

      const res = await fetch(
        `https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?target=sendAuth&walletid=${hashconnectData.pairingData[0].accountIds[0]}`
      );
      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }
      let data = await res.text();
      data = data.replace(/'/g, '"');
      data = JSON.parse(data);
      const serverSigAsArr = Object.values(data.signature);
      const serverSigAsBuffer = Buffer.from(serverSigAsArr);
      setAddPseudo(true);
      setIsLoading(true);

      const auth = await hashconnect.authenticate(
        hashconnectData.topic,
        hashconnectData.pairingData[0].accountIds[0],
        data.serverSigningAccount,
        serverSigAsBuffer,
        payload
      );
      const receivedAuthData = {
        signingAccount: hashconnectData.pairingData[0].accountIds[0],
        auth,
      };

      const serials = await CheckClientHas();

      if (serials.length > 0) {
        Auth.saveAuth(JSON.stringify(auth));

        const authent = Auth.getAuth();
        const account = Auth.getAccount();
        const body = JSON.stringify({
          userId: account,
          auth: JSON.stringify(authent.userSignature),
          sign: JSON.stringify(authent.signedPayload),
        });
        const url = `https://c9grcgr3s1.execute-api.eu-west-2.amazonaws.com/default/redirectEC2?target=getAuth&walletid=${account}`;
        const resAuth = await axios.post(url, body);

        if (serials.length > 0) {
          Auth.saveKeys(JSON.stringify(serials));
        } else {
          Auth.saveKeys(JSON.stringify([]));
        }
        const message = resAuth.data;

        if (message.msg === "ok") {
          const checkIfPseudo = await checkPseudo();
          if (checkIfPseudo === "!found") {
            setIsLoading(false);
            setAddPseudo(true);
          } else {
            const userPseudo = await getPseudo();
            setIsLoading(false);
            setPseudo(userPseudo);
            setAddPseudo(true);
            setWelcomeBack(true);
            setTimeout(() => {
              navigate("/committee");
            }, 2000);
          }
        }
      } else {
        setIsLoading(false);
        setUserNotCleo(true);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };
  console.log(user);

  useEffect(() => {
    if (isAdded) {
      setWelcome(true);
      setTimeout(() => {
        navigate("/committee");
      }, 2000);
    }
  }, [isAdded]);

  useEffect(() => {
    LoadParticles();
    if (hasError) {
      setErrorPseudo(true);
      setHasError(false);
    }
  }, [hasError]);
  return (
    <div id="intro" className="flex flex-col gap-0 h-screen ">
      <div className="h-[6vh] z-[155] bg-[#000000]">
        <div className="absolute items-center gap-5 justify-between flex left-[3%] right-[5%] top-0 right-auto lg:left-[1%] my-auto">
          <motion.button
            whileHover={{ scale: 1.1, rotate: -5 }}
            whileTap={{ scale: 0.9 }}
            className="drop-shadow-2xl flex justify-center italic  rounded-xl bg-[#8626EC] hover:bg-[#8611EC] p-2 z-[170]"
            onClick={async () => {
              // const saveData = await pairHashpack();
              const saveData = await pairHashpack();
              //setPairingString(saveData.pairingString)
              // HideIntro();
            }}
          >
            <span className="text-radial-gradient text-[1.2rem] lg:text-[1.5rem] font-bold p-1">
              Connect wallet
            </span>
          </motion.button>
          <a href="/">
            <motion.img
              whileHover={{ scale: 1.1 }}
              src="/assets/Icon_legends_of_the_past.svg"
              title="LEGENDS OF THE PAST"
              alt=""
              className="w-20 z-[170]"
            />
          </a>
        </div>
        {addPseudo && (
          <dialog className="absolute w-[100%] bg-[#25191ECC] m-auto flex flex-col justify-center h-full z-[150]">
            {!isLoading && !welcome && !welcomeBack && !userNotCleo && (
              <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-4">
                <p className="w-full text-[1rem] lg:text-[1.2rem] text-white mx-auto text-center md:text-justify font-normal leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem]">
                  First connection, please choose a nickname for your account:
                </p>
                <div>
                  <input
                    placeholder="min 3 and max 30 characters"
                    type="text"
                    maxLength="30"
                    minLength="3"
                    value={pseudo}
                    onChange={(e) => {
                      setPseudo(e.target.value), setErrorPseudo(false);
                    }}
                    className="w-full"
                  ></input>
                </div>
                {errorPseudo && (
                  <p className="w-full text-[1rem] lg:text-[1.2rem] text-red-500 text-justify">
                    Invalid nickname: min 3, max 30 characters
                  </p>
                )}
                {errorPseudoExist && (
                  <p className="w-full text-[1rem] lg:text-[1.2rem] text-red-500 text-justify">
                    This nickname already exist, please choose another
                  </p>
                )}
                {errorServer && (
                  <p className="w-full text-[1rem] lg:text-[1.2rem] text-red-500 text-justify">
                    An error occured, please try later
                  </p>
                )}
                <div className="flex justify-center items-center gap-3">
                  <button
                    className="w-full text-white border p-1 md:p-1.5 border-white text-[1rem] lg:text-[1rem]"
                    onClick={() => {
                      addNewPseudo();
                    }}
                  >
                    Validate
                  </button>
                </div>
              </div>
            )}
            {isLoading && !welcome && (
              <div className="w-[90%] lg:w-[50%] m-auto flex flex-col gap-4">
                <FontAwesomeIcon
                  style={{ fontSize: "4rem", color: "#FFFFFF" }}
                  icon={faSpinner}
                  spin
                  className="w-full"
                />
                <p className="w-full text-[1rem] lg:text-[1.2rem] text-white text-center font-normal px-5 leading-[1rem] lg:leading-[1.4rem] 2xl:leading-[1.8rem] m-auto">
                  Loading...
                </p>
              </div>
            )}
            {welcome && (
              <div className="w-[90%] lg:w-[50%] m-auto flex justify-center">
                <p className="w-full text-[3rem] lg:text-[4rem] text-white text-center font-normal leading-[3.5rem] lg:leading-[1.4rem] m-auto">
                  Welcome {pseudo}!
                </p>
              </div>
            )}
            {welcomeBack && (
              <div className="w-[90%] lg:w-[50%] m-auto flex justify-center">
                <p className="w-[45%] leading-[4rem] text-[3rem] lg:text-[4rem] text-white text-center font-normal m-auto">
                  Welcome back {pseudo}!
                </p>
              </div>
            )}
            {userNotCleo && (
              <div className="w-[90%] lg:w-[50%] m-auto flex justify-center">
                <p className="w-[100%] leading-[1.2rem] lg:leading-[3rem] text-[1rem] lg:text-[1.5rem] text-white text-center font-normal m-auto">
                  You don't seem to be a CLEO Committee member. If you want to
                  join the Committee, you can purchase a CLEO here:
                  <a
                    target="_blank"
                    className="text-[#ADD8E6] underline"
                    href="https://sentx.io/nft-marketplace/cleo-cards"
                  >
                    https://sentx.io/nft-marketplace/cleo-cards
                  </a>
                </p>
              </div>
            )}
          </dialog>
        )}
      </div>
      <div className=" h-[96%] object-cover right-0 left-0 lg:w-screen z-[130] pointer-events-none  relative overflow-hidden">
        <motion.img
          src="/assets/Cleo_committee_Intro.jpg"
          className="absolute h-[100vh] object-cover right-0 left-0 top-0 lg:w-screen z-[130] pointer-events-none"
        ></motion.img>
        <motion.img
          src="/assets/Cleo_committee_Intro.png"
          className="absolute h-[100vh] object-cover right-0 left-0 top-0 lg:w-screen z-[150] pointer-events-none"
        ></motion.img>
        <div
          id="particle"
          className="absolute h-screen object-cover right-0 left-0 top-0 mx-auto lg:w-screen z-[140] pointer-events-none"
        ></div>
      </div>
    </div>
  );
};

export default Connection;
